import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

const LocaleSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng: string) => {
    localStorage.setItem('locale', lng);
    window.location.reload();
  };

  return (
    <div>
      {i18n?.language === 'de' && (
        <Button onClick={() => changeLanguage('en')}>EN</Button>
      )}
      {i18n?.language === 'en' && (
        <Button onClick={() => changeLanguage('de')}>DE</Button>
      )}
    </div>
  );
};

export default LocaleSwitcher;
