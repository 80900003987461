import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  Link,
  List,
  ReferenceInput,
  TextField,
} from 'react-admin';

import CustomPagination from '../../../CustomPagination';

export const GateControllerTransactionFilter = (props: any) => (
  <Filter {...props}>
    <ReferenceInput source="gateControlerId" reference="GateController" alwaysOn>
      <AutocompleteInput
        label="Gate"
        optionText="gateId"
        style={{ width: 250 }}
      />
    </ReferenceInput>
    <ReferenceInput source="userId" reference="User" alwaysOn>
      <AutocompleteInput
        label="User"
        optionText="email"
        style={{ width: 250 }}
      />
    </ReferenceInput>
    <ReferenceInput source="vehicleId" reference="Vehicle" alwaysOn>
      <AutocompleteInput
        label="Vehicle"
        optionText="vin"
        style={{ width: 250 }}
      />
    </ReferenceInput>
    <ReferenceInput source="bookingId" reference="Booking" alwaysOn>
      <AutocompleteInput
        label="Booking"
        optionText="bookingHash"
        style={{ width: 250 }}
      />
    </ReferenceInput>
  </Filter>
);

export const GateControllerTransactionList = (props: any) => {
  return (
    <List
      {...props}
      exporter={false}
      filters={<GateControllerTransactionFilter />}
      sort={{ field: 'createdAt', order: 'desc' }}
      title="Gate controller transactions"
      pagination={<CustomPagination />}>
      <Datagrid bulkActionButtons={false}>
        <FunctionField
          source="gateController.gateId"
          label="Gate"
          render={(record) =>
            record?.gateController && (
              <Link
                target="_blank"
                to={`/GateController/${record?.gateController?.id}`}>
                {record?.gateController?.gateId}
              </Link>
            )
          }
        />
        <FunctionField
          source="user.email"
          label="User"
          render={(record) =>
            record?.user && (
              <Link target="_blank" to={`/User/${record?.user?.id}`}>
                {record?.user?.email}
              </Link>
            )
          }
        />
        <FunctionField
          source="booking.bookingHash"
          label="Booking"
          render={(record) =>
            record?.booking && (
              <Link target="_blank" to={`/Booking/${record?.booking?.id}/show`}>
                {record?.booking?.bookingHash}
              </Link>
            )
          }
        />
        <FunctionField
          source="vehicle.vin"
          label="Vehicle"
          render={(record) =>
            record?.vehicle && (
              <Link target="_blank" to={`/Vehicle/${record?.vehicle?.id}`}>
                {record?.vehicle?.vin}
              </Link>
            )
          }
        />
        <TextField source="comment" />
        <DateField source="createdAt" showTime locales="de" />
      </Datagrid>
    </List>
  );
};
