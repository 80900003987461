import { VFC, useState } from 'react';

import { Button } from '@mui/material';
import InsuranceRuleSetsDialog from './ruleSetDialog';
import InsuranceRuleSetsTable from './ruleSetTable';
import { VehicleInsuranceRuleSet } from '../../@generated/schemas';
import { useTranslate } from '../../locales';

const InsuranceRuleSets: VFC<{
  vehicleInsuranceTypeId: string;
  vehicleInsuranceRuleSets: VehicleInsuranceRuleSet[];
}> = ({ vehicleInsuranceTypeId, vehicleInsuranceRuleSets }) => {
  const translate = useTranslate();
  const [showDialog, setShowDialog] = useState<
    boolean | (Omit<VehicleInsuranceRuleSet, 'id'> & { id?: string })
  >(false);

  return (
    <>
      <Button
        style={{ left: '90%', marginBottom: 12 }}
        onClick={() => setShowDialog(true)}
        variant="contained">
        {translate('admin.createNewRuleSet')}
      </Button>
      <InsuranceRuleSetsTable
        rulesets={vehicleInsuranceRuleSets}
        setShowDialog={setShowDialog}
      />
      <InsuranceRuleSetsDialog
        showDialog={showDialog}
        vehicleInsuranceTypeId={vehicleInsuranceTypeId}
        setShowDialog={setShowDialog}
      />
    </>
  );
};

export default InsuranceRuleSets;
