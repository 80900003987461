import { SelectInput, useGetList } from 'react-admin';

import { useFormContext } from 'react-hook-form';
import { useTranslate } from '../locales';

const CompanyInput = (props) => {
  const { watch } = useFormContext();
  const { value, onChange, source } = props;
  const { data } = useGetList('Company');

  const selectedValue = watch(source);
  const translate = useTranslate();

  return (
    <SelectInput
      value={value}
      source={source}
      emptyText="Drivemycar"
      placeholder="Drivemycar"
      onChange={onChange}
      label={selectedValue ? translate('admin.company') : 'Drivemycar'}
      choices={data || []}
      translateChoice={false}
      variant="outlined"
      size="small"
      fullWidth
    />
  );
};

export default CompanyInput;
