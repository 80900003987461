import { FC } from 'react';
import { LoadScript } from '@react-google-maps/api';
import { LoadingElement } from '../App';

const GoogleMapWrapper: FC = ({ children }) => {
  return (
    <LoadScript
      libraries={['places']}
      loadingElement={<LoadingElement />}
      googleMapsApiKey="AIzaSyAhXVUipE7tyvWHoRG0vyK8afJVkP1ZMWg">
      <div>{children}</div>
    </LoadScript>
  );
};

export default GoogleMapWrapper;
