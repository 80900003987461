const formattedPrice = (
  value: number,
  showCurrency = true,
  customOptions: Intl.NumberFormatOptions = {},
) => {
  const options: Intl.NumberFormatOptions = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    ...customOptions,
  };

  if (showCurrency) {
    options.currency = 'CHF';
    options.style = 'currency';
  }
  return value.toLocaleString('de-CH', options);
};

export default formattedPrice;
