import { Create, SimpleForm } from 'react-admin';

import { GateControllerForm } from './form';
import { transform } from '../../../common/TranslationInput';

export const GateControllerCreate = () => {
  return (
    <Create
      transform={(data) =>
        transform(transform(data, 'contentBefore'), 'contentAfter')
      }>
      <SimpleForm>
        <GateControllerForm />
      </SimpleForm>
    </Create>
  );
};
