import {
  Box,
  Button,
  Modal,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import MuiAutocomplete, {
  createFilterOptions,
} from '@mui/material/Autocomplete';

import styled from 'styled-components';
import { useCreate } from 'react-admin';
import { useState } from 'react';
import { useTranslate } from '../../locales';

const ModalContent = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  background-color: white;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 12px;
`;

interface UnpublishReasonType {
  inputValue?: string;
  name: string;
  id?: string;
}

const filter = createFilterOptions<UnpublishReasonType>();

export const UnpublishReasonModal = (props) => {
  const translate = useTranslate();
  const [reason, setReason] = useState<UnpublishReasonType | null>(null);

  const [createUnpublishReason] = useCreate();

  const handleSaveReason = async () => {
    if (!reason.id) {
      await createUnpublishReason('UnpublishReasonType', {
        data: { name: reason.name },
      });
    }

    props.onSave(reason.name);
  };

  return (
    <Modal open={props.isOpened} onClose={props.handleClose}>
      <ModalContent>
        <Typography
          id="modal-modal-title"
          variant="h6"
          component="h2"
          style={{ marginBottom: 24 }}>
          {translate('admin.whyVehicleShouldBeDisabled')}
        </Typography>

        <MuiAutocomplete
          fullWidth
          freeSolo
          renderInput={(params) => (
            <MuiTextField
              {...params}
              margin="dense"
              variant="filled"
              label={translate('admin.addReason')}
              helperText=" "
            />
          )}
          onChange={(event, newValue) => {
            if (typeof newValue === 'string') {
              setReason({
                name: newValue,
              });
            } else if (newValue && newValue.inputValue) {
              // Create a new value from the user input
              setReason({
                name: newValue.inputValue,
              });
            } else {
              setReason(newValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);

            const { inputValue } = params;
            const isExisting = options.some(
              (option) => inputValue === option.name,
            );
            if (inputValue !== '' && !isExisting) {
              filtered.push({
                inputValue,
                name: `${translate('admin.add')} "${inputValue}"`,
              });
            }

            return filtered;
          }}
          selectOnFocus
          clearOnBlur
          getOptionLabel={(option) => {
            if (typeof option === 'string') {
              return option;
            }
            if (option.inputValue) {
              return option.inputValue;
            }
            return option.name;
          }}
          value={reason}
          options={props.unpublishReasonTypes || ([] as UnpublishReasonType[])}
        />

        <Button
          onClick={props.handleClose}
          style={{ float: 'left', marginTop: 12 }}>
          {translate('admin.close')}
        </Button>

        <Button
          variant="contained"
          disabled={!reason}
          onClick={handleSaveReason}
          style={{ float: 'right', marginTop: 12 }}>
          {translate('admin.saveReason')}
        </Button>
      </ModalContent>
    </Modal>
  );
};
