import { FC } from 'react';
import TranslateInput from '../../common/TranslationInput';

type InsuranceContentProps = {
  source?: string;
};
const InsuranceContent: FC<InsuranceContentProps> = ({
  source = 'content',
}) => {
  return <TranslateInput source={source} useRich hideName />;
};

export default InsuranceContent;
