import {
  GateControllerRuleProperty,
  GateControllerRuleSet,
} from '../../../@generated/schemas';

import CompareSelectOptions from '../../../libs/compareSelectOptions';
import dayjs from 'dayjs';

export const getValueByForProperty = (
  ruleSet: GateControllerRuleSet,
  property: GateControllerRuleProperty,
) => {
  const rule = ruleSet?.rules?.find((rule) => rule.property === property);

  if (!rule) {
    return null;
  }

  const findOperatorFrom = CompareSelectOptions.find(
    (option) => option.id === rule.fromCompareOperator,
  );
  const findOperatorTo = CompareSelectOptions.find(
    (option) => option.id === rule.toCompareOperator,
  );

  if (
    [
      GateControllerRuleProperty.BeforeBooking,
      GateControllerRuleProperty.AfterBooking,
    ].includes(property)
  ) {
    return rule.fromValue
      ? dayjs().startOf('day').add(rule.fromValue, 'minutes').format('HH:mm')
      : '00:00';
  }

  if (property === GateControllerRuleProperty.TimeRange) {
    const timeFrom = rule.fromValue
      ? dayjs().startOf('day').add(rule.fromValue, 'minutes').format('HH:mm')
      : '00:00';
    const timeTo = rule.toValue
      ? dayjs().startOf('day').add(rule.toValue, 'minutes').format('HH:mm')
      : '00:00';

    return `${findOperatorFrom?.name} ${timeFrom} ${
      findOperatorTo?.name || ''
    } ${timeTo || ''}`;
  }

  return `${findOperatorFrom?.name} ${rule.fromValue} ${
    findOperatorTo?.name ?? ''
  } ${rule.toValue ?? ''}`;
};
