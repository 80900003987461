import {
  useCreate,
  useDelete,
  useNotify,
  useRecordContext,
} from 'react-admin';

import { VehicleInsuranceType } from '../../../../@generated/schemas';
import { useFormContext } from 'react-hook-form';

export const useSelectOwnInsurance = (
  vehicleInsuranceTypeValue: VehicleInsuranceType,
) => {
  const record = useRecordContext();
  const { setValue } = useFormContext();
  const notify = useNotify();

  const [createOne] = useCreate();
  const [deleteOne] = useDelete();
  // const [updateOne] = useUpdate();

  return async (event) => {
    // ownInsurance
    if (event.target.checked) {
      const created = await createOne(
        'VehicleInsuranceType',
        {
          data: {
            name: `Own insurance for ${record.plateNumber}`,
            isPrivate: true,
          },
        },
        { returnPromise: true },
      );

      // await updateOne(
      //   'Vehicle',
      //   {
      //     id: record.id,
      //     data: {
      //       disabled: true,
      //       ownInsurance: event.target.checked,
      //       vehicleInsuranceType: created.id,
      //     },
      //   },
      //   { returnPromise: true },
      // );

      setValue('ownInsurance', event.target.checked);
      setValue('vehicleInsuranceType', created);
      setValue('disabled', true);

      notify('Please adjust own insurance details', { type: 'warning' });
    } else {
      if (vehicleInsuranceTypeValue?.isPrivate) {
        await deleteOne('VehicleInsuranceType', {
          id: vehicleInsuranceTypeValue?.id,
        });
      }

      setValue('ownInsurance', event.target.checked);
      setValue('vehicleInsuranceType', null);
      setValue('disabled', true);
      // await updateOne(
      //   'Vehicle',
      //   {
      //     id: record.id,
      //     data: {
      //       ownInsurance: event.target.checked,
      //       vehicleInsuranceType: null,
      //       disabled: true,
      //     },
      //   },
      //   { returnPromise: true },
      // );
      notify('Please select insurance', { type: 'warning' });
    }
  };
};
