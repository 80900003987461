import {
  RuleProperty,
  VehicleInsuranceRuleSet,
} from '../../@generated/schemas';

import CompareSelectOptions from '../../libs/compareSelectOptions';
import dayjs from 'dayjs';
import formattedPrice from '../../libs/formattedPrice';
import humanizeDuration from 'humanize-duration';

export const getValueByForProperty = (
  ruleset: VehicleInsuranceRuleSet,
  property: RuleProperty,
) => {
  const rule = ruleset?.vehicleInsuranceRules?.find(
    (rule) => rule.property === property,
  );

  if (!rule) {
    return null;
  }

  const findOperatorFrom = CompareSelectOptions.find(
    (option) => option.id === rule.fromCompareOperator,
  );
  const findOperatorTo = CompareSelectOptions.find(
    (option) => option.id === rule.toCompareOperator,
  );

  if (property === RuleProperty.CatalogPrice) {
    return `${findOperatorFrom?.name} ${formattedPrice(rule.fromValue)} ${
      findOperatorTo?.name ?? ''
    } ${rule.toValue ? formattedPrice(rule.toValue) : ''}`;
  }

  if (property === RuleProperty.BookingDuration) {
    const rentalsFrom =
      rule.fromValue &&
      humanizeDuration(
        dayjs.duration(rule.fromValue, 'hours').asMilliseconds(),
        {
          units: ['d', 'h'],
        },
      );
    const rentalsTo =
      rule.toValue &&
      humanizeDuration(dayjs.duration(rule.toValue, 'hours').asMilliseconds(), {
        units: ['d', 'h'],
      });

    return `${findOperatorFrom?.name} ${rentalsFrom} ${
      findOperatorTo?.name || ''
    } ${rentalsTo || ''}`;
  }

  return `${findOperatorFrom?.name} ${rule.fromValue} ${
    findOperatorTo?.name ?? ''
  } ${rule.toValue ?? ''}`;
};
