import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField as MuiTextField,
  TextField,
  Typography,
} from '@mui/material';

import AdapterDayjs from '@mui/lab/AdapterDayjs';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import CloseIcon from '@mui/icons-material/Close';
import { DateTimePicker } from '@mui/lab';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import SearchIcon from '@mui/icons-material/Search';
import deLocale from 'date-fns/locale/de';
import styled from 'styled-components';
import { useTranslate } from '../../../locales';

const ModalContent = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  background-color: white;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 24px;
`;

const FormRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const BookingDetailModal = (props: any) => {
  const { bookingDetail, onClose } = props;
  const translate = useTranslate();

  return (
    <Modal
      open={!!bookingDetail}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <ModalContent>
        <IconButton
          style={{ position: 'absolute', right: 18 }}
          onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <LocalizationProvider locale={deLocale} dateAdapter={AdapterDayjs}>
          <Typography variant="h5" style={{ marginBottom: 20 }}>
            {translate('admin.bookingDetail')}: {bookingDetail?.bookingHash}
          </Typography>

          <FormRow style={{ marginBottom: 20 }}>
            <DateTimePicker
              renderInput={(props) => (
                <TextField
                  {...props}
                  variant="outlined"
                  size="small"
                  style={{ flex: 1, marginRight: 20 }}
                />
              )}
              disabled
              inputFormat="DD.MM.YYYY HH:mm"
              ampm={false}
              label={translate('admin.startDateTime')}
              value={bookingDetail?.startDate}
              onChange={() => undefined}
            />
            <DateTimePicker
              renderInput={(props) => (
                <TextField
                  {...props}
                  variant="outlined"
                  size="small"
                  style={{ flex: 1, marginRight: 20 }}
                />
              )}
              ampm={false}
              disabled
              inputFormat="DD.MM.YYYY HH:mm"
              label={translate('admin.endDateTime')}
              value={bookingDetail?.endDate}
              onChange={() => undefined}
            />
          </FormRow>
          <FormRow style={{ marginBottom: 20 }}>
            <DateTimePicker
              renderInput={(props) => (
                <TextField
                  {...props}
                  variant="outlined"
                  size="small"
                  style={{ flex: 1, marginRight: 20 }}
                />
              )}
              disabled
              inputFormat="DD.MM.YYYY HH:mm"
              ampm={false}
              label={translate('admin.effectiveStartDateTime')}
              value={bookingDetail?.effectiveStartDate}
              onChange={() => undefined}
            />
            <DateTimePicker
              renderInput={(props) => (
                <TextField
                  {...props}
                  variant="outlined"
                  size="small"
                  style={{ flex: 1, marginRight: 20 }}
                />
              )}
              ampm={false}
              disabled
              inputFormat="DD.MM.YYYY HH:mm"
              label={translate('admin.effectiveEndDateTime')}
              value={bookingDetail?.effectiveEndDate}
              onChange={() => undefined}
            />
          </FormRow>

          <FormRow style={{ marginBottom: 20 }}>
            <MuiTextField
              defaultValue={bookingDetail?.user?.email}
              disabled
              style={{ width: '50%', marginRight: 20 }}
              variant="outlined"
              size="small"
              label={translate('admin.user')}
            />
          </FormRow>

          <a
            href={`/#/User/${bookingDetail?.user?.id}`}
            target="_blank"
            style={{ textDecoration: 'none' }}>
            <Button variant="contained" startIcon={<AssignmentIndIcon />}>
              {translate('admin.userDetail')}
            </Button>
          </a>
          <a
            href={`/#/Booking/${bookingDetail?.id}/show`}
            target="_blank"
            style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              startIcon={<SearchIcon />}
              style={{ float: 'right' }}>
              {translate('admin.bookingDetail')}
            </Button>
          </a>
        </LocalizationProvider>
      </ModalContent>
    </Modal>
  );
};
