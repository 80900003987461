import { Button, CircularProgress, Grid, Typography } from '@mui/material';
import {
  CalculationTariffItem,
  ChargingStationTransaction,
} from '../../../@generated/schemas';
import {
  DateField,
  FunctionField,
  Link,
  Show,
  SimpleShowLayout,
  TextField,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { isNil, sortBy } from 'lodash';
import {
  useFinishTransactionSessionMutation,
  useGetTransactionCalculationQuery,
  useStartTransactionSessionMutation,
  useStopTransactionSessionMutation,
} from 'src/@generated/hooks';

import dayjs from 'dayjs';
import formattedPrice from '../../../libs/formattedPrice';
import styled from 'styled-components';
import { useTranslate } from '../../../locales';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TransactionTitle = () => {
  const translate = useTranslate();
  const record = useRecordContext<ChargingStationTransaction>();
  return (
    <span>
      {translate('admin.transaction')}: {record?.transactionId} (
      {record?.chargingStationRfid ? translate('admin.rfidFlow') : translate('admin.qrFlow')})
    </span>
  );
};

const TransactionOverviewPriceRow: React.VFC<{
  amount?: number | string;
  duration?: number | string;
  bold?: boolean;
  title: string;
  price?: number;
  description?: string;
}> = ({ amount, price, duration, title, bold, description }) => {
  const style: React.CSSProperties = bold ? { fontWeight: 'bold' } : {};
  return (
    <>
      <Row style={{ marginTop: 4 }}>
        <Typography
          fontSize={12}
          style={{ ...style, flex: 2, whiteSpace: 'nowrap' }}>
          <div>{isNil(amount) ? '' : amount}</div>
          <div>{isNil(duration) ? '' : duration}</div>
        </Typography>
        <Typography fontSize={12} style={{ ...style, flex: 4 }}>
          {title}
          {description && (
            <Typography
              fontSize={12}
              fontStyle="italic"
              style={{ ...style, flex: 4 }}>
              {description}
            </Typography>
          )}
        </Typography>
        <Typography
          fontSize={12}
          style={{ ...style, flex: 3, textAlign: 'right' }}>
          {price !== undefined ? formattedPrice(price) : ''}
        </Typography>
      </Row>
    </>
  );
};

export const TransactionPriceOverview = ({
  tariffs,
  totalCost,
  totalkWh,
  totalMinutes,
  loading,
}: {
  tariffs: CalculationTariffItem[];
  loading: boolean;
  totalCost: number;
  totalMinutes: number;
  totalkWh: number;
}) => {
  const translate = useTranslate();
  const record = useRecordContext<ChargingStationTransaction>();
  const lastUsage = sortBy(
    record?.chargingStationTransactionUsages || [],
    'createdAt',
  ).reverse()?.[0];

  return (
    <div>
      {loading && (
        <div
          style={{
            height: 150,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <CircularProgress />
        </div>
      )}

      {!loading && (
        <div>
          {tariffs.map((tariff, key) => {
            const name =
              JSON.parse(tariff.name || '{}')?.en ||
              JSON.parse(tariff.name || '{}')?.de ||
              JSON.parse(tariff.name || '{}')?.it ||
              JSON.parse(tariff.name || '{}')?.fr;
            const duration = dayjs(tariff.endDate).diff(
              dayjs(tariff.startDate),
              'minute',
            );

            return (
              <TransactionOverviewPriceRow
                key={key}
                amount={`${tariff.usedkWh || 0} kWh (${tariff.percentage}%)`}
                duration={`${duration} min`}
                title={`${name}, ${formattedPrice(
                  tariff.price,
                )}/kWh, ${formattedPrice(tariff.amountPerMinute || 0)}/min`}
                price={
                  (tariff.price * tariff.usedkWh || 0) +
                  (duration * tariff.amountPerMinute || 0)
                }
                description={`${dayjs(tariff.startDate).format(
                  'DD.MM. YYYY HH:mm',
                )} - ${dayjs(tariff.endDate).format('DD.MM. YYYY HH:mm')}`}
              />
            );
          })}

          <TransactionOverviewPriceRow
            key="totalKwh"
            title={translate('admin.totalKWh')}
            amount={`${formattedPrice(
              totalkWh || lastUsage?.kwh || 0,
              false,
            )} kWh`}
            bold
          />

          <TransactionOverviewPriceRow
            key="totalMinutes"
            title={translate('admin.totalMinutes')}
            amount={`${totalMinutes} minutes`}
            bold
          />

          <TransactionOverviewPriceRow
            key="totalCost"
            title={translate('admin.totalCost')}
            price={totalCost}
            bold
          />

          <Typography fontSize={12} style={{ marginTop: 12 }}>
            {translate('admin.included')}. CHF{' '}
            {Number(
              (totalCost / Number(100 + record.vat)) * record.vat,
            ).toFixed(2)}{' '}
            ({record.vat}% VAT {translate('admin.from')} CHF {Number(totalCost).toFixed(2)})
          </Typography>
        </div>
      )}
    </div>
  );
};

const TransactionStation = () => {
  const translate = useTranslate();
  return (
    <Grid container spacing={8}>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>{translate('admin.chargingStation')}</Typography>
          <FunctionField
            label={translate('admin.eve')}
            render={(row) => {
              return (
                <span>
                  <Link
                    target="_blank"
                    to={`/ChargingStation/${row?.chargingStation?.id}`}>
                    {JSON.parse(row.chargingStation.name || '{}')?.en ||
                      JSON.parse(row.chargingStation.name || '{}')?.de ||
                      JSON.parse(row.chargingStation.name || '{}')?.it ||
                      JSON.parse(row.chargingStation.name || '{}')?.fr}
                  </Link>{' '}
                  ({row.chargingStation.evesId}, {row.chargingStation.status})
                </span>
              );
            }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography> </Typography>
          <FunctionField
            label={translate('admin.rfid')}
            render={(row) => {
              return row.chargingStationRfid ? (
                <div>
                  {translate('admin.tag')}: {row.chargingStationRfid.tag} {row.chargingStationRfid.originalTag ? `(Original: ${row.chargingStationRfid.originalTag})` : ''}<br />
                  {translate('admin.description')}: {row.chargingStationRfid.description}<br />
                  {translate('admin.billingAccount')}: {row.chargingStationRfid.billingAccount?.name}<br />
                </div>
              ) : '-';
            }}
          />
        </SimpleShowLayout>
      </Grid>
    </Grid>
  );
};

const TransactionDetail = () => {
  const translate = useTranslate();
  return (
    <Grid container spacing={8}>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>{translate('admin.transaction')}</Typography>
          <TextField source="transactionId" label={translate('admin.transactionId')} />
          <DateField
            source="startDate"
            label={translate('admin.transactionStartAt')}
            showTime
            locales="de"
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <FunctionField
            label={translate('admin.paymentHash')}
            render={(row) => row?.paymentHash || <div>&nbsp;</div>}
          />
          <DateField
            source="endDate"
            label={translate('admin.transactionEndAt')}
            locales="de-DE"
            showTime
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <TextField label={translate('admin.transactionStatus')} source="lastStatus" />
          <DateField
            source="createdAt"
            label={translate('admin.createdAt')}
            locales="de-DE"
            showTime
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
      <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <TextField label={translate('admin.transactionAuth')} source="auth" />
        </SimpleShowLayout>
      </Grid>
    </Grid>
  );
};

const TransactionUser = () => {
  const translate = useTranslate();
  return (
    <Grid container spacing={8}>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>{translate('admin.user')}</Typography>
          <TextField source="user.email" label={translate('admin.emailField')} />
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <TextField source="user.firstName" label={translate('admin.firstName')} />
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <TextField source="user.lastName" label={translate('admin.lastName')} />
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <TextField source="user.phone" label={translate('admin.phone')} />
        </SimpleShowLayout>
      </Grid>
    </Grid>
  );
};

const TransactionRFID = () => {
  const translate = useTranslate();
  return (
    <Grid container spacing={8}>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>{translate('admin.rfid')}</Typography>
          <TextField source="chargingStationRfid.tag" label={translate('admin.tag')} />
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <TextField
            label={translate('admin.billingAccount')}
            source="chargingStationRfid.billingAccount.name"
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <TextField
            label={translate('admin.emailField')}
            source="chargingStationRfid.billingAccount.contactPersonEmail"
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <TextField
            label={translate('admin.phone')}
            source="chargingStationRfid.billingAccount.contactPersonPhoneNumber"
          />
        </SimpleShowLayout>
      </Grid>
    </Grid>
  );
};

const TransactionCostSummary = () => {
  const translate = useTranslate();
  const record = useRecordContext<ChargingStationTransaction>();

  const { data, loading } = useGetTransactionCalculationQuery({
    variables: {
      transactionId: record?.id,
    },
    fetchPolicy: 'no-cache',
    skip: !record?.id,
  });

  return (
    <div>
      <Grid container spacing={8}>
        <Grid item md={4}>
          <SimpleShowLayout>
            <Typography>{translate('admin.costSummary')}</Typography>
            <FunctionField
              render={() => (
                <TransactionPriceOverview
                  loading={loading}
                  tariffs={
                    (data?.getTransactionCalculation?.tariffs ||
                      []) as CalculationTariffItem[]
                  }
                  totalCost={data?.getTransactionCalculation?.totalCost || 0}
                  totalkWh={data?.getTransactionCalculation?.totalkWh || 0}
                  totalMinutes={data?.getTransactionCalculation?.totalMinutes || 0}
                />
              )}
            />
          </SimpleShowLayout>
        </Grid>
        {/* <Grid item md={4}>
          <SimpleShowLayout>
            <Typography>&nbsp;</Typography>
            <FunctionField
              label="Stripe authorizations"
              render={() =>
                props?.stripePaymentCreateAt &&
                dayjs(props?.stripePaymentCreateAt).format('DD.MM. YYYY HH:mm')
              }
            />
          </SimpleShowLayout>
        </Grid> */}
      </Grid>
    </div>
  );
};

export const TransactionShow = (props: any) => {
  return (
    <Show
      title={<TransactionTitle />}
      className="transaction-detail"
      {...props}>
      <TransactionShowContent />
    </Show>
  );
};

const StartButton = () => {
  const translate = useTranslate();
  const record = useRecordContext();
  const refreshData = useRefresh();
  const [startTransaction] = useStartTransactionSessionMutation();

  const handleStartPower = async () => {
    await startTransaction({
      variables: {
        transactionId: record.id as string,
        userId: record.userId,
      },
    });
    refreshData();
  };

  return (
    <Button
      disabled={record.finishAt}
      variant="contained"
      color="primary"
      style={{ marginRight: 16 }}
      onClick={handleStartPower}>
      {translate('admin.startPower')}
    </Button>
  );
};

const StopButton = () => {
  const translate = useTranslate();
  const record = useRecordContext();
  const refreshData = useRefresh();
  const [stopTransaction] = useStopTransactionSessionMutation();

  const handleStopPower = async () => {
    await stopTransaction({
      variables: {
        transactionId: record.id as string,
        userId: record.userId,
      },
    });
    refreshData();
  };

  return (
    <Button
      disabled={record.finishAt}
      variant="contained"
      color="warning"
      style={{ marginRight: 16 }}
      onClick={handleStopPower}>
      {translate('admin.stopPower')}
    </Button>
  );
};

const FinishButton = () => {
  const translate = useTranslate();
  const record = useRecordContext();
  const refreshData = useRefresh();
  const [finishTransaction] = useFinishTransactionSessionMutation();

  const handleFinishSession = async () => {
    await finishTransaction({
      variables: {
        transactionId: record.id as string,
        userId: record.user_id,
      },
    });
    refreshData();
  };

  return (
    <Button
      disabled={record.finishAt}
      variant="contained"
      color="warning"
      style={{ marginRight: 16 }}
      onClick={handleFinishSession}>
      {translate('admin.finishSession')}
    </Button>
  );
};

export const TransactionShowContent = () => {
  const translate = useTranslate();
  const record = useRecordContext<ChargingStationTransaction>();

  return (
    <div>
      <TransactionDetail />

      <hr />

      <TransactionStation />

      {record?.user && (
        <>
          <hr />

          <TransactionUser />
        </>
      )}

      {record?.chargingStationRfid && (
        <>
          <hr />

          <TransactionRFID />
        </>
      )}

      <hr />

      <TransactionCostSummary />

      <SimpleShowLayout>
        <Row style={{ justifyContent: 'center' }}>
          <Button
            onClick={() => window.history.back()}
            style={{ marginRight: 16 }}>
            {translate('admin.back')}
          </Button>
          <StartButton />
          <StopButton />
          <FinishButton />
        </Row>
      </SimpleShowLayout>
    </div>
  );
};
