import { FormGroup, Grid } from '@mui/material';
import { ReferenceInput, minLength, useGetIdentity } from 'react-admin';

import DateInput from '../../../common/DateInput';
import MultipleTypeInput from '../../../common/MultipleTypeInput';
import NumberInput from '../../../common/NumberInput';
import SelectInput from '../../../common/SelectInput';
import TextInput from '../../../common/TextInput';
import TypeInput from '../../../common/TypeInput';
import UserInput from '../../../common/UserInput';
import { UserRole } from '../../../@generated/schemas';
import { omit } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { useTranslate } from '../../../locales';

export const requiredFields = [
  'firstName',
  'lastName',
  'address',
  'houseNumber',
  'postCode',
  'city',
  'country',
  'insurance',
  'plateNumber',
  'shieldColorTypeId',
  'vehicleKindTypeId',
  'brandTypeId',
  'modelTypeId',
  'vin',
  'bodyTypeId',
  'colorTypeId',
  'seatTypeId',
  'seatFrontTypeId',
  'curbWeight',
  'rootNumber',
  'payloadWeight',
  'approvalType',
  'grossWeight',
  'capacity',
  'power',
  'commissioningAt',
  'departmentEmissionCode',
];

const VehicleBasicDetails = (props) => {
  const { options } = props;
  const { identity } = useGetIdentity();
  const { setValue, watch } = useFormContext();
  const translate = useTranslate();

  const onChangeVin = (event) => {
    setValue('vin', event.target.value.replace('o', '0').replace('O', '0'));
  };

  const onBlurRootNumber = (event) => {
    setValue(
      'rootNumber',
      event.target.value
        .replaceAll('.', '')
        .replace(/.{3}/g, '$&.')
        .replace(/\.$/, ''),
    );
  };

  const handleSelectCompany = async () => {
    setValue('firstName', null);
    setValue('lastName', null);
    setValue('address', null);
    setValue('houseNumber', null);
    setValue('city', null);
    setValue('country', null);
    setValue('birthdate', null);
    setValue('postCode', null);
    setValue('userId', null);
    setValue('user', null);
  };

  let userRequiredFields = requiredFields;
  const watchData = watch();
  if (watchData.companyId) {
    userRequiredFields = Object.values(
      omit(requiredFields, [
        'firstName',
        'lastName',
        'address',
        'houseNumber',
        'postCode',
        'city',
        'birthdate',
        'country',
      ]),
    );
  }

  return (
    <Grid container spacing={8}>
      <Grid item md={6}>
        <ReferenceInput reference="Company" source="companyId">
          <SelectInput
            fullWidth
            label={translate('admin.company')}
            readOnly={identity?.userRole !== UserRole.SuperAdmin}
            optionText="name"
            onChange={handleSelectCompany}
          />
        </ReferenceInput>
        <hr />
        <UserInput readOnly={identity?.userRole !== UserRole.SuperAdmin} />
        {watchData.userId ? (
          <div>
            <FormGroup row>
              <TextInput
                source="lastName"
                requiredFieldsList={userRequiredFields}
                label={translate('admin.vehicleBasicInfo.lastName')}
                style={{ marginRight: 8 }}
              />
              <TextInput
                source="firstName"
                requiredFieldsList={userRequiredFields}
                label={translate('admin.vehicleBasicInfo.firstName')}
              />
            </FormGroup>
            <FormGroup row>
              <TextInput
                requiredFieldsList={userRequiredFields}
                source="address"
                label={translate('admin.vehicleBasicInfo.address')}
                style={{ marginRight: 8 }}
              />
              <TextInput
                requiredFieldsList={userRequiredFields}
                source="houseNumber"
                label={translate('admin.vehicleBasicInfo.houseNumber')}
              />
            </FormGroup>
            <FormGroup row>
              <TextInput
                requiredFieldsList={userRequiredFields}
                source="postCode"
                label={translate('admin.vehicleBasicInfo.postCode')}
                style={{ marginRight: 8 }}
              />
              <TextInput
                requiredFieldsList={userRequiredFields}
                source="city"
                label={translate('admin.vehicleBasicInfo.city')}
              />
            </FormGroup>
            <FormGroup row>
              <DateInput
                source="birthdate"
                label={translate('admin.vehicleBasicInfo.birthdate')}
                variant="outlined"
                requiredFieldsList={userRequiredFields}
                style={{ marginRight: 8, flex: 1 }}
              />
              <TextInput
                requiredFieldsList={userRequiredFields}
                source="country"
                label={translate('admin.vehicleBasicInfo.country')}
              />
            </FormGroup>
          </div>
        ) : null}
        <TextInput
          requiredFieldsList={requiredFields}
          source="insurance"
          multiline
          style={{ width: '100%' }}
          label={translate('admin.vehicleBasicInfo.insurance')}
          component="pre"
        />
        <TextInput
          source="cantonalNotes"
          multiline
          requiredFieldsList={requiredFields}
          style={{ width: '100%' }}
          label={translate('admin.vehicleBasicInfo.cantonalNotes')}
          component="pre"
        />
        <TextInput
          source="decreesAuthority"
          multiline
          requiredFieldsList={requiredFields}
          style={{ width: '100%' }}
          label={translate('admin.vehicleBasicInfo.decreesAuthority')}
          component="pre"
        />
      </Grid>
      <Grid item md={6}>
        <FormGroup row>
          <TextInput
            requiredFieldsList={requiredFields}
            source="plateNumber"
            label={translate('admin.vehicleBasicInfo.plateNumber')}
            style={{ marginRight: 8 }}
          />
          <TypeInput
            requiredFieldsList={requiredFields}
            source="shieldColorTypeId"
            data={options?.shieldColorTypes}
            reference="ShieldColorType"
            label={translate('admin.vehicleBasicInfo.plateColor')}
          />
        </FormGroup>
        <TypeInput
          source="particularUseTypeId"
          requiredFieldsList={requiredFields}
          data={options?.particularUseTypes}
          label={translate('admin.vehicleBasicInfo.particularUse')}
          reference="ParticularUseType"
          style={{ width: '100%' }}
        />
        <FormGroup row>
          <TypeInput
            requiredFieldsList={requiredFields}
            source="vehicleKindTypeId"
            data={options?.vehicleKindTypes}
            reference="VehicleKindType"
            label={translate('admin.vehicleBasicInfo.vehicleKind')}
            style={{ marginRight: 8 }}
          />
          <TextInput
            requiredFieldsList={requiredFields}
            source="vehicleCode"
            label={translate('admin.vehicleBasicInfo.code')}
          />
        </FormGroup>
        <FormGroup row>
          <TypeInput
            requiredFieldsList={requiredFields}
            source="brandTypeId"
            data={options?.brandTypes}
            reference="BrandType"
            label={translate('admin.vehicleBasicInfo.brand')}
            style={{ marginRight: 8 }}
          />
          <TypeInput
            requiredFieldsList={requiredFields}
            source="modelTypeId"
            data={options?.modelTypes}
            reference="ModelType"
            label={translate('admin.vehicleBasicInfo.model')}
          />
        </FormGroup>
        <TextInput
          source="vin"
          label={translate('admin.vehicleBasicInfo.vin')}
          requiredFieldsList={requiredFields}
          onChange={onChangeVin}
          style={{ width: '100%' }}
        />
        <TypeInput
          requiredFieldsList={requiredFields}
          source="bodyTypeId"
          data={options?.bodyTypes}
          reference="BodyType"
          label={translate('admin.vehicleBasicInfo.bodyType')}
          style={{ width: '50%' }}
        />
        <TypeInput
          requiredFieldsList={requiredFields}
          source="colorTypeId"
          data={options?.colorTypes}
          reference="ColorType"
          label={translate('admin.vehicleBasicInfo.color')}
          style={{ width: '100%' }}
        />
        <FormGroup row>
          <FormGroup row style={{ marginRight: 8 }}>
            <TypeInput
              requiredFieldsList={requiredFields}
              source="seatTypeId"
              data={options?.seatTypes}
              reference="SeatType"
              label={translate('admin.vehicleBasicInfo.seats')}
              style={{ marginRight: 8 }}
            />
            <MultipleTypeInput
              requiredFieldsList={requiredFields}
              source="childSeatTypes_ids"
              data={options?.childSeatTypes}
              reference="ChildSeatType"
              label={translate('admin.vehicleBasicInfo.childSeats')}
              style={{ marginRight: 8 }}
            />
            <TypeInput
              requiredFieldsList={requiredFields}
              source="seatFrontTypeId"
              data={options?.seatFrontTypes}
              reference="SeatFrontType"
              label={translate('admin.vehicleBasicInfo.frontSeats')}
            />
          </FormGroup>
          <NumberInput
            requiredFieldsList={requiredFields}
            source="curbWeight"
            label={translate('admin.vehicleBasicInfo.curbWeight')}
          />
        </FormGroup>
        <FormGroup row>
          <TextInput
            requiredFieldsList={requiredFields}
            source="rootNumber"
            label={translate('admin.vehicleBasicInfo.roofNumber')}
            onBlur={onBlurRootNumber}
            style={{ marginRight: 8 }}
          />
          <NumberInput
            requiredFieldsList={requiredFields}
            source="payloadWeight"
            label={translate('admin.vehicleBasicInfo.payloadWeight')}
          />
        </FormGroup>
        <FormGroup row>
          <TextInput
            validate={minLength(1)}
            requiredFieldsList={requiredFields}
            source="approvalType"
            label={translate('admin.vehicleBasicInfo.approvalType')}
            style={{ marginRight: 8 }}
          />
          <NumberInput
            requiredFieldsList={requiredFields}
            source="grossWeight"
            label={translate('admin.vehicleBasicInfo.grossWeight')}
          />
        </FormGroup>
        <FormGroup row>
          <NumberInput
            requiredFieldsList={requiredFields}
            source="capacity"
            label={translate('admin.vehicleBasicInfo.capacity')}
            style={{ marginRight: 8 }}
          />
          <NumberInput source="trainWeight" label={translate('admin.vehicleBasicInfo.trainWeight')} />
        </FormGroup>
        <FormGroup row>
          <NumberInput
            requiredFieldsList={requiredFields}
            source="power"
            label={translate('admin.vehicleBasicInfo.power')}
            style={{ marginRight: 8 }}
          />
          <NumberInput source="towingWeight" label={translate('admin.vehicleBasicInfo.towingCapacity')} />
        </FormGroup>
        <FormGroup row>
          <NumberInput
            source="capacityPerKg"
            requiredFieldsList={requiredFields}
            label={translate('admin.vehicleBasicInfo.powerPerKg')}
            style={{ marginRight: 8 }}
          />
          <NumberInput
            source="roofLoadWeight"
            requiredFieldsList={requiredFields}
            label={translate('admin.vehicleBasicInfo.roofLoadWeight')}
          />
        </FormGroup>
        <FormGroup row>
          <DateInput
            requiredFieldsList={requiredFields}
            source="commissioningAt"
            label={translate('admin.vehicleBasicInfo.commissioningDate')}
            variant="outlined"
            style={{ marginRight: 8, flex: 1 }}
          />
          <TextInput
            requiredFieldsList={requiredFields}
            source="departmentEmissionCode"
            label={translate('admin.vehicleBasicInfo.departmentEmissionCode')}
          />
        </FormGroup>
        <DateInput
          requiredFieldsList={requiredFields}
          source="testedAt"
          label={translate('admin.vehicleBasicInfo.testedAt')}
          variant="outlined"
          style={{ width: '50%' }}
        />
      </Grid>
    </Grid>
  );
};

export default VehicleBasicDetails;
