import './App.css';

import * as RevokeList from './modules/listManagement/revoke';
import * as TranslateType from './modules/listManagement/translateType';

import { Admin, CustomRoutes, Login, Resource } from 'react-admin';
import {
  BillingAccount,
  BillingAccountList,
} from './modules/chargingStations/billingAccounts/list';
import {
  BillingAccountCreate,
  BillingAccountEdit,
} from './modules/chargingStations/billingAccounts/crud';
import { Booking, BookingList } from './modules/bookings/list';
import {
  ChargingStation,
  ChargingStationTariff,
} from './modules/chargingStations/eves/fragments';
import {
  ChargingStationCluster,
  ChargingStationClusterList,
} from './modules/chargingStations/eveClusters/list';
import {
  ChargingStationRfidInvisia,
  ChargingStationRfidList,
} from './modules/chargingStations/rfids/list';
import {
  CompanyCreate,
  CompanyEdit,
  CompanyList,
} from './modules/company/company';
import {
  CompanyVehicleGroupCreate,
  CompanyVehicleGroupEdit,
  CompanyVehicleGroupList,
} from './modules/company/companyVehicleGroup';
import {
  GateController,
  GateControllerRuleSet,
} from './modules/gateControllers/installations/fragments';
import {
  InsuranceCreate,
  InsuranceEdit,
  InsuranceList,
} from './modules/insurance';
import { Message, MessagesList } from './modules/messages/messages';
import {
  PxlVisionManualVerification,
  PxlVisionVerificationList,
} from './modules/pxlVisionVerification';
import {
  SharedVehicleGroup,
  SharedVehicleGroupList,
  SharedVehicleGroupRelVehicle,
} from './modules/sharedVehicleGroup/list';
import { Vehicle, VehicleList } from './modules/vehicles/list';
import { VehicleAdd, VehicleEdit } from './modules/vehicles/crud';
import {
  VehicleCloudBoxxLog,
  VehicleCloudboxxLogs,
} from './modules/vehicles/cloudboxxLogs';
import {
  VehicleDamage,
  VehicleDamageEdit,
  VehicleDamageList,
} from './modules/vehicleDamages/vehicleDamages';
import {
  VehicleReport,
  VehicleReportList,
} from './modules/vehicles/reports/list';
import { combineReducers, createStore } from 'redux';
import { useEffect, useState } from 'react';

import { BookingCreate } from './modules/bookings/create';
import { BookingRequest } from './modules/bookings/bookingRequestFragment';
import { BookingScheduler } from './modules/bookings/scheduler';
import { BookingShow } from './modules/bookings/show';
import ChangeMyPassword from './modules/users/changeMyPassword';
import { ChargingStationClusterCreate } from './modules/chargingStations/eveClusters/create';
import { ChargingStationClusterEdit } from './modules/chargingStations/eveClusters/edit';
import { ChargingStationCreate } from './modules/chargingStations/eves/create';
import { ChargingStationEdit } from './modules/chargingStations/eves/edit';
import { ChargingStationList } from './modules/chargingStations/eves/list';
import { ChargingStationReportList } from './modules/chargingStations/reports/list';
import { ChargingStationTransaction } from './modules/chargingStations/transactions/fragments';
import { ChargingStationTransactionList } from './modules/chargingStations/transactions/list';
import { CircularProgress } from '@mui/material';
import CustomLayout from './CustomLayout';
import CustomTheme from './CustomTheme';
import { GateControllerCreate } from './modules/gateControllers/installations/create';
import { GateControllerEdit } from './modules/gateControllers/installations/edit';
import { GateControllerTransaction } from './modules/gateControllers/transactions/fragments';
import { GateControllerTransactionList } from './modules/gateControllers/transactions/list';
import { GateControllersList } from './modules/gateControllers/installations/list';
import LoginForm from './modules/login';
import { Options } from '@ra-data-prisma/dataprovider';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { SharedVehicleGroupCreate } from './modules/sharedVehicleGroup/create';
import { SharedVehicleGroupEdit } from './modules/sharedVehicleGroup/edit';
import { TransactionShow } from './modules/chargingStations/transactions/show';
import { User } from './modules/users/fragment';
import { UserCreate } from './modules/users/create';
import { UserEdit } from './modules/users/edit';
import { UsersList } from './modules/users/list';
import { VehicleDamageCreate } from './modules/vehicleDamages/create';
import { VehicleInsuranceType } from './modules/insurance/fragments';
import { appProvidersFactory } from './appProvidersFactory';
import i18nProvider from './locales';
import i18next from 'i18next';
import schema from '../src/graphql-schema.json';
import styled from 'styled-components';
import themeReducer from './reducers/theme';

export const Center = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`;

export const LoadingElement = () => (
  <Center>
    <CircularProgress />
  </Center>
);

const App: React.VFC<{ client: any }> = ({ client }) => {
  const options: Options = {
    client,
    introspection: {
      schema: (schema as any).__schema,
    },
    queryDialect: 'typegraphql',
    resourceViews: {
      User,
      GateController,
      ChargingStation,
      ChargingStationCluster,
      BillingAccount,
      BookingRequest,
      GateControllerRuleSet,
      ChargingStationRfidInvisia,
      ChargingStationTransaction,
      Message,
      VehicleDamage,
      Booking,
      Vehicle,
      ChargingStationTariff,
      SharedVehicleGroup,
      SharedVehicleGroupRelVehicle,
      VehicleCloudBoxxLog,
      VehicleInsuranceType,
      PxlVisionManualVerification,
      GateControllerTransaction,
      VehicleReport,
    },
  };

  const { dataProvider, authProvider } = appProvidersFactory()(options);

  const [isReady, setIsReady] = useState(false);

    // Wait for i18next to be fully loaded
    useEffect(() => {
        // Wait for i18next to be ready before rendering the Admin component
        const checkI18nReady = async () => {
            try {
                await i18next.init();  // Ensure i18next is initialized
                setIsReady(true);      // Set ready state when translations are loaded
            } catch (error) {
                console.error('i18next initialization failed', error);
            }
        };

        checkI18nReady();
    }, []);

  if (!dataProvider || !isReady) {
    return <LoadingElement />;
  }

  const customReducers = { theme: themeReducer };

  return (
    <Provider store={createStore(combineReducers(customReducers))}>
      <Admin
        dataProvider={dataProvider}
        disableTelemetry
        layout={CustomLayout}
        i18nProvider={i18nProvider}
        theme={CustomTheme}
        loginPage={(props) => (
          <Login {...props}>
            <LoginForm />
          </Login>
        )}
        authProvider={authProvider}>
        {(auth) => {
          return auth.presetPassword
            ? [
                <CustomRoutes>
                  <Route
                    path="/change-my-password"
                    element={<ChangeMyPassword />}
                  />
                </CustomRoutes>,
                <Resource name="User" />,
              ]
            : [
                <CustomRoutes>
                  <Route
                    path="/change-my-password"
                    element={<ChangeMyPassword />}
                  />
                </CustomRoutes>,
                <Resource
                  name="User"
                  list={UsersList}
                  edit={UserEdit}
                  create={UserCreate}
                />,
                <Resource
                  name="PxlVisionManualVerification"
                  options={{ label: 'User verifications' }}
                  list={PxlVisionVerificationList}
                />,
                <Resource name="Message" list={MessagesList} />,
                <Resource
                  name="Vehicle"
                  list={VehicleList}
                  create={VehicleAdd}
                  edit={VehicleEdit}
                />,
                <Resource
                  name="Company"
                  list={CompanyList}
                  create={CompanyCreate}
                  edit={CompanyEdit}
                />,
                <Resource
                  name="Booking"
                  list={BookingList}
                  show={BookingShow}
                  create={BookingCreate}
                />,
                <CustomRoutes>
                  <Route
                    path="/Booking/scheduler"
                    element={<BookingScheduler />}
                  />
                </CustomRoutes>,
                <Resource
                  name="SharedVehicleGroup"
                  list={SharedVehicleGroupList}
                  create={SharedVehicleGroupCreate}
                  edit={SharedVehicleGroupEdit}
                />,
                <Resource
                  name="CompanyVehicleGroup"
                  list={CompanyVehicleGroupList}
                  create={CompanyVehicleGroupCreate}
                  edit={CompanyVehicleGroupEdit}
                />,
                <Resource
                  name="VehicleDamage"
                  list={VehicleDamageList}
                  edit={VehicleDamageEdit}
                  create={VehicleDamageCreate}
                />,
                <Resource
                  name="VehicleCloudBoxxLog"
                  list={VehicleCloudboxxLogs}
                />,
                <Resource
                  name="RevokeReasonType"
                  list={RevokeList.List}
                  create={RevokeList.Create}
                  edit={RevokeList.Edit}
                />,
                <Resource
                  name="VehicleInsuranceType"
                  list={InsuranceList}
                  create={InsuranceCreate}
                  edit={InsuranceEdit}
                />,
                <Resource
                  name="BillingAccount"
                  list={BillingAccountList}
                  create={BillingAccountCreate}
                  edit={BillingAccountEdit}
                />,
                <Resource
                  name="ChargingStationCluster"
                  list={ChargingStationClusterList}
                  create={ChargingStationClusterCreate}
                  edit={ChargingStationClusterEdit}
                />,
                <Resource
                  name="ChargingStation"
                  list={ChargingStationList}
                  create={ChargingStationCreate}
                  edit={ChargingStationEdit}
                />,
                <Resource
                  name="ChargingStationRfidInvisia"
                  list={ChargingStationRfidList}
                />,
                <Resource
                  name="ChargingStationTransaction"
                  show={TransactionShow}
                  list={ChargingStationTransactionList}
                />,
                <Resource
                  name="ChargingStationTransactionReport"
                  list={ChargingStationReportList}
                />,
                <Resource
                  name="GateController"
                  list={GateControllersList}
                  create={GateControllerCreate}
                  edit={GateControllerEdit}
                />,
                <Resource
                  name="GateControllerType"
                  list={TranslateType.List}
                  create={TranslateType.Create}
                  edit={TranslateType.Edit}
                />,
                <Resource
                  name="GateControllerTransaction"
                  list={GateControllerTransactionList}
                />,
                <Resource name="VehicleInsuranceRuleSet" />,
                <Resource name="VehicleInsuranceRule" />,
                <Resource name="VehicleInsuranceDocument" />,
                <Resource name="VehicleDamageComment" />,
                <Resource
                  name="FuelType"
                  list={TranslateType.List}
                  create={TranslateType.Create}
                  edit={TranslateType.Edit}
                />,
                <Resource
                  name="VehicleType"
                  list={TranslateType.List}
                  create={TranslateType.Create}
                  edit={TranslateType.Edit}
                />,
                <Resource
                  name="TransmissionType"
                  list={TranslateType.List}
                  create={TranslateType.Create}
                  edit={TranslateType.Edit}
                />,
                <Resource
                  name="DriveType"
                  list={TranslateType.List}
                  create={TranslateType.Create}
                  edit={TranslateType.Edit}
                />,
                <Resource
                  name="SeatType"
                  list={TranslateType.List}
                  create={TranslateType.Create}
                  edit={TranslateType.Edit}
                />,
                <Resource
                  name="ChildSeatType"
                  list={TranslateType.List}
                  create={TranslateType.Create}
                  edit={TranslateType.Edit}
                />,
                <Resource
                  name="DoorType"
                  list={TranslateType.List}
                  create={TranslateType.Create}
                  edit={TranslateType.Edit}
                />,
                <Resource
                  name="EnergyEfficiencyType"
                  list={TranslateType.List}
                  create={TranslateType.Create}
                  edit={TranslateType.Edit}
                />,
                <Resource
                  name="EmissionCodeType"
                  list={TranslateType.List}
                  create={TranslateType.Create}
                  edit={TranslateType.Edit}
                />,
                <Resource
                  name="ConsumptionType"
                  list={TranslateType.List}
                  create={TranslateType.Create}
                  edit={TranslateType.Edit}
                />,
                <Resource
                  name="ShieldColorType"
                  list={TranslateType.List}
                  create={TranslateType.Create}
                  edit={TranslateType.Edit}
                />,
                <Resource
                  name="ParticularUseType"
                  list={TranslateType.List}
                  create={TranslateType.Create}
                  edit={TranslateType.Edit}
                />,
                <Resource
                  name="BrandType"
                  list={TranslateType.List}
                  create={TranslateType.Create}
                  edit={TranslateType.Edit}
                />,
                <Resource
                  name="ModelType"
                  list={TranslateType.List}
                  create={TranslateType.Create}
                  edit={TranslateType.Edit}
                />,
                <Resource
                  name="BodyType"
                  list={TranslateType.List}
                  create={TranslateType.Create}
                  edit={TranslateType.Edit}
                />,
                <Resource
                  name="ColorType"
                  list={TranslateType.List}
                  create={TranslateType.Create}
                  edit={TranslateType.Edit}
                />,
                <Resource
                  name="SeatFrontType"
                  list={TranslateType.List}
                  create={TranslateType.Create}
                  edit={TranslateType.Edit}
                />,
                <Resource
                  name="ApprovalType"
                  list={TranslateType.List}
                  create={TranslateType.Create}
                  edit={TranslateType.Edit}
                />,
                <Resource name="UnpublishReasonType" />,
                <Resource name="VehicleEquipment" />,
                <Resource
                  name="EquipmentType"
                  list={TranslateType.List}
                  create={TranslateType.Create}
                  edit={TranslateType.Edit}
                />,
                <Resource
                  name="VehicleKindType"
                  list={TranslateType.List}
                  create={TranslateType.Create}
                  edit={TranslateType.Edit}
                />,
                <Resource
                  name="VehicleReport"
                  options={{
                    label: 'Vehicle reports',
                  }}
                  list={VehicleReportList}
                />,
                <Resource name="VehicleLocation" />,
                <Resource name="VehiclePhoto" />,
                <Resource name="BookingAdditionalDriver" />,
                <Resource name="BookingQuestion" />,
                <Resource name="BookingFuelPassword" />,
                <Resource name="CompanyVehicleGroupRelVehicle" />,
                <Resource name="SharedVehicleGroupRelVehicle" />,
                <Resource name="SharedVehicleGroupRelUser" />,
                <Resource name="SharedVehicleGroupRelCompany" />,
                <Resource name="ChargingStationInstallation" />,
                <Resource name="ChargingStationRfid" />,
                <Resource name="BillingAccountDocument" />,
                <Resource name="GateControllerRuleSet" />,
                <Resource name="GateControllerRelVehicle" />,
                <Resource name="GateControllerRelUser" />,
                <Resource name="ChargingStationRfidAlias" />,
                <Resource name="BookingRequest" />,
              ];
        }}
      </Admin>
    </Provider>
  );
};

export default App;
