import {
  Datagrid,
  EditButton,
  List,
  TextField,
} from 'react-admin';

import CustomPagination from '../../../CustomPagination';

export const GateControllersList = () => {
  return (
    <List title="Gate controllers" hasCreate pagination={<CustomPagination />}>
      <Datagrid>
        <TextField source="gateId" />
        <TextField source="company.name" />
        <TextField source="gateType.name" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
