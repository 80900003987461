import { TextInput as AdminTextInput } from 'react-admin';
import { getValidate } from '../libs/checkRequiredField';
import { useFormContext } from 'react-hook-form';

const TextInput = (props) => {
  const { error, style, source } = props;
  const { getFieldState } = useFormContext();
  const fieldState = getFieldState(source);
  const validate = getValidate(props);

  const errorMessage = fieldState.error?.message || error || '';

  return (
    <AdminTextInput
      source={source}
      variant="outlined"
      label={props.label}
      size="small"
      validate={validate}
      className={!!errorMessage ? 'form-has-error' : undefined}
      helperText={errorMessage}
      style={{ flex: 1, ...style }}
    />
  );
};

export default TextInput;
