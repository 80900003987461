import {
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  Create as RACreate,
  Edit as RAEdit,
  Filter as RAFilter,
  List as RAList,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';

import CustomPagination from '../../CustomPagination';
import { useTranslate } from '../../locales';

export const Create = (props: any) => {
  const translate = useTranslate();
  return (
    <RACreate {...props} mutationMode="pessimistic">
      <SimpleForm>
        <TextInput multiline source="name" label={translate('admin.name')} />
      </SimpleForm>
    </RACreate>
  )
};

export const Edit = (props: any) => {
  const translate = useTranslate();
  return (
    <RAEdit {...props} redirect={false} mutationMode="pessimistic">
      <SimpleForm>
        <TextInput multiline source="name" label={translate('admin.name')} />
      </SimpleForm>
    </RAEdit>
  )
}

export const Filter = (props: any) => {
  const translate = useTranslate();
  return (
    <RAFilter {...props}>
      <TextInput source="q" label={translate('admin.search')} alwaysOn />
    </RAFilter>
  )
}

export const List = (props: any) => {
  const translate = useTranslate();
  return (
    <RAList
      {...props}
      filters={<Filter />}
      filter={{
        deletedAt: { equals: null },
      }}
      undoable="false"
      perPage={50}
      pagination={<CustomPagination />}>
      <Datagrid>
        <TextField source="name" label={translate('admin.name')} />
        <EditButton />
        <DeleteWithConfirmButton mutationMode="pessimistic" />
      </Datagrid>
    </RAList>
  );
};
