import { BooleanInput } from 'react-admin';
import InsuranceContent from './content';
import { useTranslate } from '../../locales';

const InsuranceContentVAG = () => {
  const translate = useTranslate();
  return (
    <>
      <BooleanInput source="showVAG" label={translate('admin.showVag45')} />
      <InsuranceContent source="contentVAG" />
    </>
  );
};

export default InsuranceContentVAG;
