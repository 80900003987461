import { ResourceView } from '@ra-data-prisma/dataprovider';
import { gql } from '@apollo/client';

const fragment = gql`
  fragment GateControllerTransactionFragment on GateControllerTransaction {
    id
    gateController {
      id
      gateId
    }
    createdAt
    comment
    user {
      id
      email
    }
    booking {
      id
      bookingHash
    }
    vehicle {
      id
      vin
    }
  }
`;

export const GateControllerTransaction: ResourceView = {
  resource: 'GateControllerTransaction',
  fragment: {
    one: {
      type: 'document',
      mode: 'replace',
      doc: fragment,
    },
    many: {
      type: 'document',
      mode: 'replace',
      doc: fragment,
    },
  },
};
