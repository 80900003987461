import * as React from 'react';

import {
  Collapse,
  List,
  ListItemIcon,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import { ReactElement, ReactNode } from 'react';

import ExpandMore from '@mui/icons-material/ExpandMore';
import { useSidebarState } from 'react-admin';

interface Props {
  dense: boolean;
  handleToggle: () => void;
  icon: ReactElement;
  isOpen: boolean;
  name: string;
  children: ReactNode;
}

const CustomSubMenu = (props: Props) => {
  const { handleToggle, isOpen, name, icon, children, dense } = props;

  const [sidebarIsOpen] = useSidebarState();

  const header = (
    <MenuItem dense={dense} onClick={handleToggle}>
      <ListItemIcon>{isOpen ? <ExpandMore /> : icon}</ListItemIcon>
      <Typography
        variant="inherit"
        style={{ marginLeft: 2 }}
        color="textSecondary">
        {name}
      </Typography>
    </MenuItem>
  );

  return (
    <div>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={name} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          dense={dense}
          component="div"
          disablePadding
          sx={{
            '& a': {
              transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
              paddingLeft: sidebarIsOpen ? 4 : 2,
            },
          }}>
          {children}
        </List>
      </Collapse>
    </div>
  );
};

export default CustomSubMenu;
