import {
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  Create as RACreate,
  Edit as RAEdit,
  Filter as RAFilter,
  List as RAList,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';

import CustomPagination from '../../CustomPagination';

export const Create = (props: any) => (
  <RACreate {...props} mutationMode="pessimistic">
    <SimpleForm>
      <TextInput multiline source="name" />
    </SimpleForm>
  </RACreate>
);

export const Edit = (props: any) => (
  <RAEdit {...props} redirect={false} mutationMode="pessimistic">
    <SimpleForm>
      <TextInput multiline source="name" />
    </SimpleForm>
  </RAEdit>
);

export const Filter = (props: any) => (
  <RAFilter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </RAFilter>
);

export const List = (props: any) => {
  return (
    <RAList
      {...props}
      filters={<Filter />}
      filter={{
        deletedAt: { equals: null },
      }}
      undoable="false"
      perPage={50}
      pagination={<CustomPagination />}>
      <Datagrid>
        <TextField source="name" />
        <EditButton />
        <DeleteWithConfirmButton mutationMode="pessimistic" />
      </Datagrid>
    </RAList>
  );
};
