import { ResourceView } from '@ra-data-prisma/dataprovider';
import { gql } from '@apollo/client';

const chargingStationTariffFragment = gql`
  fragment ChargingStationTariffFragment on ChargingStationTariff {
    chargingStationRules {
      id
      property
      fromCompareOperator
      fromValue
      toCompareOperator
      toValue
    }
    users {
      id
      email
    }
    rfids {
      id
      tag
    }
  }
`;

export const ChargingStationTariff: ResourceView = {
  resource: 'ChargingStationTariff',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: chargingStationTariffFragment,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: chargingStationTariffFragment,
    },
  },
};

const chargingStationFragment = gql`
  fragment ChargingStationragment on ChargingStation {
    status
    chargingStationCluster {
      id
      company {
        id
        name
        commissionChargingStations
      }
    }
  }
`;

export const ChargingStation: ResourceView = {
  resource: 'ChargingStation',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: chargingStationFragment,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: chargingStationFragment,
    },
  },
};
