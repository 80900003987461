import {
  AutocompleteInput,
  Create,
  ReferenceInput,
  SimpleForm,
  useGetIdentity,
  useNotify,
} from 'react-admin';
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  ListItemText,
  MenuItem,
  Modal,
  TextField as MuiTextField,
  Toolbar as MuiToolbar,
  OutlinedInput,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  Circle,
  GoogleMap,
  Marker,
  StandaloneSearchBox,
} from '@react-google-maps/api';
import { UserRole, VehicleExtended } from '../../@generated/schemas';
import {
  useCreateNewBookingMutation,
  useGetAvailableVehiclesLazyQuery,
  useVehicleFiltersQuery,
} from '../../@generated/hooks';

import AdapterDayjs from '@mui/lab/AdapterDayjs';
import { BookingPriceOverview } from './show';
import CloseIcon from '@mui/icons-material/Close';
import { DateTimePicker } from '@mui/lab';
import { DefaultLocation } from '../vehicles/locations';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import dayjs from 'dayjs';
import deLocale from 'date-fns/locale/de';
import styled from 'styled-components';
import { useState } from 'react';

const FormRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const GoogleMapsInputWrapper = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100;
`;

const ModalContent = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: white;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 24px;
`;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const FilterSelect = ({ label, data, filter, setFilter }) => {
  return (
    <FormControl
      size="small"
      style={{ width: '20%', marginRight: 12, marginBottom: 12 }}>
      <InputLabel>{label}</InputLabel>
      <Select
        label={label}
        multiple
        size="small"
        value={filter}
        onChange={setFilter}
        input={<OutlinedInput label="Tag" />}
        renderValue={(selected) => {
          const values = data
            .filter((item) => selected.includes(item.id))
            .map((item) => item.name);
          return values.join(', ');
        }}
        MenuProps={MenuProps}>
        {data?.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            <Checkbox checked={filter.includes(item.id)} />
            <ListItemText primary={item.name} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export const BookingCreate = (props: any) => {
  const notify = useNotify();
  const [searchBoxRef, setSearchBoxRef] =
    useState<google.maps.places.SearchBox>();
  const [, setMapRef] = useState<google.maps.Map>();
  const geocoder = new google.maps.Geocoder();
  const [position, setPosition] = useState(DefaultLocation);
  const [activeVehicle, setActiveVehicle] = useState<string | null>(null);
  const [selectedVehicle, setSelectedVehicle] =
    useState<VehicleExtended | null>(null);
  const [driver, setDriver] = useState<string | null>(null);
  const [date, setDate] = useState({
    startDateTime: dayjs().toDate(),
    endDateTime: dayjs().add(1, 'hours').toDate(),
  });
  const { identity } = useGetIdentity();

  const defaultUserFilter = {
    deletedAt: {
      equals: null,
    },
    companyId:
      identity?.userRole && identity?.userRole !== UserRole.SuperAdmin
        ? {
            equals: identity.companyId,
          }
        : {},
  };

  const [bookingComment, setBookingComment] = useState('');
  const [filter, setFilter] = useState({
    doorTypes: [],
    driveTypes: [],
    fuelTypes: [],
    seatTypes: [],
    transmissionTypes: [],
    vehicleTypes: [],
  });

  const [getAvailableVehicles, { data, loading }] =
    useGetAvailableVehiclesLazyQuery();
  const [createNewBooking] = useCreateNewBookingMutation();
  const { data: vehicleFiltersData } = useVehicleFiltersQuery();

  const handleSearchboxChanged = () => {
    const places = searchBoxRef.getPlaces();
    const firstPlace = places[0];

    const lat = firstPlace.geometry.location.lat();
    const lng = firstPlace.geometry.location.lng();

    setPosition({
      ...position,
      latitude: lat,
      longitude: lng,
    });
  };

  const handleClose = () => {
    setBookingComment('');
    setSelectedVehicle(null);
  };

  const handleSearchVehicles = () => {
    driver &&
      getAvailableVehicles({
        variables: {
          userId: driver,
          startDateTime: dayjs(date.startDateTime).toISOString(),
          endDateTime: dayjs(date.endDateTime).toISOString(),
          latitude: position.latitude,
          longitude: position.longitude,
        },
      });
  };

  const handleSendBookingToUser = async () => {
    try {
      const booking = await createNewBooking({
        variables: {
          bookingComment,
          userId: driver,
          vehicleId: selectedVehicle.id,
          startDate: dayjs(date.startDateTime).toISOString(),
          endDate: dayjs(date.endDateTime).toISOString(),
        },
      });

      handleClose();

      window.location.href = `${window.location.origin}/#/Booking/${booking?.data?.createNewBooking?.id}/show`;
    } catch (error) {
      notify(error.message, { type: 'error' });
    }
  };

  const filteredData =
    data?.getAvailableVehicles
      ?.filter((v) => {
        if (filter.driveTypes.length > 0) {
          return filter.driveTypes.includes(v.driveType?.id);
        }
        return v;
      })
      .filter((v) => {
        if (filter.fuelTypes.length > 0) {
          return filter.fuelTypes.includes(v.fuelType?.id);
        }
        return v;
      })
      .filter((v) => {
        if (filter.transmissionTypes.length > 0) {
          return filter.transmissionTypes.includes(v.transmissionType?.id);
        }
        return v;
      })
      .filter((v) => {
        if (filter.vehicleTypes.length > 0) {
          return filter.vehicleTypes.includes(v.vehicleType?.id);
        }
        return v;
      }) || [];

  return (
    <Create {...props}>
      <SimpleForm
        toolbar={
          <MuiToolbar>
            <Button
              size="medium"
              disabled={!driver}
              onClick={handleSearchVehicles}
              variant="contained">
              Search{' '}
              {loading && (
                <div
                  style={{
                    height: 12,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    marginLeft: 10,
                  }}>
                  <CircularProgress
                    color="secondary"
                    style={{ height: 20, width: 20 }}
                  />
                </div>
              )}
            </Button>
          </MuiToolbar>
        }>
        <Grid container spacing={8}>
          <Grid item md={4}>
            <FormRow style={{ flex: 1 }}>
              <ReferenceInput
                label="Driver"
                filter={defaultUserFilter}
                style={{ width: '100%' }}
                source="userId"
                reference="User">
                <AutocompleteInput
                  variant="outlined"
                  size="small"
                  onChange={setDriver}
                  style={{ width: '100%' }}
                  optionText={(option) => {
                    if (!option || !option.id) {
                      return 'Clear';
                    }
                    return `${option.firstName || ''} ${
                      option.lastName || ''
                    } (${option.email})`;
                  }}
                />
              </ReferenceInput>
            </FormRow>

            <LocalizationProvider locale={deLocale} dateAdapter={AdapterDayjs}>
              <FormRow style={{ marginBottom: 20 }}>
                <DateTimePicker
                  renderInput={(props) => (
                    <MuiTextField
                      {...props}
                      variant="outlined"
                      size="small"
                      style={{ flex: 1, marginRight: 20 }}
                    />
                  )}
                  inputFormat="DD.MM.YYYY HH:mm"
                  ampm={false}
                  label="Start date time"
                  value={date.startDateTime}
                  onChange={(newValue) => {
                    setDate({
                      ...date,
                      startDateTime: newValue,
                    });
                  }}
                />
                <DateTimePicker
                  renderInput={(props) => (
                    <MuiTextField
                      {...props}
                      variant="outlined"
                      size="small"
                      style={{ flex: 1, marginRight: 20 }}
                    />
                  )}
                  ampm={false}
                  inputFormat="DD.MM.YYYY HH:mm"
                  label="End date time"
                  value={date.endDateTime}
                  onChange={(newValue) => {
                    setDate({
                      ...date,
                      endDateTime: newValue,
                    });
                  }}
                />
              </FormRow>
            </LocalizationProvider>

            <GoogleMap
              onLoad={setMapRef}
              options={{ mapTypeControl: false }}
              mapContainerStyle={{
                width: '100%',
                height: 400,
              }}
              center={{
                lat: position.latitude,
                lng: position.longitude,
              }}
              zoom={12}>
              <GoogleMapsInputWrapper>
                <StandaloneSearchBox
                  onLoad={setSearchBoxRef}
                  onPlacesChanged={handleSearchboxChanged}>
                  <MuiTextField
                    variant="outlined"
                    size="small"
                    placeholder="Search address"
                    style={{ background: 'white' }}
                  />
                </StandaloneSearchBox>
              </GoogleMapsInputWrapper>
              <Circle
                center={{
                  lat: position.latitude,
                  lng: position.longitude,
                }}
                radius={5000}
              />
              <Marker
                key={`${position.latitude}-${position.longitude}`}
                draggable
                onDragEnd={(e) => {
                  geocoder.geocode({ location: e.latLng }).then((response) => {
                    if (response.results[0]) {
                      setPosition({
                        ...position,
                        latitude: e.latLng.lat(),
                        longitude: e.latLng.lng(),
                      });
                    }
                  });
                }}
                position={{
                  lat: position.latitude,
                  lng: position.longitude,
                }}
              />
              {filteredData.map((vehicle, key) => (
                <Marker
                  key={`${vehicle.id}-${key}`}
                  onClick={() => setActiveVehicle(vehicle.id)}
                  icon="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzEiIGhlaWdodD0iMzciIHZpZXdCb3g9IjAgMCAzMSAzNyIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDE1LjEwOTJDMCA2Ljc1MzU1IDYuOTgyNDEgMCAxNS40MjgzIDBDMjMuODk4MSAwIDMwLjg4MDUgNi43NTM1NSAzMC44ODA1IDE1LjEwOTJDMzAuODgwNSAxOS4zMTk3IDI5LjM0OTIgMjMuMjI4NyAyNi44Mjg4IDI2LjU0MTlDMjQuMDQ4MyAzMC4xOTY2IDIwLjYyMTIgMzMuMzgwOCAxNi43NjM2IDM1Ljg4MDNDMTUuODgwOCAzNi40NTc5IDE1LjA4NCAzNi41MDE1IDE0LjExNSAzNS44ODAzQzEwLjIzNTUgMzMuMzgwOCA2LjgwODQgMzAuMTk2NiA0LjA1MTcgMjYuNTQxOUMxLjUyOTQ2IDIzLjIyODcgMCAxOS4zMTk3IDAgMTUuMTA5MlpNMTAuMzQzNSAxNS41Nzk2QzEwLjM0MzUgMTguMzc4OCAxMi42Mjc2IDIwLjU4MDMgMTUuNDI4MyAyMC41ODAzQzE4LjIzMDggMjAuNTgwMyAyMC41MzY5IDE4LjM3ODggMjAuNTM2OSAxNS41Nzk2QzIwLjUzNjkgMTIuODAyMyAxOC4yMzA4IDEwLjQ5MzYgMTUuNDI4MyAxMC40OTM2QzEyLjYyNzYgMTAuNDkzNiAxMC4zNDM1IDEyLjgwMjMgMTAuMzQzNSAxNS41Nzk2WiIgZmlsbD0iIzBBMUU0QiIvPgo8Y2lyY2xlIGN4PSIxNS40NDA0IiBjeT0iMTYuMjY5OSIgcj0iOC41Mjk2NSIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg=="
                  position={{
                    lat: vehicle.defaultVehicleLocation.latitude,
                    lng: vehicle.defaultVehicleLocation.longitude,
                  }}
                />
              ))}
            </GoogleMap>
          </Grid>
          <Grid item md={8} style={{ position: 'relative' }}>
            <FilterSelect
              label="Vehicle type"
              filter={filter.vehicleTypes}
              setFilter={(event) =>
                setFilter({
                  ...filter,
                  vehicleTypes: event.target.value as string[],
                })
              }
              data={vehicleFiltersData?.vehicleFilters?.vehicleTypes}
            />
            <FilterSelect
              label="Door type"
              filter={filter.doorTypes}
              setFilter={(event) =>
                setFilter({
                  ...filter,
                  doorTypes: event.target.value as string[],
                })
              }
              data={vehicleFiltersData?.vehicleFilters?.doorTypes}
            />
            <FilterSelect
              label="Drive types"
              filter={filter.driveTypes}
              setFilter={(event) =>
                setFilter({
                  ...filter,
                  driveTypes: event.target.value as string[],
                })
              }
              data={vehicleFiltersData?.vehicleFilters?.driveTypes}
            />
            <FilterSelect
              label="Fuel types"
              filter={filter.fuelTypes}
              setFilter={(event) =>
                setFilter({
                  ...filter,
                  fuelTypes: event.target.value as string[],
                })
              }
              data={vehicleFiltersData?.vehicleFilters?.fuelTypes}
            />
            <FilterSelect
              label="Transmission types"
              filter={filter.transmissionTypes}
              setFilter={(event) =>
                setFilter({
                  ...filter,
                  transmissionTypes: event.target.value as string[],
                })
              }
              data={vehicleFiltersData?.vehicleFilters?.transmissionTypes}
            />
            <FilterSelect
              label="Seat types"
              filter={filter.seatTypes}
              setFilter={(event) =>
                setFilter({
                  ...filter,
                  seatTypes: event.target.value as string[],
                })
              }
              data={vehicleFiltersData?.vehicleFilters?.seatTypes}
            />
            <TableContainer style={{ maxHeight: 550 }}>
              <Table
                stickyHeader
                sx={{ minWidth: 650 }}
                style={{ maxHeight: '5wh' }}
                aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>VIN</TableCell>
                    <TableCell>Plate number</TableCell>
                    <TableCell>Brand</TableCell>
                    <TableCell>Model</TableCell>
                    <TableCell>Distance</TableCell>
                    <TableCell>Total cost</TableCell>
                    <TableCell align="right" width="20%">
                      Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((vehicle) => (
                    <TableRow
                      key={vehicle.id}
                      selected={activeVehicle === vehicle.id}>
                      <TableCell component="th" scope="row">
                        <a
                          target="_blank"
                          href={`${window.location.origin}/#/Vehicle/${vehicle.id}`}>
                          {vehicle.vin}
                        </a>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <a
                          target="_blank"
                          href={`${window.location.origin}/#/Vehicle/${vehicle.id}`}>
                          {vehicle.plateNumber}
                        </a>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {vehicle.brandType.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {vehicle.modelType.name}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {Math.round(vehicle.distance / 1000)}km
                      </TableCell>
                      <TableCell component="th" scope="row">
                        CHF{vehicle.price.totalCost}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <Button
                          onClick={() =>
                            setSelectedVehicle(vehicle as VehicleExtended)
                          }
                          variant="outlined">
                          Book now
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Modal open={!!selectedVehicle} onClose={handleClose}>
              <ModalContent>
                <IconButton
                  style={{ position: 'absolute', right: 18, top: 10 }}
                  onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
                <Typography variant="h5" style={{ marginBottom: 20 }}>
                  Booking for vehicle
                </Typography>

                <BookingPriceOverview
                  bookingPrice={{
                    startPrice: selectedVehicle?.price,
                    endPrice: selectedVehicle?.price,
                  }}
                  {...selectedVehicle}
                />

                <MuiTextField
                  label="Booking comment"
                  size="small"
                  value={bookingComment}
                  onChange={(event) => setBookingComment(event.target.value)}
                  style={{ marginTop: 20, marginBottom: 20, width: '100%' }}
                />

                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    justifyContent: 'center',
                  }}>
                  <Button
                    variant="contained"
                    onClick={handleSendBookingToUser}
                    disabled={!bookingComment}>
                    Send booking to user
                  </Button>
                </div>
              </ModalContent>
            </Modal>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};
