import {
  ChargingStationRuleProperty,
  ChargingStationTariff,
} from '../../../@generated/schemas';

import CompareSelectOptions from '../../../libs/compareSelectOptions';
import dayjs from 'dayjs';

export const getValueByForProperty = (
  tariff: ChargingStationTariff,
  property: ChargingStationRuleProperty,
) => {
  const rule = tariff?.chargingStationRules?.find(
    (rule) => rule.property === property,
  );

  if (!rule) {
    return null;
  }

  const findOperatorFrom = CompareSelectOptions.find(
    (option) => option.id === rule.fromCompareOperator,
  );
  const findOperatorTo = CompareSelectOptions.find(
    (option) => option.id === rule.toCompareOperator,
  );

  if (property === ChargingStationRuleProperty.Time) {
    const timeFrom =
      rule.fromValue ? dayjs().startOf('day').add(rule.fromValue, 'minutes').format('HH:mm') : '00:00';
    const timeTo =
      rule.toValue ? dayjs().startOf('day').add(rule.toValue, 'minutes').format('HH:mm') : '00:00';

    return `${findOperatorFrom?.name} ${timeFrom} ${findOperatorTo?.name || ''
      } ${timeTo || ''}`;
  }

  return `${findOperatorFrom?.name} ${rule.fromValue} ${findOperatorTo?.name ?? ''
    } ${rule.toValue ?? ''}`;
};
