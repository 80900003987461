import React, { useEffect } from 'react';
import { TextInput, useRecordContext } from 'react-admin';

import { RichTextInput } from 'ra-input-rich-text';
import { get } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { useTranslate } from '../locales';

const TranslateInput: React.VFC<{
  source: string;
  label?: string;
  useRich?: boolean;
  hideName?: boolean;
}> = ({ source = 'content', label, useRich, hideName }) => {
  const record = useRecordContext();
  const translate = useTranslate();
  const { watch, setValue } = useFormContext();

  const originalData = watch();
  const parsed =
    (get(record, source) && JSON.parse(get(record, source) || '{}')) || {};

  useEffect(() => {
    const result = JSON.stringify({
      en: get(originalData, `${source}_en`) || '',
      de: get(originalData, `${source}_de`) || '',
      it: get(originalData, `${source}_it`) || '',
      fr: get(originalData, `${source}_fr`) || '',
    });
    setValue(source, result);
  }, [
    parsed?.en,
    parsed?.de,
    parsed?.it,
    parsed?.fr,
    get(originalData, `${source}_en`),
    get(originalData, `${source}_de`),
    get(originalData, `${source}_it`),
    get(originalData, `${source}_fr`),
  ]);

  const Component = useRich ? RichTextInput : TextInput;

  return (
    <>
      {!hideName && <TextInput source="name" label={translate('admin.name')} />}
      <Component
        source={`${source}_en`}
        label={label || 'EN'}
        defaultValue={parsed?.en || ''}
        style={{ width: '100%' }}
      />
      <Component
        source={`${source}_de`}
        label={label || 'DE'}
        defaultValue={parsed?.de || ''}
        style={{ width: '100%' }}
      />
      <Component
        source={`${source}_it`}
        label={label || 'IT'}
        defaultValue={parsed?.it || ''}
        style={{ width: '100%' }}
      />
      <Component
        source={`${source}_fr`}
        label={label || 'FR'}
        defaultValue={parsed?.fr || ''}
        style={{ width: '100%' }}
      />
    </>
  );
};

export const transform = (data, source = 'content') => {
  const result = JSON.stringify({
    en: data[`${source}_en`] || '',
    de: data[`${source}_de`] || '',
    it: data[`${source}_it`] || '',
    fr: data[`${source}_fr`] || '',
  });

  delete data[`${source}_en`];
  delete data[`${source}_de`];
  delete data[`${source}_it`];
  delete data[`${source}_fr`];

  return {
    ...data,
    [source]: result,
  };
};

export default TranslateInput;
