import {
  BooleanField,
  BooleanInput,
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  FunctionField,
  Create as RACreate,
  Edit as RAEdit,
  Filter as RAFilter,
  List as RAList,
  SimpleForm,
  TextField,
  TextInput,
  useRecordContext,
} from 'react-admin';
import { FormGroup, Tab } from '@mui/material';
import InsuranceDetails, { requiredFields } from './details';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import {
  VehicleInsuranceRuleSet,
  VehicleInsuranceType,
} from '../../@generated/schemas';

import CustomPagination from '../../CustomPagination';
import InsuranceContent from './content';
import InsuranceContentVAG from './contentVAG';
import InsuranceField from './insuranceField';
import InsuranceRuleSets from './ruleSets';
import Reporting from './reporting';
import queryString from 'query-string';
import { transform } from '../../common/TranslationInput';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

export const TABS = {
  details: 'details',
  content: 'content',
  vag: 'vag',
  links: 'links',
  ruleSetsTarifs: 'ruleSetsTarifs',
};

const Title = () => {
  const record = useRecordContext();
  return <span>Insurance: {record?.name}</span>;
};

const Form = () => {
  const { watch } = useFormContext();
  const record = useRecordContext();
  const { search } = useLocation();
  const [selectedTab, setTab] = useState<string>(
    (queryString.parse(search)?.tab || TABS.details) as string,
  );

  const vehicleInsuranceRuleSets: VehicleInsuranceRuleSet[] =
    watch('vehicleInsuranceRuleSets') || record?.vehicleInsuranceRuleSets;

  const onChangeTab = (tabName: string) => {
    if (tabName) {
      setTab(tabName);

      const url = new URL(window.location.href);
      url.searchParams.set('tab', tabName);
      window.history.pushState(
        {},
        '',
        `/#/VehicleInsuranceType/${record?.id || 'create'}${url.search}`,
      );
    }
  };

  return (
    <TabContext value={selectedTab}>
      <TabList
        value={selectedTab}
        onChange={(_, value) => onChangeTab(value)}
        aria-label="">
        <Tab label="Insurance details" value={TABS.details} />
        <Tab label="Content" value={TABS.content} />
        <Tab label="VAG45" value={TABS.vag} />
        <Tab label="Links" value={TABS.links} />
        {record?.id && (
          <Tab label="Rulesets & Tarifs" value={TABS.ruleSetsTarifs} />
        )}
      </TabList>

      <TabPanel value={TABS.details} style={{ width: '100%' }}>
        <FormGroup row>
          <BooleanInput source="isDefault" style={{ marginRight: 8 }} />
        </FormGroup>
        <InsuranceDetails vehicleInsuranceTypeId={record?.id as string}>
          <InsuranceField source="name" requiredFields={requiredFields} />
          <InsuranceField
            source="policyNumber"
            requiredFields={requiredFields}
          />
          <InsuranceField
            source="policyHolder"
            requiredFields={requiredFields}
          />
          <InsuranceField
            source="postalAddress"
            requiredFields={requiredFields}
          />
          <InsuranceField
            source="billingAddress"
            requiredFields={requiredFields}
          />
          <InsuranceField
            source="phoneNumber"
            requiredFields={requiredFields}
          />
          <Reporting />
        </InsuranceDetails>
      </TabPanel>
      <TabPanel value={TABS.content} style={{ width: '100%' }}>
        <InsuranceContent source="content" />
      </TabPanel>
      <TabPanel value={TABS.vag} style={{ width: '100%' }}>
        <InsuranceContentVAG />
      </TabPanel>
      <TabPanel value={TABS.links} style={{ width: '100%' }}>
        <InsuranceContent source="contentLinks" />
      </TabPanel>
      {record?.id && (
        <TabPanel value={TABS.ruleSetsTarifs} style={{ width: '100%' }}>
          <InsuranceRuleSets
            vehicleInsuranceTypeId={record?.id as string}
            vehicleInsuranceRuleSets={vehicleInsuranceRuleSets}
          />
        </TabPanel>
      )}
    </TabContext>
  );
};

const sharedTransform = (data) => {
  const { content } = transform(data, 'content');
  const { contentVAG } = transform(data, 'contentVAG');
  const { contentLinks } = transform(data, 'contentLinks');

  return {
    ...data,
    content,
    contentVAG,
    contentLinks,
  };
};

export const InsuranceCreate = (props: any) => (
  <RACreate
    {...props}
    undoable="false"
    mutationMode="pessimistic"
    transform={sharedTransform}>
    <SimpleForm defaultValues={{ isDefault: false }}>
      <Form />
    </SimpleForm>
  </RACreate>
);

export const InsuranceEdit = (props: any) => (
  <RAEdit
    {...props}
    title={<Title />}
    redirect={false}
    mutationMode="pessimistic"
    transform={sharedTransform}>
    <SimpleForm>
      <Form />
    </SimpleForm>
  </RAEdit>
);

export const InsuranceFilter = (props: any) => (
  <RAFilter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </RAFilter>
);

export const InsuranceList = (props: any) => {
  return (
    <RAList
      {...props}
      filters={<InsuranceFilter />}
      filter={{
        deletedAt: { equals: null },
        // we want to show not own insurances
        isPrivate: { equals: false },
      }}
      undoable="false"
      perPage={50}
      pagination={<CustomPagination />}>
      <Datagrid>
        <TextField source="name" />
        <BooleanField source="isDefault" />
        <FunctionField
          source="Vehicles"
          render={(record: VehicleInsuranceType) => record.vehicles.length}
        />
        <FunctionField
          label="Rulesets"
          render={(record: VehicleInsuranceType) =>
            record.vehicleInsuranceRuleSets.length
          }
        />
        <EditButton />
        <DeleteWithConfirmButton mutationMode="pessimistic" />
      </Datagrid>
    </RAList>
  );
};
