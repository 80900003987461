import {
  BillingAccountPaymentFrequency,
  ChargingStationRfid,
} from '../../../@generated/schemas';
import {
  Card,
  CardContent,
  List,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  Create,
  Edit,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  useRecordContext,
} from 'react-admin';

import { Documents } from './documents';
import { Form } from './form';
import { useTranslate } from '../../../locales';

export const TABS = {
  basicInfo: 'basicInfo',
  documents: 'documents',
  paymentFrequency: 'paymentFrequency',
};

const AssideContent = () => {
  const record = useRecordContext();
  const translate = useTranslate();
  return (
    <Card
      variant="outlined"
      style={{
        minWidth: 300,
        margin: '0 1em 0',
        float: 'right',
        height: '90vh',
        position: 'sticky',
        top: 30,
      }}>
      <CardContent style={{ height: '50vh' }}>
        <Typography variant="h6">{translate('admin.assignedRfids')}</Typography>
        <List
          style={{
            overflow: 'auto',
            maxWidth: 360,
            height: '80vh',
          }}>
          {record?.chargingStationRFIDs?.map((rfid: ChargingStationRfid) => (
            <ListItemButton
              key={rfid.id}
              alignItems="flex-start"
              component="div">
              <ListItemText primary={rfid.tag} secondary={rfid.description} />
            </ListItemButton>
          ))}
        </List>
      </CardContent>
    </Card>
  );
};

export const BillingAccountCreate = (props: any) => (
  <Create {...props} mutationMode="pessimistic">
    <SimpleForm
      defaultValues={{
        paymentFrequency: BillingAccountPaymentFrequency.Weekly,
      }}>
      <Form {...props} />
    </SimpleForm>
  </Create>
);

export const BillingAccountEdit = (props: any) => {
  const translate = useTranslate();
  return (
    <Edit
      {...props}
      mutationMode="pessimistic"
      redirect={false}
      aside={<AssideContent />}>
      <SimpleForm>
        <TabbedShowLayout>
          <Tab label={translate('admin.basicInfo')} tabIndex={0}>
            <Form {...props} />
          </Tab>
          <Tab label={translate('admin.documents')} path={TABS.documents}>
            <Documents />
          </Tab>
        </TabbedShowLayout>
      </SimpleForm>
    </Edit>
  );
};
