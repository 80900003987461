import {
  AutocompleteArrayInput,
  NumberInput,
  ReferenceArrayInput,
  SelectInput,
  TimeInput,
  required,
  useCreate,
  useDataProvider,
  useRecordContext,
  useUpdate,
} from 'react-admin';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormLabel,
} from '@mui/material';
import {
  ChargingStationRuleProperty,
  ChargingStationTariff,
  ChargingStationTariffRule,
  ChargingStationTariffType,
} from '../../../@generated/schemas';
import TranslateInput, {
  transform as transformTranslate,
} from '../../../common/TranslationInput';
import { VFC, useEffect } from 'react';
import { isNil, omit } from 'lodash';

import CompareSelectOptions from '../../../libs/compareSelectOptions';
import dayjs from 'dayjs';
import formattedPrice from '../../../libs/formattedPrice';
import mapEnumSelectInput from '../../../libs/mapEnumSelectInput';
import sanitizeEmptyValues from '../../../libs/sanitizeEmptyValues';
import { useFormContext } from 'react-hook-form';
import { useTranslate } from '../../../locales';

const FormRuleSet: VFC<{
  title: string;
  rulesetName: string;
}> = ({ title, rulesetName }) => {
  const translate = useTranslate();
  const { watch } = useFormContext();
  const watchCompareFrom = watch(`${rulesetName}CompareFrom`);
  const watchValueFrom = watch(`${rulesetName}ValueFrom`);

  return (
    <FormGroup style={{ width: '100%' }}>
      <FormLabel>{title}</FormLabel>

      <FormGroup row>
        <SelectInput
          label={translate('admin.compare')}
          source={`${rulesetName}CompareFrom`}
          style={{
            width: '20%',
            minWidth: 'auto',
            marginRight: 8,
          }}
          choices={CompareSelectOptions}
        />
        <TimeInput
          source={`${rulesetName}ValueFrom`}
          label={translate('admin.from')}
          style={{ width: '25%', marginRight: 8 }}
        />
        <SelectInput
          disabled={isNil(watchCompareFrom) || isNil(watchValueFrom)}
          label={translate('admin.company')}
          source={`${rulesetName}CompareTo`}
          style={{
            width: '20%',
            minWidth: 'auto',
            marginRight: 8,
          }}
          choices={CompareSelectOptions}
        />
        <TimeInput
          disabled={isNil(watchCompareFrom) || isNil(watchValueFrom)}
          source={`${rulesetName}ValueTo`}
          label={translate('admin.to')}
          style={{ width: '25%', marginRight: 8 }}
        />
      </FormGroup>
    </FormGroup>
  );
};

const TariffDialog: VFC<{
  refetch: () => void;
  commission: number;
  showDialog: boolean | (Omit<ChargingStationTariff, 'id'> & { id?: string });
  setShowDialog: (showDialog: boolean) => void;
}> = ({ commission, showDialog, setShowDialog, refetch }) => {
  const record = useRecordContext();
  const translate = useTranslate();
  const { setValue, handleSubmit, watch, getValues } = useFormContext();
  const dataProvider = useDataProvider();
  const [createOne] = useCreate();
  const [updateOne] = useUpdate();

  const watchValues = watch();

  const handleCloseDialog = () => {
    setShowDialog(false);
    refetch();
  };

  const handleOnSubmit = async () => {
    // with input into this function we are not receiving all data properties from form
    const inputs = transformTranslate(getValues(), 'tariffName');

    const data = {
      name: inputs.tariffName,
      price: inputs.price,
      tariffType: inputs.tariffType,
      guaranteeAmount: inputs.guaranteeAmount,
      amountPerMinute: inputs.amountPerMinute,
      chargingStation: record.id,
      chargingStationRules: [],
      users: inputs.users || [],
      rfids: inputs.rfids || [],
    };

    if (inputs.timeCompareFrom && inputs.timeValueFrom) {
      const rule: Partial<ChargingStationTariffRule> = {
        property: ChargingStationRuleProperty.Time,
        fromCompareOperator: inputs.timeCompareFrom || null,
        fromValue: inputs.timeValueFrom
          ? dayjs(inputs.timeValueFrom).diff(dayjs().startOf('day'), 'minutes')
          : null,
        toCompareOperator: inputs.timeCompareTo || null,
        toValue: inputs.timeValueTo
          ? dayjs(inputs.timeValueTo).diff(dayjs().startOf('day'), 'minutes')
          : null,
      };
      if (inputs.timeId) {
        rule.id = inputs.timeId;
      }
      data.chargingStationRules.push(rule);
    }

    if (typeof showDialog === 'object' && showDialog.id) {
      const previousData = await dataProvider.getOne('ChargingStationTariff', {
        id: showDialog.id,
      });

      await updateOne(
        'ChargingStationTariff',
        {
          id: showDialog.id,
          data,
          previousData: omit(sanitizeEmptyValues(previousData.data), [
            'chargingStationTransactions',
            'chargingStationTransactionAppliedTariffs',
          ]),
        },
        { returnPromise: true },
      );
    } else {
      await createOne(
        'ChargingStationTariff',
        {
          data,
        },
        { returnPromise: true },
      );
    }

    refetch();
    handleCloseDialog();
  };

  useEffect(() => {
    async function initValues() {
      if (showDialog) {
        setValue('guaranteeAmount', null);
        setValue('amountPerMinute', null);
        setValue('tariffType', null);
        setValue('users', []);
        setValue('rfids', []);
        setValue('price', null);
        setValue('tariffName_en', null);
        setValue('tariffName_de', null);
        setValue('tariffName_it', null);
        setValue('tariffName_fr', null);

        ['time'].map((prefix) => {
          setValue(`${prefix}Id`, null);
          setValue(`${prefix}CompareFrom`, null);
          setValue(`${prefix}ValueFrom`, null);
          setValue(`${prefix}CompareTo`, null);
          setValue(`${prefix}ValueTo`, null);
        });
      }

      if (showDialog && typeof showDialog === 'object') {
        setValue('guaranteeAmount', showDialog.guaranteeAmount);
        setValue('amountPerMinute', showDialog.amountPerMinute);
        setValue('tariffType', showDialog.tariffType);
        setValue('price', showDialog.price);
        setValue(
          'users',
          showDialog.users.map((user) => user.id),
        );
        setValue(
          'rfids',
          showDialog.rfids.map((rfid) => rfid.id),
        );

        const translation = JSON.parse(showDialog.name || '{}');
        setValue('tariffName_en', translation.en || '');
        setValue('tariffName_de', translation.de || '');
        setValue('tariffName_it', translation.it || '');
        setValue('tariffName_fr', translation.fr || '');

        showDialog.chargingStationRules.map((rule) => {
          let prefix = '';

          if (rule.property === ChargingStationRuleProperty.Time) {
            prefix = 'time';
          }

          if (showDialog.id) {
            setValue(`${prefix}Id`, rule.id);
          }
          setValue(`${prefix}CompareFrom`, rule.fromCompareOperator);
          setValue(
            `${prefix}ValueFrom`,
            dayjs().startOf('day').add(rule.fromValue, 'minutes'),
          );
          setValue(`${prefix}CompareTo`, rule.toCompareOperator);
          setValue(
            `${prefix}ValueTo`,
            dayjs().startOf('day').add(rule.toValue, 'minutes'),
          );
        });
      }
    }
    initValues();
  }, [showDialog]);

  if (!showDialog) return null;

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={!!showDialog}
      title={translate('admin.createRuleSet')}
      onClose={handleCloseDialog}>
      <DialogTitle>
        {typeof showDialog === 'boolean' || !showDialog?.id
          ? translate('admin.createNewTariffRule')
          : translate('admin.updateNewTariffRule')}
      </DialogTitle>
      <DialogContent>
        <FormGroup>
          <SelectInput
            source="tariffType"
            title={translate('admin.tariffType')}
            validate={[required()]}
            style={{ maxWidth: 300 }}
            choices={mapEnumSelectInput(ChargingStationTariffType)}
          />
        </FormGroup>
        {watchValues?.tariffType === ChargingStationTariffType.Cards && (
          <FormGroup>
            <ReferenceArrayInput source="users" reference="User">
              <AutocompleteArrayInput
                optionText={(row) => row.email}
                label={translate('admin.users')}
              />
            </ReferenceArrayInput>
          </FormGroup>
        )}
        {watchValues?.tariffType === ChargingStationTariffType.Cards && (
          <FormGroup>
            <ReferenceArrayInput source="rfids" reference="ChargingStationRfid">
              <AutocompleteArrayInput
                optionText={(row) => row.tag}
                label={translate('admin.rfids')}
              />
            </ReferenceArrayInput>
          </FormGroup>
        )}
        <FormGroup>
          <TranslateInput source="tariffName" hideName />
        </FormGroup>

        <FormRuleSet title={translate('admin.time')} rulesetName="time" />

        <FormGroup>
          <NumberInput
            style={{ maxWidth: 300 }}
            source="price"
            validate={[required()]}
            title={translate('admin.pricePerkWh')}
          />
        </FormGroup>
        <FormGroup>
          <NumberInput
            style={{ maxWidth: 300 }}
            source="amountPerMinute"
            validate={[required()]}
            title={translate('admin.pricePerMinute')}
          />
        </FormGroup>
        {watchValues.tariffType === ChargingStationTariffType.QrCode && (
          <FormGroup>
            <NumberInput
              style={{ maxWidth: 300 }}
              source="guaranteeAmount"
              validate={[required()]}
              title={translate('admin.guaranteeAmount')}
            />
          </FormGroup>
        )}
        <FormGroup>
          {translate('admin.total')}: {formattedPrice(watchValues.price || 0)}
        </FormGroup>
        <FormGroup>
          {translate('admin.factoringFees')}:{' '}
          {formattedPrice(watchValues.price * ((100 - commission) / 100)) || 0}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(handleOnSubmit)} variant="contained">
          {translate('admin.save')}
        </Button>
        <Button onClick={handleCloseDialog}>{translate('admin.cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TariffDialog;
