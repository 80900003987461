import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from '@mui/material';
import { VehiclePhoto, VehiclePhotoType } from '../../../@generated/schemas';
import { sortBy, values } from 'lodash';
import {
  useDeleteImageMutation,
  useUploadImagesMutation,
} from '../../../@generated/hooks';
import { useEffect, useRef, useState } from 'react';
import {
  useGetList,
  useRecordContext,
  useRefresh,
  useUpdate,
} from 'react-admin';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';
import StarIcon from '@mui/icons-material/Star';
import getStoragePath from '../../../libs/getStoragePath';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import { useTranslate } from '../../../locales';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const GalleryImageField: React.VFC<
  VehiclePhoto & {
    isLastOne: boolean;
    deleteImage: (id: string) => void;
    makeProfile: (id: string) => void;
    move: (id: string, up: boolean) => void;
  }
> = (props) => {
  const { isLastOne, orderIndex, move, id, name, deleteImage, makeProfile } =
    props;
  let positionTitle = 'Main photo';

  if (orderIndex !== 0) {
    positionTitle = `Position ${orderIndex}`;
  }

  return (
    <Card sx={{ width: '20%', margin: '0 12px' }}>
      <CardMedia
        component="img"
        height="140"
        image={getStoragePath(`gallery/${name}`)}
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          {positionTitle}
        </Typography>
      </CardContent>
      <CardActions>
        <IconButton disabled={orderIndex === 0} onClick={() => move(id, false)}>
          <ArrowBackIcon />
        </IconButton>
        <IconButton disabled={isLastOne} onClick={() => move(id, true)}>
          <ArrowForwardIcon />
        </IconButton>
        {orderIndex !== 0 && (
          <IconButton onClick={() => makeProfile(id)}>
            <StarIcon />
          </IconButton>
        )}
        <IconButton
          style={{ marginLeft: 'auto' }}
          onClick={() => deleteImage(id)}>
          <DeleteIcon />
        </IconButton>
      </CardActions>
    </Card>
  );
};

export const UploadButton: React.VFC<{
  vehicleId?: string;
  addImages: (images: VehiclePhoto[]) => void;
}> = ({ addImages, vehicleId }) => {
  const translate = useTranslate();
  const [upload] = useUploadImagesMutation();
  const uploadInputRef = useRef<any>();

  const onChange = async (event) => {
    const files = event.target.files;

    try {
      const response = await upload({
        variables: {
          files,
          vehicleId,
        },
      });

      if (response?.data?.uploadImages.length > 0) {
        addImages(response?.data?.uploadImages);
      }

      uploadInputRef.current.value = null;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <label htmlFor="upload-file">
      <input
        accept="image/*"
        ref={uploadInputRef}
        id="upload-file"
        onChange={onChange}
        multiple
        type="file"
        hidden
      />
      <Button
        style={{
          marginBottom: 20,
        }}
        variant="contained"
        onClick={() => uploadInputRef?.current?.click()}>
        {translate('admin.addNewImage')}
        <CloudUploadIcon style={{ marginLeft: 8 }} />
      </Button>
    </label>
  );
};

export const VehicleGallery: React.VFC = () => {
  const { setValue } = useForm();
  const record = useRecordContext();
  const refresh = useRefresh();
  const [updateVehiclePhoto] = useUpdate();
  const [images, setImages] = useState<VehiclePhoto[]>([]);
  const [deleteImageMutation] = useDeleteImageMutation();

  const { data, refetch: refetchVehiclePhotos } = useGetList('VehiclePhoto', {
    pagination: { page: 1, perPage: 30 },
    sort: { field: 'orderIndex', order: 'asc' },
    filter: {
      vehicleId: record?.id || 'created',
      type: VehiclePhotoType.Gallery,
    },
  });

  useEffect(() => {
    if (record?.id) {
      const _data = values(data || {}) as VehiclePhoto[];
      setImages(_data.filter((item) => item.type === VehiclePhotoType.Gallery));
    }
  }, [data, record]);

  const updateImages = async (originalImages: VehiclePhoto[]) => {
    const newImages = sortBy(originalImages, ['orderIndex']).map(
      (image, orderIndex) => ({
        ...image,
        orderIndex,
      }),
    );

    if (record?.id) {
      const promiseUpdate = await newImages.map((image: any) => {
        delete image?.vehicle_id;
        return updateVehiclePhoto(
          'VehiclePhoto',
          {
            id: image.id,
            data: image,
          },
          { returnPromise: true },
        );
      });

      await Promise.all(promiseUpdate);

      refetchVehiclePhotos();
      refresh();
    } else {
      setImages(newImages);
      setValue('vehiclePhotos', newImages);
    }
  };

  const addImages = (newImages: VehiclePhoto[]) => {
    updateImages([...images, ...newImages]);
  };

  const deleteImage = async (id: string) => {
    const newImages = images.filter((image) => image.id !== id);

    if (record?.id) {
      await deleteImageMutation({
        variables: {
          vehiclePhotoId: id,
          vehicleId: record.id as string,
        },
      });
    }

    updateImages(newImages);
  };

  const makeProfile = (id: string) => {
    const newImages = images.map((image) => {
      if (image.id === id) {
        // preparation for reordering
        return { ...image, orderIndex: -1 };
      }
      return image;
    });

    updateImages(newImages);
  };

  const move = (id: string, up: boolean) => {
    const newImages = images.map((image) => {
      if (image.id === id) {
        return { ...image, orderIndex: image.orderIndex + (up ? 1.5 : -1.5) };
      }
      return image;
    });

    updateImages(newImages);
  };

  return (
    <div>
      <UploadButton vehicleId={record?.id as string} addImages={addImages} />
      <Row>
        {sortBy(images, ['orderIndex']).map((image, key) => (
          <GalleryImageField
            key={image.id}
            {...image}
            deleteImage={deleteImage}
            move={move}
            makeProfile={makeProfile}
            isLastOne={images.length - 1 === key}
          />
        ))}
      </Row>
    </div>
  );
};
