import {
  Datagrid,
  EditButton,
  FunctionField,
  List,
  TextField,
} from 'react-admin';

import CustomPagination from '../../../CustomPagination';
import { useTranslate } from '../../../locales';

export const ChargingStationList = () => {
  const translate = useTranslate();
  return (
    <List
      title="EVE's"
      hasCreate
      filter={{
        deletedAt: {
          equals: null,
        },
      }}
      pagination={<CustomPagination />}>
      <Datagrid>
        <FunctionField
          source="name"
          label={translate('admin.name')}
          render={(row) =>
            JSON.parse(row.name || '{}')?.en ||
            JSON.parse(row.name || '{}')?.de ||
            JSON.parse(row.name || '{}')?.it ||
            JSON.parse(row.name || '{}')?.fr
          }
        />
        <TextField source="evesId" label={translate('admin.evesId')} />
        <TextField source="status" label={translate('admin.status')} />
        <TextField
          label={translate('admin.company')}
          source="chargingStationCluster.company.name"
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};
