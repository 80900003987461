import { Booking, Vehicle } from "../../@generated/schemas";
import { ConfigFormValues, SchedulerProjectData, ZoomLevel } from "../../libs/scheduler/types/global";
import { useCallback, useEffect, useMemo, useState } from "react";

import { LoadingElement } from "../../App";
import { ParsedDatesRange } from "../../libs/scheduler/utils/getDatesRange";
import { Scheduler } from "../../libs/scheduler";
import { SchedulerData } from "../../libs/scheduler";
import dayjs from "dayjs";
import styled from "styled-components";
import { useGetList } from "react-admin";

export const StyledSchedulerFrame = styled.div`
  position: relative;
  height: calc(100vh - 100px);
  width: (100vw - 200px);
`;

export const LoadingElementWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.5);
`;

export const BookingScheduler = () => {
  const [values, setValues] = useState({
    startDate: undefined,
    loading: true,
  });

  const [schedulerData, setSchedulerData] = useState<SchedulerData>([]);

  const [range, setRange] = useState<ParsedDatesRange>({
    startDate: dayjs().startOf('day').toDate(),
    endDate: dayjs().endOf('day').toDate()
  });

  const { data: bookings, isLoading } = useGetList<Booking>("Booking", {
    pagination: {
      page: 1, perPage: 10000
    },
    filter: {
      startDate: {
        gte: range.startDate
      },
      endDate: {
        lte: range.endDate
      },
    }
  });

  const { data: vehicles } = useGetList<Vehicle>("Vehicle", {
    pagination: {
      page: 1, perPage: 10000
    },
    filter: {
      deletedAt: {
        equals: null
      }
    }
  });

  useEffect(() => {
    console.log('isLoading', isLoading);
    setValues({
      ...values,
      loading: isLoading
    });
  }, [
    isLoading
  ]);

  const handleRangeChange = useCallback((range: ParsedDatesRange) => {
    // setValues({
    //   ...values,
    //   loading: true
    // });
    console.log('handleRangeChange - ', range);

    setRange(range);
  }, []);

  useEffect(
    () => {
      const _vehicles = {};
      for (const vehicle of (vehicles || [])) {
        if (!_vehicles[vehicle.id]) {
          _vehicles[vehicle.id] = {
            id: vehicle.id,
            label: {
              title: vehicle.plateNumber,
              subtitle: `${vehicle.brandType.name} ${vehicle.modelType.name}`
            },
            collapsed: true,
            data: []
          };
        }
      }

      for (const booking of (bookings || [])) {
        if (_vehicles[booking.vehicleId]) {
          _vehicles[booking.vehicleId].data.push({
            id: booking.id,
            startDate: booking.startDate,
            endDate: booking.endDate,
            title: `${booking.user.lastName} ${booking.user.firstName}`,
            description: booking.bookingHash,
            subtitle: ''
          });
        }
      }

      // console.log('tuu', Object.values(_vehicles) || []);
      setSchedulerData(Object.values(_vehicles) || []);
    },
    [bookings, vehicles, range.endDate, range.startDate]
  );

  const handleFilterData = () => console.log(`Filters button was clicked.`);

  const handleTileClick = (data: SchedulerProjectData) =>
    console.log(
      `Item ${data.title} - ${data.subtitle} was clicked. \n==============\nStart date: ${data.startDate} \n==============\nEnd date: ${data.endDate}\n==============\nOccupancy: ${data.occupancy}`
    );

  const onVehicleClick = (vehicleId: string) => {
    console.log('onVehicleClick', vehicleId);
    setSchedulerData((prevData) => {
      return prevData.map((vehicle) => {
        if (vehicle.id === vehicleId) {
          return {
            ...vehicle,
            collapsed: !vehicle.collapsed
          };
        }

        return vehicle;
      });
    });
  }
  console.log('values', values);
  return (
    <StyledSchedulerFrame>
      {values.loading ? <LoadingElementWrapper><LoadingElement /></LoadingElementWrapper> : null}
      <Scheduler
        startDate={values.startDate ? new Date(values.startDate).toISOString() : undefined}
        onRangeChange={handleRangeChange}
        data={schedulerData}
        isLoading={values.loading}
        onVehicleClick={onVehicleClick}
        onTileClick={handleTileClick}
        onFilterData={handleFilterData}
        config={{ zoom: 1, showThemeToggle: true }}
        onItemClick={(data) => console.log("clicked: ", data)}
      />
    </StyledSchedulerFrame>
  );
};

