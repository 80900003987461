import { Create, SimpleForm, useGetIdentity } from 'react-admin';
import { SharedVehicleGroupForm, getTransformedData } from './form';

import { UserRole } from '../../@generated/schemas';

export const SharedVehicleGroupCreate = (props: any) => {
  const { identity } = useGetIdentity();

  return (
    <Create
      {...props}
      transform={getTransformedData}
      mutationMode="pessimistic">
      <SimpleForm
        defaultValues={{
          discount: 0,
          '@@ra-many-to-many/SharedVehicleGroup/SharedVehicleGroupRelCompany/Company':
            identity?.userRole && identity?.userRole !== UserRole.SuperAdmin
              ? [identity.companyId]
              : [],
        }}>
        <SharedVehicleGroupForm />
      </SimpleForm>
    </Create>
  );
};
