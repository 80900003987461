import {
  ArrayInput,
  BooleanInput,
  NumberInput,
  SimpleFormIterator,
  TextInput,
  useRecordContext,
} from 'react-admin';
import { Box, Button, Grid, Modal, TextField, Typography } from '@mui/material';
import {
  Circle,
  GoogleMap,
  Marker,
  StandaloneSearchBox,
} from '@react-google-maps/api';
import { useEffect, useState } from 'react';

import AddCircleOutline from '@mui/icons-material/AddCircleOutline';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { useTranslate } from '../../../locales';

export const DefaultLocation = {
  latitude: 47.3769,
  longitude: 8.5417,
};

const GoogleMapsInputWrapper = styled.div`
  position: absolute;
  top: 65px;
  left: 10px;
  z-index: 100;
`;

const ModalContent = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
  background-color: white;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 12px;
`;

const VehicleLocations = () => {
  const record = useRecordContext();
  const { setValue, watch } = useFormContext();
  const locations = watch('vehicleLocation');
  const translate = useTranslate();
  const [opened, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [searchBoxRef, setSearchBoxRef] =
    useState<google.maps.places.SearchBox>();
  const [mapRef, setMapRef] = useState<google.maps.Map>();
  const geocoder = new google.maps.Geocoder();

  useEffect(() => {
    if (locations.length && mapRef) {
      const bounds = new window.google.maps.LatLngBounds();
      locations.forEach((location) => {
        bounds.extend({
          lat: location.latitude,
          lng: location.longitude,
        });
      });
      mapRef.fitBounds(bounds, 10);
      mapRef.setZoom(8);
    }
  }, [locations, mapRef]);

  const [bounds] = useState();
  const [position, setPosition] = useState({
    ...DefaultLocation,
    radius: 100,
    name: '',
    isDefault: true,
    vehicleId: record?.id,
  });

  const handleSearchboxChanged = () => {
    const places = searchBoxRef.getPlaces();
    const firstPlace = places[0];

    const lat = firstPlace.geometry.location.lat();
    const lng = firstPlace.geometry.location.lng();

    setPosition({
      ...position,
      name: firstPlace.formatted_address,
      latitude: lat,
      longitude: lng,
    });
  };

  const handleUpdateLocationField = (vehicleLocation) => {
    setValue('vehicleLocation', vehicleLocation);
  };

  const handleAddLocation = () => {
    const newLocations = locations.map((location) => ({
      ...location,
      isDefault: false,
    }));
    handleClose();
    handleUpdateLocationField(newLocations.concat(position));
  };

  return (
    <div>
      <Grid container spacing={8}>
        <Grid item md={6}>
          <ArrayInput source="vehicleLocation" label={translate('admin.vehicleLocation')}>
            <SimpleFormIterator
              disableReordering
              disableAdd
              disableClear
              inline>
              <BooleanInput source="isDefault" label={translate('admin.isDefault')} />
              <TextInput source="name" label={translate('admin.name')} />
              <NumberInput source="radius" label={translate('admin.radius')} />
            </SimpleFormIterator>
          </ArrayInput>
          <Button
            variant="contained"
            onClick={handleOpen}
            startIcon={<AddCircleOutline />}>
            {translate('admin.addNewLocation')}
          </Button>
        </Grid>
        <Grid item md={6}>
          <GoogleMap
            onLoad={setMapRef}
            mapContainerStyle={{
              width: '100%',
              height: 400,
            }}
            center={{
              lat: position.latitude,
              lng: position.longitude,
            }}
            zoom={8}>
            {locations.map((location, key) => (
              <Marker
                key={key}
                position={{
                  lat: location.latitude,
                  lng: location.longitude,
                }}
              />
            ))}
            {locations.map((location, key) => (
              <Circle
                key={key}
                center={{
                  lat: location.latitude,
                  lng: location.longitude,
                }}
                radius={location.radius}
              />
            ))}
          </GoogleMap>
        </Grid>
      </Grid>

      <Modal open={opened} onClose={handleClose}>
        <ModalContent>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ marginBottom: 24 }}>
            {translate('admin.addNewLocation')}
          </Typography>

          <GoogleMap
            mapContainerStyle={{
              width: '100%',
              height: 400,
            }}
            center={{
              lat: position.latitude,
              lng: position.longitude,
            }}
            zoom={16}>
            <GoogleMapsInputWrapper>
              <StandaloneSearchBox
                onLoad={setSearchBoxRef}
                bounds={bounds}
                onPlacesChanged={handleSearchboxChanged}>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder={translate('admin.searchAddress')}
                  style={{ background: 'white' }}
                />
              </StandaloneSearchBox>
            </GoogleMapsInputWrapper>
            <Marker
              key={`${position.latitude}-${position.longitude}`}
              draggable
              onDragEnd={(e) => {
                geocoder.geocode({ location: e.latLng }).then((response) => {
                  if (response.results[0]) {
                    setPosition({
                      ...position,
                      latitude: e.latLng.lat(),
                      longitude: e.latLng.lng(),
                      name: response.results[0].formatted_address,
                    });
                  }
                });
              }}
              position={{
                lat: position.latitude,
                lng: position.longitude,
              }}
            />
          </GoogleMap>

          <Button
            onClick={handleClose}
            style={{ float: 'left', marginTop: 12 }}>
            {translate('admin.close')}
          </Button>

          <Button
            variant="contained"
            onClick={handleAddLocation}
            style={{ float: 'right', marginTop: 12 }}>
            {translate('admin.saveLocation')}
          </Button>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default VehicleLocations;
