import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  Link,
  List,
  ReferenceInput,
  useGetIdentity,
  useRecordContext,
  useRefresh,
  useUpdate,
} from 'react-admin';
import { Button, Chip, CircularProgress } from '@mui/material';

import CustomPagination from '../../CustomPagination';
import {
  UserRole,
  type PxlVisionManualVerification as PxlVisionManualVerificationType,
} from '../../@generated/schemas';
import { ResourceView } from '@ra-data-prisma/dataprovider';
import { VFC } from 'react';
import cookie from 'js-cookie';
import { gql } from '@apollo/client';
import { url } from 'src';

export const PxlVisionVerificationFilter = () => {
  const { identity } = useGetIdentity();
  const defaultUserFilter = {
    deletedAt: { equals: null },
    companyId:
      identity?.userRole && identity?.userRole !== UserRole.SuperAdmin
        ? {
            equals: identity.companyId,
          }
        : {},
  };

  return (
    <Filter>
      <ReferenceInput
        source="userId"
        reference="User"
        alwaysOn
        filter={defaultUserFilter}>
        <AutocompleteInput optionText={(choice: any) => choice?.email} />
      </ReferenceInput>
    </Filter>
  );
};

const VerifyButton: VFC = () => {
  const record = useRecordContext<PxlVisionManualVerificationType>();
  const { identity } = useGetIdentity();
  const refreshData = useRefresh();
  const [update, { isLoading }] = useUpdate();

  const handleClick = async () => {
    await update(
      'PxlVisionManualVerification',
      {
        id: record.id,
        data: {
          verifiedByUser: record?.verifiedAt ? null : identity?.id,
          verifiedAt: record?.verifiedAt
            ? {
                set: null,
              }
            : new Date(),
        },
      },
      { returnPromise: true },
    );

    refreshData();
  };

  return (
    <Button
      variant="text"
      disabled={isLoading}
      onClick={handleClick}
      color="primary">
      {isLoading && (
        <CircularProgress style={{ height: 20, width: 20, marginRight: 4 }} />
      )}
      {record?.verifiedAt ? 'Unverify' : 'Verified'}
    </Button>
  );
};

const DownloadPxlVision = () => {
  const record = useRecordContext<PxlVisionManualVerificationType>();
  return (
    (record && (
      <a
        target="_blank"
        href={`${url}/admin/pxl-vision-verification/${
          record.transactionCode
        }?access_token=${cookie.get('access_token')}`}
        download>
        Download
      </a>
    )) ||
    null
  );
};

export const PxlVisionVerificationList = () => {
  const { identity } = useGetIdentity();
  const defaultFilter = {
    deletedAt: { equals: null },
    user:
      identity?.userRole && identity?.userRole !== UserRole.SuperAdmin
        ? {
            is: {
              companyId: {
                equals: identity.companyId,
              },
            },
          }
        : {},
  };

  return (
    <List
      filters={<PxlVisionVerificationFilter />}
      filter={defaultFilter}
      pagination={<CustomPagination />}>
      <Datagrid>
        <FunctionField
          label="User"
          render={(row) => (
            <Link to={`/User/${row.userId}`}>{row?.user?.email}</Link>
          )}
        />
        <DateField source="createdAt" showTime />
        <FunctionField
          label="Verified by"
          render={(row) => (
            <Link to={`/User/${row.verifiedByUserId}`}>
              {row?.verifiedByUser?.email}
            </Link>
          )}
        />
        <FunctionField
          label="Type"
          render={(row) => <Chip label={row?.type} />}
        />
        <DateField source="verifiedAt" />
        <VerifyButton />
        <DownloadPxlVision />
      </Datagrid>
    </List>
  );
};

export const PxlVisionManualVerification: ResourceView = {
  resource: 'PxlVisionManualVerification',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment PxlVisionManualVerificationFragment on PxlVisionManualVerification {
          user {
            id
            email
          }
          verifiedByUser {
            id
            email
          }
        }
      `,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment PxlVisionManualVerificationFragment on PxlVisionManualVerification {
          user {
            id
            email
          }
          verifiedByUser {
            id
            email
          }
        }
      `,
    },
  },
};
