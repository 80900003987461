import { SchedulerData, SchedulerProjectData } from '../types/global';

import mergeRanges from 'merge-ranges';
import { setProjectsInRows } from './setProjectsInRows';
import { sortBy } from 'lodash';

type ProjectsData = [
  projectsPerPerson: SchedulerProjectData[][][],
  rowsPerPerson: number[],
];

export const projectsOnGrid = (data: SchedulerData) => {
  const initialProjectsData: ProjectsData = [[], []];
  const [projectsPerPerson, rowsPerPerson] = data.reduce((acc, curr) => {
    if (!curr.collapsed) {
      // mergeRanges();
      console.log('curr.data', curr.data);
      console.log('merge', mergeRanges(
        curr.data.map((item) => [item.startDate, item.endDate]),
      ));
      const _merged = setProjectsInRows(mergeRanges(
        sortBy(curr.data.map((item) => [item.startDate, item.endDate]), (item) => item[0]),
      ).map((range, id) => ({
        id,
        startDate: range[0],
        endDate: range[1],
        title: '',
        subtitle: '',
        description: '',
      })));
      acc[0].push(_merged);
      acc[1].push(_merged.length);

      console.log('_merged', _merged);

      return acc;
    }

    const projectsInRows = setProjectsInRows(curr.data);

    acc[0].push(projectsInRows);
    acc[1].push(Math.max(projectsInRows.length, 1));

    return acc;
  }, initialProjectsData);
  return { projectsPerPerson, rowsPerPerson };
};
