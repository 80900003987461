import { SelectInput, useGetList } from 'react-admin';

import { useFormContext } from 'react-hook-form';

const CompanyInput = (props) => {
  const { watch } = useFormContext();
  const { value, onChange, source } = props;
  const { data } = useGetList('Company');

  const selectedValue = watch(source);

  return (
    <SelectInput
      value={value}
      source={source}
      emptyText="Drivemycar"
      placeholder="Drivemycar"
      onChange={onChange}
      label={selectedValue ? 'Company' : 'Drivemycar'}
      choices={data || []}
      variant="outlined"
      size="small"
      fullWidth
    />
  );
};

export default CompanyInput;
