import { SelectInput as AdminSelectInput, SelectInputProps } from 'react-admin';

import { getValidate } from '../libs/checkRequiredField';
import { useFormContext } from 'react-hook-form';

export type CustomSelectInputProps = SelectInputProps & {
  requiredFieldsList?: string[];
  error?: string | any;
};

const SelectInput = (props: CustomSelectInputProps) => {
  const { getFieldState } = useFormContext();
  const fieldState = getFieldState(props.source);
  const validate = getValidate(props);
  const errorMessage = props.error || fieldState?.error?.message || '';

  return (
    <AdminSelectInput
      {...props}
      variant="outlined"
      size="small"
      validate={validate}
      className={!!errorMessage ? 'form-has-error' : undefined}
      helperText={errorMessage}
      style={{ flex: 1, ...props.style }}
    />
  );
};

export default SelectInput;
