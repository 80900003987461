import { ResourceView } from '@ra-data-prisma/dataprovider';
import { gql } from '@apollo/client';

export const VehicleInsuranceType: ResourceView = {
  resource: 'VehicleInsuranceType',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment VehicleInsuranceTypeFragment on vehicleInsuranceType {
          vehicleInsuranceRuleSets {
            id
            ownership
            deductiblePerCollision
            deductiblePerPartialCover
            amount
            updatedAt
            vehicleInsuranceRules {
              property
              fromCompareOperator
              fromValue
              toCompareOperator
              toValue
            }
          }
        }
      `,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment VehicleInsuranceTypeFragment on VehicleInsuranceType {
          vehicleInsuranceRuleSets {
            id
            ownership
            deductiblePerCollision
            deductiblePerPartialCover
            amount
            updatedAt
            vehicleInsuranceRules {
              property
              fromCompareOperator
              fromValue
              toCompareOperator
              toValue
            }
          }
        }
      `,
    },
  },
};
