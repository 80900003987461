import {
  Box,
  Button,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { useNotify, useRecordContext } from 'react-admin';

import styled from 'styled-components';
import { useOpenGateControllerMutation } from '../../../@generated/hooks';
import { useState } from 'react';
import { useTranslate } from '../../../locales';

const ModalContent = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  background-color: white;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 12px;
`;

export const OpenGateControllerModal = () => {
  const record = useRecordContext();
  const notify = useNotify();
  const translate = useTranslate();
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState('');

  const [openGateController] = useOpenGateControllerMutation();

  const handleSaveReason = async () => {
    await openGateController({
      variables: { gateId: record.gateId, comment },
    });

    notify(translate('admin.gateWasSuccessfullyOpened'), { type: 'success' });

    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <ModalContent>
          <Typography
            id="modal-modal-title"
            variant="h6"
            component="h2"
            style={{ marginBottom: 24 }}>
            {translate('admin.whyGateShouldBeOpened')}
          </Typography>

          <div>
            <TextField
              value={comment}
              style={{width: '100%'}}
              rows={4}
              onChange={(event) => setComment(event.target.value)}
              label={translate('admin.comment')}
            />
          </div>

          <Button
            onClick={handleClose}
            style={{ float: 'left', marginTop: 12 }}>
            {translate('admin.close')}
          </Button>

          <Button
            variant="contained"
            disabled={!comment}
            onClick={handleSaveReason}
            style={{ float: 'right', marginTop: 12 }}>
            {translate('admin.openGate')}
          </Button>
        </ModalContent>
      </Modal>
      <Button
        variant="contained"
        style={{ marginLeft: 8 }}
        onClick={() => setOpen(true)}>
        {translate('admin.openGate')}
      </Button>
    </>
  );
};
