import {
  BooleanInput,
  SelectArrayInput,
  useGetList,
  useNotify,
  useRecordContext,
} from 'react-admin';
import {
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import {
  Ownership,
  Vehicle,
  VehicleConnectionType,
} from '../../../@generated/schemas';
import {
  useCloudBoxxStatusLogsQuery,
  useVehicleCloudBoxxActivateMutation,
  useVehicleCloudBoxxConnectMutation,
  useVehicleCloudBoxxDisconnectMutation,
} from '../../../@generated/hooks';

import { AssignedSharedVehicleGroupList } from '../../sharedVehicleGroup/assignedList';
import NumberInput from '../../../common/NumberInput';
import { ReferenceManyToManyInput } from '@react-admin/ra-relationships';
import { RichTextInput } from 'ra-input-rich-text';
import SelectInput from '../../../common/SelectInput';
import TextInput from '../../../common/TextInput';
import TranslateInput from '../../../common/TranslationInput';
import TypeInput from '../../../common/TypeInput';
import dayjs from 'dayjs';
import mapEnumSelectInput from '../../../libs/mapEnumSelectInput';
import { uniqBy } from 'lodash';
import { useFormContext } from 'react-hook-form';
import { useInsuranceCheckForVehicle } from './hooks/useInsuranceCheckForVehicle';
import { useSelectOwnInsurance } from './hooks/useSelectOwnInsurance';
import { useTranslate } from '../../../locales';

export const requiredFields = [
  'connectionType',
  'qnr',
  'smartcarVehicle',
  'catalogPrice',
  'initialOdometer',
  'valueOfAccessories',
  'trunkDimension',
  'fuelTypeId',
  'transmissionTypeId',
  'driveTypeId',
  'doorTypeId',
  'vehicleTypeId',
  'vehicleInsuranceTypeId',
];

const VehicleAdditionalInfo = (props) => {
  const { options } = props;
  const record = useRecordContext<Vehicle>();
  const { getValues, setValue, watch } = useFormContext();
  const notify = useNotify();
  const { data: vehicleInsuranceTypes = [] } = useGetList(
    'VehicleInsuranceType',
    {
      filter: { isPrivate: false },
    },
  );
  const translate = useTranslate();

  const qnrValue = watch('qnr');
  const smartcarVehicleValue = watch('smartcarVehicle');
  const connectionTypeValue = watch('connectionType');
  const vinValue = watch('vin');
  const ownInsuranceValue = watch('ownInsurance');
  const vehicleInsuranceTypeValue = watch('vehicleInsuranceType');

  const handleOnSelectOwnInsurance = useSelectOwnInsurance(
    vehicleInsuranceTypeValue,
  );
  const { vehicleInsuranceOption } = useInsuranceCheckForVehicle();

  const cloudBoxxActivatedAtValue = getValues('cloudBoxxActivatedAt');
  const [connect, { loading: connectLoading }] =
    useVehicleCloudBoxxConnectMutation();
  const [disconnect, { loading: disconnectLoading }] =
    useVehicleCloudBoxxDisconnectMutation();
  const [activate, { loading: activateLoading }] =
    useVehicleCloudBoxxActivateMutation();
  const {
    data: { cloudBoxxStatusLogs } = { cloudBoxxStatusLogs: [] },
    refetch: refetchCloudBoxxStatusLogs,
  } = useCloudBoxxStatusLogsQuery({
    variables: {
      vehicleId: record?.id as string,
    },
    skip: !record?.id,
  });

  const disableActionButtons =
    activateLoading || connectLoading || disconnectLoading;

  const handleConnectCloudboxx = async () => {
    if (qnrValue) {
      await disconnect({ variables: { vehicleId: record?.id as string } });
      setValue('qnr', '');
      setValue('cloudBoxxActivatedAt', null);
    } else {
      try {
        const response = await connect({
          variables: {
            vin: vinValue || record?.vin,
            vehicleId: record?.id as string,
          },
        });
        setValue('qnr', response.data.vehicleCloudBoxxConnect.qnr);
        setValue(
          'cloudBoxxActivatedAt',
          response.data.vehicleCloudBoxxConnect.isActivated ? new Date() : null,
        );
      } catch (error) {
        notify(error.message, { type: 'error' });
      }
    }

    refetchCloudBoxxStatusLogs();
  };

  const handleActiveCloudboxx = async () => {
    await activate({
      variables: {
        qnr: qnrValue,
        activate: !cloudBoxxActivatedAtValue,
        vehicleId: record?.id as string,
      },
    });

    refetchCloudBoxxStatusLogs();
    setValue(
      'cloudBoxxActivatedAt',
      cloudBoxxActivatedAtValue ? null : new Date(),
    );
  };

  return (
    <div>
      <Grid container spacing={8}>
        <Grid item md={4}>
          <SelectInput
            source="connectionType"
            label={translate('admin.connectionType')}
            choices={mapEnumSelectInput(VehicleConnectionType)}
          />
          {connectionTypeValue === VehicleConnectionType.Cloudboxx ? (
            <>
              <TextInput disabled source="qnr" label={translate('admin.qnr')} style={{ width: '100%' }} />
              <div style={{ marginBottom: 20, padding: '0 8px' }}>
                <Button
                  variant={qnrValue ? 'outlined' : 'contained'}
                  onClick={handleConnectCloudboxx}
                  disabled={disableActionButtons}
                  style={{ right: 8 }}>
                  {qnrValue ? translate('admin.disconnect') : translate('admin.connect')}
                </Button>
                {qnrValue && (
                  <Button
                    variant={
                      cloudBoxxActivatedAtValue ? 'outlined' : 'contained'
                    }
                    onClick={handleActiveCloudboxx}
                    disabled={disableActionButtons}>
                    {cloudBoxxActivatedAtValue ? translate('admin.deactivate') : translate('admin.activate')}
                  </Button>
                )}
              </div>
            </>
          ) : null}
          {connectionTypeValue === VehicleConnectionType.Convadis ? (
            <>
              <TextInput source="convadisVehicle" label={translate('admin.convadisVehicle')} style={{ width: '100%' }} />
              <TextInput source="convadisOrganisation" label={translate('admin.convadisOrganisation')} style={{ width: '100%' }} />
              <TextInput source="convadisAppKey" label={translate('admin.convadisAppKey')} style={{ width: '100%' }} />
              <BooleanInput source="enabledRfidCard1" label={translate('admin.enabledRfidCard1')} />
              <BooleanInput source="enabledRfidCard2" label={translate('admin.enabledRfidCard2')} />
            </>
          ) : null}
          {connectionTypeValue === VehicleConnectionType.Smartcar ? (
            <a target="_blank" href={record.smartcarConnectAuthUrl}>
              <Button
                style={{ marginTop: 9, marginLeft: 20 }}
                variant={smartcarVehicleValue ? 'outlined' : 'contained'}
                disabled={smartcarVehicleValue}>
                {smartcarVehicleValue ? translate('admin.connected') : translate('admin.connect')}
              </Button>
            </a>
          ) : null}

          <BooleanInput source="enabledSmsAlerts" label={translate('admin.enabledSmsAlerts')} />
          <List
            sx={{
              width: '100%',
              position: 'relative',
              overflow: 'auto',
              maxHeight: 200,
              marginBottom: 4,
              '& ul': { padding: 0 },
            }}>
            {cloudBoxxStatusLogs.map((log) => (
              <ListItem key={log.id}>
                {log.status !== null && (
                  <ListItemText
                    primary={`${log.status ? translate('admin.activated') : translate('admin.deactivated')} ${translate('admin.by')} ${log.user.email
                      }`}
                    secondary={dayjs(log.createdAt).format(
                      'DD.MM. YYYY HH:mm:ss',
                    )}
                  />
                )}
                {log.connected !== null && (
                  <ListItemText
                    primary={`${log.connected ? translate('admin.connected') : translate('admin.diconnected')
                      } by ${log.user.email}`}
                    secondary={dayjs(log.createdAt).format(
                      'DD.MM. YYYY HH:mm:ss',
                    )}
                  />
                )}
              </ListItem>
            ))}
          </List>

          <SelectInput
            fullWidth
            source="ownership"
            label={translate('admin.ownership')}
            choices={[
              {
                id: Ownership.Private,
                name: translate('admin.private'),
              },
              {
                id: Ownership.Company,
                name: translate('admin.company'),
              },
            ]}
          />
          <BooleanInput
            source="ownInsurance"
            label={translate('admin.ownInsurance')}
            onChange={handleOnSelectOwnInsurance}
          />
          {!ownInsuranceValue && (
            <SelectInput
              source="vehicleInsuranceTypeId"
              label={translate('admin.vehicleInsuranceType')}
              fullWidth
              requiredFieldsList={requiredFields}
              choices={vehicleInsuranceTypes.map(vehicleInsuranceOption)}
            />
          )}
        </Grid>
        <Grid item md={4}>
          <NumberInput
            source="catalogPrice"
            label={translate('admin.catalogPrice')}
            requiredFieldsList={requiredFields}
            style={{ width: '100%' }}
            onChange={(event) => {
              setValue(
                'valueOfAccessories',
                parseInt(Number(event.target.value * 0.1).toFixed(0)),
              );
            }}
          />
          <NumberInput
            source="initialOdometer"
            label={translate('admin.initialOdometer')}
            requiredFieldsList={requiredFields}
            style={{ width: '100%' }}
          />
          <NumberInput
            source="valueOfAccessories"
            label={translate('admin.valueOfAccessories')}
            requiredFieldsList={requiredFields}
            style={{ width: '100%' }}
          />
          <TextInput
            source="trunkDimension"
            label={translate('admin.trunkDimension')}
            requiredFieldsList={requiredFields}
            style={{ width: '100%' }}
          />
          <TypeInput
            source="energyEfficiencyTypeId"
            label={translate('admin.energyEfficiencyType')}
            requiredFieldsList={requiredFields}
            data={options?.energyEfficiencyTypes}
            reference="EnergyEfficiencyType"
            style={{ width: '100%' }}
          />
          <TypeInput
            source="emissionCodeTypeId"
            label={translate('admin.emissionCodeType')}
            requiredFieldsList={requiredFields}
            data={options?.emissionCodeTypes}
            reference="EmissionCodeType"
            style={{ width: '100%' }}
          />
        </Grid>
        <Grid item md={4}>
          <TypeInput
            source="fuelTypeId"
            label={translate('admin.fuelType')}
            requiredFieldsList={requiredFields}
            data={options?.fuelTypes}
            reference="FuelType"
            style={{ width: '100%' }}
          />
          <TypeInput
            source="transmissionTypeId"
            label={translate('admin.transmissionType')}
            requiredFieldsList={requiredFields}
            reference="TransmissionType"
            data={options?.transmissionTypes}
            style={{ width: '100%' }}
          />
          <TypeInput
            source="driveTypeId"
            label={translate('admin.driveType')}
            requiredFieldsList={requiredFields}
            data={options?.driveTypes}
            reference="DriveType"
            style={{ width: '100%' }}
          />
          <TypeInput
            source="doorTypeId"
            label={translate('admin.doorType')}
            requiredFieldsList={requiredFields}
            data={options?.doorTypes}
            reference="DoorType"
            style={{ width: '100%' }}
          />
          <TypeInput
            source="vehicleTypeId"
            label={translate('admin.vehicleType')}
            requiredFieldsList={requiredFields}
            data={options?.vehicleTypes}
            reference="VehicleType"
            style={{ width: '100%' }}
          />
          <TextInput
            source="fuelPassword"
            label={translate('admin.fuelPassword')}
            requiredFieldsList={requiredFields}
            style={{ width: '100%' }}
          />
        </Grid>
      </Grid>

      <Typography
        variant="subtitle1"
        style={{ marginRight: 12, paddingTop: 14 }}>
        {translate('admin.rules')}
      </Typography>
      <Divider style={{ marginBottom: 20 }} />
      <TranslateInput source="rules" useRich hideName />

      <Typography
        variant="subtitle1"
        style={{ marginRight: 12, paddingTop: 14 }}>
        {translate('admin.bookingInformation')}
      </Typography>
      <Divider style={{ marginBottom: 20 }} />
      <RichTextInput source="bookingInformation" label={translate('admin.bookingInformation')} />

      <Typography
        variant="subtitle1"
        style={{ marginRight: 12, paddingTop: 14 }}>
        {translate('admin.equipments')}
      </Typography>
      <Divider style={{ marginBottom: 20 }} />

      <ReferenceManyToManyInput
        resource="Vehicle"
        perPage={100}
        reference="EquipmentType"
        through="VehicleEquipment"
        source="id"
        perPageChoices={100}
        record={{ id: record?.id }}
        using="vehicleId,equipmentTypeId">
        <SelectArrayInput label={translate('admin.equipments')} optionText="name" fullWidth />
      </ReferenceManyToManyInput>

      <Typography
        variant="subtitle1"
        style={{ marginRight: 12, paddingTop: 14 }}>
        {translate('admin.assignedSvgs')}
      </Typography>
      <Divider style={{ marginBottom: 20 }} />
      <AssignedSharedVehicleGroupList
        sharedVehicleGroupRels={uniqBy(
          [
            ...(record?.sharedVehicleGroupRelVehicle || []),
            ...(record?.company?.sharedVehicleGroupRelCompany || []),
            ...(record?.company?.defaultSharedVehicleGroup
              ? [
                {
                  id: '',
                  sharedVehicleGroup:
                    record?.company?.defaultSharedVehicleGroup,
                },
              ]
              : []),
          ],
          'sharedVehicleGroup.name',
        ).filter(item => item.sharedVehicleGroup.deletedAt === null)}
      />
    </div>
  );
};

export default VehicleAdditionalInfo;
