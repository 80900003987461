import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { useEffect, useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import { LoadingElement } from '../../App';
import styled from 'styled-components';
import { useGetVehicleStatusLazyQuery } from '../../@generated/hooks';
import { useTranslate } from '../../locales';

export const LoadingElementWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.5);
`;

export const SchedulerVehicleMap = ({ vehicleId, handleClose }) => {
  const translate = useTranslate();
  const [loading, setLoading] = useState(false);
  const [useGetVehicleStatusQuery] = useGetVehicleStatusLazyQuery();
  const [position, setPosition] = useState({
    latitude: 0,
    longitude: 0,
  });

  useEffect(() => {
    async function init() {
      if (vehicleId) {
        setLoading(true);
        const { data } = await useGetVehicleStatusQuery({
          variables: {
            vehicleId,
          },
        });

        setPosition({
          latitude: data?.getVehicleStatus?.latitude || null,
          longitude: data?.getVehicleStatus?.longitude || null,
        });
        setLoading(false);
      } else {
        setPosition({
          latitude: null,
          longitude: null,
        });
      }
    }
    init();
  }, [vehicleId]);

  return (
    <Dialog
      fullWidth
      open={!!vehicleId}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <DialogTitle>
        <div>{translate('admin.vehicleCurrentPosition')}</div>
        <IconButton
          style={{ position: 'absolute', right: 18, top: 14 }}
          onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent id="modal-modal-title">
        {loading ? (
          <LoadingElementWrapper>
            <LoadingElement />
          </LoadingElementWrapper>
        ) : null}
        <GoogleMap
          mapContainerStyle={{
            width: '100%',
            height: 400,
          }}
          center={{
            lat: position.latitude,
            lng: position.longitude,
          }}
          zoom={16}>
          <Marker
            key={`${position.latitude}-${position.longitude}`}
            draggable
            position={{
              lat: position.latitude,
              lng: position.longitude,
            }}
          />
        </GoogleMap>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'flex-start' }}>
        <Button onClick={handleClose}>{translate('admin.close')}</Button>
      </DialogActions>
    </Dialog>
  );
};
