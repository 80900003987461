import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  Link,
  List,
  ReferenceInput,
  TextInput,
} from 'react-admin';
import { Card, CardContent, Typography } from '@mui/material';

import CustomPagination from '../../../CustomPagination';
import ReactJson from 'react-json-view';
import { ResourceView } from '@ra-data-prisma/dataprovider';
import { gql } from '@apollo/client';
import { useTranslate } from '../../../locales';

const LogShow = (props) => {
  const { record } = props;
  const translate = useTranslate();
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h6" gutterBottom>
          {translate('admin.detail')}
        </Typography>
        <ReactJson src={JSON.parse(record.content)} />
      </CardContent>
    </Card>
  );
};

export const VehicleCloudboxxLogsFilter = (props: any) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <TextInput label={translate('admin.search')} source="q" alwaysOn />
      <TextInput source="vehicle.vin" label={translate('admin.vin')} />
      <TextInput source="content" label={translate('admin.eventBody')} />
      <ReferenceInput
        source="vehicleId"
        reference="Vehicle"
        label={translate('admin.vehicle')}>
        <AutocompleteInput
          optionText={(choice: any) => choice?.vin || translate('admin.clear')}
          label={translate('admin.vehicle')}
        />
      </ReferenceInput>
    </Filter>
  );
};

export const VehicleCloudboxxLogs = (props) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      filters={<VehicleCloudboxxLogsFilter />}
      pagination={<CustomPagination />}>
      <Datagrid expand={<LogShow />} bulkActionButtons={false}>
        <FunctionField
          source="vehicle.vin"
          label={translate('admin.vin')}
          render={(record) =>
            record?.vehicle?.id && (
              <Link to={`/Vehicle/${record.vehicle.id}`}>
                {record.vehicle.vin}
              </Link>
            )
          }
        />
        <FunctionField
          source="Event type"
          label={translate('admin.eventType')}
          render={(record) => JSON.parse(record.content).reasons.toString()}
        />
        <DateField
          source="createdAt"
          label={translate('admin.createdAt')}
          showTime
        />
      </Datagrid>
    </List>
  );
};

const fragment = gql`
  fragment VehicleCloudBoxxLogFragment on VehicleCloudBoxxLog {
    vehicle {
      id
      vin
    }
  }
`;

export const VehicleCloudBoxxLog: ResourceView = {
  resource: 'VehicleCloudBoxxLog',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: fragment,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: fragment,
    },
  },
};
