import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  Link,
  List,
  ReferenceInput,
  TextField,
  TextInput,
  useRecordContext,
} from 'react-admin';

import { ChargingStationTransactionReport } from '../../../@generated/schemas';
import CustomPagination from '../../../CustomPagination';
import cookie from 'js-cookie';
import { url } from '../../../';

export const ChargingStationReportFilter = (props: any) => (
  <Filter {...props}>
    <TextInput source="reportHash" alwaysOn />
    <ReferenceInput
      source="billingAccountId"
      reference="BillingAccount"
      alwaysOn>
      <AutocompleteInput label="Billing account" style={{ width: 250 }} />
    </ReferenceInput>
  </Filter>
);

const Download = () => {
  const record = useRecordContext<ChargingStationTransactionReport>();
  return (
    (record && (
      <a
        target="_blank"
        href={`${url}/admin/transaction-report/${
          record.reportHash
        }?access_token=${cookie.get('access_token')}`}
        download>
        Download
      </a>
    )) ||
    null
  );
};

export const ChargingStationReportList = (props: any) => {
  return (
    <List
      {...props}
      exporter={false}
      filters={<ChargingStationReportFilter />}
      sort={{ field: 'createdAt', order: 'desc' }}
      title="Charging station reports"
      pagination={<CustomPagination />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="reportHash" />
        <DateField source="createdAt" locales="de-DE" />
        <FunctionField
          source="billingAccount.id"
          label="Billing account"
          render={(record) =>
            record?.billingAccount?.id && (
              <Link
                target="_blank"
                to={`/BillingAccount/${record?.billingAccount?.id}`}>
                {record?.billingAccount?.name}
              </Link>
            )
          }
        />
        <Download />
      </Datagrid>
    </List>
  );
};
