import {
  Button,
  FormGroup,
  Grid,
  TextField as MuiTextField,
  Typography,
} from '@mui/material';
import {
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  TextInput,
  required,
  useRecordContext,
} from 'react-admin';
import { GoogleMap, Marker, StandaloneSearchBox } from '@react-google-maps/api';

import { ChargingStation } from '../../../@generated/schemas';
import QRCode from 'react-qr-code';
import TranslateInput from '../../../common/TranslationInput';
import styled from 'styled-components';
import { useFetchInvisiaListQuery } from 'src/@generated/hooks';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';

type DownloadType = 'image' | 'svg';

export const DefaultLocation = {
  latitude: 47.3769,
  longitude: 8.5417,
};

export const ChargingStationForm = () => {
  const record = useRecordContext<ChargingStation>();
  const { watch } = useFormContext();

  const evesId = watch('evesId');

  const { data } = useFetchInvisiaListQuery({ fetchPolicy: 'no-cache' });
  const selectedEves = data?.fetchInvisiaList?.find(
    (item) => item.eves_id === evesId,
  );

  const handleDownloadQRCode = (downloadType: DownloadType) => {
    const svg = document.getElementById('QRCode');
    const svgData = new XMLSerializer().serializeToString(svg);

    if (downloadType === 'image') {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL('image/png');
        const downloadLink = document.createElement('a');
        downloadLink.download = 'QRCode';
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
      };
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    } else if (downloadType === 'svg') {
      const a = document.createElement('a');
      const e = new MouseEvent('click');
      a.download = 'QRCode.svg';
      a.href = `data:image/svg+xml;base64,${btoa(svgData)}`;
      a.dispatchEvent(e);
    }
  };

  return (
    <Grid container spacing={8}>
      <Grid item md={6}>
        <FormGroup row>
          <TranslateInput source="name" hideName />
        </FormGroup>
        <FormGroup row>
          <TextInput source="status" fullWidth disabled />
        </FormGroup>
        <ReferenceInput
          reference="ChargingStationCluster"
          label="EVE cluster"
          source="chargingStationCluster_id">
          <SelectInput
            optionText={(row) =>
              JSON.parse(row.name || '{}')?.en ||
              JSON.parse(row.name || '{}')?.de ||
              JSON.parse(row.name || '{}')?.it ||
              JSON.parse(row.name || '{}')?.fr
            }
            fullWidth
          />
        </ReferenceInput>
        <SelectInput
          fullWidth
          source="evesId"
          choices={(data?.fetchInvisiaList || []).map((eveInstallation) => ({
            id: eveInstallation.eves_id,
            name: `${eveInstallation.eves_id}
            ${eveInstallation.companyId ? '(used)' : ''}`,
          }))}
        />

        {selectedEves && (
          <>
            <Typography variant="h6" gutterBottom style={{ marginTop: 24 }}>
              Invisia details
            </Typography>
            <Typography variant="body2" gutterBottom>
              Serial number: {selectedEves?.serialnumber}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Description gui: {selectedEves?.descriptionGui}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Description hmi: {selectedEves?.descriptionHmi}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Rfid enabled: {selectedEves?.rfidEnabled}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Rfid learning mode: {selectedEves?.rfidLearningMode}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Connector type: {selectedEves?.connectorType}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Disabled: {selectedEves?.disabled}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Fuse number: {selectedEves?.fuseNumber}
            </Typography>
            <Typography variant="body2" gutterBottom>
              Meter ID: {selectedEves?.meterId}
            </Typography>
          </>
        )}

        {record?.id && (
          <>
            <QRCode id="QRCode" level="M" value={record?.id} />
            <br />
            <br />
            <Button
              variant="contained"
              onClick={() => handleDownloadQRCode('image')}>
              Download QR code PNG
            </Button>
            <Button
              style={{ marginLeft: 12 }}
              variant="contained"
              onClick={() => handleDownloadQRCode('svg')}>
              Download QR code SVG
            </Button>
          </>
        )}
      </Grid>
      <Grid item md={6}>
        <FormDataConsumer>
          {() => {
            const { setValue, watch } = useFormContext();
            const [searchBoxRef, setSearchBoxRef] =
              useState<google.maps.places.SearchBox>();
            const geocoder = new google.maps.Geocoder();

            const latitude = watch('latitude') || DefaultLocation.latitude;
            const longitude = watch('longitude') || DefaultLocation.longitude;

            const handleSearchboxChanged = () => {
              const places = searchBoxRef.getPlaces();
              const firstPlace = places[0];

              const lat = firstPlace.geometry.location.lat();
              const lng = firstPlace.geometry.location.lng();

              setValue('latitude', lat);
              setValue('longitude', lng);
            };
            return (
              <>
                <GoogleMap
                  mapContainerStyle={{
                    width: '100%',
                    height: 400,
                  }}
                  center={{
                    lat: latitude,
                    lng: longitude,
                  }}
                  zoom={16}>
                  <GoogleMapsInputWrapper>
                    <StandaloneSearchBox
                      onLoad={setSearchBoxRef}
                      onPlacesChanged={handleSearchboxChanged}>
                      <MuiTextField
                        variant="outlined"
                        onKeyUp={(event) => {
                          if (event.key === 'Enter') {
                            event.stopPropagation();
                          }
                        }}
                        placeholder="Search address"
                        style={{ background: 'white' }}
                      />
                    </StandaloneSearchBox>
                  </GoogleMapsInputWrapper>
                  <Marker
                    draggable
                    onDragEnd={(e) => {
                      geocoder
                        .geocode({ location: e.latLng })
                        .then((response) => {
                          if (response.results[0]) {
                            setValue('latitude', e.latLng.lat());
                            setValue('longitude', e.latLng.lng());
                          }
                        });
                    }}
                    position={{
                      lat: latitude,
                      lng: longitude,
                    }}
                  />
                </GoogleMap>
                <TextInput source="latitude" validate={[required()]} />
                <TextInput source="longitude" validate={[required()]} />
              </>
            );
          }}
        </FormDataConsumer>
      </Grid>
    </Grid>
  );
};

const GoogleMapsInputWrapper = styled.div`
  position: absolute;
  top: 65px;
  left: 10px;
  z-index: 100;
`;
