import { FormGroup, FormLabel, styled } from '@mui/material';

import NumberInput from '../../../common/NumberInput';
import { useTranslate } from '../../../locales';

export const BookingDuration: React.VFC = () => {
  const translate = useTranslate();
  return (
    <FormGroup>
      <StyledFormLabel>{translate('admin.bookingDuration')}</StyledFormLabel>
      <FormGroup row>
        <NumberInput
          source="preBookingInterval"
          style={{ margin: '0 8px' }}
          label={translate('admin.preBookingInterval')}
        />
        <NumberInput
          source="postBookingInterval"
          style={{ margin: '0 8px' }}
          label={translate('admin.postBookingInterval')}
        />
        <NumberInput
          source="earlyOpenInterval"
          style={{ margin: '0 8px' }}
          label={translate('admin.earlyBookingInterval')}
        />
      </FormGroup>
    </FormGroup>
  );
};

const StyledFormLabel = styled(FormLabel)`
  margin-bottom: 22px;
`;
