import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  Link,
  List,
  ReferenceInput,
  TextField,
} from 'react-admin';

import CustomPagination from '../../../CustomPagination';
import { useTranslate } from '../../../locales';

export const GateControllerTransactionFilter = (props: any) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <ReferenceInput
        source="gateControlerId"
        reference="GateController"
        alwaysOn>
        <AutocompleteInput
          label={translate('admin.gate')}
          optionText="gateId"
          style={{ width: 250 }}
        />
      </ReferenceInput>
      <ReferenceInput source="userId" reference="User" alwaysOn>
        <AutocompleteInput
          label={translate('admin.user')}
          optionText="email"
          style={{ width: 250 }}
        />
      </ReferenceInput>
      <ReferenceInput source="vehicleId" reference="Vehicle" alwaysOn>
        <AutocompleteInput
          label={translate('admin.vehicle')}
          optionText="vin"
          style={{ width: 250 }}
        />
      </ReferenceInput>
      <ReferenceInput source="bookingId" reference="Booking" alwaysOn>
        <AutocompleteInput
          label={translate('admin.bookingColumn')}
          optionText="bookingHash"
          style={{ width: 250 }}
        />
      </ReferenceInput>
    </Filter>
  );
};

export const GateControllerTransactionList = (props: any) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      exporter={false}
      filters={<GateControllerTransactionFilter />}
      sort={{ field: 'createdAt', order: 'desc' }}
      title={translate('admin.gateControllerTransactions')}
      pagination={<CustomPagination />}>
      <Datagrid bulkActionButtons={false}>
        <FunctionField
          source="gateController.gateId"
          label={translate('admin.gate')}
          render={(record) =>
            record?.gateController && (
              <Link
                target="_blank"
                to={`/GateController/${record?.gateController?.id}`}>
                {record?.gateController?.gateId}
              </Link>
            )
          }
        />
        <FunctionField
          source="user.email"
          label={translate('admin.user')}
          render={(record) =>
            record?.user && (
              <Link target="_blank" to={`/User/${record?.user?.id}`}>
                {record?.user?.email}
              </Link>
            )
          }
        />
        <FunctionField
          source="booking.bookingHash"
          label={translate('admin.bookingColumn')}
          render={(record) =>
            record?.booking && (
              <Link target="_blank" to={`/Booking/${record?.booking?.id}/show`}>
                {record?.booking?.bookingHash}
              </Link>
            )
          }
        />
        <FunctionField
          source="vehicle.vin"
          label={translate('admin.vehicle')}
          render={(record) =>
            record?.vehicle && (
              <Link target="_blank" to={`/Vehicle/${record?.vehicle?.id}`}>
                {record?.vehicle?.vin}
              </Link>
            )
          }
        />
        <TextField source="comment" label={translate('admin.comment')} />
        <DateField
          source="createdAt"
          showTime
          locales="de"
          label={translate('admin.createdAt')}
        />
      </Datagrid>
    </List>
  );
};
