import { Edit, SimpleForm, useRefresh } from 'react-admin';
import { SharedVehicleGroupForm, getTransformedData } from './form';

export const SharedVehicleGroupEdit = (props: any) => {
  const refresh = useRefresh();
  return (
    <Edit
      {...props}
      transform={getTransformedData}
      redirect={false}
      mutationOptions={{
        onSuccess: () => refresh(),
      }}
      mutationMode="pessimistic">
      <SimpleForm>
        <SharedVehicleGroupForm />
      </SimpleForm>
    </Edit>
  );
};
