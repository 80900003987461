import {
  BooleanInput,
  TextInput,
  email,
  required,
} from 'react-admin';
import { Divider, FormGroup, FormLabel } from '@mui/material';

import { InsuranceReporting } from '../../@generated/schemas';
import SelectInput from '../../common/SelectInput';
import { VFC } from 'react';
import dayjs from 'dayjs';
import mapEnumSelectInput from '../../libs/mapEnumSelectInput';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { useTranslate } from '../../locales';

const Reporting: VFC<{
  sourcePrefix?: string;
}> = ({ sourcePrefix }) => {
  const translate = useTranslate();
  const { watch } = useFormContext();

  const sourceEmailAddress = `${
    sourcePrefix ? `${sourcePrefix}.` : ''
  }emailAddress`;
  const sourceEnabledReporting = `${
    sourcePrefix ? `${sourcePrefix}.` : ''
  }enabledReporting`;
  const sourceReportingInterval = `${
    sourcePrefix ? `${sourcePrefix}.` : ''
  }reportingInterval`;
  const sourceReportingDay = `${
    sourcePrefix ? `${sourcePrefix}.` : ''
  }reportingDay`;

  const isEnabledForm = watch(sourceEnabledReporting);

  return (
    <Base>
      <Divider style={{ marginTop: 10, marginBottom: 16 }} />
      <FormLabel>{translate('admin.reporting')}</FormLabel>
      <BooleanInput source={sourceEnabledReporting} label={translate('admin.enabledReporting')} fullWidth />
      <TextInput
        source={sourceEmailAddress}
        type="email"
        label={translate('admin.emailField')}
        variant="outlined"
        size="small"
        fullWidth
        disabled={!isEnabledForm}
        validate={isEnabledForm ? [email(), required()] : []}
      />
      <FormGroup row>
        <SelectInput
          fullWidth
          label={translate('admin.sourceReportingInterval')}
          disabled={!isEnabledForm}
          source={sourceReportingInterval}
          choices={mapEnumSelectInput(InsuranceReporting)}
        />
        <SelectInput
          source={sourceReportingDay}
          disabled={!isEnabledForm}
          label={translate('admin.sourceReportingDay')}
          choices={[...Array(7).keys()].map((day) => ({
            id: day + 1,
            name: dayjs()
              .weekday(day + 1)
              .format('dddd'),
          }))}
          style={{ marginLeft: 8, marginRight: 8 }}
        />
        <p>{translate('admin.at')} 9:00</p>
      </FormGroup>
    </Base>
  );
};

export default Reporting;

const Base = styled.div``;
