import {
  Button,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  GateControllerRuleProperty,
  GateControllerRuleSet,
  GateControllerRuleSetType,
} from '../../../@generated/schemas';
import { VFC, useState } from 'react';
import {
  useDelete,
  useGetList,
  useRecordContext,
  useRefresh,
} from 'react-admin';

import DeleteIcon from '@mui/icons-material/Delete';
import DuplicateIcon from '@mui/icons-material/CopyAll';
import EditIcon from '@mui/icons-material/Edit';
import RuleSetDialog from './ruleSetDialog';
import { getValueByForProperty } from './utils';
import { omit } from 'lodash';
import { useTranslate } from '../../../locales';

const RuleSetsTable: VFC = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const translate = useTranslate();
  const [showDialog, setShowDialog] = useState<
    boolean | (Omit<GateControllerRuleSet, 'id'> & { id?: string })
  >(false);

  const [deleteOne] = useDelete();

  const { data, refetch } = useGetList<GateControllerRuleSet>(
    'GateControllerRuleSet',
    {
      pagination: { page: 1, perPage: 1000 },
      filter: {
        deletedAt: { equals: null },
        gateControllerId: record.id,
      },
    },
  );

  const handleDeleteRuleset = async (ruleSetId: string) => {
    await deleteOne('GateControllerRuleSet', { id: ruleSetId });
    handleReload();
  };

  const handleReload = () => {
    refetch();
    refresh();
  };

  return (
    <>
      <Button
        style={{ marginLeft: 'auto', marginBottom: 12 }}
        onClick={() => setShowDialog(true)}
        variant="contained">
        {translate('admin.createNewRuleSet')}
      </Button>

      <TableContainer style={{ width: '100%' }}>
        <Table
          stickyHeader
          sx={{ minWidth: 650 }}
          style={{ maxHeight: '5wh' }}
          aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{translate('admin.ruleSetType')}</TableCell>
              <TableCell>{translate('admin.conditionMinutes')}</TableCell>
              <TableCell>{translate('admin.vehicles')}</TableCell>
              <TableCell>{translate('admin.users')}</TableCell>
              <TableCell align="right" width="200">
                {translate('admin.actions')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((ruleSet) => (
              <TableRow key={ruleSet.id}>
                <TableCell component="th" scope="row">
                  {ruleSet.ruleSetType}
                </TableCell>
                {ruleSet.ruleSetType === GateControllerRuleSetType.Booking && (
                  <TableCell component="th" scope="row">
                    <span>
                      {translate('admin.before')}:{' '}
                      {getValueByForProperty(
                        ruleSet,
                        GateControllerRuleProperty.BeforeBooking,
                      )}
                    </span>
                    <br />
                    <span>
                    {translate('admin.after')}:
                      {getValueByForProperty(
                        ruleSet,
                        GateControllerRuleProperty.AfterBooking,
                      )}
                    </span>
                  </TableCell>
                )}
                {ruleSet.ruleSetType ===
                  GateControllerRuleSetType.UserAccess && (
                  <TableCell component="th" scope="row">
                    {getValueByForProperty(
                      ruleSet,
                      GateControllerRuleProperty.TimeRange,
                    )}
                  </TableCell>
                )}
                <TableCell component="th" scope="row">
                  <Chip label={ruleSet.vehicles.length} />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Chip label={ruleSet.users.length} />
                </TableCell>
                <TableCell component="th" align="right" scope="row">
                  <IconButton
                    onClick={() => setShowDialog(omit(ruleSet, ['id']))}>
                    <DuplicateIcon />
                  </IconButton>
                  <IconButton onClick={() => setShowDialog(ruleSet)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteRuleset(ruleSet.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <RuleSetDialog
        showDialog={showDialog}
        setShowDialog={setShowDialog}
        refetch={handleReload}
      />
    </>
  );
};

export default RuleSetsTable;
