import { NumberInput as AdminNumberInput } from 'react-admin';
import { getValidate } from '../libs/checkRequiredField';
import { useFormContext } from 'react-hook-form';

const NumberInput = (props) => {
  const { getFieldState } = useFormContext();
  const fieldState = getFieldState(props.source);
  const validate = getValidate(props);
  const errorMessage = props.error || fieldState?.error?.message || '';

  return (
    <AdminNumberInput
      {...props}
      variant="outlined"
      size="small"
      validate={validate}
      className={!!errorMessage ? 'form-has-error' : undefined}
      helperText={errorMessage}
      style={{ flex: 1, ...props.style }}
    />
  );
};

export default NumberInput;
