import {
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  Create as RACreate,
  Edit as RAEdit,
  Filter as RAFilter,
  List as RAList,
  SimpleForm,
  TextField,
  TextInput,
} from 'react-admin';
import TranslateInput, { transform } from '../../common/TranslationInput';

import CustomPagination from '../../CustomPagination';

export const Create = (props: any) => (
  <RACreate
    {...props}
    mutationMode="pessimistic"
    redirect="list"
    transform={(data) => transform(data)}>
    <SimpleForm>
      <TranslateInput {...props} />
    </SimpleForm>
  </RACreate>
);

export const Edit = (props: any) => (
  <RAEdit
    {...props}
    mutationMode="pessimistic"
    transform={(data) => transform(data)}>
    <SimpleForm>
      <TranslateInput {...props} />
    </SimpleForm>
  </RAEdit>
);

export const Filter = (props: any) => (
  <RAFilter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </RAFilter>
);

export const List = (props: any) => {
  return (
    <RAList
      {...props}
      filters={<Filter />}
      filter={{
        deletedAt: { equals: null },
      }}
      undoable="false"
      perPage={50}
      pagination={<CustomPagination />}>
      <Datagrid>
        <TextField source="name" />
        <EditButton />
        <DeleteWithConfirmButton mutationMode="pessimistic" />
      </Datagrid>
    </RAList>
  );
};
