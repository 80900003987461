import {
  AutocompleteArrayInput,
  Datagrid,
  EditButton,
  FunctionField,
  List,
  ReferenceArrayInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useCreate,
  useDeleteMany,
  useRecordContext,
  useRedirect,
  useRefresh,
  useUpdateMany,
} from 'react-admin';
import { useEffect, useState } from 'react';

import { BulkActionButtons } from '../bulkUpdate';
import { Chip } from '@mui/material';
import CustomPagination from '../../../CustomPagination';
import { EditDialog } from '@react-admin/ra-form-layout';
import { ResourceView } from '@ra-data-prisma/dataprovider';
import { gql } from '@apollo/client';
import { useTranslate } from '../../../locales';

const Form = () => {
  const record = useRecordContext();
  const [aliases, setAlias] = useState([]);
  const [create] = useCreate();
  const [deleteMany] = useDeleteMany();
  const translate = useTranslate();

  useEffect(() => {
    if (record?.chargingStationRfidAlias) {
      setAlias(record?.chargingStationRfidAlias);
    }
  }, [record]);

  const onHandleChange = async (value, records) => {
    if (records?.id) {
      return false;
    }

    const removedAliases = aliases.filter(
      (alias) => !records?.find((record) => record.id === alias.id),
    );

    if (removedAliases?.length > 0) {
      await deleteMany('ChargingStationRfidAlias', {
        ids: removedAliases.map((alias) => alias.id),
      });
    }

    setAlias(records);
  };

  return (
    <SimpleForm>
      <TextInput
        source="description"
        fullWidth
        label={translate('admin.description')}
      />
      <TextInput
        source="originalTag"
        fullWidth
        label={translate('admin.originalTag')}
      />
      <ReferenceInput reference="BillingAccount" source="billingAccount_id">
        <SelectInput
          optionText="name"
          label={translate('admin.billingAccount')}
          fullWidth
        />
      </ReferenceInput>
      <ReferenceArrayInput
        source="chargingStationRfidAlias_ids"
        reference="ChargingStationRfidAlias">
        <AutocompleteArrayInput
          style={{ width: '100%' }}
          optionText="tag"
          label={translate('admin.chargingStationRfidAlias')}
          onChange={onHandleChange}
          onCreate={async (filter) => {
            const newValue = prompt(translate('admin.enterNewAlias'));

            if (!newValue) {
              return false;
            }
            const newRecord = await create(
              'ChargingStationRfidAlias',
              {
                data: {
                  tag: newValue,
                  chargingStationRfid: record.originalId,
                },
              },
              {
                returnPromise: true,
              },
            );

            const item = {
              id: newRecord?.id,
              tag: newRecord?.tag,
            };

            setAlias([...aliases, item]);

            return item;
          }}
          source="chargingStationRfidAlias_ids"
          choices={aliases}
        />
      </ReferenceArrayInput>
    </SimpleForm>
  );
};

export const ChargingStationRfidList = (props: any) => {
  const [updateMany] = useUpdateMany();
  const refresh = useRefresh();
  const redirect = useRedirect();
  const translate = useTranslate();

  const handleManyUpdate = async ({
    ids,
    billingAccountId,
    description,
    originalTag,
  }) => {
    await updateMany(
      'ChargingStationRfidInvisia',
      {
        ids,
        data: { billingAccount: billingAccountId, description, originalTag },
      },
      { returnPromise: true },
    );

    refresh();
  };

  return (
    <>
      <List
        {...props}
        title={translate('admin.chargingStationRfidsInvisia')}
        bulkActionButtons={<BulkActionButtons update={handleManyUpdate} />}
        pagination={<CustomPagination />}>
        <Datagrid rowClick="edit">
          <TextField
            source="id"
            sortable={false}
            label={translate('admin.id')}
          />
          <TextField
            source="description"
            sortable={false}
            label={translate('admin.description')}
          />
          <TextField
            source="installationId"
            sortable={false}
            label={translate('admin.installationId')}
          />
          <TextField
            source="billingAccount.name"
            sortable={false}
            label={translate('admin.billingAccount')}
          />
          <TextField
            source="originalTag"
            sortable={false}
            label={translate('admin.originalTag')}
          />
          <FunctionField
            source="chargingStationRfidAlias.tag"
            label={translate('admin.alias')}
            sortable={false}
            render={(row) => {
              return row?.chargingStationRfidAlias?.map((alias) => (
                <div key={alias.id}>
                  <Chip label={alias.tag} />
                </div>
              ));
            }}
          />
          <EditButton />
        </Datagrid>
      </List>
      <EditDialog
        mutationMode="pessimistic"
        mutationOptions={{
          onSuccess: () => {
            redirect('/ChargingStationRfidInvisia');
            refresh();
          },
        }}>
        <Form />
      </EditDialog>
    </>
  );
};

export const ChargingStationRfidInvisia: ResourceView = {
  resource: 'ChargingStationRfidInvisia',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment ChargingStationRfidInvisiaFragment on ChargingStationRfidInvisia {
          billingAccount {
            id
            name
          }
          chargingStationRfidAlias {
            id
            tag
            chargingStationRfidId
          }
        }
      `,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment ChargingStationRfidInvisiaFragment on ChargingStationRfidInvisia {
          billingAccount {
            id
            name
          }
          chargingStationRfidAlias {
            id
            tag
            chargingStationRfidId
          }
        }
      `,
    },
  },
};
