import {
  AutocompleteArrayInput,
  SelectInput,
  TimeInput,
  required,
  useCreate,
  useDataProvider,
  useRecordContext,
  useUpdate,
} from 'react-admin';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormLabel,
} from '@mui/material';
import {
  GateControllerRule,
  GateControllerRuleProperty,
  GateControllerRuleSet,
  GateControllerRuleSetType,
  RuleCompareOperator,
} from '../../../@generated/schemas';
import { VFC, useEffect } from 'react';

import { ReferenceManyToManyInput } from '@react-admin/ra-relationships';
import dayjs from 'dayjs';
import mapEnumSelectInput from '../../../libs/mapEnumSelectInput';
import { omit } from 'lodash';
import sanitizeEmptyValues from '../../../libs/sanitizeEmptyValues';
import { useFormContext } from 'react-hook-form';
import { useTranslate } from '../../../locales';

export const TIME_RANGE_PREFIX = 'timeRange';
export const BEFORE_BOOKING_PREFIX = 'beforeBooking';
export const AFTER_BOOKING_PREFIX = 'afterBooking';

const FormRuleSet: VFC<{
  title: string;
  rulesetName:
    | typeof TIME_RANGE_PREFIX
    | typeof BEFORE_BOOKING_PREFIX
    | typeof AFTER_BOOKING_PREFIX;
}> = ({ title, rulesetName }) => {
  return (
    <FormGroup style={{ width: '100%' }}>
      <FormLabel>{title}</FormLabel>

      <FormGroup row>
        <TimeInput
          source={`${rulesetName}ValueFrom`}
          label={title}
          style={{ width: '25%', marginRight: 8 }}
        />
        {rulesetName === TIME_RANGE_PREFIX && (
          <TimeInput
            source={`${rulesetName}ValueTo`}
            label={title}
            style={{
              width: '25%',
              marginRight: 8,
            }}
          />
        )}
      </FormGroup>
    </FormGroup>
  );
};

const RuleSetDialog: VFC<{
  refetch: () => void;
  showDialog: boolean | (Omit<GateControllerRuleSet, 'id'> & { id?: string });
  setShowDialog: (showDialog: boolean) => void;
}> = ({ showDialog, setShowDialog, refetch }) => {
  const record = useRecordContext();
  const translate = useTranslate();
  const { setValue, handleSubmit, watch, getValues } = useFormContext();
  const dataProvider = useDataProvider();
  const [createOne] = useCreate();
  const [updateOne] = useUpdate();

  const watchValues = watch();

  const handleCloseDialog = () => {
    setShowDialog(false);
    refetch();
  };

  const handleOnSubmit = async () => {
    const inputs = getValues();

    const data = {
      ruleSetType: inputs.ruleSetType,
      gateController: record.id as string,
      rules: [],
      users: [],
      vehicles: [],
    };

    if (data.ruleSetType === GateControllerRuleSetType.Booking) {
      data.vehicles =
        inputs[
          '@@ra-many-to-many/GateControllerRuleSet/GateControllerRelVehicle/Vehicle'
        ];

      const ruleBefore: Partial<GateControllerRule> = {
        property: GateControllerRuleProperty.BeforeBooking,
        fromCompareOperator: RuleCompareOperator.EqualTo,
        fromValue: inputs[`${BEFORE_BOOKING_PREFIX}ValueFrom`]
          ? dayjs(inputs[`${BEFORE_BOOKING_PREFIX}ValueFrom`]).diff(
              dayjs().startOf('day'),
              'minutes',
            )
          : 0,
        toCompareOperator: null,
        toValue: null,
      };
      if (inputs[`${BEFORE_BOOKING_PREFIX}Id`]) {
        ruleBefore.id = inputs[`${BEFORE_BOOKING_PREFIX}Id`];
      }
      data.rules.push(ruleBefore);

      const ruleAfter: Partial<GateControllerRule> = {
        property: GateControllerRuleProperty.AfterBooking,
        fromCompareOperator: RuleCompareOperator.EqualTo,
        fromValue: inputs[`${AFTER_BOOKING_PREFIX}ValueFrom`]
          ? dayjs(inputs[`${AFTER_BOOKING_PREFIX}ValueFrom`]).diff(
              dayjs().startOf('day'),
              'minutes',
            )
          : 0,
        toCompareOperator: null,
        toValue: null,
      };
      if (inputs[`${AFTER_BOOKING_PREFIX}Id`]) {
        ruleAfter.id = inputs[`${AFTER_BOOKING_PREFIX}Id`];
      }
      data.rules.push(ruleAfter);
    }

    if (data.ruleSetType === GateControllerRuleSetType.UserAccess) {
      data.users =
        inputs[
          '@@ra-many-to-many/GateControllerRuleSet/GateControllerRelUser/User'
        ];

      const rule: Partial<GateControllerRule> = {
        property: GateControllerRuleProperty.TimeRange,
        fromCompareOperator: RuleCompareOperator.GreaterThanEqualTo,
        fromValue: inputs[`${TIME_RANGE_PREFIX}ValueFrom`]
          ? dayjs(inputs[`${TIME_RANGE_PREFIX}ValueFrom`]).diff(
              dayjs().startOf('day'),
              'minutes',
            )
          : null,
        toCompareOperator: RuleCompareOperator.LessThanEqualTo,
        toValue: inputs[`${TIME_RANGE_PREFIX}ValueTo`]
          ? dayjs(inputs[`${TIME_RANGE_PREFIX}ValueTo`]).diff(
              dayjs().startOf('day'),
              'minutes',
            )
          : null,
      };
      if (inputs[`${TIME_RANGE_PREFIX}Id`]) {
        rule.id = inputs[`${TIME_RANGE_PREFIX}Id`];
      }
      data.rules.push(rule);
    }

    if (typeof showDialog === 'object' && showDialog.id) {
      const previousData = await dataProvider.getOne('GateControllerRuleSet', {
        id: showDialog.id,
      });

      await updateOne(
        'GateControllerRuleSet',
        {
          id: showDialog.id,
          data,
          previousData: omit(sanitizeEmptyValues(previousData.data), [
            'createdAt',
            // 'chargingStationTransactions',
            // 'chargingStationTransactionAppliedTariffs',
          ]),
        },
        { returnPromise: true },
      );
    } else {
      await createOne(
        'GateControllerRuleSet',
        {
          data,
        },
        { returnPromise: true },
      );
    }

    refetch();
    handleCloseDialog();
  };

  useEffect(() => {
    async function initValues() {
      if (showDialog) {
        setValue('ruleSetType', null);

        [TIME_RANGE_PREFIX, BEFORE_BOOKING_PREFIX, AFTER_BOOKING_PREFIX].map(
          (prefix) => {
            setValue(`${prefix}Id`, null);
            setValue(`${prefix}CompareFrom`, null);
            setValue(`${prefix}ValueFrom`, null);
            setValue(`${prefix}CompareTo`, null);
            setValue(`${prefix}ValueTo`, null);
            setValue(`vehicles`, []);
            setValue(`users`, []);
          },
        );
      }

      if (showDialog && typeof showDialog === 'object') {
        setValue('ruleSetType', showDialog.ruleSetType);
        setValue('vehicles', showDialog.vehicles);
        setValue('users', showDialog.users);

        showDialog.rules.map((rule) => {
          let prefix = '';

          switch (rule.property) {
            case GateControllerRuleProperty.TimeRange:
              prefix = TIME_RANGE_PREFIX;
              break;
            case GateControllerRuleProperty.BeforeBooking:
              prefix = BEFORE_BOOKING_PREFIX;
              break;
            case GateControllerRuleProperty.AfterBooking:
              prefix = AFTER_BOOKING_PREFIX;
              break;
          }

          if (showDialog.id) {
            setValue(`${prefix}Id`, rule.id);
          }
          setValue(`${prefix}CompareFrom`, rule.fromCompareOperator);
          setValue(
            `${prefix}ValueFrom`,
            dayjs().startOf('day').add(rule.fromValue, 'minutes'),
          );
          setValue(`${prefix}CompareTo`, rule.toCompareOperator);
          setValue(
            `${prefix}ValueTo`,
            dayjs().startOf('day').add(rule.toValue, 'minutes'),
          );
        });
      }
    }
    initValues();
  }, [showDialog]);

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={!!showDialog}
      title={translate('admin.createRuleSet')}
      onClose={handleCloseDialog}>
      <DialogTitle>
        {typeof showDialog === 'boolean' || !showDialog?.id ? translate('admin.creatingRuleSet'): translate('admin.editingRuleSet')}
      </DialogTitle>
      <DialogContent>
        <FormGroup>
          <SelectInput
            source="ruleSetType"
            label={translate('admin.ruleSetType')}
            validate={[required()]}
            style={{ maxWidth: 300 }}
            choices={mapEnumSelectInput(GateControllerRuleSetType)}
          />
        </FormGroup>

        {watchValues.ruleSetType === GateControllerRuleSetType.Booking && (
          <>
            <FormRuleSet
              title={translate('admin.beforeBooking')}
              rulesetName={BEFORE_BOOKING_PREFIX}
            />
            <FormRuleSet
              title={translate('admin.afterBooking')}
              rulesetName={AFTER_BOOKING_PREFIX}
            />

            <ReferenceManyToManyInput
              resource="GateControllerRuleSet"
              reference="Vehicle"
              through="GateControllerRelVehicle"
              source="id"
              record={{
                id: typeof showDialog !== 'boolean' ? showDialog?.id : null,
              }}
              using="gateControllerRuleSetId,vehicleId">
              <AutocompleteArrayInput
                label={translate('admin.vehicles')}
                optionText="vin"
                fullWidth
              />
            </ReferenceManyToManyInput>
          </>
        )}
        {watchValues.ruleSetType === GateControllerRuleSetType.UserAccess && (
          <>
            <FormRuleSet title={translate('admin.time')} rulesetName={TIME_RANGE_PREFIX} />
            <br />
            <ReferenceManyToManyInput
              resource="GateControllerRuleSet"
              reference="User"
              through="GateControllerRelUser"
              source="id"
              record={{
                id: typeof showDialog !== 'boolean' ? showDialog?.id : null,
              }}
              using="gateControllerRuleSetId,userId">
              <AutocompleteArrayInput
                label={translate('admin.users')}
                optionText="email"
                fullWidth
              />
            </ReferenceManyToManyInput>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(handleOnSubmit)} variant="contained">
          {translate('admin.save')}
        </Button>
        <Button onClick={handleCloseDialog}>{translate('admin.cancel')}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RuleSetDialog;
