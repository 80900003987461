import {
  AutocompleteInput,
  BooleanInput,
  Create,
  Datagrid,
  DateTimeInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  Filter,
  FunctionField,
  List,
  NumberInput,
  ReferenceArrayField,
  ReferenceInput,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  maxValue,
  minValue,
  required,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { Button, CircularProgress, Grid } from '@mui/material';
import {
  useGenerateCompanyOnboardingStripeLinkMutation,
  useUploadCompanyLogoMutation,
} from '../../@generated/hooks';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Company } from '../../@generated/schemas';
import CustomPagination from '../../CustomPagination';
import QRCode from 'react-qr-code';
import getStoragePath from '../../libs/getStoragePath';
import styled from 'styled-components';
import { url } from '../..';
import { useRef } from 'react';
import { useTranslate } from '../../locales';

type DownloadType = 'image' | 'svg';

export const UploadButton: React.VFC<{
  refetch: () => void;
}> = ({ refetch }) => {
  const translate = useTranslate();
  const record = useRecordContext<Company>();
  const [upload, { loading }] = useUploadCompanyLogoMutation();
  const uploadInputRef = useRef<any>();

  const onChange = async (event) => {
    const files = event.target.files;

    try {
      await upload({
        variables: {
          file: files[0],
          companyId: record.id,
        },
      });

      refetch();

      uploadInputRef.current.value = null;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <label htmlFor="upload-file">
      <input
        ref={uploadInputRef}
        id="upload-file"
        onChange={onChange}
        type="file"
        hidden
      />
      <Button
        style={{
          marginBottom: 20,
        }}
        disabled={loading}
        variant="contained"
        onClick={() => uploadInputRef?.current?.click()}>
        {translate('admin.uploadLogo')}
        {loading ? (
          <CircularProgress
            size="1rem"
            color="inherit"
            style={{ marginLeft: 8 }}
          />
        ) : (
          <CloudUploadIcon style={{ marginLeft: 8 }} />
        )}
      </Button>
    </label>
  );
};

const Form = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const translate = useTranslate();
  const [stripeOnboarding, { loading }] =
    useGenerateCompanyOnboardingStripeLinkMutation();

  const handleResetStripeLink = async () => {
    try {
      await stripeOnboarding({
        variables: { companyId: record.id as string },
      });

      notify(translate('admin.emailWasSuccessfullySent'), { type: 'success' });

      refresh();
    } catch (error) {
      notify(error.message, { type: 'error' });
    }
  };

  const handleDownloadQRCode = (downloadType: DownloadType) => {
    const svg = document.getElementById('QRCode');
    const svgData = new XMLSerializer().serializeToString(svg);

    if (downloadType === 'image') {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL('image/png');
        const downloadLink = document.createElement('a');
        downloadLink.download = 'QRCode';
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
      };
      img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
    } else if (downloadType === 'svg') {
      const a = document.createElement('a');
      const e = new MouseEvent('click');
      a.download = 'QRCode.svg';
      a.href = `data:image/svg+xml;base64,${btoa(svgData)}`;
      a.dispatchEvent(e);
    }
  };

  return (
    <Grid container spacing={8}>
      <Grid item md={5}>
        <Row>
          <TextInput source="name" label={translate('admin.name')} />
          <TextInput
            source="identifier"
            label={translate('admin.identifier')}
            validate={[required()]}
          />
        </Row>
        <ReferenceInput
          source="defaultSharedVehicleGroup_id"
          reference="SharedVehicleGroup"
          filter={{
            deletedAt: { equals: null },
          }}>
          <AutocompleteInput
            fullWidth
            optionText="name"
            label={translate('admin.defaultSharedVehicleGroup')}
          />
        </ReferenceInput>

        <Row>
          <TextInput
            source="address"
            validate={[required()]}
            label={translate('admin.address')}
          />
          <TextInput
            source="houseNumber"
            validate={[required()]}
            label={translate('admin.houseNumber')}
          />
        </Row>
        <Row>
          <TextInput
            source="city"
            validate={[required()]}
            label={translate('admin.city')}
          />
          <TextInput
            source="postCode"
            validate={[required()]}
            label={translate('admin.postCode')}
          />
          <TextInput
            source="country"
            validate={[required()]}
            label={translate('admin.country')}
          />
        </Row>
        <Row>
          <TextInput source="phoneNumber" label={translate('admin.phoneNumber')} />
          <TextInput source="email" label={translate('admin.emailField')} />
        </Row>
        <Row>
          <TextInput
            source="contactPersonFullname"
            label={translate('admin.contactPersonFullname')}
          />
        </Row>
        <Row>
          <TextInput
            source="contactPersonPhoneNumber"
            label={translate('admin.contactPersonPhoneNumber')}
          />
        </Row>
        <Row>
          <TextInput
            source="contactPersonEmail"
            validate={[required()]}
            label={translate('admin.contactPersonEmail')}
          />
        </Row>

        {record?.stripeAccountLink && (
          <>
            <a target="_blank" href={record?.stripeAccountLink}>
              {translate('admin.stripeAccountOnboarding')}
            </a>
            <br />
            <br />
          </>
        )}

        <DateTimeInput
          label={translate('admin.stripeAccountConnectedAt')}
          variant="outlined"
          size="small"
          style={{ width: '100%' }}
          source="stripeAccountConfirmedAt"
          readOnly
        />

        <Button
          variant="contained"
          disabled={loading}
          startIcon={loading && <CircularProgress size="1em" />}
          onClick={handleResetStripeLink}>
          {translate('admin.resendStripeLink')}
        </Button>
      </Grid>
      <Grid item md={5}>
        <UploadButton refetch={refresh} />
        {record?.logo && (
          <LogoWrapper>
            <img
              style={{ height: 50, objectFit: 'cover' }}
              src={getStoragePath(`${record.logo}`, 'drivemycar-company-logo')}
            />
          </LogoWrapper>
        )}
        <SimpleShowLayout>
          <ReferenceArrayField
            label="Billing accounts"
            source="billingAccounts_ids"
            reference="BillingAccount">
            <Datagrid style={{ width: '100%' }} bulkActionButtons={false}>
              <TextField source="name" label={translate('admin.name')} />
              <FunctionField
                source="chargingStationRFIDs"
                label={translate('admin.rfids')}
                render={(row) => row?.chargingStationRFIDs?.length}
              />
            </Datagrid>
          </ReferenceArrayField>
        </SimpleShowLayout>

        <div style={{ marginTop: 30 }} />

        <Row>
          <BooleanInput
            source="enabledBookings"
            label={translate('admin.enabledBookings')}
          />
          <NumberInput
            label={translate('admin.commissionForBookings')}
            source="commissionBookings"
            validate={[minValue(0), maxValue(100)]}
          />
        </Row>
        <Row>
          <BooleanInput
            label={translate('admin.enabledChargingSessions')}
            source="enabledChargingSessions"
          />
          <NumberInput
            label={translate('admin.commissionCharging')}
            source="commissionChargingStations"
            validate={[minValue(0), maxValue(100)]}
          />
        </Row>
        <Row>
          <BooleanInput
            label={translate('admin.enabledScooters')}
            source="enabledScooters"
          />
          <NumberInput
            label={translate('admin.commissionScooters')}
            source="commissionScooters"
            validate={[minValue(0), maxValue(100)]}
          />
        </Row>
        {record?.id && (
          <div style={{ marginTop: 26, textAlign: 'right' }}>
            <QRCode
              id="QRCode"
              level="M"
              value={`${url}/company/signup/${record?.id}`}
            />
            <br />
            <br />
            <Button
              variant="contained"
              onClick={() => handleDownloadQRCode('image')}>
              {translate('admin.downloadQRCodePng')}
            </Button>
            <Button
              style={{ marginLeft: 12 }}
              variant="contained"
              onClick={() => handleDownloadQRCode('svg')}>
              {translate('admin.downloadQRCodeSvg')}
            </Button>
          </div>
        )}
      </Grid>
    </Grid>
  );
};

export const CompanyCreate = (props: any) => (
  <Create {...props} mutationMode="pessimistic">
    <SimpleForm
      defaultValues={{
        commissionBookings: 0,
        commissionChargingStations: 0,
        commissionScooters: 0,
      }}>
      <Form {...props} />
    </SimpleForm>
  </Create>
);

export const CompanyEdit = (props: any) => (
  <Edit {...props} redirect={false} mutationMode="pessimistic">
    <SimpleForm>
      <Form {...props} />
    </SimpleForm>
  </Edit>
);

export const CompanyFilter = (props: any) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <TextInput label={translate('admin.search')} source="q" alwaysOn />
    </Filter>
  );
};

export const CompanyList = (props: any) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      filters={<CompanyFilter />}
      pagination={<CustomPagination />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" label={translate('admin.name')} />
        <EditButton />
        <DeleteWithConfirmButton mutationMode="pessimistic" />
      </Datagrid>
    </List>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  & > div {
    flex: 1;
  }
  div ~ div {
    margin-left: 10px;
  }
`;

const LogoWrapper = styled.div`
  background-color: #f0f0f0;
  max-height: 100px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
