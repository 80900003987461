const sanitizeEmptyValues = (values) => {
  const result = {};
  Object.keys(values).forEach((key: string) => {
    if (key.includes('Id')) {
      result[key.replace('Id', '')] = values[key];
    } else if (!key.includes('_id') && !result[key.replace('Id', '')]) {
      result[key] = values[key];
    }
  });

  return result;
};

export default sanitizeEmptyValues;
