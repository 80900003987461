import { TimeUnits } from "../types/global";
import dayjs from "dayjs";

export const getDuration = (seconds: number): TimeUnits => {
  const duration = dayjs.duration(seconds, "seconds");
  const itemHours = duration.hours();
  const itemMinutes = duration.minutes();

  return { hours: itemHours, minutes: itemMinutes };
};
