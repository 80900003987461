import { FormGroup } from '@mui/material';
import NumberInput from '../../../common/NumberInput';
import { useTranslate } from '../../../locales';

export const requiredFields = [
  'priceHour',
  'includedKmHour',
  'priceDay',
  'includedKmDay',
  'priceWeek',
  'includedKmWeek',
  'priceKm',
];

export const VehiclePrices: React.VFC = () => {
  const translate = useTranslate();
  return (
    <div>
      <br />
      <FormGroup row style={{ maxWidth: 500 }}>
        <NumberInput
          source="priceHour"
          style={{ margin: '0 8px' }}
          label={translate('admin.hourPrice')}
          requiredFieldsList={requiredFields}
        />
        <NumberInput
          source="includedKmHour"
          style={{ margin: '0 8px' }}
          label={translate('admin.inckudedKmPerHour')}
          requiredFieldsList={requiredFields}
        />
      </FormGroup>
      <FormGroup row style={{ maxWidth: 500 }}>
        <NumberInput
          source="priceDay"
          style={{ margin: '0 8px' }}
          label={translate('admin.dailyPrice')}
          requiredFieldsList={requiredFields}
        />
        <NumberInput
          source="includedKmDay"
          style={{ margin: '0 8px' }}
          label={translate('admin.inckudedKmPerDay')}
          requiredFieldsList={requiredFields}
        />
      </FormGroup>
      <FormGroup row style={{ maxWidth: 500 }}>
        <NumberInput
          source="priceWeek"
          style={{ margin: '0 8px' }}
          label={translate('admin.weeklyPrice')}
          requiredFieldsList={requiredFields}
        />
        <NumberInput
          source="includedKmWeek"
          style={{ margin: '0 8px' }}
          label={translate('admin.inckudedKmPerWeek')}
          requiredFieldsList={requiredFields}
        />
      </FormGroup>
      <FormGroup row style={{ maxWidth: 500 }}>
        <NumberInput
          source="priceKm"
          style={{ margin: '0 8px' }}
          label={translate('admin.pricePerKm')}
          requiredFieldsList={requiredFields}
        />
      </FormGroup>
    </div>
  );
};
