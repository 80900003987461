import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  Link,
  List,
  ReferenceInput,
  TextInput,
} from 'react-admin';
import { Card, CardContent, Typography } from '@mui/material';

import CustomPagination from '../../../CustomPagination';
import ReactJson from 'react-json-view';
import { ResourceView } from '@ra-data-prisma/dataprovider';
import { gql } from '@apollo/client';

const LogShow = (props) => {
  const { record } = props;
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h6" gutterBottom>
          Detail
        </Typography>
        <ReactJson src={JSON.parse(record.content)} />
      </CardContent>
    </Card>
  );
};

export const VehicleCloudboxxLogsFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <TextInput source="vehicle.vin" />
    <TextInput source="content" label="Event body" />
    <ReferenceInput source="vehicleId" reference="Vehicle">
      <AutocompleteInput optionText={(choice: any) => choice?.vin || 'Clear'} />
    </ReferenceInput>
  </Filter>
);

export const VehicleCloudboxxLogs = (props) => {
  return (
    <List
      {...props}
      filters={<VehicleCloudboxxLogsFilter />}
      pagination={<CustomPagination />}>
      <Datagrid expand={<LogShow />} bulkActionButtons={false}>
        <FunctionField
          source="vehicle.vin"
          render={(record) =>
            record?.vehicle?.id && (
              <Link to={`/Vehicle/${record.vehicle.id}`}>
                {record.vehicle.vin}
              </Link>
            )
          }
        />
        <FunctionField
          source="Event type"
          render={(record) => JSON.parse(record.content).reasons.toString()}
        />
        <DateField source="createdAt" showTime />
      </Datagrid>
    </List>
  );
};

const fragment = gql`
  fragment VehicleCloudBoxxLogFragment on VehicleCloudBoxxLog {
    vehicle {
      id
      vin
    }
  }
`;

export const VehicleCloudBoxxLog: ResourceView = {
  resource: 'VehicleCloudBoxxLog',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: fragment,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: fragment,
    },
  },
};
