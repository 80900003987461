import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  IconButton,
  TextField as MuiTextField,
} from '@mui/material';
import { useCreate, useDelete, useGetIdentity, useNotify, useUpdate } from 'react-admin';

import AdapterDayjs from '@mui/lab/AdapterDayjs';
import CloseIcon from '@mui/icons-material/Close';
import { DateTimePicker } from '@mui/lab';
import { LoadingElement } from '../../App';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import dayjs from 'dayjs';
import enLocale from 'date-fns/locale/en-GB';
import styled from 'styled-components';
import { useTranslate } from '../../locales';

export const LoadingElementWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1000;
  background: rgba(255, 255, 255, 0.5);
`;

const FormRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

export const RequestBookingSchedulerDialog = ({
  startDate,
  endDate,
  id,
  users,
  userId,
  loading,
  setLoading,
  note,
  pickupLocationNotes,
  returnLocationNotes,
  refetchBookings,
  setSelectedRequestBookingParam,
  resetSelectedRequestBooking,
}) => {
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const [create] = useCreate();
  const [update] = useUpdate();
  const [deleteOne] = useDelete();
  const notify = useNotify();

  const handleCloseDetail = () => {
    if (!loading) {
      resetSelectedRequestBooking(null);
    }
  };

  const handleSaveBooking = async () => {
    setLoading(true);

    if (id && id !== 'NEW') {
      await update(
        'BookingRequest',
        {
          id: id as string,
          data: {
            startDate: dayjs(startDate).format(),
            endDate: dayjs(endDate).format(),
            user: userId,
            note,
            pickupLocationNotes,
            returnLocationNotes,
          },
        },
        {
          onSuccess: async () => {
            setLoading(false);

            notify(translate('admin.requestBookingWasSuccessfullyUpdated'), {
              type: 'success',
            });
            await refetchBookings(true, {
              startDate,
              endDate,
            });
            handleCloseDetail();
          },
        },
      );
    } else {
      try {
        await create('BookingRequest', {
          data: {
            startDate: dayjs(startDate).format(),
            endDate: dayjs(endDate).format(),
            user: userId,
            note,
            pickupLocationNotes,
            returnLocationNotes,
            createdBy: identity.id
          },
        });

        setLoading(false);

        notify(translate('admin.requestBookingWasSuccessfullyCreated'), { type: 'success' });
        await refetchBookings(true, {
          startDate,
          endDate,
        });
        handleCloseDetail();
      } catch (error) {
        setLoading(false);
        notify(error.message, { type: 'error' });
      }
    }
  };

  const handleDeleteRequestBooking = async () => {
    setLoading(true);

    deleteOne(
      'BookingRequest',
      {
        id: id as string,
      },
      {
        onSuccess: async () => {
          setLoading(false);

          notify(translate('admin.requestBookingWasSuccessfullyDeleted'), {
            type: 'success',
          });
          await refetchBookings(true, {
            startDate,
            endDate,
          });
          handleCloseDetail();
        },
      },
    );
  };

  return (
    <Dialog
      open={!!id}
      onClose={handleCloseDetail}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <DialogTitle>
        <div>
          {translate('admin.requestBooking')}: {id && id !== 'NEW' ? translate('admin.updating') : translate('admin.creating')}
        </div>
        <IconButton
          style={{ position: 'absolute', right: 18, top: 14 }}
          onClick={handleCloseDetail}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent id="modal-modal-title">
        {loading ? (
          <LoadingElementWrapper>
            <LoadingElement />
          </LoadingElementWrapper>
        ) : null}
        <DialogContentText style={{ paddingTop: 6 }}></DialogContentText>
        <LocalizationProvider locale={enLocale} dateAdapter={AdapterDayjs}>
          <FormRow style={{ marginBottom: 20 }}>
            <DateTimePicker
              renderInput={(props) => (
                <MuiTextField
                  {...props}
                  variant="outlined"
                  size="small"
                  error={dayjs(startDate).isAfter(endDate)}
                  style={{ flex: 1, marginRight: 20 }}
                />
              )}
              inputFormat="DD.MM.YYYY HH:mm"
              ampm={false}
              label={translate('admin.startDateTime')}
              value={startDate}
              onChange={(newValue) => {
                setSelectedRequestBookingParam('startDate', newValue);
              }}
            />
            <DateTimePicker
              renderInput={(props) => (
                <MuiTextField
                  {...props}
                  variant="outlined"
                  size="small"
                  error={dayjs(endDate).isBefore(startDate)}
                  style={{ flex: 1 }}
                />
              )}
              inputFormat="DD.MM.YYYY HH:mm"
              ampm={false}
              label={translate('admin.endDateTime')}
              value={endDate}
              onChange={(newValue) => {
                setSelectedRequestBookingParam('endDate', newValue);
              }}
            />
          </FormRow>
        </LocalizationProvider>
        <FormControl fullWidth style={{ marginBottom: 20 }}>
          <Autocomplete
            value={users.find((user) => user.id === userId)}
            size="small"
            placeholder=""
            getOptionLabel={(option) => option.label}
            options={users}
            onChange={(event: any, value: any) => {
              setSelectedRequestBookingParam('userId', value.id);
            }}
            renderInput={(params) => (
              <MuiTextField {...params} placeholder="" label={translate('admin.user')} />
            )}
          />
        </FormControl>
        <FormControl fullWidth style={{ marginBottom: 20 }}>
          <MuiTextField
            value={note}
            size="small"
            onChange={(event) =>
              setSelectedRequestBookingParam('note', event.target.value)
            }
            placeholder=""
            label={translate('admin.comment')}
          />
        </FormControl>
        <FormControl fullWidth style={{ marginBottom: 20 }}>
          <MuiTextField
            value={pickupLocationNotes}
            size="small"
            onChange={(event) =>
              setSelectedRequestBookingParam('pickupLocationNotes', event.target.value)
            }
            placeholder=""
            label={translate('admin.pickupLocationNotes')}
          />
        </FormControl>
        <FormControl fullWidth style={{ marginBottom: 20 }}>
          <MuiTextField
            value={returnLocationNotes}
            size="small"
            onChange={(event) =>
              setSelectedRequestBookingParam('returnLocationNotes', event.target.value)
            }
            placeholder=""
            label={translate('admin.returnLocationNotes')}
          />
        </FormControl>
      </DialogContent>
      <DialogActions style={{ justifyContent: 'flex-start' }}>
        <Button onClick={handleCloseDetail}>{translate('admin.close')}</Button>

        <Button
          variant="contained"
          onClick={handleSaveBooking}
          disabled={dayjs(endDate).isBefore(startDate)}
          style={{ marginLeft: 'auto' }}>
          {translate('admin.save')}
        </Button>
        {id && id !== 'NEW' ? (
          <Button
            variant="contained"
            color="error"
            onClick={handleDeleteRequestBooking}>
            {translate('admin.delete')}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};
