import { FormGroup, FormLabel, styled } from '@mui/material';

import NumberInput from '../../../common/NumberInput';

export const BookingDuration: React.VFC = () => {
  return (
    <FormGroup>
      <StyledFormLabel>Booking duration</StyledFormLabel>
      <FormGroup row>
        <NumberInput
          source="preBookingInterval"
          style={{ margin: '0 8px' }}
          label="Pre booking interval (minutes)"
        />
        <NumberInput
          source="postBookingInterval"
          style={{ margin: '0 8px' }}
          label="Post booking interval (minutes)"
        />
        <NumberInput
          source="earlyOpenInterval"
          style={{ margin: '0 8px' }}
          label="Early booking interval (minutes)"
        />
      </FormGroup>
    </FormGroup>
  );
};

const StyledFormLabel = styled(FormLabel)`
  margin-bottom: 22px;
`;
