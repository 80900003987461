import Button from '@mui/material/Button';
import { useLocaleState } from 'react-admin';

const LocaleSwitcher = () => {
  const [locale, setLocale] = useLocaleState();

  const onClick = (newLocale) => {
    localStorage.setItem('locale', newLocale);
    setLocale(newLocale);
  };

  return (
    <div>
      {locale === 'de' && <Button onClick={() => onClick('en')}>EN</Button>}
      {locale === 'en' && <Button onClick={() => onClick('de')}>DE</Button>}
    </div>
  );
};

export default LocaleSwitcher;
