import {
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  required,
} from 'react-admin';

import { BillingAccountPaymentFrequency } from '../../../@generated/schemas';
import { Grid } from '@mui/material';
import styled from 'styled-components';
import { useTranslate } from '../../../locales';

export const Form = () => {
  const translate = useTranslate();
  return (
    <Grid container spacing={8}>
      <Grid item md={6}>
        <Row>
          <ReferenceInput
            source="company_id"
            reference="Company"
            allowEmpty
            autoComplete={false}
            filter={{
              deletedAt: { equals: null },
            }}>
            <AutocompleteInput
              autoComplete={false}
              style={{ width: 400 }}
              label={translate('admin.company')}
              optionText="name"
              validate={[required()]}
            />
          </ReferenceInput>
        </Row>
        <Row>
          <TextInput
            source="name"
            validate={[required()]}
            label={translate('admin.billingAccountName')}
          />
          <TextInput
            source="departmentName"
            label={translate('admin.departmentName')}
          />
        </Row>
        <Row>
          <TextInput
            source="uidNumber"
            label={translate('admin.uidNumber')}
            validate={[required()]}
          />
        </Row>
        <Row>
          <TextInput
            source="address"
            label={translate('admin.address')}
            validate={[required()]}
          />
          <TextInput
            source="houseNumber"
            label={translate('admin.houseNumber')}
            validate={[required()]}
          />
        </Row>
        <Row>
          <TextInput
            source="city"
            label={translate('admin.city')}
            validate={[required()]}
          />
          <TextInput
            source="postCode"
            label={translate('admin.postCode')}
            validate={[required()]}
          />
          <TextInput
            source="country"
            label={translate('admin.country')}
            validate={[required()]}
          />
        </Row>
        <Row>
          <TextInput source="phoneNumber" label={translate('admin.phoneNumber')} />
          <TextInput source="email" label={translate('admin.emailField')} />
        </Row>
      </Grid>
      <Grid item md={6}>
        <Row>
          <TextInput
            source="contactPersonFullname"
            label={translate('admin.contactPersonFullname')}
          />
        </Row>
        <Row>
          <TextInput
            source="contactPersonPhoneNumber"
            label={translate('admin.contactPersonPhoneNumber')}
          />
        </Row>
        <Row>
          <TextInput
            source="contactPersonEmail"
            label={translate('admin.contactPersonEmail')}
            validate={[required()]}
          />
        </Row>
        <Row>
          <SelectInput
            source="paymentFrequency"
            label={translate('admin.paymentFrequency')}
            choices={[
              {
                id: BillingAccountPaymentFrequency.Weekly,
                name: translate('admin.paymentFrequencyWeekly'),
              },
              {
                id: BillingAccountPaymentFrequency.Monthly,
                name: translate('admin.paymentFrequencyMonthly'),
              },
            ]}
          />
        </Row>
      </Grid>
    </Grid>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  & > div {
    flex: 1;
  }
  div ~ div {
    margin-left: 10px;
  }
`;
