import {
  Edit,
  SaveButton,
  SimpleForm,
  Tab,
  TabbedShowLayout,
  Toolbar,
} from 'react-admin';
import TranslateInput, { transform } from '../../../common/TranslationInput';

import { GateControllerForm } from './form';
import { Grid } from '@mui/material';
import { OpenGateControllerModal } from './openGateControllerModal';
import RulesetsTable from './ruleSetsTable';

export const TABS = {
  basicInfo: 'basicInfo',
  contents: 'contents',
  rulesets: 'rulesets',
};

const FormTransform = (data) => {
  if (window.location.href.includes('/contents')) {
    const { contentAfter } = transform(data, 'contentAfter');
    const { contentBefore } = transform(data, 'contentBefore');

    return {
      ...data,
      contentBefore,
      contentAfter,
    };
  } else {
    return data;
  }
};

export const GateControllerEdit = () => {
  return (
    <Edit mutationMode="pessimistic" transform={FormTransform}>
      <TabbedShowLayout>
        <Tab label="Basic info" tabIndex={0}>
          <SimpleForm
            warnWhenUnsavedChanges
            toolbar={
              <Toolbar>
                <SaveButton />
                <OpenGateControllerModal />
              </Toolbar>
            }>
            <GateControllerForm />
          </SimpleForm>
        </Tab>
        <Tab label="Contents" path={TABS.contents}>
          <SimpleForm warnWhenUnsavedChanges>
            <Grid container spacing={8}>
              <Grid item md={6}>
                <TranslateInput source="contentBefore" hideName />
              </Grid>
              <Grid item md={6}>
                <TranslateInput source="contentAfter" hideName />
              </Grid>
            </Grid>
          </SimpleForm>
        </Tab>
        <Tab label="Rulesets" path={TABS.rulesets}>
          <SimpleForm>
            Rulesets
            <RulesetsTable />
          </SimpleForm>
        </Tab>
      </TabbedShowLayout>
    </Edit>
  );
};
