import { DeleteButton, Edit, SaveButton, SimpleForm, Tab, TabbedShowLayout, Toolbar, TopToolbar, useRecordContext, useRefresh, useShowContext } from 'react-admin';
import { useStartChargingStationMutation, useStopChargingStationMutation } from '../../../@generated/hooks';

import { Button } from '@mui/material';
import { ChargingStationForm } from './form';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import StopCircleIcon from '@mui/icons-material/StopCircle';
import TariffTable from './tariffTable';
import { transform } from '../../../common/TranslationInput';
import { useTranslate } from '../../../locales';

export const TABS = {
  basicInfo: 'basicInfo',
  tariffs: 'tariffs',
};

const StopButton = (props) => {
  const record = useRecordContext();
  const translate = useTranslate();
  const refreshData = useRefresh();
  const [stopTransaction] = useStopChargingStationMutation();

  const handleStopStation = async () => {
    await stopTransaction({
      variables: {
        evseId: record.id as string,
      },
    });
    refreshData();
  };

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<StopCircleIcon />}
      style={{ marginLeft: 16 }}
      onClick={handleStopStation}>
      {translate('admin.stopPower')}
    </Button>
  );
};

const StartButton = () => {
  const record = useRecordContext();
  const refreshData = useRefresh();
  const [startTransaction] = useStartChargingStationMutation();
  const translate = useTranslate();

  const handleStartStation = async () => {
    await startTransaction({
      variables: {
        evseId: record.id as string,
      },
    });
    refreshData();
  };

  return (
    <Button
      variant="contained"
      color="primary"
      startIcon={<PlayCircleFilledIcon />}
      style={{ marginLeft: 16 }}
      onClick={handleStartStation}>
      {translate('admin.startPower')}
    </Button>
  );
};

const CustomToolbar = () => (
  <Toolbar>
      <SaveButton />
      <StartButton />
      <StopButton />
      <DeleteButton style={{marginLeft: 'auto'}} />
  </Toolbar>
);

export const ChargingStationEdit = () => {
  const translate = useTranslate();
  return (
    <Edit
      mutationMode="pessimistic"
      transform={(data) => transform(data, 'name')}>
      <TabbedShowLayout>
        <Tab label={translate('admin.basicInfo')} tabIndex={0}>
          <SimpleForm toolbar={<CustomToolbar />}>
            <ChargingStationForm />
          </SimpleForm>
        </Tab>
        <Tab label={translate('admin.tariffs')} path={TABS.tariffs}>
          <SimpleForm>
            <TariffTable />
          </SimpleForm>
        </Tab>
      </TabbedShowLayout>
    </Edit>
  );
};
