import {
  Create,
  SimpleForm,
  useCreate,
  useGetIdentity,
  useNotify,
  useRedirect,
} from 'react-admin';

import { UserAside } from './form';
import { UserForm } from './form';
import { UserTabs } from './tabs';
import dayjs from 'dayjs';
import { omit } from 'lodash';
import sanitizeEmptyValues from '../../libs/sanitizeEmptyValues';
import { useCallback } from 'react';
import { useErrors } from '../../libs/useErrors';
import { useTranslate } from '../../locales';

export const UserCreate = (props: any) => {
  const { errors, handleSetErrors } = useErrors();
  const { identity } = useGetIdentity();
  const redirect = useRedirect();
  const translate = useTranslate();
  const notify = useNotify();
  const [saveCreate] = useCreate();

  const onSubmit = useCallback(async (values) => {
    handleSetErrors({});
    try {
      const user = await saveCreate(
        'User',
        {
          data: sanitizeEmptyValues(
            omit(values, [
              'stripeAccountConfirmedAt',
              'failedLoginAttempts',
              'phoneConfirmedAt',
            ]),
          ),
        },
        {
          returnPromise: true,
        },
      );
      notify(translate('admin.successfullyCreated'), { type: 'success' });
      redirect(`/User/${user.id}`);
    } catch (error) {
      notify(error.message, { type: 'error' });
      return handleSetErrors(error);
    }
  }, []);
  return (
    <Create
      {...props}
      undoable={false}
      mutationMode="pessimistic"
      aside={<UserAside />}>
      <SimpleForm
        onSubmit={onSubmit}
        defaultValues={{
          company: identity?.companyId,
          phoneConfirmedAt: null,
          commissionBookings: null,
          commissionChargingStations: null,
          commissionScooters: null,
          failedLoginAttempts: 0,
          birthdate: dayjs().subtract(17, 'year').toDate(),
          driverExpirationAt: dayjs().subtract(1, 'year').toDate(),
          driverBirthdate: dayjs().subtract(17, 'year').toDate(),
        }}>
        <UserTabs {...props}>
          <UserForm {...props} errors={errors} />
        </UserTabs>
      </SimpleForm>
    </Create>
  );
};
