import { Edit, SimpleForm } from 'react-admin';

import { ChargingStationClusterForm } from './form';
import { transform } from '../../../common/TranslationInput';

export const ChargingStationClusterEdit = () => {
  return (
    <Edit
      mutationMode="pessimistic"
      transform={(data) => transform(data, 'name')}>
      <SimpleForm>
        <ChargingStationClusterForm />
      </SimpleForm>
    </Edit>
  );
};
