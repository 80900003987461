import { SelectArrayInput, SelectInput, useCreate } from 'react-admin';
import { useEffect, useState } from 'react';

import { getValidate } from '../libs/checkRequiredField';
import { useFormContext } from 'react-hook-form';

const MultipleTypeInput = (props) => {
  const { getFieldState, setValue, getValues } = useFormContext();
  const field = getFieldState(props.source);
  const [create] = useCreate();

  const errorMessage = props.error || field?.error?.message || '';

  const [list, setList] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    setList(
      (props.data || []).map((item) => ({
        id: item.id,
        name: item.name,
      })),
    );
  }, [props.data]);

  const validate = getValidate(props);

  return (
    <SelectArrayInput
      {...props}
      validate={validate}
      source={props.source}
      choices={list || []}
      variant="outlined"
      label={props.label}
      style={{ flex: 1, marginBottom: 0, ...props.style }}
      error={!!errorMessage}
      helperText={errorMessage}
    />
  );
};

export default MultipleTypeInput;
