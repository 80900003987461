import {
  AutocompleteInput,
  ReferenceInput,
  SelectInput,
  TextInput,
  required,
} from 'react-admin';

import { BillingAccountPaymentFrequency } from '../../../@generated/schemas';
import { Grid } from '@mui/material';
import styled from 'styled-components';

export const Form = () => {
  return (
    <Grid container spacing={8}>
      <Grid item md={6}>
        <Row>
          <ReferenceInput
            source="company_id"
            reference="Company"
            allowEmpty
            autoComplete={false}
            filter={{
              deletedAt: { equals: null },
            }}>
            <AutocompleteInput
              autoComplete={false}
              style={{ width: 400 }}
              label="Company name"
              optionText="name"
              validate={[required()]}
            />
          </ReferenceInput>
        </Row>
        <Row>
          <TextInput
            source="name"
            validate={[required()]}
            label="Billing account name"
          />
          <TextInput source="departmentName" />
        </Row>
        <Row>
          <TextInput source="uidNumber" validate={[required()]} />
        </Row>
        <Row>
          <TextInput source="address" validate={[required()]} />
          <TextInput source="houseNumber" validate={[required()]} />
        </Row>
        <Row>
          <TextInput source="city" validate={[required()]} />
          <TextInput source="postCode" validate={[required()]} />
          <TextInput source="country" validate={[required()]} />
        </Row>
        <Row>
          <TextInput source="phoneNumber" />
          <TextInput source="email" />
        </Row>
      </Grid>
      <Grid item md={6}>
        <Row>
          <TextInput source="contactPersonFullname" />
        </Row>
        <Row>
          <TextInput source="contactPersonPhoneNumber" />
        </Row>
        <Row>
          <TextInput source="contactPersonEmail" validate={[required()]} />
        </Row>
        <Row>
          <SelectInput
            source="paymentFrequency"
            choices={[
              {
                id: BillingAccountPaymentFrequency.Weekly,
                name: 'Weekly (always on Monday)',
              },
              {
                id: BillingAccountPaymentFrequency.Monthly,
                name: 'Monthly (first business day of Month)',
              },
            ]}
          />
        </Row>
      </Grid>
    </Grid>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  & > div {
    flex: 1;
  }
  div ~ div {
    margin-left: 10px;
  }
`;
