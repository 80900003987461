import { FC, useState } from 'react';
import { Tab, Typography } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';

import queryString from 'query-string';
import { useRecordContext } from 'react-admin';

export const TABS = {
  basicInfo: 'basicInfo',
  bookingQuestions: 'bookingQuestions',
};

export const UserTabs: FC = (props) => {
  const record = useRecordContext();
  const [selectedTab, setTab] = useState<string>(
    (queryString.parse(window.location.search)?.tab ||
      TABS.basicInfo) as string,
  );

  const onChangeTab = (tabName: string) => {
    if (tabName) {
      setTab(tabName);
    }
  };

  return (
    <TabContext value={selectedTab}>
      <div style={{ position: 'relative' }}>
        <Typography variant="h5" style={{ marginBottom: 12 }}>
          {record?.id ? 'Edit user' : 'Create user'}
        </Typography>

        <TabList
          value={selectedTab}
          onChange={(_, value) => onChangeTab(value)}
          aria-label="">
          <Tab label="Basic info" value={TABS.basicInfo} />
          {record?.id && (
            <Tab label="Booking questions" value={TABS.bookingQuestions} />
          )}
        </TabList>
        {props.children}
      </div>
    </TabContext>
  );
};
