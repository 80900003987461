import {
  Ownership,
  RuleCompareOperator,
  RuleProperty,
  VehicleInsuranceType,
} from '../../../../@generated/schemas';

import dayjs from 'dayjs';
import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useNotify } from 'react-admin';

function compareFunction(
  compareOperator: RuleCompareOperator,
  compareValue: number,
  vehicleValue: number,
) {
  if (
    compareOperator === RuleCompareOperator.EqualTo &&
    vehicleValue === compareValue
  )
    return true;
  if (
    compareOperator === RuleCompareOperator.GreaterThan &&
    vehicleValue > compareValue
  )
    return true;
  if (
    compareOperator === RuleCompareOperator.GreaterThanEqualTo &&
    vehicleValue >= compareValue
  )
    return true;
  if (
    compareOperator === RuleCompareOperator.LessThan &&
    vehicleValue < compareValue
  )
    return true;
  if (
    compareOperator === RuleCompareOperator.LessThanEqualTo &&
    vehicleValue <= compareValue
  )
    return true;
  if (
    compareOperator === RuleCompareOperator.NotEqualTo &&
    vehicleValue != compareValue
  )
    return true;

  return false;
}

export const useInsuranceCheckForVehicle = () => {
  const { watch, setValue } = useFormContext();
  const notify = useNotify();

  const vehicleInsuranceTypeValue = watch('vehicleInsuranceType');
  const ownershipValue: Ownership = watch('ownership');
  const catalogPriceValue: number = watch('catalogPrice');
  const valueOfAccessoriesValue: number = watch('valueOfAccessories');
  const commissioningAtValue: Date = watch('commissioningAt');

  useEffect(() => {
    if (vehicleInsuranceTypeValue) {
      const { disabled } = vehicleInsuranceOption(vehicleInsuranceTypeValue);
      if (disabled) {
        setValue('vehicleInsuranceTypeId', null);
        notify('Your change has affect for insurance calculation!', {
          type: 'error',
        });
      }
    }
  }, [
    catalogPriceValue,
    commissioningAtValue,
    valueOfAccessoriesValue,
    vehicleInsuranceTypeValue,
    ownershipValue,
  ]);

  const vehicleInsuranceOption = (record: VehicleInsuranceType) => {
    const possibleRulesets = record?.vehicleInsuranceRuleSets.filter(
      (ruleset) => {
        if (
          ruleset.ownership !== ownershipValue &&
          ruleset.ownership !== Ownership.PrivateAndCompany
        ) {
          return false;
        }

        const enabledRules = ruleset?.vehicleInsuranceRules.filter((rule) =>
          [RuleProperty.AgeVehicle, RuleProperty.CatalogPrice].includes(
            rule.property,
          ),
        );
        const possibleRules = enabledRules.filter((rule) => {
          let compareValue = null;
          switch (rule.property) {
            case RuleProperty.AgeVehicle:
              compareValue = dayjs().diff(commissioningAtValue, 'years', true);
              break;
            case RuleProperty.CatalogPrice:
              compareValue = catalogPriceValue + valueOfAccessoriesValue;
              break;
          }

          const fromCompared = compareFunction(
            rule.fromCompareOperator,
            rule.fromValue,
            compareValue,
          );
          const toCompared = compareFunction(
            rule.toCompareOperator,
            rule.toValue,
            compareValue,
          );

          if (
            rule.fromCompareOperator &&
            rule.toCompareOperator &&
            fromCompared &&
            toCompared
          ) {
            return true;
          }
          if (
            rule.fromCompareOperator &&
            fromCompared &&
            !rule.toCompareOperator &&
            !toCompared
          ) {
            return true;
          }

          return false;
        });

        return possibleRules.length === enabledRules.length;
      },
    );

    return {
      id: record.id,
      name: record.name,
      disabled:
        possibleRulesets.length === 0 &&
        record.vehicleInsuranceRuleSets.length > 0,
    };
  };

  return { vehicleInsuranceOption };
};
