import { List, ListItemButton, ListItemText } from '@mui/material';
import {
  SharedVehicleGroupRelCompany,
  SharedVehicleGroupRelUser,
  SharedVehicleGroupRelVehicle,
} from '../../@generated/schemas';

import { VFC } from 'react';
import { useTranslate } from '../../locales';

type SharedVehicleGroupRelProps =
  | SharedVehicleGroupRelVehicle
  | SharedVehicleGroupRelUser
  | SharedVehicleGroupRelCompany;

type AssignedSharedVehicleGroupListProps = {
  sharedVehicleGroupRels: SharedVehicleGroupRelProps[];
};

export const AssignedSharedVehicleGroupList: VFC<
  AssignedSharedVehicleGroupListProps
> = ({ sharedVehicleGroupRels }) => {
  const translate = useTranslate();
  return (
    <List
      sx={{
        overflow: 'auto',
        height: '35vh',
        maxWidth: 360,
        bgcolor: 'background.paper',
      }}>
      {Object.values(sharedVehicleGroupRels || []).map(
        (sharedVehicleGroupRel: SharedVehicleGroupRelProps) => (
          <ListItemButton
            key={sharedVehicleGroupRel?.sharedVehicleGroup?.id}
            alignItems="flex-start"
            href={`#/SharedVehicleGroup/${sharedVehicleGroupRel?.sharedVehicleGroup?.id}`}
            target="_blank"
            component="a">
            <ListItemText
              primary={sharedVehicleGroupRel?.sharedVehicleGroup?.name}
              secondary={`${translate('admin.discount')}: ${sharedVehicleGroupRel?.sharedVehicleGroup?.discount || 0
                }%, ${translate('admin.private')}: ${sharedVehicleGroupRel?.sharedVehicleGroup?.isPrivate
                }, ${translate('admin.ownerBehavior')}: ${sharedVehicleGroupRel?.sharedVehicleGroup?.ownerBehavior
                }`}
            />
          </ListItemButton>
        ),
      )}
    </List>
  );
};
