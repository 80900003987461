import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  RuleProperty,
  VehicleInsuranceRuleSet,
} from '../../@generated/schemas';
import { omit, sortBy } from 'lodash';
import { useDelete, useRefresh } from 'react-admin';

import DeleteIcon from '@mui/icons-material/Delete';
import DuplicateIcon from '@mui/icons-material/CopyAll';
import EditIcon from '@mui/icons-material/Edit';
import { VFC } from 'react';
import formattedPrice from '../../libs/formattedPrice';
import { getValueByForProperty } from './utils';

const InsuranceRuleSetsTable: VFC<{
  rulesets: VehicleInsuranceRuleSet[];
  setShowDialog: (
    ruleset: Omit<VehicleInsuranceRuleSet, 'id'> & { id?: string },
  ) => void;
}> = ({ rulesets, setShowDialog }) => {
  const refresh = useRefresh();
  const [deleteOne] = useDelete();

  const handleDeleteRuleset = async (rulesetId: string) => {
    await deleteOne('VehicleInsuranceRuleSet', { id: rulesetId });
    refresh();
  };

  return (
    <TableContainer style={{ width: '100%' }}>
      <Table
        stickyHeader
        sx={{ minWidth: 650 }}
        style={{ maxHeight: '5wh' }}
        aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Ownership</TableCell>
            <TableCell>Rentals</TableCell>
            <TableCell>Driver's age</TableCell>
            <TableCell>Vehicle's age</TableCell>
            <TableCell>
              Catalog price
              <br />
              (including accessories)
            </TableCell>
            <TableCell>
              Deductible per
              <br />
              collision event
            </TableCell>
            <TableCell>
              Deductible per
              <br />
              partial cover event
            </TableCell>
            <TableCell>
              Tariff per booking
              <br />
              including 5% stamp duty
            </TableCell>
            <TableCell align="right" width="200">
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {sortBy(rulesets, ['updatedAt'])?.map((ruleset) => (
            <TableRow key={ruleset.id}>
              <TableCell component="th" scope="row">
                {ruleset.ownership}
              </TableCell>
              <TableCell component="th" scope="row">
                {getValueByForProperty(ruleset, RuleProperty.BookingDuration)}
              </TableCell>
              <TableCell component="th" scope="row">
                {getValueByForProperty(ruleset, RuleProperty.AgeUser)}
              </TableCell>
              <TableCell component="th" scope="row">
                {getValueByForProperty(ruleset, RuleProperty.AgeVehicle)}
              </TableCell>
              <TableCell component="th" scope="row">
                {getValueByForProperty(ruleset, RuleProperty.CatalogPrice)}
              </TableCell>
              <TableCell component="th" scope="row">
                {formattedPrice(ruleset.deductiblePerCollision)}
              </TableCell>
              <TableCell component="th" scope="row">
                {formattedPrice(ruleset.deductiblePerPartialCover)}
              </TableCell>
              <TableCell component="th" scope="row">
                {formattedPrice(ruleset.amount)}
              </TableCell>
              <TableCell component="th" align="right" scope="row">
                <IconButton
                  onClick={() => setShowDialog(omit(ruleset, ['id']))}>
                  <DuplicateIcon />
                </IconButton>
                <IconButton onClick={() => setShowDialog(ruleset)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => handleDeleteRuleset(ruleset.id)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InsuranceRuleSetsTable;
