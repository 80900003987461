import { AppBar, Logout, MenuItemLink, UserMenu } from 'react-admin';

import LocaleSwitcher from './LocaleSwitcher';
import { Lock as LockIcon } from '@mui/icons-material';
// import ThemeSwitcher from './ThemeSwitcher';
import { Typography } from '@mui/material';
import { forwardRef } from 'react';
import { useTranslate } from './locales';

const ChangeMyPasswordMenu = forwardRef<any, any>((props, ref) => {
  const translate = useTranslate();
  return (
    <MenuItemLink
      ref={ref}
      to="/change-my-password"
      primaryText={translate('admin.changePassword')}
      style={{ marginLeft: -4 }}
      leftIcon={<LockIcon />}
      onClick={props.onClick}
      sidebarIsOpen
    />
  );
});

const CustomUserMenu = (props: any) => (
  <UserMenu {...props}>
    <ChangeMyPasswordMenu />
    <Logout />
  </UserMenu>
);

const CustomAppBar = (props) => {
  return (
    <AppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
      <img
        src="/dmcLogo.svg"
        alt="logo"
        style={{
          flex: 1,
          maxWidth: '100px',
          marginLeft: 0,
          display: 'flex',
          alignItems: 'center',
        }}
      />
      <small style={{paddingLeft: 12}}>
        {`(${process.env.REACT_APP_RELEASE_VERSION}v)`}
      </small>
      <Typography
        variant="h6"
        color="inherit"
        style={{
          flex: 1,
          textAlign: 'center',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
        }}
        id="react-admin-title"
      />
      <LocaleSwitcher />
      {/* <ThemeSwitcher /> */}
    </AppBar>
  );
};

export default CustomAppBar;
