import {
  Button,
  Toolbar,
  useGetList,
  useRefresh,
  useUnselectAll,
} from 'react-admin';
import {
  CardContent,
  Dialog,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';

import { FormDialogTitle } from '@react-admin/ra-form-layout';
import { useState } from 'react';

export const BulkActionButtons = (props) => {
  const refresh = useRefresh();
  const unselectAll = useUnselectAll(props.resource);
  const [dialogIsOpen, setDialogIsOpen] = useState(false);
  const [billingAccountId, setBillingAccountId] = useState(null);
  const [description, setDescription] = useState('');
  const { data } = useGetList('BillingAccount', {
    pagination: { page: 1, perPage: 100 },
  });

  const handleClose = () => {
    setDialogIsOpen(false);
  };

  const handleOnSubmit = async () => {
    const { selectedIds } = props;
    await props.update({
      ids: selectedIds,
      billingAccountId,
      description,
    });

    handleClose();
    unselectAll();
    refresh();
  };

  return (
    <>
      <Dialog open={dialogIsOpen} onClose={handleClose} title="Bulk actions">
        <FormDialogTitle
          id="edit-dialog-title"
          title="Bulk actions"
          onClose={handleClose}
        />
        <form>
          <CardContent>
            <TextField
              size="small"
              label="Description"
              fullWidth
              variant="filled"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            />
            <br />
            <br />
            <FormControl sx={{ minWidth: 300 }} size="small">
              <InputLabel id="demo-select-small">Billing account</InputLabel>
              <Select
                labelId="demo-select-small"
                value={billingAccountId}
                variant="filled"
                size="small"
                onChange={(event) => setBillingAccountId(event.target.value)}>
                <MenuItem key={null} divider value={null}>
                  Empty
                </MenuItem>
                {data?.map((billingAccount) => (
                  <MenuItem key={billingAccount.id} value={billingAccount.id}>
                    {billingAccount.name} ({billingAccount.company?.name})
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CardContent>
          <Toolbar className="RaToolbar-desktopToolbar RaToolbar-root">
            <div className="RaToolbar-defaultToolbar">
              <Button
                label="Save"
                size="large"
                variant="contained"
                onClick={() => handleOnSubmit()}
              />
            </div>
          </Toolbar>
        </form>
      </Dialog>

      <Button label="Bulk update" onClick={() => setDialogIsOpen(true)} />
    </>
  );
};
