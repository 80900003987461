import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormGroup,
  FormLabel,
} from '@mui/material';
import {
  NumberInput,
  SelectInput,
  required,
  useCreate,
  useRefresh,
  useUpdate,
} from 'react-admin';
import {
  Ownership,
  RuleProperty,
  VehicleInsuranceRuleSet,
} from '../../@generated/schemas';
import { VFC, useEffect } from 'react';
import { isNil, omit } from 'lodash';

import CompareSelectOptions from '../../libs/compareSelectOptions';
import mapEnumSelectInput from 'src/libs/mapEnumSelectInput';
import { useFormContext } from 'react-hook-form';
import { useTranslate } from '../../locales';

const TWO_WEEKS = 24 * 7 * 2;

const FormRuleSet: VFC<{
  title: string;
  rulesetName: string;
}> = ({ title, rulesetName }) => {
  const translate = useTranslate();
  const { watch } = useFormContext();
  const watchCompareFrom = watch(`${rulesetName}CompareFrom`);
  const watchValueFrom = watch(`${rulesetName}ValueFrom`);

  return (
    <FormGroup style={{ width: '100%' }}>
      <FormLabel>{title}</FormLabel>

      <FormGroup row>
        <SelectInput
          label={translate('admin.compare')}
          source={`${rulesetName}CompareFrom`}
          style={{
            width: '20%',
            minWidth: 'auto',
            marginRight: 8,
          }}
          choices={CompareSelectOptions}
        />
        <NumberInput
          source={`${rulesetName}ValueFrom`}
          min={0}
          max={rulesetName === 'rentals' ? TWO_WEEKS : undefined}
          style={{ width: '25%', marginRight: 8 }}
        />
        <SelectInput
          disabled={isNil(watchCompareFrom) || isNil(watchValueFrom)}
          label={translate('admin.compare')}
          source={`${rulesetName}CompareTo`}
          style={{
            width: '20%',
            minWidth: 'auto',
            marginRight: 8,
          }}
          choices={CompareSelectOptions}
        />
        <NumberInput
          disabled={isNil(watchCompareFrom) || isNil(watchValueFrom)}
          min={0}
          max={rulesetName === 'rentals' ? TWO_WEEKS : undefined}
          source={`${rulesetName}ValueTo`}
          style={{ width: '25%' }}
        />
      </FormGroup>
    </FormGroup>
  );
};

const InsuranceRuleSetsDialog: VFC<{
  showDialog: boolean | (Omit<VehicleInsuranceRuleSet, 'id'> & { id?: string });
  setShowDialog: (showDialog: boolean) => void;
  vehicleInsuranceTypeId?: string;
}> = ({ showDialog, vehicleInsuranceTypeId, setShowDialog }) => {
  const { getValues, setValue, handleSubmit } = useFormContext();
  const [createOne] = useCreate();
  const [updateOne] = useUpdate();
  const refresh = useRefresh();

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  const handleOnSubmit = async () => {
    // with input into this function we are not receiving all data properties from form
    const inputs = getValues();

    const data = {
      amount: inputs.amount,
      deductiblePerCollision: inputs.deductiblePerCollision || 0,
      deductiblePerPartialCover: inputs.deductiblePerPartialCover || 0,
      ownership: inputs.ownership,
      vehicleInsurance: vehicleInsuranceTypeId,
      vehicleInsuranceRules: [],
    };
    if (inputs.rentalsCompareFrom && inputs.rentalsValueFrom) {
      data.vehicleInsuranceRules.push({
        property: RuleProperty.BookingDuration,
        fromCompareOperator: inputs.rentalsCompareFrom || null,
        fromValue: inputs.rentalsValueFrom || null,
        toCompareOperator: inputs.rentalsCompareTo || null,
        toValue: inputs.rentalsValueTo || null,
      });
    }

    if (!isNil(inputs.driversAgeValueFrom)) {
      data.vehicleInsuranceRules.push({
        property: RuleProperty.AgeUser,
        fromCompareOperator: inputs.driversAgeCompareFrom,
        fromValue: inputs.driversAgeValueFrom,
        toCompareOperator: inputs.driversAgeCompareTo || null,
        toValue: inputs.driversAgeValueTo || null,
      });
    }

    if (!isNil(inputs.catalogPriceValueFrom)) {
      data.vehicleInsuranceRules.push({
        property: RuleProperty.CatalogPrice,
        fromCompareOperator: inputs.catalogPriceCompareFrom,
        fromValue: inputs.catalogPriceValueFrom,
        toCompareOperator: inputs.catalogPriceCompareTo || null,
        toValue: inputs.catalogPriceValueTo || null,
      });
    }

    if (!isNil(inputs.vehiclesAgeValueFrom)) {
      data.vehicleInsuranceRules.push({
        property: RuleProperty.AgeVehicle,
        fromCompareOperator: inputs.vehiclesAgeCompareFrom,
        fromValue: inputs.vehiclesAgeValueFrom,
        toCompareOperator: inputs.vehiclesAgeCompareTo || null,
        toValue: inputs.vehiclesAgeValueTo || null,
      });
    }

    if (typeof showDialog === 'object' && showDialog.id) {
      await updateOne(
        'VehicleInsuranceRuleSet',
        {
          id: showDialog.id,
          data: omit(data, ['id']),
        },
        { returnPromise: true },
      );
    } else {
      await createOne(
        'VehicleInsuranceRuleSet',
        {
          data,
        },
        { returnPromise: true },
      );
    }

    refresh();
    handleCloseDialog();
  };

  useEffect(() => {
    async function initValues() {
      if (showDialog) {
        setValue('deductiblePerCollision', null);
        setValue('deductiblePerPartialCover', null);
        setValue('ownership', null);
        setValue('amount', null);

        ['driversAge', 'rentals', 'catalogPrice', 'vehiclesAge'].map(
          (prefix) => {
            setValue(`${prefix}CompareFrom`, null);
            setValue(`${prefix}ValueFrom`, null);
            setValue(`${prefix}CompareTo`, null);
            setValue(`${prefix}ValueTo`, null);
          },
        );
      }

      if (showDialog && typeof showDialog === 'object') {
        setValue('deductiblePerCollision', showDialog.deductiblePerCollision);
        setValue(
          'deductiblePerPartialCover',
          showDialog.deductiblePerPartialCover,
        );
        setValue('ownership', showDialog.ownership);
        setValue('amount', showDialog.amount);

        showDialog.vehicleInsuranceRules.map((rule) => {
          let prefix = '';

          if (rule.property === RuleProperty.AgeUser) {
            prefix = 'driversAge';
          }
          if (rule.property === RuleProperty.BookingDuration) {
            prefix = 'rentals';
          }
          if (rule.property === RuleProperty.CatalogPrice) {
            prefix = 'catalogPrice';
          }
          if (rule.property === RuleProperty.AgeVehicle) {
            prefix = 'vehiclesAge';
          }

          setValue(`${prefix}CompareFrom`, rule.fromCompareOperator);
          setValue(`${prefix}ValueFrom`, rule.fromValue);
          setValue(`${prefix}CompareTo`, rule.toCompareOperator);
          setValue(`${prefix}ValueTo`, rule.toValue);
        });
      }
    }
    initValues();
  }, [showDialog]);

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={!!showDialog}
      title="Create rule set"
      onClose={handleCloseDialog}>
      <DialogTitle>
        {typeof showDialog === 'boolean' || !showDialog?.id ? 'Create' : 'Edit'}{' '}
        new ruleset
      </DialogTitle>
      <DialogContent>
        <FormGroup>
          <SelectInput
            source="ownership"
            style={{ maxWidth: 300 }}
            choices={mapEnumSelectInput(Ownership)}
          />
        </FormGroup>
        <FormRuleSet title="Rentals" rulesetName="rentals" />
        <FormRuleSet title="Driver's age" rulesetName="driversAge" />
        <FormRuleSet title="Vehicle's age" rulesetName="vehiclesAge" />
        <FormRuleSet title="Catalog price" rulesetName="catalogPrice" />

        <FormGroup>
          <NumberInput
            style={{ maxWidth: 300 }}
            source="deductiblePerCollision"
          />
        </FormGroup>
        <FormGroup>
          <NumberInput
            style={{ maxWidth: 300 }}
            source="deductiblePerPartialCover"
          />
        </FormGroup>
        <FormGroup>
          <NumberInput
            source="amount"
            style={{ maxWidth: 300 }}
            validate={required()}
          />
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit(handleOnSubmit)} variant="contained">
          Save
        </Button>
        <Button onClick={handleCloseDialog}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default InsuranceRuleSetsDialog;
