import {
  RuleCompareOperator,
} from '../@generated/schemas';

const CompareSelectOptions = Object.values(RuleCompareOperator).map(
  (option): { id: RuleCompareOperator; name: string } => {
    switch (option) {
      case RuleCompareOperator.EqualTo:
        return { id: RuleCompareOperator.EqualTo, name: '=' };
      case RuleCompareOperator.GreaterThan:
        return { id: RuleCompareOperator.GreaterThan, name: '>' };
      case RuleCompareOperator.GreaterThanEqualTo:
        return { id: RuleCompareOperator.GreaterThanEqualTo, name: '>=' };
      case RuleCompareOperator.LessThan:
        return { id: RuleCompareOperator.LessThan, name: '<' };
      case RuleCompareOperator.LessThanEqualTo:
        return { id: RuleCompareOperator.LessThanEqualTo, name: '<=' };
      case RuleCompareOperator.NotEqualTo:
        return { id: RuleCompareOperator.NotEqualTo, name: '!=' };
    }
  },
);

export default CompareSelectOptions;