import {
  AutocompleteInput,
  BooleanInput,
  Create,
  Datagrid,
  DateTimeInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  Filter,
  FunctionField,
  List,
  NumberInput,
  ReferenceArrayField,
  ReferenceInput,
  ShowButton,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  maxValue,
  minValue,
  required,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { Button, CircularProgress, Grid } from '@mui/material';
import {
  useGenerateCompanyOnboardingStripeLinkMutation,
  useUploadCompanyLogoMutation,
} from '../../@generated/hooks';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { Company } from '../../@generated/schemas';
import CustomPagination from '../../CustomPagination';
import getStoragePath from '../../libs/getStoragePath';
import styled from 'styled-components';
import { useRef } from 'react';

export const UploadButton: React.VFC<{
  refetch: () => void;
}> = ({ refetch }) => {
  const record = useRecordContext<Company>();
  const [upload, { loading }] = useUploadCompanyLogoMutation();
  const uploadInputRef = useRef<any>();

  const onChange = async (event) => {
    const files = event.target.files;

    try {
      await upload({
        variables: {
          file: files[0],
          companyId: record.id,
        },
      });

      refetch();

      uploadInputRef.current.value = null;
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <label htmlFor="upload-file">
      <input
        ref={uploadInputRef}
        id="upload-file"
        onChange={onChange}
        type="file"
        hidden
      />
      <Button
        style={{
          marginBottom: 20,
        }}
        disabled={loading}
        variant="contained"
        onClick={() => uploadInputRef?.current?.click()}>
        Upload logo
        {loading ? (
          <CircularProgress
            size="1rem"
            color="inherit"
            style={{ marginLeft: 8 }}
          />
        ) : (
          <CloudUploadIcon style={{ marginLeft: 8 }} />
        )}
      </Button>
    </label>
  );
};

const Form = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const notify = useNotify();
  const [stripeOnboarding, { loading }] =
    useGenerateCompanyOnboardingStripeLinkMutation();

  const handleResetStripeLink = async () => {
    try {
      await stripeOnboarding({
        variables: { companyId: record.id as string },
      });

      notify('E-mail was successfully sent', { type: 'success' });

      refresh();
    } catch (error) {
      notify(error.message, { type: 'error' });
    }
  };

  return (
    <Grid container spacing={8}>
      <Grid item md={5}>
        <Row>
          <TextInput source="name" />
          <TextInput
            source="identifier"
            validate={[required()]}
          />
        </Row>
        <ReferenceInput
          source="defaultSharedVehicleGroup_id"
          reference="SharedVehicleGroup"
          filter={{
            deletedAt: { equals: null },
          }}>
          <AutocompleteInput
            fullWidth
            optionText="name"
            label="Default shared vehicle group"
          />
        </ReferenceInput>

        <Row>
          <TextInput source="address" validate={[required()]} />
          <TextInput source="houseNumber" validate={[required()]} />
        </Row>
        <Row>
          <TextInput source="city" validate={[required()]} />
          <TextInput source="postCode" validate={[required()]} />
          <TextInput source="country" validate={[required()]} />
        </Row>
        <Row>
          <TextInput source="phoneNumber" />
          <TextInput source="email" />
        </Row>
        <Row>
          <TextInput source="contactPersonFullname" />
        </Row>
        <Row>
          <TextInput source="contactPersonPhoneNumber" />
        </Row>
        <Row>
          <TextInput source="contactPersonEmail" validate={[required()]} />
        </Row>

        {record?.stripeAccountLink && (
          <>
            <a target="_blank" href={record?.stripeAccountLink}>
              Stripe account onboarding
            </a>
            <br />
            <br />
          </>
        )}

        <DateTimeInput
          label="Stripe account connected at"
          variant="outlined"
          size="small"
          style={{ width: '100%' }}
          source="stripeAccountConfirmedAt"
          disabled
        />

        <Button
          variant="contained"
          disabled={loading}
          startIcon={loading && <CircularProgress size="1em" />}
          onClick={handleResetStripeLink}>
          Resend stripe link
        </Button>
      </Grid>
      <Grid item md={5}>
        <UploadButton refetch={refresh} />
        {record?.logo && (
          <LogoWrapper>
            <img
              style={{ height: 50, objectFit: 'cover' }}
              src={getStoragePath(`${record.logo}`, 'drivemycar-company-logo')}
            />
          </LogoWrapper>
        )}
        <SimpleShowLayout>
          <ReferenceArrayField
            label="Billing accounts"
            source="billingAccounts_ids"
            reference="BillingAccount">
            <Datagrid style={{ width: '100%' }} bulkActionButtons={false}>
              <TextField source="name" />
              <FunctionField
                source="chargingStationRFIDs"
                label="RFIDs"
                render={(row) => row?.chargingStationRFIDs?.length}
              />
            </Datagrid>
          </ReferenceArrayField>
        </SimpleShowLayout>

        <div style={{marginTop: 30}} />

        <Row>
          <BooleanInput label="Enabled bookings" source="enabledBookings" />
          <NumberInput
            label="Commission for bookings (%)"
            source="commissionBookings"
            validate={[minValue(0), maxValue(100)]}
          />
        </Row>
        <Row>
          <BooleanInput
            label="Enabled charging sessions"
            source="enabledChargingSessions"
          />
          <NumberInput
            label="Commission for charging stations (%)"
            source="commissionChargingStations"
            validate={[minValue(0), maxValue(100)]}
          />
        </Row>
        <Row>
          <BooleanInput label="Enabled scooters" source="enabledScooters" />
          <NumberInput
            label="Commission for scooters (%)"
            source="commissionScooters"
            validate={[minValue(0), maxValue(100)]}
          />
        </Row>
      </Grid>
    </Grid>
  );
};

export const CompanyCreate = (props: any) => (
  <Create {...props} mutationMode="pessimistic">
    <SimpleForm defaultValues={{
      commissionBookings: 0,
      commissionChargingStations: 0,
      commissionScooters: 0
    }}>
      <Form {...props} />
    </SimpleForm>
  </Create>
);

export const CompanyEdit = (props: any) => (
  <Edit {...props} redirect={false} mutationMode="pessimistic">
    <SimpleForm>
      <Form {...props} />
    </SimpleForm>
  </Edit>
);

export const CompanyFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const CompanyList = (props: any) => {
  return (
    <List
      {...props}
      filters={<CompanyFilter />}
      pagination={<CustomPagination />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" />
        <EditButton />
        <DeleteWithConfirmButton mutationMode="pessimistic" />
      </Datagrid>
    </List>
  );
};

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  & > div {
    flex: 1;
  }
  div ~ div {
    margin-left: 10px;
  }
`;

const LogoWrapper = styled.div`
  background-color: #f0f0f0;
  max-height: 100px;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
