import {
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  TextInput,
  required,
} from 'react-admin';
import { FormGroup, Grid, TextField as MuiTextField } from '@mui/material';
import { GoogleMap, Marker, StandaloneSearchBox } from '@react-google-maps/api';

import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { useState } from 'react';

export const DefaultLocation = {
  latitude: 47.3769,
  longitude: 8.5417,
};

export const GateControllerForm = () => {
  return (
    <Grid container spacing={8}>
      <Grid item md={6}>
        <FormGroup row>
          <TextInput source="gateId" label="Invisia gate ID" />
        </FormGroup>
        <FormGroup row>
          <ReferenceInput reference="Company" source="company_id">
            <SelectInput optionText="name" />
          </ReferenceInput>
        </FormGroup>
        <FormGroup row>
          <ReferenceInput reference="GateControllerType" source="gateType_id">
            <SelectInput optionText="name" />
          </ReferenceInput>
        </FormGroup>
        <FormGroup>
          <TextInput source="operatorCompanyName" />
        </FormGroup>
        <FormGroup>
          <TextInput source="operatorCompanyPerson" />
        </FormGroup>
        <FormGroup>
          <TextInput source="operatorCompanyPhone" />
        </FormGroup>
        <FormGroup>
          <TextInput source="operatorCompanyEmail" />
        </FormGroup>
      </Grid>
      <Grid item md={6}>
        <FormDataConsumer>
          {() => {
            const { setValue, watch } = useFormContext();
            const [searchBoxRef, setSearchBoxRef] =
              useState<google.maps.places.SearchBox>();
            const geocoder = new google.maps.Geocoder();

            const latitude = watch('latitude') || DefaultLocation.latitude;
            const longitude = watch('longitude') || DefaultLocation.longitude;

            const handleSearchboxChanged = () => {
              const places = searchBoxRef.getPlaces();
              const firstPlace = places[0];

              const lat = firstPlace.geometry.location.lat();
              const lng = firstPlace.geometry.location.lng();

              setValue('latitude', lat);
              setValue('longitude', lng);
            };
            return (
              <>
                <GoogleMap
                  mapContainerStyle={{
                    width: '100%',
                    height: 400,
                  }}
                  center={{
                    lat: latitude,
                    lng: longitude,
                  }}
                  zoom={16}>
                  <GoogleMapsInputWrapper>
                    <StandaloneSearchBox
                      onLoad={setSearchBoxRef}
                      onPlacesChanged={handleSearchboxChanged}>
                      <MuiTextField
                        variant="outlined"
                        onKeyUp={(event) => {
                          if (event.key === 'Enter') {
                            event.stopPropagation();
                          }
                        }}
                        placeholder="Search address"
                        style={{ background: 'white' }}
                      />
                    </StandaloneSearchBox>
                  </GoogleMapsInputWrapper>
                  <Marker
                    draggable
                    onDragEnd={(e) => {
                      geocoder
                        .geocode({ location: e.latLng })
                        .then((response) => {
                          if (response.results[0]) {
                            setValue('latitude', e.latLng.lat());
                            setValue('longitude', e.latLng.lng());
                          }
                        });
                    }}
                    position={{
                      lat: latitude,
                      lng: longitude,
                    }}
                  />
                </GoogleMap>
                <TextInput source="latitude" disabled validate={[required()]} />
                <TextInput
                  source="longitude"
                  disabled
                  validate={[required()]}
                />
              </>
            );
          }}
        </FormDataConsumer>
      </Grid>
    </Grid>
  );
};

const GoogleMapsInputWrapper = styled.div`
  position: absolute;
  top: 65px;
  left: 10px;
  z-index: 100;
`;
