import {
  AutocompleteInput,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  Link,
  List,
  ReferenceInput,
  TextField,
  TextInput,
  useRecordContext,
} from 'react-admin';

import { ChargingStationTransactionReport } from '../../../@generated/schemas';
import CustomPagination from '../../../CustomPagination';
import cookie from 'js-cookie';
import { url } from '../../../';
import { useTranslate } from '../../../locales';

export const ChargingStationReportFilter = (props: any) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <TextInput source="reportHash" label={translate('admin.reportHash')} alwaysOn />
      <ReferenceInput
        source="billingAccountId"
        reference="BillingAccount"
        alwaysOn>
        <AutocompleteInput
          label={translate('admin.billingAccount')}
          style={{ width: 250 }}
          optionText={(record) => record?.name}
        />
      </ReferenceInput>
    </Filter>
  );
};

const Download = () => {
  const record = useRecordContext<ChargingStationTransactionReport>();
  const translate = useTranslate();
  return (
    (record && (
      <a
        target="_blank"
        href={`${url}/admin/transaction-report/${
          record.reportHash
        }?access_token=${cookie.get('access_token')}`}
        download>
        {translate('admin.download')}
      </a>
    )) ||
    null
  );
};

export const ChargingStationReportList = (props: any) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      exporter={false}
      filters={<ChargingStationReportFilter />}
      sort={{ field: 'createdAt', order: 'desc' }}
      title={translate('admin.chargingStationReports')}
      pagination={<CustomPagination />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="reportHash" label={translate('admin.reportHash')} />
        <DateField
          source="createdAt"
          locales="de-DE"
          label={translate('admin.createdAt')}
        />
        <FunctionField
          source="billingAccount.id"
          label={translate('admin.billingAccount')}
          render={(record) =>
            record?.billingAccount?.id && (
              <Link
                target="_blank"
                to={`/BillingAccount/${record?.billingAccount?.id}`}>
                {record?.billingAccount?.name}
              </Link>
            )
          }
        />
        <Download />
      </Datagrid>
    </List>
  );
};
