import { BooleanInput } from 'react-admin';
import InsuranceContent from './content';

const InsuranceContentVAG = () => {
  return (
    <>
      <BooleanInput source="showVAG" label="Show VAG45" />
      <InsuranceContent source="contentVAG" />
    </>
  );
};

export default InsuranceContentVAG;
