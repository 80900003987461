import {
  AutocompleteInput,
  Datagrid,
  DateTimeInput,
  DeleteWithConfirmButton,
  Filter,
  FunctionField,
  Link,
  List,
  ReferenceInput,
  SelectInput,
  ShowButton,
  TextField,
  TextInput,
  downloadCSV,
} from 'react-admin';
import {
  ChargingStationTransaction,
  ChargingStationTransactionAuthType,
} from '../../../@generated/schemas';

import { Chip } from '@mui/material';
import CustomPagination from '../../../CustomPagination';
import { convertEnumFilterItems } from '../../../libs/convertEnumFilterItems';
import dayjs from 'dayjs';
import formattedPrice from '../../../libs/formattedPrice';
import jsonExport from 'jsonexport/dist';

export const ChargingStationTransactionFilter = (props: any) => (
  <Filter {...props}>
    <DateTimeInput source="createdAt_gte" alwaysOn />
    <DateTimeInput source="startDate_gte" alwaysOn />
    <DateTimeInput source="endDate_lte" alwaysOn />
    <TextInput source="paymentHash" alwaysOn />
    <TextInput source="transactionId" alwaysOn />
    <SelectInput
      source="auth"
      choices={convertEnumFilterItems(ChargingStationTransactionAuthType)}
      alwaysOn
    />
    <ReferenceInput
      source="chargingStationRfid.is.billingAccountId_id"
      reference="BillingAccount"
      alwaysOn>
      <AutocompleteInput
        label="Billing account"
        optionText="name"
        style={{ width: 250 }}
      />
    </ReferenceInput>
    <ReferenceInput source="userId" reference="User" alwaysOn>
      <AutocompleteInput
        label="User"
        optionText="email"
        style={{ width: 250 }}
      />
    </ReferenceInput>
    <ReferenceInput
      source="chargingStationRfidId"
      reference="ChargingStationRfid"
      alwaysOn>
      <AutocompleteInput label="RFID" optionText="tag" style={{ width: 250 }} />
    </ReferenceInput>
    <ReferenceInput
      source="chargingStationId"
      reference="ChargingStation"
      alwaysOn>
      <AutocompleteInput
        label="Installation"
        optionText="evesId"
        style={{ width: 250 }}
      />
    </ReferenceInput>
  </Filter>
);

const customExporter = (items: ChargingStationTransaction[]) => {
  const listForExport = items.map((row) => {
    const chargingStationName =
      JSON.parse(row.chargingStation.name || '{}')?.en ||
      JSON.parse(row.chargingStation.name || '{}')?.de ||
      JSON.parse(row.chargingStation.name || '{}')?.it ||
      JSON.parse(row.chargingStation.name || '{}')?.fr;
    return {
      Transaction: row.transactionId,
      Auth: row.auth,
      Status: row.lastStatus,
      RFID:
        row.chargingStationRfid?.originalTag ||
        row.chargingStationRfid?.tag ||
        '-',
      'Payment hash': row.paymentHash,
      'Charging station': chargingStationName,
      'Start date': row.startDate
        ? dayjs(row.startDate).format('DD.MM. YYYY')
        : '',
      'Start time': row.startDate ? dayjs(row.startDate).format('HH:mm') : '',
      'End date': row.finishAt ? dayjs(row.finishAt).format('DD.MM. YYYY') : '',
      'End time': row.finishAt ? dayjs(row.finishAt).format('HH:mm') : '',
      Tariff:
        row.calculation?.tariffs?.map(
          (tariff) => JSON.parse(tariff.name || '{}')?.en,
        )?.[0] || '',
      'Cost for kWh': formattedPrice(row.calculation?.tariffs?.[0]?.price || 0),
      'Cost per minute': formattedPrice(
        row.calculation?.tariffs?.[0]?.amountPerMinute || 0,
      ),
      User: row.user?.email || '-',
      'Billing account': row?.chargingStationRfid?.billingAccount?.name || '',
      'Total usage': row.calculation?.totalkWh,
      'Total minutes': row.calculation?.totalMinutes,
      'Total cost': formattedPrice(row.calculation?.totalCost),
    };
  });

  jsonExport(listForExport, { rowDelimiter: ';' }, (err, csv) =>
    downloadCSV(csv, 'Transactions report'),
  );
};

export const ChargingStationTransactionList = (props: any) => {
  return (
    <List
      {...props}
      exporter={customExporter}
      filters={<ChargingStationTransactionFilter />}
      sort={{ field: 'startDate', order: 'desc' }}
      filterDefaultValues={{
        createdAt_gte: dayjs().subtract(1, 'week').toDate(),
      }}
      title="Charging station transactions"
      pagination={<CustomPagination />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="transactionId" />
        <FunctionField
          source="chargingStation.evesId"
          label="Charging station"
          render={(row) => {
            const name =
              JSON.parse(row.chargingStation.name || '{}')?.en ||
              JSON.parse(row.chargingStation.name || '{}')?.de ||
              JSON.parse(row.chargingStation.name || '{}')?.it ||
              JSON.parse(row.chargingStation.name || '{}')?.fr;
            return (
              <div>
                <Link
                  target="_blank"
                  to={`/ChargingStation/${row.chargingStation?.id}`}>
                  {name}
                </Link>
                <br />({row.chargingStation?.evesId})
              </div>
            );
          }}
        />
        <TextField source="auth" />
        <FunctionField
          source="lastStatus"
          label="Status"
          render={(row) => {
            if (row.lastStatus === 'CHARGE_TERMINATED') {
              return <Chip color="success" label="Terminated" />;
            }
            if (row.lastStatus === 'CHARGE_STARTED') {
              return <Chip color="primary" label="Running" />;
            }

            return <Chip color="error" label="Error" />;
          }}
        />
        <FunctionField
          source="startDate"
          label="Date time"
          render={(record) => {
            return (
              <div>
                <div>
                  <b>Start:</b>
                </div>{' '}
                {record.startDate
                  ? dayjs(record.startDate).format('DD.MM. YYYY HH:mm')
                  : '-'}
                <br />
                <div>
                  <b>End:</b>
                </div>{' '}
                {record.endDate
                  ? dayjs(record.endDate).format('DD.MM. YYYY HH:mm')
                  : '-'}
              </div>
            );
          }}
        />
        <FunctionField
          source="chargingStationRfid.tag"
          label="RFID"
          render={(row) =>
            <div>
              <div>{row?.chargingStationRfid?.tag}</div>
              <div>{row?.chargingStationRfid?.originalTag ? `(${row?.chargingStationRfid?.originalTag})` : ''}</div>
            </div>
          }
        />
        <TextField source="user.email" label="User" />
        <TextField source="paymentHash" />
        <FunctionField
          source="chargingStationRfid.billingAccount.id"
          label="Billing account"
          render={(record) =>
            record?.chargingStationRfid?.billingAccount?.id && (
              <Link
                target="_blank"
                to={`/BillingAccount/${record?.chargingStationRfid?.billingAccount?.id}`}>
                {record?.chargingStationRfid?.billingAccount?.name}
              </Link>
            )
          }
        />
        <ShowButton />
        <DeleteWithConfirmButton />
      </Datagrid>
    </List>
  );
};
