import {
  Alert,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  MenuItem,
  Modal,
  TextField as MuiTextField,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  FunctionField,
  Link,
  ReferenceArrayField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  TextField,
  useNotify,
  useRecordContext,
  useRefresh,
  useShowContext,
  useUpdate,
} from 'react-admin';
import {
  Booking,
  BookingQuestionKind,
  BookingQuestionType,
  BookingStatus,
  SendMessageEnum,
} from '../../@generated/schemas';
import { useEffect, useState } from 'react';
import {
  useGetBookingCalculationQuery,
  useGetBookingCheckAvailabilityLazyQuery,
  useGetBookingDetailQuery,
  useGetCancellationFeesLazyQuery,
  useRevokeBookingMutation,
  useSendMessageMutation,
  useVehicleTypesQueryQuery,
} from '../../@generated/hooks';

import AdapterDayjs from '@mui/lab/AdapterDayjs';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { DateTimePicker } from '@mui/lab';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import SmsIcon from '@mui/icons-material/Sms';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import StarIcon from '@mui/icons-material/Star';
import cookie from 'js-cookie';
import dayjs from 'dayjs';
import enLocale from 'date-fns/locale/en-GB';
import formattedPrice from '../../libs/formattedPrice';
import styled from 'styled-components';
import { url } from '../../';

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const BookingTitle = () => {
  const record = useRecordContext();
  return <span>Booking: {record?.bookingHash}</span>;
};

const DownloadDriverLicense = (props) => {
  const record = useRecordContext();
  const { source } = props;
  return (
    (record && (
      <a
        target="_blank"
        href={`${url}/admin/driverlicense/${
          record[source]
        }?access_token=${cookie.get('access_token')}`}
        download>
        Download
      </a>
    )) ||
    null
  );
};

const BookingOverviewPriceRow: React.VFC<{
  amount?: number;
  bold?: boolean;
  title: string;
  price?: number;
}> = ({ amount, price, title, bold }) => {
  const style: React.CSSProperties = bold ? { fontWeight: 'bold' } : {};
  return (
    <Row style={{ marginTop: 4 }}>
      <Typography fontSize={12} style={{ ...style, flex: 1 }}>
        {amount}
      </Typography>
      <Typography fontSize={12} style={{ ...style, flex: 4 }}>
        {title}
      </Typography>
      <Typography
        fontSize={12}
        style={{ ...style, flex: 2, textAlign: 'right' }}>
        {price !== undefined
          ? `CHF ${parseFloat(Number(price).toFixed(2))}`
          : ''}
      </Typography>
    </Row>
  );
};

const DEFAULT_PRICE = {
  totalCost: 0,
  costForDuration: 0,
  costForDistance: 0,
  includedDistance_km: 0,
  insuranceCost: 0,
  deductibleReduction: 0,
  totalCostWithoutInsurance: 0,
  totalHourlyCost: 0,
  totalDailyCost: 0,
  totalWeeklyCost: 0,
  totalHours: 0,
  totalDays: 0,
  totalWeeks: 0,
};

export const BookingPriceOverview = (props) => {
  const recordContext = useRecordContext();
  const record = recordContext || props;

  const price = props?.bookingPrice || {
    startPrice: DEFAULT_PRICE,
    endPrice: DEFAULT_PRICE,
  };

  const excessCost = price.endPrice?.costForDistance || 0;
  const originalBookingCost = record.totalCost;

  const additionalCost =
    parseFloat(
      formattedPrice(
        (price?.endPrice?.totalCostWithoutInsurance || 0) -
          (record?.totalCost ||
            price?.startPrice?.totalCostWithoutInsurance ||
            0),
        false,
      ),
    ) - excessCost;

  const finalPrice =
    price?.endPrice?.totalCostWithoutInsurance ||
    price?.startPrice?.totalCostWithoutInsurance ||
    0;

  const totalHours =
    (price.endPrice
      ? price.endPrice?.totalHours
      : price.startPrice.totalHours) || 0;
  const totalHourlyCost =
    (price.endPrice
      ? price.endPrice?.totalHourlyCost
      : price.startPrice.totalHourlyCost) || 0;

  const totalDays =
    (price.endPrice ? price.endPrice?.totalDays : price.startPrice.totalDays) ||
    0;
  const totalDailyCost =
    (price.endPrice
      ? price.endPrice?.totalDailyCost
      : price.startPrice.totalDailyCost) || 0;

  const totalWeeks =
    (price.endPrice
      ? price.endPrice?.totalWeeks
      : price.startPrice.totalWeeks) || 0;
  const totalWeeklyCost =
    (price.endPrice
      ? price.endPrice?.totalWeeklyCost
      : price.startPrice.totalWeeklyCost) || 0;

  return (
    <div>
      {props?.loading && (
        <div
          style={{
            height: 150,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <CircularProgress />
        </div>
      )}

      {!props?.loading && (
        <div>
          {totalHours > 0 && (
            <BookingOverviewPriceRow
              amount={totalHours || 0}
              title={`Hourly Rate, CHF ${
                record.priceHour || props.priceHour || 0
              }`}
              price={totalHourlyCost}
            />
          )}
          {(totalDays || 0) > 0 && (
            <BookingOverviewPriceRow
              amount={totalDays || 0}
              title={`Daily Rate, CHF ${
                record.priceDay || props.priceDay || 0
              }`}
              price={totalDailyCost}
            />
          )}
          {totalWeeks > 0 && (
            <BookingOverviewPriceRow
              amount={totalWeeks}
              title={`Weekly Rate, CHF ${
                record.priceWeek || props.priceWeek || 0
              }`}
              price={totalWeeklyCost}
            />
          )}
          <BookingOverviewPriceRow
            amount={
              price.endPrice?.includedDistance_km ||
              price.startPrice?.includedDistance_km ||
              0
            }
            title={`included km*`}
          />
          {(props.excessKm || price.excessKm || 0) > 0 && (
            <BookingOverviewPriceRow
              amount={
                price.endPrice?.excessKm ||
                price.startPrice?.excessKm ||
                undefined
              }
              title="excess km"
              price={props.excessKmCost || price.costForDistance || 0}
            />
          )}

          {(props.excessCost > 0 || additionalCost > 0) && (
            <>
              <BookingOverviewPriceRow title="Additional rental costs" />
              {props?.excessCost > 0 && (
                <BookingOverviewPriceRow
                  amount={props?.excessKm || price.startPrice?.excessKm || 0}
                  title={`Excess Mileage, CHF ${price.startPrice?.excessKm}/km`}
                  price={props?.excessCost}
                />
              )}
              {additionalCost > 0 && (
                <BookingOverviewPriceRow
                  title="Late fees"
                  price={additionalCost}
                />
              )}
            </>
          )}

          <BookingOverviewPriceRow
            title="Subtotal rental costs"
            price={
              price?.endPrice?.totalCostWithoutInsurance ||
              price?.startPrice?.totalCostWithoutInsurance ||
              0
            }
            bold
          />

          <BookingOverviewPriceRow title="Insurance costs" />
          <BookingOverviewPriceRow
            title="Insurance cost"
            price={price.startPrice?.insuranceCost || 0}
          />
          <BookingOverviewPriceRow
            title="Subtotal insurance costs:"
            price={price.startPrice?.insuranceCost || 0}
            bold
          />
          <BookingOverviewPriceRow
            title="Total cost"
            price={
              price.endPrice?.totalCost > price.startPrice?.totalCost
                ? price.endPrice?.totalCost
                : price.startPrice?.totalCost
            }
            bold
          />
          <BookingOverviewPriceRow title="Payment summary" />

          {props.totalCost !==
            (price?.endPrice?.totalCost || price?.startPrice?.totalCost) && (
            <BookingOverviewPriceRow
              title="Original booking total cost"
              price={props.totalCost || 0}
            />
          )}

          <BookingOverviewPriceRow
            title="Paid at booking start"
            price={price?.startPrice?.totalCost || 0}
          />
          <BookingOverviewPriceRow
            title="Balance at booking end"
            price={
              (price?.endPrice?.totalCost || 0) > props?.totalCost
                ? (price?.endPrice?.totalCost || 0) - (price?.startPrice?.totalCost || 0)
                : 0
            }
          />
          {(price.endPrice?.totalCost || price.startPrice?.totalCost) -
            originalBookingCost >
            0 &&
            [BookingStatus.Running, BookingStatus.Late].includes(
              props.bookingStatus,
            ) && (
              <BookingOverviewPriceRow
                title="Balance to be paid"
                price={
                  (price.endPrice?.totalCost || price.startPrice?.totalCost) -
                  originalBookingCost
                }
              />
            )}
          {(price.endPrice?.totalCost || price.startPrice?.totalCost) -
            originalBookingCost <
            0 &&
            [BookingStatus.Running, BookingStatus.Late].includes(
              props.bookingStatus,
            ) && (
              <BookingOverviewPriceRow
                title="Balance to be refunded"
                price={Math.abs(
                  (price.endPrice?.totalCost || price.startPrice?.totalCost) -
                    originalBookingCost,
                )}
              />
            )}

          {price.startPrice?.refunding?.map((item) => (
            <BookingOverviewPriceRow
              key={item.createdAt}
              title={`Refunded at ${dayjs(item.createdAt).format(
                'DD.MM.YYYY HH:mm',
              )}`}
              price={item.refunding}
            />
          ))}
          <BookingOverviewPriceRow
            key="effectiveBookingCost"
            title="Effective booking cost"
            price={record.effectiveBookingCost}
          />

          <Typography fontSize={12} style={{ marginTop: 12 }}>
            incl. Fr. CHF{' '}
            {Number(
              ((price.endPrice?.totalCostWithoutInsurance ||
                price.startPrice?.totalCostWithoutInsurance ||
                0) /
                Number(100 + props.vat)) *
                props.vat,
            ).toFixed(2)}{' '}
            ({props.vat}% VAT from CHF {Number(finalPrice).toFixed(2)})
          </Typography>
          <Typography fontSize={12} style={{ marginTop: 12 }}>
            incl. Fr.{' '}
            {Number((price.startPrice?.insuranceCost || 0) * 0.05).toFixed(2)}{' '}
            (5% Stempelsteuer from CHF{' '}
            {Number(price.startPrice?.insuranceCost || 0).toFixed(2)})
          </Typography>
          <Typography fontSize={12} style={{ marginTop: 12 }}>
            *excess km will be charged with CHF{' '}
            {record.priceKm || props.priceKm || 0}/km
          </Typography>
        </div>
      )}
    </div>
  );
};

const ModalContent = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 500px;
  background-color: white;
  box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 24px;
`;

const RevokeButton = (props) => {
  const { record } = useShowContext();
  const refreshData = useRefresh();
  const [open, setOpen] = useState(false);
  const [refunding, setRefunding] = useState<number | undefined>(undefined);
  const [withInsurance, setWithInsurance] = useState(false);
  const [revokeReason, setRevokeReason] = useState<string>('');
  const onClose = () => setOpen(false);
  const { data } = useVehicleTypesQueryQuery({ fetchPolicy: 'no-cache' });
  const [revoke] = useRevokeBookingMutation();

  const handleSave = async () => {
    await revoke({
      variables: {
        bookingId: record.id,
        revokeReason,
        refunding,
        withInsurance,
      },
    });
    setRevokeReason('');
    setWithInsurance(false);
    setRefunding(undefined);
    onClose();
    refreshData();
  };

  const refundAmount =
    Math.round(
      withInsurance
        ? props?.startPrice?.totalCost
        : props?.startPrice?.totalCostWithoutInsurance,
    ) *
    (refunding / 100);

  return (
    <div>
      <Button
        disabled={record.revokedAt}
        variant="contained"
        color="error"
        style={{ marginRight: 16 }}
        onClick={() => setOpen(true)}>
        Revoke booking
      </Button>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <ModalContent>
          <IconButton
            style={{ position: 'absolute', right: 18, top: 10 }}
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" style={{ marginBottom: 20 }}>
            Revoke reason
          </Typography>

          <Select
            style={{ width: '100%' }}
            value={revokeReason}
            variant="outlined"
            size="small"
            onChange={(event) => setRevokeReason(event.target.value)}>
            {(data?.revokeReasonTypes || []).map((item) => (
              <MenuItem key={item.id} value={item.name}>
                {item.name}
              </MenuItem>
            ))}
          </Select>

          {record.stripePaymentConfirmedAt && (
            <FormControlLabel
              style={{
                marginTop: 18,
              }}
              control={
                <Checkbox
                  checked={refunding !== undefined}
                  onChange={() =>
                    setRefunding(refunding === undefined ? 0 : undefined)
                  }
                />
              }
              label="Refunding"
            />
          )}

          {refunding !== undefined && (
            <FormGroup
              style={{
                marginTop: 18,
                display: 'flex',
                flexDirection: 'row',
              }}>
              <FormControlLabel
                style={{ flex: 1 }}
                control={
                  <Checkbox
                    checked={withInsurance}
                    onChange={() => setWithInsurance(!withInsurance)}
                  />
                }
                label="With insurance"
              />
              <OutlinedInput
                style={{ flex: 1 }}
                value={refunding}
                onChange={(event) =>
                  Number(event?.target?.value) >= 0 &&
                  Number(event?.target?.value) <= 100 &&
                  setRefunding(Number(event?.target?.value))
                }
                endAdornment={<InputAdornment position="end">%</InputAdornment>}
                size="small"
                type="number"
              />
            </FormGroup>
          )}

          {refunding !== undefined && (
            <List dense={true}>
              <ListItem>
                <ListItemButton>
                  <ListItemText
                    primary={`Total cost: CHF ${props?.startPrice?.totalCost}`}
                  />
                </ListItemButton>
              </ListItem>
              <ListItem>
                <ListItemButton>
                  <ListItemText
                    style={{ fontWeight: 'bold' }}
                    primary={
                      <b>{`Refunded cost: CHF ${Number(
                        withInsurance
                          ? refundAmount > props?.startPrice?.totalCost
                            ? props?.startPrice?.totalCost
                            : refundAmount
                          : refundAmount >
                            props?.startPrice?.totalCostWithoutInsurance
                          ? props?.startPrice?.totalCostWithoutInsurance
                          : refundAmount,
                      ).toFixed(2)}`}</b>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </List>
          )}

          <Button
            variant="contained"
            onClick={handleSave}
            style={{ float: 'right', marginTop: 20 }}>
            Save and revoke
          </Button>
        </ModalContent>
      </Modal>
    </div>
  );
};

const BookingVehicle = (props: any) => {
  return (
    <Grid container spacing={8}>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>Vehicle</Typography>
          <FunctionField
            label="VIN"
            render={(row) => {
              return (
                <Link to={`/Vehicle/${row?.vehicleId}`}>{props?.vin}</Link>
              );
            }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <FunctionField
            label="Licence plate"
            render={(row) => {
              return (
                <Link to={`/Vehicle/${row?.vehicleId}`}>
                  {props?.plateNumber}
                </Link>
              );
            }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <FunctionField
            label="Brand"
            render={() => {
              return `${props?.brandType?.name}`;
            }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <FunctionField
            label="Model"
            render={() => {
              return `${props?.modelType?.name}`;
            }}
          />
        </SimpleShowLayout>
      </Grid>
    </Grid>
  );
};

const BookingDetail = (props: any) => {
  return (
    <Grid container spacing={8}>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>Booking</Typography>
          <TextField source="bookingHash" />
          <DateField
            source="startDate"
            label="Booking start"
            showTime
            locales="de"
          />
          <DateField
            source="effectiveStartDate"
            label="Effective start"
            showTime
            locales="de"
          />
          <DateField
            source="createdAt"
            label="Created at"
            showTime
            locales="de"
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <FunctionField
            label="Pickup location"
            render={() => {
              return `${props?.vehicleLocation?.name}`;
            }}
          />
          <DateField source="endDate" locales="de-DE" showTime />
          <DateField
            source="effectiveEndDate"
            label="Effective end"
            showTime
            locales="de"
          />
          {props.cancelDate && (
            <DateField
              source="cancelDate"
              label="Cancelled at"
              showTime
              locales="de"
            />
          )}
          {props.revokedAt && (
            <DateField
              source="revokedAt"
              label="Revoked at"
              showTime
              locales="de"
            />
          )}
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <TextField label="Booking status" source="bookingStatus" />
          <FunctionField
            source="vehicleMileageOnEffectiveStart"
            label="Driven kilometers"
            render={(row) =>
              `${
                row.vehicleMileageOnEffectiveEnd
                  ? Math.abs(
                      row.vehicleMileageOnEffectiveEnd -
                        row.vehicleMileageOnEffectiveStart,
                    )
                  : 0
              } km`
            }
          />
          <FunctionField
            source="vehicleMileageOnEffectiveStart"
            label="Start/end mileage"
            render={(row) =>
              `${row.vehicleMileageOnEffectiveStart || 0} km / ${
                row.vehicleMileageOnEffectiveEnd || 0
              } km`
            }
          />
          <FunctionField
            render={(record) => `${record.commission}%`}
            source="commission"
            label="Commission to owner"
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <FunctionField
            source="revokeReason"
            label="Revoke comment"
            render={(row) => row.revokeReason || '-'}
          />
          <FunctionField
            source="adminBookingComment"
            label="Admin comment"
            render={(row) => row.adminBookingComment || '-'}
          />
          <FunctionField
            source="adminChangeComment"
            label="Admin date change comment"
            render={(row) => row.adminChangeComment || '-'}
          />
          <BooleanField
            source="skipVehicleStatus"
            label="Skipped vehicle status"
            defaultChecked={false}
          />
        </SimpleShowLayout>
      </Grid>
    </Grid>
  );
};

const BookingUser = (props: any) => {
  return (
    <Grid container spacing={8}>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>User</Typography>
          <ReferenceField label="Email" source="userId" reference="User">
            <TextField source="email" />
          </ReferenceField>
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <FunctionField
            label="First name"
            render={() => {
              return `${props?.firstName}`;
            }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <FunctionField
            label="Last name"
            render={() => {
              return `${props?.lastName}`;
            }}
          />
        </SimpleShowLayout>
      </Grid>
      <Grid item md={3}>
        <SimpleShowLayout>
          <Typography>&nbsp;</Typography>
          <FunctionField
            label="Phone number"
            render={() => {
              return `${props?.phone}`;
            }}
          />
        </SimpleShowLayout>
      </Grid>
    </Grid>
  );
};

const BookingInsurance = (props: any) => {
  const vehicleInsuranceType = props?.vehicleInsuranceType;
  return (
    <div>
      <Grid container spacing={8}>
        <Grid item md={3}>
          <SimpleShowLayout>
            <Typography>Insurance</Typography>
            <FunctionField
              label="Insurance name"
              render={() =>
                vehicleInsuranceType?.name && (
                  <Link
                    to={`/VehicleInsuranceType/${vehicleInsuranceType?.id}`}>
                    {vehicleInsuranceType?.name}
                  </Link>
                )
              }
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item md={3}>
          <SimpleShowLayout>
            <Typography>&nbsp;</Typography>
            <FunctionField
              label="Insurance cost"
              render={() => {
                return `CHF ${Number(
                  (props?.startPrice?.totalCost || 0) -
                    props?.startPrice?.totalCostWithoutInsurance,
                ).toFixed(2)}`;
              }}
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
      <div style={{ width: '100%' }}>
        <SimpleShowLayout>
          <ReferenceArrayField
            label="Additional drivers"
            source="bookingAdditionalDriver_ids"
            reference="BookingAdditionalDriver">
            <Datagrid style={{ width: '100%' }}>
              <TextField source="firstName" />
              <TextField source="lastName" />
              <DownloadDriverLicense label="Front" source="photoFrontName" />
              <DownloadDriverLicense label="Back" source="photoBackName" />
            </Datagrid>
          </ReferenceArrayField>
        </SimpleShowLayout>
      </div>
    </div>
  );
};

const BookingAnswers = (props: any) => {
  return props.data.map((bookingQuestion) => (
    <div key={bookingQuestion.id} style={{ marginBottom: 20 }}>
      <div style={{ minWidth: 100 }}>
        <b>
          {bookingQuestion?.bookingQuestion?.parsedTitle['en'] ||
            Object.keys(bookingQuestion?.bookingQuestion?.parsedTitle)[0]}
          :
        </b>
      </div>
      {bookingQuestion.bookingQuestion.kind === BookingQuestionKind.Boolean && (
        <div>
          {(bookingQuestion?.answer === 'true' && <CheckIcon />) || (
            <CloseIcon />
          )}
        </div>
      )}
      {bookingQuestion.bookingQuestion.kind === BookingQuestionKind.Rating && (
        <Row style={{ width: 200 }}>
          {Array(5)
            .fill('')
            .map((_, key) => {
              if (Number(bookingQuestion.answer) <= Number(key)) {
                return <StarBorderIcon />;
              }
              return <StarIcon />;
            })}
        </Row>
      )}
      {bookingQuestion.bookingQuestion.kind === BookingQuestionKind.String && (
        <div>{bookingQuestion?.answer}</div>
      )}
    </div>
  ));
};

const BookingDamages = (props: any) => {
  const { bookingAnswers, bookingId } = props;
  const preBookingQuestions = [];
  const postBookingQuestions = [];

  (bookingAnswers || []).forEach((bookingAnswer) => {
    if (bookingAnswer.bookingQuestion.type === BookingQuestionType.Pre) {
      preBookingQuestions.push(bookingAnswer);
    }

    if (bookingAnswer.bookingQuestion.type === BookingQuestionType.Post) {
      postBookingQuestions.push(bookingAnswer);
    }
  });

  return (
    <div>
      <Grid container spacing={8}>
        <Grid item md={4}>
          <SimpleShowLayout>
            <Typography>Damages</Typography>
            {props?.bookingDamages?.length === 0 && 'No damages'}
            {props?.bookingDamages?.length > 0 && (
              <FunctionField
                label="Reported damages"
                render={() =>
                  props?.bookingDamages?.length === 0 ? (
                    'No Damages'
                  ) : (
                    <a
                      target="_blank"
                      href={`/#/VehicleDamage/?displayedFilters=%7B"bookingId"%3Atrue%7D&filter=%7B"bookingId"%3A"${bookingId}"%7D&order=DESC&page=1&perPage=10&sort=createdAt`}>
                      Redirect to list
                    </a>
                  )
                }
              />
            )}
          </SimpleShowLayout>
        </Grid>
        <Grid item md={4}>
          <SimpleShowLayout>
            <Typography style={{ marginBottom: 24 }}>
              Pre booking questions
            </Typography>
            <BookingAnswers data={preBookingQuestions} />
          </SimpleShowLayout>
        </Grid>
        <Grid item md={4}>
          <SimpleShowLayout>
            <Typography style={{ marginBottom: 24 }}>
              Post booking questions
            </Typography>
            <BookingAnswers data={postBookingQuestions} />
          </SimpleShowLayout>
        </Grid>
      </Grid>
    </div>
  );
};

const BookingCostSummary = (props: any) => {
  return (
    <div>
      <Grid container spacing={8}>
        <Grid item md={4}>
          <SimpleShowLayout>
            <Typography>Cost summary</Typography>
            <FunctionField
              label="Price"
              render={() => <BookingPriceOverview {...props} />}
            />
            <FunctionField
              source="sharedVehicleGroup"
              label="SVG"
              render={(row) =>
                row.sharedVehicleGroup ? (
                  <Link
                    to={`/SharedVehicleGroup/${row.sharedVehicleGroup?.id}`}>
                    {row.sharedVehicleGroup?.name} (Discount:{' '}
                    {row.sharedVehicleGroup?.discount}%, Owner behavior:{' '}
                    {row.sharedVehicleGroup?.ownerBehavior ? 'Yes' : 'No'},
                    Private: {row.sharedVehicleGroup?.isPrivate ? 'Yes' : 'No'})
                  </Link>
                ) : (
                  '-'
                )
              }
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item md={4}>
          <SimpleShowLayout>
            <Typography>&nbsp;</Typography>
            <FunctionField
              label="Stripe authorizations"
              render={() =>
                props?.stripePaymentCreateAt &&
                dayjs(props?.stripePaymentCreateAt).format('DD.MM. YYYY HH:mm')
              }
            />

            <FunctionField
              label="Changed history"
              render={() =>
                props.bookingChangeHistory?.map((history) => (
                  <div key={history.id}>
                    <Row style={{ marginTop: 4 }}>
                      <Typography fontSize={12} style={{ flex: 4 }}>
                        Original date:{' '}
                        {dayjs(history.originalStartDate).format(
                          'DD.MM.YYYY HH:mm',
                        )}{' '}
                        -{' '}
                        {dayjs(history.originalEndDate).format(
                          'DD.MM.YYYY HH:mm',
                        )}{' '}
                        (CHF {history.originalTotalCost})
                        <br />
                        New:{' '}
                        {dayjs(history.newStartDate).format(
                          'DD.MM.YYYY HH:mm',
                        )}{' '}
                        - {dayjs(history.newEndDate).format('DD.MM.YYYY HH:mm')}{' '}
                        (CHF {history.newTotalCost})
                      </Typography>
                      <Typography
                        fontSize={12}
                        style={{ flex: 2, textAlign: 'right' }}>
                        By {history.createdByUser.email}{' '}
                        {history.createdByUser.userRole ? `(${history.createdByUser.userRole})` : ''}
                        <br />
                        At {dayjs(history.createdAt).format('DD.MM.YYYY HH:mm')}
                      </Typography>
                    </Row>
                    <hr />
                  </div>
                ))
              }
            />
          </SimpleShowLayout>
        </Grid>
        <Grid item md={4}>
          <SimpleShowLayout>
            <Typography>&nbsp;</Typography>
            <ArrayField
              label="Booking fuel password requests"
              source="bookingFuelPassword">
              <Datagrid style={{ width: '100%' }} bulkActionButtons={false}>
                <DateField source="createdAt" label="Requested at" showTime />
              </Datagrid>
            </ArrayField>
          </SimpleShowLayout>
        </Grid>
      </Grid>
    </div>
  );
};

type SendMessageProps = {
  variant: SendMessageEnum;
  title: string;
  bookingId: string;
  icon: React.ReactElement;
};

const SendMessage = (props: SendMessageProps) => {
  const notify = useNotify();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState<string>('');
  const onClose = () => setOpen(false);
  const [sendMessage] = useSendMessageMutation();

  const handleSend = async () => {
    await sendMessage({
      variables: {
        bookingId: props?.bookingId,
        message,
        variant: props.variant,
      },
    });
    setMessage('');
    onClose();
    notify('Message was successfully sent', { type: 'success' });
  };

  return (
    <div>
      <Button
        variant="contained"
        style={{ marginRight: 16 }}
        startIcon={props.icon}
        onClick={() => setOpen(true)}>
        {props.title}
      </Button>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <ModalContent>
          <IconButton
            style={{ position: 'absolute', right: 18, top: 10 }}
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" style={{ marginBottom: 20 }}>
            {props.title}
          </Typography>

          <MuiTextField
            label="Message"
            multiline
            style={{ width: '100%' }}
            maxRows={4}
            value={message}
            onChange={(event) => setMessage(event.target.value)}
            variant="outlined"
            size="small"
          />

          <Button
            variant="contained"
            onClick={handleSend}
            style={{ float: 'right', marginTop: 20 }}>
            Send
          </Button>
        </ModalContent>
      </Modal>
    </div>
  );
};

type SkipVehicleStatusButtonProps = {
  title: string;
  bookingId: string;
  onRefresh: () => void;
};

const SkipVehicleStatusButton = (props: SkipVehicleStatusButtonProps) => {
  const notify = useNotify();
  const record = useRecordContext<Booking>();
  const [updateBooking] = useUpdate();

  const handleSkipVehicleStatus = async () => {
    await updateBooking('Booking', {
      id: record.id as string,
      data: {
        skipVehicleStatus: true,
      },
    });
    notify('Message was successfully updated', { type: 'success' });
    props.onRefresh();
  };

  return (
    <Button
      variant="contained"
      style={{ marginRight: 16 }}
      onClick={handleSkipVehicleStatus}>
      {props.title}
    </Button>
  );
};

export const BookingShow = (props: any) => {
  return (
    <Show title={<BookingTitle />} className="booking-detail" {...props}>
      <BookingShowContent {...props} />
    </Show>
  );
};

const EditBookingDate = (props: { onRefresh: () => void }) => {
  const notify = useNotify();
  const record = useRecordContext<Booking>();

  const [open, setOpen] = useState(false);
  const [error, setError] = useState('');
  const [adminChangeComment, setSdminChangeComment] = useState<string>('');
  const [startDate, setStartDate] = useState<string>(record?.startDate);
  const [endDate, setEndDate] = useState<string>(record?.endDate);
  const onClose = () => {
    setError('');
    setStartDate(record?.startDate);
    setEndDate(record?.endDate);
    setOpen(false);
  };
  const [updateBooking] = useUpdate();
  const [getBookingCheckAvailabilityLazyQuery] =
    useGetBookingCheckAvailabilityLazyQuery();
  const [getCancellationFeesLazyQuery] = useGetCancellationFeesLazyQuery();

  const checkAvailability = async () => {
    try {
      const { data } = await getBookingCheckAvailabilityLazyQuery({
        variables: {
          userId: record.userId,
          bookingId: record.id as string,
          startDateTime: dayjs(startDate).toISOString(),
          endDateTime: dayjs(endDate).toISOString(),
        },
      });

      const { data: cancellationData } = await getCancellationFeesLazyQuery({
        variables: {
          bookingId: record.id as string,
        },
      });

      console.log('cancellationData', cancellationData);

      const originalDuration = dayjs(record.endDate).diff(
        dayjs(record.startDate),
        'minutes',
      );
      const newDuration = dayjs(endDate).diff(dayjs(startDate), 'minutes');

      // shorter booking within cancellation fees should not be possible
      if (
        cancellationData?.getCancellationFees?.cancellationFees > 0 &&
        originalDuration > newDuration
      ) {
        return setError(
          `It is not possible within cancellation fees: CHF ${cancellationData?.getCancellationFees?.cancellationFees}`,
        );
      }

      if (
        !(data?.getBookingCheckAvailability || []).includes(record.vehicleId)
      ) {
        return setError('Not available');
      }

      return true;
    } catch (e) {
      setError(e.message);
      return false;
    }
  };

  useEffect(() => {
    if (startDate && endDate) {
      if (dayjs(endDate).diff(dayjs(startDate), 'hours', true) < 1) {
        setError('Min reservation is 1 hour');
      } else {
        setError('');
      }

      checkAvailability();
    }
  }, [startDate, endDate]);

  const handleSend = async () => {
    setError('');

    const isAvailable = await checkAvailability();

    if (!isAvailable) return false;

    await updateBooking('Booking', {
      id: record.id as string,
      data: {
        startDate,
        endDate,
        userConfirmedAt: null,
        adminChangeComment,
        adminChangeCommentAt: new Date(),
      },
    });
    onClose();
    notify('Message was successfully updated', { type: 'success' });
    props.onRefresh();
  };

  return (
    <LocalizationProvider locale={enLocale} dateAdapter={AdapterDayjs}>
      <Button
        variant="contained"
        disabled={
          ![
            BookingStatus.Pending,
            BookingStatus.Confirmed,
            BookingStatus.Running,
            BookingStatus.Late,
          ].includes(record?.bookingStatus)
        }
        startIcon={<QueryBuilderIcon />}
        style={{ marginRight: 16 }}
        onClick={() => setOpen(true)}>
        Edit date
      </Button>
      <Modal
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <ModalContent>
          <IconButton
            style={{ position: 'absolute', right: 18, top: 10 }}
            onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h5" style={{ marginBottom: 20 }}>
            Edit booking date
          </Typography>
          <DateTimePicker
            renderInput={(props) => (
              <MuiTextField
                {...props}
                variant="outlined"
                size="small"
                style={{ flex: 1, marginRight: 20 }}
              />
            )}
            disabled={
              ![BookingStatus.Pending, BookingStatus.Confirmed].includes(
                record?.bookingStatus,
              )
            }
            inputFormat="DD.MM.YYYY HH:mm"
            ampm={false}
            label="Start date time"
            value={startDate}
            onChange={setStartDate}
          />
          <DateTimePicker
            renderInput={(props) => (
              <MuiTextField
                {...props}
                variant="outlined"
                size="small"
                style={{ flex: 1, marginBottom: 16 }}
              />
            )}
            inputFormat="DD.MM.YYYY HH:mm"
            ampm={false}
            label="End date time"
            value={endDate}
            onChange={setEndDate}
          />

          <MuiTextField
            label="Admin comment"
            multiline
            style={{ width: '100%' }}
            maxRows={4}
            value={adminChangeComment}
            onChange={(event) => setSdminChangeComment(event.target.value)}
            variant="outlined"
            size="small"
          />

          {error && (
            <Alert style={{ marginTop: 12 }} severity="error">
              {error}
            </Alert>
          )}

          <Button
            variant="contained"
            disabled={!adminChangeComment || !!error}
            onClick={handleSend}
            style={{ float: 'right', marginTop: 20 }}>
            Update
          </Button>
        </ModalContent>
      </Modal>
    </LocalizationProvider>
  );
};

export const BookingShowContent = () => {
  const record = useRecordContext<Booking>();
  const refresh = useRefresh();

  const { data, refetch: refetchBookingDetail } = useGetBookingDetailQuery({
    variables: {
      bookingId: record?.id as string,
    },
    skip: !record?.id,
  });
  const {
    data: bookingPriceData,
    loading,
    refetch: refetchBookingCalculation,
  } = useGetBookingCalculationQuery({
    variables: {
      bookingId: record?.id as string,
    },
    fetchPolicy: 'no-cache',
    skip: !record?.id,
  });

  const handleRefresh = async () => {
    refresh();
    await refetchBookingDetail();
    await refetchBookingCalculation();
  };

  const bookingDetail = data?.getBookingDetail;
  const bookingDamages = data?.getBookingDetail?.vehicleDamage || [];
  const vehicleDetail = bookingDetail?.vehicle;
  const bookingPrice = bookingPriceData?.getBookingCalculation;

  return (
    <div>
      <BookingDetail {...bookingDetail} {...record} />

      <hr />

      <BookingVehicle {...vehicleDetail} />

      <hr />

      <BookingUser {...bookingDetail?.user} />

      <hr />

      <BookingInsurance {...bookingDetail} {...bookingPrice} />

      <hr />

      <BookingDamages
        bookingAnswers={bookingDetail?.bookingAnswer}
        bookingHash={bookingDetail?.bookingHash}
        bookingId={bookingDetail?.id}
        bookingDamages={bookingDamages}
      />

      <hr />

      <BookingCostSummary
        {...bookingDetail}
        loading={loading}
        bookingPrice={bookingPrice}
      />

      <hr />

      <SimpleShowLayout>
        <Row style={{ justifyContent: 'center' }}>
          <Button
            onClick={() => window.history.back()}
            style={{ marginRight: 16 }}>
            Back
          </Button>
          <Button
            variant="outlined"
            onClick={() => window.open('/#/VehicleDamage/create', '_blank')}
            style={{ marginRight: 16 }}>
            Report damage
          </Button>
          {!bookingDetail?.effectiveEndDate &&
            !record?.cancelDate &&
            !record?.revokedAt && <RevokeButton {...bookingPrice} />}
          <SendMessage
            title="Send push notification"
            icon={<NotificationsNoneIcon />}
            bookingId={bookingDetail?.id}
            variant={SendMessageEnum.Notification}
          />
          <SendMessage
            title="Send sms"
            icon={<SmsIcon />}
            bookingId={bookingDetail?.id}
            variant={SendMessageEnum.Sms}
          />
          <SkipVehicleStatusButton
            title="Skip vehicle status"
            onRefresh={handleRefresh}
            bookingId={bookingDetail?.id}
          />
           <Button
            variant="outlined"
            onClick={() => window.open(`${url}/booking/report/${bookingDetail?.id}`, '_blank')}
            style={{ marginRight: 16 }}>
            Download PDF
          </Button>
          <EditBookingDate onRefresh={handleRefresh} />
        </Row>
      </SimpleShowLayout>
    </div>
  );
};
