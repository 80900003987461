import {
  Button,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import {
  ChargingStationRuleProperty,
  ChargingStationTariff,
} from '../../../@generated/schemas';
import { VFC, useState } from 'react';
import { omit, sortBy } from 'lodash';
import {
  useDelete,
  useGetList,
  useRecordContext,
  useRefresh,
} from 'react-admin';

import DeleteIcon from '@mui/icons-material/Delete';
import DuplicateIcon from '@mui/icons-material/CopyAll';
import EditIcon from '@mui/icons-material/Edit';
import TariffDialog from './tariffDialog';
import { getValueByForProperty } from './utils';

const TariffTable: VFC = () => {
  const record = useRecordContext();
  const refresh = useRefresh();
  const [showDialog, setShowDialog] = useState<
    boolean | (Omit<ChargingStationTariff, 'id'> & { id?: string })
  >(false);

  const [deleteOne] = useDelete();

  const { data, refetch } = useGetList<ChargingStationTariff>(
    'ChargingStationTariff',
    {
      pagination: { page: 1, perPage: 1000 },
      filter: {
        deletedAt: { equals: null },
        chargingStationId: record.id,
      },
    },
  );

  const handleDeleteTariff = async (tariffId: string) => {
    await deleteOne('ChargingStationTariff', { id: tariffId });
    handleReload();
  };

  const handleReload = () => {
    refetch();
    refresh();
  };

  return (
    <>
      <Button
        style={{ marginLeft: 'auto', marginBottom: 12 }}
        onClick={() => setShowDialog(true)}
        variant="contained">
        Create new tariff rule
      </Button>

      <TableContainer style={{ width: '100%' }}>
        <Table
          stickyHeader
          sx={{ minWidth: 650 }}
          style={{ maxHeight: '5wh' }}
          aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Tariff type</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Time</TableCell>
              <TableCell>Assignments</TableCell>
              <TableCell>Price for kWh (CHF)</TableCell>
              <TableCell>Guarantee amount</TableCell>
              <TableCell>Amount per minute</TableCell>
              <TableCell align="right" width="200">
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortBy(data, ['updatedAt'])?.map((tariff) => (
              <TableRow key={tariff.id}>
                <TableCell component="th" scope="row">
                  {tariff.tariffType}
                </TableCell>
                <TableCell component="th" scope="row">
                  {JSON.parse(tariff.name || '{}')?.en ||
                    JSON.parse(tariff.name || '{}')?.de ||
                    JSON.parse(tariff.name || '{}')?.it ||
                    JSON.parse(tariff.name || '{}')?.fr}
                </TableCell>
                <TableCell component="th" scope="row">
                  {getValueByForProperty(
                    tariff,
                    ChargingStationRuleProperty.Time,
                  )}
                </TableCell>
                <TableCell component="th" scope="row">
                  Users:{' '}
                  {tariff.users.map((user) => (
                    <Chip key={user.id} label={user.email} />
                  ))}
                  <br />
                  RFID:{' '}
                  {tariff.rfids.map((rfid) => (
                    <Chip key={rfid.id} label={rfid.tag} />
                  ))}
                </TableCell>
                <TableCell component="th" scope="row">
                  {tariff.price}
                </TableCell>
                <TableCell component="th" scope="row">
                  {tariff.guaranteeAmount}
                </TableCell>
                <TableCell component="th" scope="row">
                  {tariff.amountPerMinute}
                </TableCell>
                <TableCell component="th" align="right" scope="row">
                  <IconButton
                    onClick={() => setShowDialog(omit(tariff, ['id']))}>
                    <DuplicateIcon />
                  </IconButton>
                  <IconButton onClick={() => setShowDialog(tariff)}>
                    <EditIcon />
                  </IconButton>
                  <IconButton onClick={() => handleDeleteTariff(tariff.id)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <TariffDialog
        showDialog={showDialog}
        commission={
          record?.chargingStationCluster?.company?.commissionChargingStations
        }
        setShowDialog={setShowDialog}
        refetch={handleReload}
      />
    </>
  );
};

export default TariffTable;
