import { initReactI18next, useTranslation } from 'react-i18next';

import dayjs from 'dayjs';
import de from './de.json';
// import deLocize from '../i18n/de/index.json';
import en from './en.json';
// import enLocize from '../i18n/en/index.json';
import englishMessages from 'ra-language-english';
import germanMessages from 'ra-language-german';
import i18next from 'i18next';
import locizeBackend from 'i18next-locize-backend';
import { merge } from 'lodash';
import { resolveBrowserLocale } from 'react-admin';

export enum LANGUAGE {
  DE = 'de',
  EN = 'en',
}

export const FALLBACK_LANGUAGE = LANGUAGE.EN;

export const defaultNS = 'index';

export const languageCode = (
  localStorage.getItem('locale') ||
  resolveBrowserLocale() ||
  'en'
).toLowerCase();

dayjs.locale(languageCode);

i18next
  .use(locizeBackend)
  .use(initReactI18next)
  .init({
    fallbackLng: FALLBACK_LANGUAGE,
    debug: false,
    backend: {
      projectId: '71033d2c-eb74-40a9-97fc-9b30da19b414',
      apiKey: '751e567d-7e54-407f-91a5-89d3dd1efdcf',
      referenceLng: languageCode,
      reloadInterval: false,
      version: 'latest',
    } as any,
    ns: 'index',
    resources:  {},
    partialBundledLanguages: true,
    lng: languageCode,
    pluralSeparator: '_',
    interpolation: {
      escapeValue: false, // React already escapes values
      prefix: '%{',
      suffix: '}',
    },
    react: {
      useSuspense: false,
    },
  });

i18next.addResourceBundle('en', 'index', merge(englishMessages, en));
i18next.addResourceBundle('de', 'index', merge(germanMessages, de));

const getMergedMessages = async (
  locale: string,
): Promise<Record<string, any>> => {
  const localMessages: Record<string, any> =
    {
      en: merge(englishMessages, en),
      de: merge(germanMessages, de),
    }[locale] || englishMessages;

  try {
    const remoteMessages = await i18next.services.resourceStore.data[locale];
    return { ...localMessages, ...remoteMessages };
  } catch (error) {
    console.error('Failed to load Locize translations', error);
    return localMessages;
  }
};

const customI18nProvider = {
  translate: (key: string, options?: any): string => {
    if (key.includes('resources.') && key.includes('.name')) {
      return i18next.t(`admin.${key.toLowerCase()}`, options);
    }

    if (key.includes('ra.')) {
      return i18next.t(key, options);
    }

    if (key.includes('admin.')) {
      return i18next.t(key, options);
    }

    if (key.includes('admin.')) {
      return i18next.t(key, options);
    }

    if (!key) {
      return '';
    }

    return i18next.t(key, options);
  },
  changeLocale: async (locale: string, options?: any): Promise<any> => {
    localStorage.setItem('locale', locale);
    // await i18next.changeLanguage(locale);
    // dayjs.locale(locale);
    window.location.reload();

    return getMergedMessages(locale);
  },
  getLocale: () => i18next.language || 'en',
};

export const useTranslate = () => {
  const { t } = useTranslation();
  return (key: string, options?: any) => {
    return key ? t(key, options) : '';
  };
};

export default customI18nProvider;

/*
if (key !== i18next.t(key)) {
      return i18next.t(key);
    } else {
      if (i18next.t(`admin.${key}`) !== `admin.${key}`) {
        return i18next.t(`admin.${key}`);
      }
    }

    return `{{${key}}}`;*/