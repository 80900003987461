import {
  Alert,
  Button,
  Card,
  CardContent,
  FormControl,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { Title, useGetIdentity, useUpdate } from 'react-admin';

import IconButton from '@mui/material/IconButton';
import SaveIcon from '@mui/icons-material/Save';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useState } from 'react';
import { useTranslate } from '../../locales';

const ChangeMyPassword = () => {
  const { identity } = useGetIdentity();
  const [update, { isLoading }] = useUpdate();
  const translate = useTranslate();
  const [values, setValues] = useState({
    newPassword: '',
    showNewPassword: false,
    confirmPassword: '',
    showConfirmPassword: false,
    showErrorPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({
      ...values,
      [prop]: event.target.value,
      showErrorPassword: false,
    });
  };

  const handleShowPassword = (type, value) => {
    setValues({
      ...values,
      [type]: value,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = async () => {
    if (isLoading) return false;

    if (!values.newPassword || !values.confirmPassword) {
      return handleShowPassword(
        'showErrorPassword',
        translate('admin.passwordValueIsMissing'),
      );
    }

    if (values.newPassword !== values.confirmPassword) {
      return handleShowPassword(
        'showErrorPassword',
        translate('admin.passwordMatch')
      );
    }

    const data: any = {
      password: values.newPassword,
      presetPassword: false,
    };

    await update(
      'User',
      { id: identity?.id, data },
      {
        returnPromise: true,
      },
    );

    localStorage.setItem(
      'user',
      JSON.stringify({ ...identity, presetPassword: false }),
    );

    window.location.href = '/';
  };

  return (
    <Card>
      <Title title={translate('admin.changeMyPassword')} />
      <CardContent>
        <form onSubmit={onSubmit}>
          {values.showErrorPassword && (
            <Alert severity="error" style={{ marginBottom: 26 }}>
              {values.showErrorPassword}
            </Alert>
          )}

          <FormControl size="small">
            <InputLabel>{translate('admin.newPassword')}</InputLabel>
            <OutlinedInput
              style={{ height: 40 }}
              type={values.showNewPassword ? 'text' : 'password'}
              size="small"
              label={translate('admin.newPassword')}
              value={values.newPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      handleShowPassword(
                        'showNewPassword',
                        !values.showNewPassword,
                      )
                    }
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {values.showNewPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              onChange={handleChange('newPassword')}
            />
          </FormControl>

          <br />

          <FormControl size="small" style={{ marginTop: 20 }}>
            <InputLabel>{translate('admin.confirmNewPassword')}</InputLabel>
            <OutlinedInput
              type={values.showConfirmPassword ? 'text' : 'password'}
              size="small"
              style={{ height: 40 }}
              label={translate('admin.confirmNewPassword')}
              value={values.confirmPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    onClick={() =>
                      handleShowPassword(
                        'showConfirmPassword',
                        !values.showConfirmPassword,
                      )
                    }
                    onMouseDown={handleMouseDownPassword}
                    edge="end">
                    {values.showConfirmPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              onChange={handleChange('confirmPassword')}
            />
          </FormControl>

          <br />

          <Button
            variant="contained"
            style={{ marginTop: 20 }}
            startIcon={<SaveIcon />}
            disabled={isLoading}
            onClick={onSubmit}>
            {translate('admin.save')}
          </Button>
        </form>
      </CardContent>
    </Card>
  );
};

export default ChangeMyPassword;
