import { Button, TextField, ThemeProvider, createTheme } from '@mui/material';
import { Notification, defaultTheme, useNotify } from 'react-admin';

import PropTypes from 'prop-types';
import { useAuth } from '../hooks/useAuth';
import { useState } from 'react';
import { useTranslate } from '../locales';

const LoginForm = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { handleLogin } = useAuth();
  const translate = useTranslate();

  const notify = useNotify();

  const submit = async (e) => {
    e.preventDefault();
    try {
      const login = await handleLogin(email, password);
      if (login?.presetPassword) {
        window.location.href = '/#/change-my-password';
      } else {
        window.location.href = '/';
      }
    } catch (error) {
      notify(translate('admin.invalidEmailOrPassword'), { type: 'error' });
    }
  };

  return (
    <ThemeProvider theme={createTheme(defaultTheme)}>
      <form onSubmit={submit} style={{ padding: '0 20px 20px 20px' }}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 6,
            marginBottom: 20,
          }}>
          <img src="/dmcLogo.svg" alt="logo" />
        </div>

        <div>
          <TextField
            name="email"
            type="email"
            style={{ width: '100%' }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div>
          <TextField
            name="password"
            type="password"
            style={{ width: '100%' }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: 20,
          }}>
          <Button color="primary" type="submit" onClick={submit}>
            {translate('admin.login')}
          </Button>
        </div>
      </form>
      <Notification />
    </ThemeProvider>
  );
};

LoginForm.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

const LoginWithTheme = (props: any) => <LoginForm {...props} />;

export default LoginWithTheme;
