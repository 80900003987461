import { AvailabilityType, Booking } from '../../../@generated/schemas';
import FullCalendar, {
  DatesSetArg,
  EventClickArg,
  EventInput,
} from '@fullcalendar/react';
import { useEffect, useState } from 'react';
import { useRecordContext, useRefresh } from 'react-admin';

import { AvailabilityDetailModal } from './availabilityDetailModal';
import { BookingDetailModal } from './bookingDetailModal';
import dayGridPlugin from '@fullcalendar/daygrid';
import dayjs from 'dayjs';
import i18next from 'i18next';
import interactionPlugin from '@fullcalendar/interaction';
// import { makeStyles } from '@mui/material';
import timeGridPlugin from '@fullcalendar/timegrid';
import { useTranslate } from '../../../locales';
import { useVehicleAvailabilityLazyQuery } from '../../../@generated/hooks';

const defaultNewEventValues = {
  availabilityId: undefined,
  comment: '',
  sharedVehicleGroups: [],
  startDateTime: null,
  endDateTime: null,
  expiredAt: null,
  isAvailable: true,
  type: AvailabilityType.Single,
};

export const VehicleAvailability = () => {
  const refresh = useRefresh();
  const record = useRecordContext();
  const [showEventForm, setShowEventForm] = useState<boolean>(false);
  const [filter, setFilter] = useState({
    startDate: dayjs().startOf('month').format(),
    endDate: dayjs().startOf('month').add(47, 'day').format(),
  });
  const translate = useTranslate();
  const [newEvent, setNewEvent] = useState({
    ...defaultNewEventValues,
    vehicleId: record?.id,
  });
  const [bookingDetail, setBookingDetail] = useState<Booking>(undefined);
  const [fetchAvailability, { data }] = useVehicleAvailabilityLazyQuery({
    fetchPolicy: 'no-cache',
  });

  const refetch = (filter) =>
    record?.id &&
    fetchAvailability({
      variables: {
        vehicleId: record.id as string,
        startDate: new Date(filter.startDate),
        endDate: new Date(filter.endDate),
      },
    });

  useEffect(() => {
    refetch(filter);
  }, []);

  const handleDatesSet = (dateInfo: DatesSetArg): void => {
    const newFilter = {
      startDate: dateInfo.startStr,
      endDate: dateInfo.endStr,
    };
    setFilter(newFilter);
    refetch(newFilter);
  };

  const handleDateSelect = (event: any): void => {
    if (dayjs(event.start).isBefore(dayjs().subtract(1, 'days'))) return;

    setNewEvent({
      ...newEvent,
      startDateTime: event.start,
      endDateTime: event.end,
    });
    const calendarApi = event.view.calendar;
    calendarApi.unselect();

    setShowEventForm(true);
  };

  const handleEventClick = (event: EventClickArg): void => {
    const { bookingHash } = event.event._def.extendedProps;

    if (bookingHash) {
      setBookingDetail({
        ...(event.event._def.extendedProps as any),
        id: event.event.id,
      });
    } else {
      setNewEvent({
        ...newEvent,
        ...event.event._def.extendedProps,
      });
      setShowEventForm(true);
    }

    const calendarApi = event.view.calendar;
    calendarApi.unselect();
  };

  const handleCloseModal = () => {
    setBookingDetail(undefined);
    setShowEventForm(false);
    setNewEvent({
      ...defaultNewEventValues,
      vehicleId: record?.id,
    });
    refresh();
  };

  const handleChangeNewEventProperty = (property: string, value: any) => {
    setNewEvent({
      ...newEvent,
      [property]: value,
    });
  };

  const getFormattedEvents = (): EventInput[] => {
    const availabilityEvents = (
      data?.vehicleAvailability?.availability || []
    ).map((event, key): EventInput => {
      return {
        id: key.toString(),
        ...event,
        sharedVehicleGroups: event.sharedVehicleGroupRelAvailability.map(
          (item) => item.sharedVehicleGroup?.id,
        ),
        start: dayjs(event.startDateTime).tz('Europe/Zurich').toISOString(),
        end: dayjs(event.endDateTime).tz('Europe/Zurich').toISOString(),
        backgroundColor: event.isAvailable ? 'blue' : 'red',
        color: event.isAvailable ? 'blue' : 'red',
        title: event.comment,
        editable: true,
      };
    });
    const bookingEvents = (data?.vehicleAvailability?.bookings || []).map(
      (booking): EventInput => ({
        id: booking.id,
        ...booking,
        start: dayjs(booking.effectiveStartDate || booking.startDate)
          .tz('Europe/Zurich')
          .toISOString(),
        end: dayjs(booking.effectiveEndDate || booking.endDate)
          .tz('Europe/Zurich')
          .toISOString(),
        backgroundColor: 'black',
        color: 'black',
        title: `Booking: ${booking.bookingHash}`,
        editable: true,
      }),
    );

    return [...availabilityEvents, ...bookingEvents];
  };

  const createNewEvent = () => {
    setNewEvent({
      ...newEvent,
      ...defaultNewEventValues,
    });

    setShowEventForm(true);
  };

  return (
    <div>
      <div>
        <FullCalendar
          height="60vh"
          titleFormat={{
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }}
          views={{
            dayGridMonth: {
              dayHeaderFormat: {
                weekday: 'long',
              },
            },
            timeGridWeek: {
              dayHeaderFormat: {
                day: 'numeric',
                month: 'long',
              },
            },
            timeGridDay: {
              dayHeaderFormat: {
                weekday: 'long',
                day: 'numeric',
              },
            },
          }}
          eventTimeFormat={{
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            meridiem: false,
            hour12: false,
          }}
          slotLabelFormat={{
            hour: '2-digit',
            minute: '2-digit',
            meridiem: false,
            hour12: false,
          }}
          firstDay={1}
          customButtons={{
            addEventButton: {
              text: translate('admin.addEvent'),
              click: () => createNewEvent(),
            },
          }}
          eventAllow={() => false}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          headerToolbar={{
            left: 'prev,next today',
            center: 'title',
            right: 'addEventButton dayGridMonth,timeGridWeek,timeGridDay',
          }}
          timeZone="local"
          locale={i18next.language}
          buttonText={{
            today: translate('admin.today'),
            month: translate('admin.month'),
            week: translate('admin.week'),
            day: translate('admin.day'),
          }}
          initialView="dayGridMonth"
          selectable
          nowIndicator
          dayMaxEvents
          navLinks
          datesSet={handleDatesSet}
          select={handleDateSelect}
          events={getFormattedEvents()}
          eventClick={handleEventClick}
        />
      </div>

      <AvailabilityDetailModal
        newEvent={newEvent}
        open={showEventForm}
        onClose={handleCloseModal}
        refetch={refetch}
        filter={filter}
        onChange={handleChangeNewEventProperty}
      />

      <BookingDetailModal
        bookingDetail={bookingDetail}
        onClose={handleCloseModal}
      />
    </div>
  );
};
