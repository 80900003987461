import { Button, Grid } from '@mui/material';
import { FunctionField, SimpleShowLayout, useRecordContext } from 'react-admin';
import { GoogleMap, Marker } from '@react-google-maps/api';
import { Vehicle, VehicleConnectionType } from '../../../@generated/schemas';
import {
  useChangeDeviceStatusMutation,
  useGetVehicleStatusQuery,
} from '../../../@generated/hooks';

import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { useState } from 'react';
import { useTranslate } from '../../../locales';

const VehicleTroubleshoot = () => {
  const record = useRecordContext<Vehicle>();
  const translate = useTranslate();

  const [, setMapRef] = useState<google.maps.Map>();
  const { data, refetch } = useGetVehicleStatusQuery({
    variables: {
      vehicleId: record?.id as string,
    },
    skip:
      !record?.id ||
      (!record?.qnr && !record?.smartcarVehicle && !record.convadisVehicle),
  });

  const [changeStatus] = useChangeDeviceStatusMutation();

  const handleChangeStatus = async (property: string, value: string) => {
    await changeStatus({
      variables: {
        vehicleId: record?.id as string,
        property,
        value,
      },
    });

    refetch();
  };

  return (
    <div>
      <Grid container spacing={8} style={{ marginBottom: 30 }}>
        <Grid item md={6}>
          <SimpleShowLayout>
            {data?.getVehicleStatus?.fuel_level !== null ? (
              <FunctionField
                label={translate('admin.fuelLevel')}
                render={() => {
                  return `${data?.getVehicleStatus?.fuel_level || ''}%`;
                }}
              />
            ) : null}
            {data?.getVehicleStatus?.ignition !== null ? (
              <FunctionField
                label={translate('admin.ignition')}
                render={() => {
                  return data?.getVehicleStatus?.ignition;
                }}
              />
            ) : null}
            <FunctionField
              label={translate('admin.immobilizer')}
              render={() => {
                return data?.getVehicleStatus?.immobilizer;
              }}
            />
            <FunctionField
              label={translate('admin.centralLock')}
              render={() => {
                return data?.getVehicleStatus?.central_lock;
              }}
            />
            {data?.getVehicleStatus?.keyfob !== null ? (
              <FunctionField
                label={translate('admin.keyfob')}
                render={() => {
                  return data?.getVehicleStatus?.keyfob;
                }}
              />
            ) : null}
            <FunctionField
              label={translate('admin.mileage')}
              render={() => {
                return data?.getVehicleStatus?.mileage;
              }}
            />
            <FunctionField
              label={translate('admin.charging')}
              render={() => {
                return data?.getVehicleStatus?.charging;
              }}
            />
            <FunctionField
              label={translate('admin.battery')}
              render={() => {
                return data?.getVehicleStatus?.battery;
              }}
            />
            {data?.getVehicleStatus?.rfid_tag_state_1 !== null ? (
              <FunctionField
                label={translate('admin.rfidTag1')}
                render={() => {
                  return data?.getVehicleStatus?.rfid_tag_state_1;
                }}
              />
            ) : null}

            {data?.getVehicleStatus?.rfid_tag_state_2 !== null ? (
              <FunctionField
                label={translate('admin.rfidTag2')}
                render={() => {
                  return data?.getVehicleStatus?.rfid_tag_state_2;
                }}
              />
            ) : null}
          </SimpleShowLayout>
        </Grid>
        <Grid item md={6}>
          <GoogleMap
            onLoad={setMapRef}
            mapContainerStyle={{
              width: '100%',
              height: 400,
            }}
            center={{
              lat: data?.getVehicleStatus?.latitude || 0,
              lng: data?.getVehicleStatus?.longitude || 0,
            }}
            zoom={8}>
            <Marker
              position={{
                lat: data?.getVehicleStatus?.latitude || 0,
                lng: data?.getVehicleStatus?.longitude || 0,
              }}
            />
          </GoogleMap>
        </Grid>
      </Grid>
      {record?.connectionType === VehicleConnectionType.Cloudboxx && (
        <>
          <Button
            style={{ marginRight: 15 }}
            disabled={!record?.qnr}
            startIcon={<LockOpenIcon />}
            onClick={() => handleChangeStatus('immobilizer', 'unlocked')}>
            {translate('admin.unlockImmobilizer')}
          </Button>
          <Button
            style={{ marginRight: 15 }}
            variant="contained"
            disabled={!record?.qnr}
            startIcon={<LockIcon />}
            onClick={() => handleChangeStatus('immobilizer', 'locked')}>
            {translate('admin.lockImmobilizer')}
          </Button>
          <Button
            style={{ marginRight: 15 }}
            disabled={!record?.qnr}
            startIcon={<LockOpenIcon />}
            onClick={() => handleChangeStatus('central_lock', 'unlocked')}>
            {translate('admin.unlockCentralLock')}
          </Button>
          <Button
            variant="contained"
            disabled={!record?.qnr}
            startIcon={<LockIcon />}
            onClick={() => handleChangeStatus('central_lock', 'locked')}>
            {translate('admin.lockCentralLock')}
          </Button>
        </>
      )}
      {[VehicleConnectionType.Smartcar, VehicleConnectionType.Convadis].includes(record?.connectionType) && (
        <>
          <Button
            style={{ marginRight: 15 }}
            disabled={!record?.smartcarVehicle && !record.convadisVehicle}
            startIcon={<LockOpenIcon />}
            onClick={() => handleChangeStatus('central_lock', 'unlocked')}>
            {translate('admin.unlock')}
          </Button>
          <Button
            style={{ marginRight: 15 }}
            variant="contained"
            disabled={!record?.smartcarVehicle && !record.convadisVehicle}
            startIcon={<LockIcon />}
            onClick={() => handleChangeStatus('central_lock', 'locked')}>
            {translate('admin.lock')}
          </Button>
        </>
      )}
    </div>
  );
};

export default VehicleTroubleshoot;
