import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';

import { useState } from 'react';
import { useTranslate } from '../locales';

function ConfirmationDialog(props) {
  const translate = useTranslate();
  const [open, setOpen] = useState(false);

  const showDialog = (event: any) => {
    event?.preventDefault();
    event?.stopPropagation();

    setOpen(true);
  };

  const hideDialog = (event: any) => {
    event?.preventDefault();
    event?.stopPropagation();

    setOpen(false);
  };

  const confirmRequest = (event: any) => {
    event?.preventDefault();
    event?.stopPropagation();

    props.response();
    hideDialog(event);
  };

  return (
    <>
      {props.children(showDialog)}
      {open && (
        <Dialog
          open={open}
          onClose={hideDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {props.description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={confirmRequest} color="primary">
              {translate('admin.yes')}
            </Button>
            <Button onClick={hideDialog} color="primary">
              {translate('admin.no')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
}

export default ConfirmationDialog;
