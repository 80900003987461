import {
  BooleanField,
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  FunctionField,
  List,
  TextField,
  useGetIdentity,
} from 'react-admin';

import { Chip } from '@mui/material';
import CustomPagination from '../../CustomPagination';
import { ResourceView } from '@ra-data-prisma/dataprovider';
import { UserRole } from '../../@generated/schemas';
import { gql } from '@apollo/client';

export const SharedVehicleGroupList = (props: any) => {
  const { identity } = useGetIdentity();

  const defaultFilter = {
    deletedAt: {
      equals: null,
    },
    OR: [
      {
        companies:
          identity?.userRole && identity?.userRole !== UserRole.SuperAdmin
            ? {
              some: {
                  companyId: {
                    equals: identity.companyId,
                  },
                },
              }
            : {},
      },
      {
        vehicles:
          identity?.userRole && identity?.userRole !== UserRole.SuperAdmin
            ? {
                some: {
                  vehicle: {
                    is: {
                      user: {
                        is: {
                          companyId: {
                            equals: identity.companyId,
                          },
                        },
                      },
                    },
                  },
                },
              }
            : {},
      },
      {
        users:
          identity?.userRole && identity?.userRole !== UserRole.SuperAdmin
            ? {
              some: {
                  user: {
                    is: {
                      companyId: {
                        equals: identity.companyId,
                      },
                    },
                  },
                },
              }
            : {},
      },
    ],
  };

  return (
    <List {...props} filter={defaultFilter} pagination={<CustomPagination />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" />
        <FunctionField
          source="vehicles"
          label="Vehicles"
          render={(record) => <Chip label={record.vehicles.length} />}
        />
        <FunctionField
          source="users"
          label="Users"
          render={(record) => <Chip label={record.users.length} />}
        />
        <FunctionField
          source="companies"
          label="Companies"
          render={(record) => <Chip label={record.companies.length} />}
        />
        <BooleanField source="ownerBehavior" />
        <BooleanField source="isPrivate" />
        <BooleanField source="discountIncludesInsurance" />
        <EditButton />
        <DeleteWithConfirmButton mutationMode="pessimistic" />
      </Datagrid>
    </List>
  );
};

export const SharedVehicleGroup: ResourceView = {
  resource: 'SharedVehicleGroup',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment SharedVehicleGroupFragment on SharedVehicleGroup {
          vehicles {
            id
            vehicle {
              id
              vin
              plateNumber
              brandType {
                name
              }
              modelType {
                name
              }
            }
          }
          companies {
            id
            company {
              id
              name
            }
          }
          users {
            id
            user {
              id
              email
              firstName
              lastName
            }
          }
        }
      `,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment SharedVehicleGroupFragment on SharedVehicleGroup {
          vehicles {
            id
            vehicle {
              id
              vin
              plateNumber
              brandType {
                name
              }
              modelType {
                name
              }
            }
          }
          companies {
            id
            company {
              id
              name
            }
          }
          users {
            id
            user {
              id
              email
              firstName
              lastName
            }
          }
        }
      `,
    },
  },
};

export const SharedVehicleGroupRelVehicle: ResourceView = {
  resource: 'SharedVehicleGroupRelVehicle',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment Vehicle on Vehicle {
          vehicle {
            id
            vin
            plateNumber
            brandType {
              name
            }
            modelType {
              name
            }
          }
        }
      `,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment Vehicle on Vehicle {
          vehicle {
            id
            vin
            plateNumber
            brandType {
              name
            }
            modelType {
              name
            }
          }
        }
      `,
    },
  },
};
