import * as Types from './operations';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {};

export const GetBookingCalculationDocument = gql`
  query GetBookingCalculation($bookingId: String!) {
    getBookingCalculation(bookingId: $bookingId) {
      startPrice {
        totalCost
        costForDuration
        costForDistance
        includedDistance_km
        insuranceCost
        deductibleReduction
        totalCostWithoutInsurance
        totalHourlyCost
        totalDailyCost
        totalWeeklyCost
        lateFees
        totalHours
        totalDays
        totalWeeks
        excessKm
        last4Digits
        cardBrand
        refunding {
          refunding
          createdAt
        }
      }
      endPrice {
        totalCost
        costForDuration
        costForDistance
        includedDistance_km
        insuranceCost
        deductibleReduction
        totalCostWithoutInsurance
        totalHourlyCost
        totalDailyCost
        totalWeeklyCost
        lateFees
        totalHours
        totalDays
        totalWeeks
        excessKm
        last4Digits
        cardBrand
      }
    }
  }
`;

/**
 * __useGetBookingCalculationQuery__
 *
 * To run a query within a React component, call `useGetBookingCalculationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingCalculationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingCalculationQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetBookingCalculationQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetBookingCalculationQuery,
    Types.GetBookingCalculationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetBookingCalculationQuery,
    Types.GetBookingCalculationQueryVariables
  >(GetBookingCalculationDocument, options);
}
export function useGetBookingCalculationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetBookingCalculationQuery,
    Types.GetBookingCalculationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetBookingCalculationQuery,
    Types.GetBookingCalculationQueryVariables
  >(GetBookingCalculationDocument, options);
}
export type GetBookingCalculationQueryHookResult = ReturnType<
  typeof useGetBookingCalculationQuery
>;
export type GetBookingCalculationLazyQueryHookResult = ReturnType<
  typeof useGetBookingCalculationLazyQuery
>;
export type GetBookingCalculationQueryResult = Apollo.QueryResult<
  Types.GetBookingCalculationQuery,
  Types.GetBookingCalculationQueryVariables
>;
export const GetBookingDetailDocument = gql`
  query GetBookingDetail($bookingId: String!) {
    getBookingDetail(bookingId: $bookingId) {
      id
      vat
      bookingHash
      adminChangeComment
      adminChangeCommentAt
      bookingStatus
      revokeReason
      startDate
      endDate
      totalCost
      effectiveStartDate
      effectiveEndDate
      excessKm
      excessKmCost
      pickupLocationNotes
      returnLocationNotes
      lateAttemptMessage
      lateAttemptMessageAt
      stripeIntentId
      stripePaymentCreateAt
      stripePaymentAuthorisationAttempts
      stripeAdditionalPaymentIntentId
      stripeAdditionalPaymentConfirmed
      stripeAdditionalPaymentAuthorizedAt
      vehicleInsuranceType {
        id
        name
      }
      bookingAdditionalDriver {
        photoFrontName
        photoBackName
      }
      vehicleDamage {
        id
      }
      vehicleLocation {
        latitude
        longitude
        name
        isDefault
      }
      vehicle {
        vin
        plateNumber
        brandType {
          name
        }
        modelType {
          name
        }
      }
      user {
        email
        firstName
        lastName
        phone
      }
      bookingChangeHistory {
        id
        originalStartDate
        originalEndDate
        originalTotalCost
        newStartDate
        newEndDate
        newTotalCost
        reason
        createdAt
        createdByUser {
          id
          email
          userRole
        }
      }
      bookingAnswer {
        id
        createdAt
        answer
        bookingQuestion {
          id
          title
          type
          kind
          orderIndex
          parsedTitle
          isRequired
        }
        userId
      }
    }
  }
`;

/**
 * __useGetBookingDetailQuery__
 *
 * To run a query within a React component, call `useGetBookingDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingDetailQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetBookingDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetBookingDetailQuery,
    Types.GetBookingDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetBookingDetailQuery,
    Types.GetBookingDetailQueryVariables
  >(GetBookingDetailDocument, options);
}
export function useGetBookingDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetBookingDetailQuery,
    Types.GetBookingDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetBookingDetailQuery,
    Types.GetBookingDetailQueryVariables
  >(GetBookingDetailDocument, options);
}
export type GetBookingDetailQueryHookResult = ReturnType<
  typeof useGetBookingDetailQuery
>;
export type GetBookingDetailLazyQueryHookResult = ReturnType<
  typeof useGetBookingDetailLazyQuery
>;
export type GetBookingDetailQueryResult = Apollo.QueryResult<
  Types.GetBookingDetailQuery,
  Types.GetBookingDetailQueryVariables
>;
export const CreateNewBookingDocument = gql`
  mutation CreateNewBooking(
    $vehicleId: String!
    $userId: String!
    $startDate: String!
    $endDate: String!
    $bookingComment: String!
    $pickupLocationNotes: String!
    $returnLocationNotes: String!
  ) {
    createNewBooking(
      userId: $userId
      bookingComment: $bookingComment
      startDate: $startDate
      endDate: $endDate
      vehicleId: $vehicleId
      pickupLocationNotes: $pickupLocationNotes
      returnLocationNotes: $returnLocationNotes
    ) {
      id
    }
  }
`;
export type CreateNewBookingMutationFn = Apollo.MutationFunction<
  Types.CreateNewBookingMutation,
  Types.CreateNewBookingMutationVariables
>;

/**
 * __useCreateNewBookingMutation__
 *
 * To run a mutation, you first call `useCreateNewBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewBookingMutation, { data, loading, error }] = useCreateNewBookingMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      userId: // value for 'userId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      bookingComment: // value for 'bookingComment'
 *      pickupLocationNotes: // value for 'pickupLocationNotes'
 *      returnLocationNotes: // value for 'returnLocationNotes'
 *   },
 * });
 */
export function useCreateNewBookingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateNewBookingMutation,
    Types.CreateNewBookingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateNewBookingMutation,
    Types.CreateNewBookingMutationVariables
  >(CreateNewBookingDocument, options);
}
export type CreateNewBookingMutationHookResult = ReturnType<
  typeof useCreateNewBookingMutation
>;
export type CreateNewBookingMutationResult =
  Apollo.MutationResult<Types.CreateNewBookingMutation>;
export type CreateNewBookingMutationOptions = Apollo.BaseMutationOptions<
  Types.CreateNewBookingMutation,
  Types.CreateNewBookingMutationVariables
>;
export const GetAvailableVehiclesDocument = gql`
  query GetAvailableVehicles(
    $userId: String!
    $startDateTime: String!
    $endDateTime: String!
    $latitude: Float
    $longitude: Float
  ) {
    getAvailableVehicles(
      userId: $userId
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      latitude: $latitude
      longitude: $longitude
    ) {
      id
      vin
      distance
      defaultVehicleImage
      defaultVehicleLocation {
        latitude
        longitude
        name
      }
      fuelType {
        id
      }
      brandType {
        id
        name
      }
      modelType {
        id
        name
      }
      vehicleType {
        id
      }
      transmissionType {
        id
      }
      driveType {
        id
      }
      seatType {
        id
      }
      doorType {
        id
      }
      plateNumber
      priceHour
      priceDay
      priceKm
      priceWeek
      includedKmHour
      includedKmDay
      includedKmWeek
      price {
        totalCost
        costForDuration
        costForDistance
        includedDistance_km
        insuranceCost
        deductibleReduction
        totalCostWithoutInsurance
        totalHourlyCost
        totalDailyCost
        totalWeeklyCost
        totalHours
        totalDays
        totalWeeks
        excessKm
        last4Digits
        cardBrand
      }
    }
  }
`;

/**
 * __useGetAvailableVehiclesQuery__
 *
 * To run a query within a React component, call `useGetAvailableVehiclesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableVehiclesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableVehiclesQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *      latitude: // value for 'latitude'
 *      longitude: // value for 'longitude'
 *   },
 * });
 */
export function useGetAvailableVehiclesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetAvailableVehiclesQuery,
    Types.GetAvailableVehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetAvailableVehiclesQuery,
    Types.GetAvailableVehiclesQueryVariables
  >(GetAvailableVehiclesDocument, options);
}
export function useGetAvailableVehiclesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetAvailableVehiclesQuery,
    Types.GetAvailableVehiclesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetAvailableVehiclesQuery,
    Types.GetAvailableVehiclesQueryVariables
  >(GetAvailableVehiclesDocument, options);
}
export type GetAvailableVehiclesQueryHookResult = ReturnType<
  typeof useGetAvailableVehiclesQuery
>;
export type GetAvailableVehiclesLazyQueryHookResult = ReturnType<
  typeof useGetAvailableVehiclesLazyQuery
>;
export type GetAvailableVehiclesQueryResult = Apollo.QueryResult<
  Types.GetAvailableVehiclesQuery,
  Types.GetAvailableVehiclesQueryVariables
>;
export const GetBookingCheckAvailabilityDocument = gql`
  query GetBookingCheckAvailability(
    $userId: String!
    $startDateTime: String!
    $endDateTime: String!
    $bookingId: String!
  ) {
    getBookingCheckAvailability(
      userId: $userId
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      bookingId: $bookingId
    )
  }
`;

/**
 * __useGetBookingCheckAvailabilityQuery__
 *
 * To run a query within a React component, call `useGetBookingCheckAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBookingCheckAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBookingCheckAvailabilityQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetBookingCheckAvailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetBookingCheckAvailabilityQuery,
    Types.GetBookingCheckAvailabilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetBookingCheckAvailabilityQuery,
    Types.GetBookingCheckAvailabilityQueryVariables
  >(GetBookingCheckAvailabilityDocument, options);
}
export function useGetBookingCheckAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetBookingCheckAvailabilityQuery,
    Types.GetBookingCheckAvailabilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetBookingCheckAvailabilityQuery,
    Types.GetBookingCheckAvailabilityQueryVariables
  >(GetBookingCheckAvailabilityDocument, options);
}
export type GetBookingCheckAvailabilityQueryHookResult = ReturnType<
  typeof useGetBookingCheckAvailabilityQuery
>;
export type GetBookingCheckAvailabilityLazyQueryHookResult = ReturnType<
  typeof useGetBookingCheckAvailabilityLazyQuery
>;
export type GetBookingCheckAvailabilityQueryResult = Apollo.QueryResult<
  Types.GetBookingCheckAvailabilityQuery,
  Types.GetBookingCheckAvailabilityQueryVariables
>;
export const GetCancellationFeesDocument = gql`
  query GetCancellationFees($bookingId: String!) {
    getCancellationFees(bookingId: $bookingId) {
      cancellationFees
      chargedPercent
    }
  }
`;

/**
 * __useGetCancellationFeesQuery__
 *
 * To run a query within a React component, call `useGetCancellationFeesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCancellationFeesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCancellationFeesQuery({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *   },
 * });
 */
export function useGetCancellationFeesQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetCancellationFeesQuery,
    Types.GetCancellationFeesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetCancellationFeesQuery,
    Types.GetCancellationFeesQueryVariables
  >(GetCancellationFeesDocument, options);
}
export function useGetCancellationFeesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetCancellationFeesQuery,
    Types.GetCancellationFeesQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetCancellationFeesQuery,
    Types.GetCancellationFeesQueryVariables
  >(GetCancellationFeesDocument, options);
}
export type GetCancellationFeesQueryHookResult = ReturnType<
  typeof useGetCancellationFeesQuery
>;
export type GetCancellationFeesLazyQueryHookResult = ReturnType<
  typeof useGetCancellationFeesLazyQuery
>;
export type GetCancellationFeesQueryResult = Apollo.QueryResult<
  Types.GetCancellationFeesQuery,
  Types.GetCancellationFeesQueryVariables
>;
export const RevokeBookingDocument = gql`
  mutation revokeBooking(
    $bookingId: String!
    $revokeReason: String!
    $refunding: Float
    $withInsurance: Boolean
  ) {
    revokeBooking(
      bookingId: $bookingId
      revokeReason: $revokeReason
      refunding: $refunding
      withInsurance: $withInsurance
    )
  }
`;
export type RevokeBookingMutationFn = Apollo.MutationFunction<
  Types.RevokeBookingMutation,
  Types.RevokeBookingMutationVariables
>;

/**
 * __useRevokeBookingMutation__
 *
 * To run a mutation, you first call `useRevokeBookingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeBookingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeBookingMutation, { data, loading, error }] = useRevokeBookingMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      revokeReason: // value for 'revokeReason'
 *      refunding: // value for 'refunding'
 *      withInsurance: // value for 'withInsurance'
 *   },
 * });
 */
export function useRevokeBookingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.RevokeBookingMutation,
    Types.RevokeBookingMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.RevokeBookingMutation,
    Types.RevokeBookingMutationVariables
  >(RevokeBookingDocument, options);
}
export type RevokeBookingMutationHookResult = ReturnType<
  typeof useRevokeBookingMutation
>;
export type RevokeBookingMutationResult =
  Apollo.MutationResult<Types.RevokeBookingMutation>;
export type RevokeBookingMutationOptions = Apollo.BaseMutationOptions<
  Types.RevokeBookingMutation,
  Types.RevokeBookingMutationVariables
>;
export const SendMessageDocument = gql`
  mutation sendMessage(
    $bookingId: String!
    $message: String!
    $variant: SendMessageEnum!
  ) {
    sendMessage(bookingId: $bookingId, message: $message, variant: $variant)
  }
`;
export type SendMessageMutationFn = Apollo.MutationFunction<
  Types.SendMessageMutation,
  Types.SendMessageMutationVariables
>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      message: // value for 'message'
 *      variant: // value for 'variant'
 *   },
 * });
 */
export function useSendMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SendMessageMutation,
    Types.SendMessageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.SendMessageMutation,
    Types.SendMessageMutationVariables
  >(SendMessageDocument, options);
}
export type SendMessageMutationHookResult = ReturnType<
  typeof useSendMessageMutation
>;
export type SendMessageMutationResult =
  Apollo.MutationResult<Types.SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<
  Types.SendMessageMutation,
  Types.SendMessageMutationVariables
>;
export const VehicleFiltersDocument = gql`
  query VehicleFilters {
    vehicleFilters {
      vehicleTypes {
        id
        name
      }
      transmissionTypes {
        id
        name
      }
      fuelTypes {
        id
        name
      }
      driveTypes {
        id
        name
      }
      doorTypes {
        id
        name
      }
      seatTypes {
        id
        name
      }
      minPrice
      maxPrice
    }
  }
`;

/**
 * __useVehicleFiltersQuery__
 *
 * To run a query within a React component, call `useVehicleFiltersQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleFiltersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleFiltersQuery({
 *   variables: {
 *   },
 * });
 */
export function useVehicleFiltersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.VehicleFiltersQuery,
    Types.VehicleFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.VehicleFiltersQuery,
    Types.VehicleFiltersQueryVariables
  >(VehicleFiltersDocument, options);
}
export function useVehicleFiltersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.VehicleFiltersQuery,
    Types.VehicleFiltersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.VehicleFiltersQuery,
    Types.VehicleFiltersQueryVariables
  >(VehicleFiltersDocument, options);
}
export type VehicleFiltersQueryHookResult = ReturnType<
  typeof useVehicleFiltersQuery
>;
export type VehicleFiltersLazyQueryHookResult = ReturnType<
  typeof useVehicleFiltersLazyQuery
>;
export type VehicleFiltersQueryResult = Apollo.QueryResult<
  Types.VehicleFiltersQuery,
  Types.VehicleFiltersQueryVariables
>;
export const UploadBillingAccountDocumentsDocument = gql`
  mutation uploadBillingAccountDocuments(
    $files: [Upload!]!
    $billingAccountId: String
  ) {
    uploadBillingAccountDocuments(
      files: $files
      billingAccountId: $billingAccountId
    ) {
      id
      name
      originalName
    }
  }
`;
export type UploadBillingAccountDocumentsMutationFn = Apollo.MutationFunction<
  Types.UploadBillingAccountDocumentsMutation,
  Types.UploadBillingAccountDocumentsMutationVariables
>;

/**
 * __useUploadBillingAccountDocumentsMutation__
 *
 * To run a mutation, you first call `useUploadBillingAccountDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadBillingAccountDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadBillingAccountDocumentsMutation, { data, loading, error }] = useUploadBillingAccountDocumentsMutation({
 *   variables: {
 *      files: // value for 'files'
 *      billingAccountId: // value for 'billingAccountId'
 *   },
 * });
 */
export function useUploadBillingAccountDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UploadBillingAccountDocumentsMutation,
    Types.UploadBillingAccountDocumentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UploadBillingAccountDocumentsMutation,
    Types.UploadBillingAccountDocumentsMutationVariables
  >(UploadBillingAccountDocumentsDocument, options);
}
export type UploadBillingAccountDocumentsMutationHookResult = ReturnType<
  typeof useUploadBillingAccountDocumentsMutation
>;
export type UploadBillingAccountDocumentsMutationResult =
  Apollo.MutationResult<Types.UploadBillingAccountDocumentsMutation>;
export type UploadBillingAccountDocumentsMutationOptions =
  Apollo.BaseMutationOptions<
    Types.UploadBillingAccountDocumentsMutation,
    Types.UploadBillingAccountDocumentsMutationVariables
  >;
export const DeleteBillingAccountDocumentDocument = gql`
  mutation deleteBillingAccountDocument(
    $documentId: String!
    $billingAccountId: String
  ) {
    deleteBillingAccountDocument(
      documentId: $documentId
      billingAccountId: $billingAccountId
    )
  }
`;
export type DeleteBillingAccountDocumentMutationFn = Apollo.MutationFunction<
  Types.DeleteBillingAccountDocumentMutation,
  Types.DeleteBillingAccountDocumentMutationVariables
>;

/**
 * __useDeleteBillingAccountDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteBillingAccountDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteBillingAccountDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteBillingAccountDocumentMutation, { data, loading, error }] = useDeleteBillingAccountDocumentMutation({
 *   variables: {
 *      documentId: // value for 'documentId'
 *      billingAccountId: // value for 'billingAccountId'
 *   },
 * });
 */
export function useDeleteBillingAccountDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteBillingAccountDocumentMutation,
    Types.DeleteBillingAccountDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteBillingAccountDocumentMutation,
    Types.DeleteBillingAccountDocumentMutationVariables
  >(DeleteBillingAccountDocumentDocument, options);
}
export type DeleteBillingAccountDocumentMutationHookResult = ReturnType<
  typeof useDeleteBillingAccountDocumentMutation
>;
export type DeleteBillingAccountDocumentMutationResult =
  Apollo.MutationResult<Types.DeleteBillingAccountDocumentMutation>;
export type DeleteBillingAccountDocumentMutationOptions =
  Apollo.BaseMutationOptions<
    Types.DeleteBillingAccountDocumentMutation,
    Types.DeleteBillingAccountDocumentMutationVariables
  >;
export const FetchInvisiaListDocument = gql`
  query fetchInvisiaList {
    fetchInvisiaList {
      eves_id
      fuseNumber
      disabled
      connectorType
      serialnumber
      descriptionGui
      descriptionHmi
      meterId
      rfidEnabled
      rfidLearningMode
      companyId
    }
  }
`;

/**
 * __useFetchInvisiaListQuery__
 *
 * To run a query within a React component, call `useFetchInvisiaListQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchInvisiaListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchInvisiaListQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchInvisiaListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.FetchInvisiaListQuery,
    Types.FetchInvisiaListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.FetchInvisiaListQuery,
    Types.FetchInvisiaListQueryVariables
  >(FetchInvisiaListDocument, options);
}
export function useFetchInvisiaListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.FetchInvisiaListQuery,
    Types.FetchInvisiaListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.FetchInvisiaListQuery,
    Types.FetchInvisiaListQueryVariables
  >(FetchInvisiaListDocument, options);
}
export type FetchInvisiaListQueryHookResult = ReturnType<
  typeof useFetchInvisiaListQuery
>;
export type FetchInvisiaListLazyQueryHookResult = ReturnType<
  typeof useFetchInvisiaListLazyQuery
>;
export type FetchInvisiaListQueryResult = Apollo.QueryResult<
  Types.FetchInvisiaListQuery,
  Types.FetchInvisiaListQueryVariables
>;
export const FinishTransactionSessionDocument = gql`
  mutation FinishTransactionSession($transactionId: String!, $userId: String) {
    finishChargingStationTransactionSession(
      transactionId: $transactionId
      userId: $userId
    )
  }
`;
export type FinishTransactionSessionMutationFn = Apollo.MutationFunction<
  Types.FinishTransactionSessionMutation,
  Types.FinishTransactionSessionMutationVariables
>;

/**
 * __useFinishTransactionSessionMutation__
 *
 * To run a mutation, you first call `useFinishTransactionSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinishTransactionSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finishTransactionSessionMutation, { data, loading, error }] = useFinishTransactionSessionMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useFinishTransactionSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.FinishTransactionSessionMutation,
    Types.FinishTransactionSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.FinishTransactionSessionMutation,
    Types.FinishTransactionSessionMutationVariables
  >(FinishTransactionSessionDocument, options);
}
export type FinishTransactionSessionMutationHookResult = ReturnType<
  typeof useFinishTransactionSessionMutation
>;
export type FinishTransactionSessionMutationResult =
  Apollo.MutationResult<Types.FinishTransactionSessionMutation>;
export type FinishTransactionSessionMutationOptions =
  Apollo.BaseMutationOptions<
    Types.FinishTransactionSessionMutation,
    Types.FinishTransactionSessionMutationVariables
  >;
export const StartChargingStationDocument = gql`
  mutation StartChargingStation($evseId: String!) {
    startChargingStation(evseId: $evseId)
  }
`;
export type StartChargingStationMutationFn = Apollo.MutationFunction<
  Types.StartChargingStationMutation,
  Types.StartChargingStationMutationVariables
>;

/**
 * __useStartChargingStationMutation__
 *
 * To run a mutation, you first call `useStartChargingStationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartChargingStationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startChargingStationMutation, { data, loading, error }] = useStartChargingStationMutation({
 *   variables: {
 *      evseId: // value for 'evseId'
 *   },
 * });
 */
export function useStartChargingStationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.StartChargingStationMutation,
    Types.StartChargingStationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.StartChargingStationMutation,
    Types.StartChargingStationMutationVariables
  >(StartChargingStationDocument, options);
}
export type StartChargingStationMutationHookResult = ReturnType<
  typeof useStartChargingStationMutation
>;
export type StartChargingStationMutationResult =
  Apollo.MutationResult<Types.StartChargingStationMutation>;
export type StartChargingStationMutationOptions = Apollo.BaseMutationOptions<
  Types.StartChargingStationMutation,
  Types.StartChargingStationMutationVariables
>;
export const StartTransactionSessionDocument = gql`
  mutation StartTransactionSession($transactionId: String!, $userId: String) {
    startChargingStationTransactionSession(
      transactionId: $transactionId
      userId: $userId
    )
  }
`;
export type StartTransactionSessionMutationFn = Apollo.MutationFunction<
  Types.StartTransactionSessionMutation,
  Types.StartTransactionSessionMutationVariables
>;

/**
 * __useStartTransactionSessionMutation__
 *
 * To run a mutation, you first call `useStartTransactionSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartTransactionSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startTransactionSessionMutation, { data, loading, error }] = useStartTransactionSessionMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useStartTransactionSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.StartTransactionSessionMutation,
    Types.StartTransactionSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.StartTransactionSessionMutation,
    Types.StartTransactionSessionMutationVariables
  >(StartTransactionSessionDocument, options);
}
export type StartTransactionSessionMutationHookResult = ReturnType<
  typeof useStartTransactionSessionMutation
>;
export type StartTransactionSessionMutationResult =
  Apollo.MutationResult<Types.StartTransactionSessionMutation>;
export type StartTransactionSessionMutationOptions = Apollo.BaseMutationOptions<
  Types.StartTransactionSessionMutation,
  Types.StartTransactionSessionMutationVariables
>;
export const StopChargingStationDocument = gql`
  mutation StopChargingStation($evseId: String!) {
    stopChargingStation(evseId: $evseId)
  }
`;
export type StopChargingStationMutationFn = Apollo.MutationFunction<
  Types.StopChargingStationMutation,
  Types.StopChargingStationMutationVariables
>;

/**
 * __useStopChargingStationMutation__
 *
 * To run a mutation, you first call `useStopChargingStationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopChargingStationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopChargingStationMutation, { data, loading, error }] = useStopChargingStationMutation({
 *   variables: {
 *      evseId: // value for 'evseId'
 *   },
 * });
 */
export function useStopChargingStationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.StopChargingStationMutation,
    Types.StopChargingStationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.StopChargingStationMutation,
    Types.StopChargingStationMutationVariables
  >(StopChargingStationDocument, options);
}
export type StopChargingStationMutationHookResult = ReturnType<
  typeof useStopChargingStationMutation
>;
export type StopChargingStationMutationResult =
  Apollo.MutationResult<Types.StopChargingStationMutation>;
export type StopChargingStationMutationOptions = Apollo.BaseMutationOptions<
  Types.StopChargingStationMutation,
  Types.StopChargingStationMutationVariables
>;
export const StopTransactionSessionDocument = gql`
  mutation StopTransactionSession($transactionId: String!, $userId: String!) {
    stopChargingStationTransactionSession(
      transactionId: $transactionId
      userId: $userId
    )
  }
`;
export type StopTransactionSessionMutationFn = Apollo.MutationFunction<
  Types.StopTransactionSessionMutation,
  Types.StopTransactionSessionMutationVariables
>;

/**
 * __useStopTransactionSessionMutation__
 *
 * To run a mutation, you first call `useStopTransactionSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopTransactionSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopTransactionSessionMutation, { data, loading, error }] = useStopTransactionSessionMutation({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useStopTransactionSessionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.StopTransactionSessionMutation,
    Types.StopTransactionSessionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.StopTransactionSessionMutation,
    Types.StopTransactionSessionMutationVariables
  >(StopTransactionSessionDocument, options);
}
export type StopTransactionSessionMutationHookResult = ReturnType<
  typeof useStopTransactionSessionMutation
>;
export type StopTransactionSessionMutationResult =
  Apollo.MutationResult<Types.StopTransactionSessionMutation>;
export type StopTransactionSessionMutationOptions = Apollo.BaseMutationOptions<
  Types.StopTransactionSessionMutation,
  Types.StopTransactionSessionMutationVariables
>;
export const GetTransactionCalculationDocument = gql`
  query GetTransactionCalculation($transactionId: String!) {
    getTransactionCalculation(transactionId: $transactionId) {
      tariffs {
        id
        name
        price
        guaranteeAmount
        amountPerMinute
        tariffType
        percentage
        usedkWh
        startDate
        endDate
      }
      totalCost
      totalkWh
      totalMinutes
    }
  }
`;

/**
 * __useGetTransactionCalculationQuery__
 *
 * To run a query within a React component, call `useGetTransactionCalculationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionCalculationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionCalculationQuery({
 *   variables: {
 *      transactionId: // value for 'transactionId'
 *   },
 * });
 */
export function useGetTransactionCalculationQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetTransactionCalculationQuery,
    Types.GetTransactionCalculationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetTransactionCalculationQuery,
    Types.GetTransactionCalculationQueryVariables
  >(GetTransactionCalculationDocument, options);
}
export function useGetTransactionCalculationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetTransactionCalculationQuery,
    Types.GetTransactionCalculationQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetTransactionCalculationQuery,
    Types.GetTransactionCalculationQueryVariables
  >(GetTransactionCalculationDocument, options);
}
export type GetTransactionCalculationQueryHookResult = ReturnType<
  typeof useGetTransactionCalculationQuery
>;
export type GetTransactionCalculationLazyQueryHookResult = ReturnType<
  typeof useGetTransactionCalculationLazyQuery
>;
export type GetTransactionCalculationQueryResult = Apollo.QueryResult<
  Types.GetTransactionCalculationQuery,
  Types.GetTransactionCalculationQueryVariables
>;
export const UploadCompanyLogoDocument = gql`
  mutation uploadCompanyLogo($file: Upload!, $companyId: String!) {
    uploadCompanyLogo(file: $file, companyId: $companyId)
  }
`;
export type UploadCompanyLogoMutationFn = Apollo.MutationFunction<
  Types.UploadCompanyLogoMutation,
  Types.UploadCompanyLogoMutationVariables
>;

/**
 * __useUploadCompanyLogoMutation__
 *
 * To run a mutation, you first call `useUploadCompanyLogoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCompanyLogoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCompanyLogoMutation, { data, loading, error }] = useUploadCompanyLogoMutation({
 *   variables: {
 *      file: // value for 'file'
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useUploadCompanyLogoMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UploadCompanyLogoMutation,
    Types.UploadCompanyLogoMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UploadCompanyLogoMutation,
    Types.UploadCompanyLogoMutationVariables
  >(UploadCompanyLogoDocument, options);
}
export type UploadCompanyLogoMutationHookResult = ReturnType<
  typeof useUploadCompanyLogoMutation
>;
export type UploadCompanyLogoMutationResult =
  Apollo.MutationResult<Types.UploadCompanyLogoMutation>;
export type UploadCompanyLogoMutationOptions = Apollo.BaseMutationOptions<
  Types.UploadCompanyLogoMutation,
  Types.UploadCompanyLogoMutationVariables
>;
export const OpenGateControllerDocument = gql`
  mutation OpenGateController($gateId: String!, $comment: String!) {
    openGateController(gateId: $gateId, comment: $comment)
  }
`;
export type OpenGateControllerMutationFn = Apollo.MutationFunction<
  Types.OpenGateControllerMutation,
  Types.OpenGateControllerMutationVariables
>;

/**
 * __useOpenGateControllerMutation__
 *
 * To run a mutation, you first call `useOpenGateControllerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOpenGateControllerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [openGateControllerMutation, { data, loading, error }] = useOpenGateControllerMutation({
 *   variables: {
 *      gateId: // value for 'gateId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useOpenGateControllerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.OpenGateControllerMutation,
    Types.OpenGateControllerMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.OpenGateControllerMutation,
    Types.OpenGateControllerMutationVariables
  >(OpenGateControllerDocument, options);
}
export type OpenGateControllerMutationHookResult = ReturnType<
  typeof useOpenGateControllerMutation
>;
export type OpenGateControllerMutationResult =
  Apollo.MutationResult<Types.OpenGateControllerMutation>;
export type OpenGateControllerMutationOptions = Apollo.BaseMutationOptions<
  Types.OpenGateControllerMutation,
  Types.OpenGateControllerMutationVariables
>;
export const UploadInsuranceDocumentsDocument = gql`
  mutation uploadInsuranceDocuments(
    $files: [Upload!]!
    $vehicleInsuranceTypeId: String
  ) {
    uploadDocuments(
      files: $files
      vehicleInsuranceTypeId: $vehicleInsuranceTypeId
    ) {
      id
      name
    }
  }
`;
export type UploadInsuranceDocumentsMutationFn = Apollo.MutationFunction<
  Types.UploadInsuranceDocumentsMutation,
  Types.UploadInsuranceDocumentsMutationVariables
>;

/**
 * __useUploadInsuranceDocumentsMutation__
 *
 * To run a mutation, you first call `useUploadInsuranceDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadInsuranceDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadInsuranceDocumentsMutation, { data, loading, error }] = useUploadInsuranceDocumentsMutation({
 *   variables: {
 *      files: // value for 'files'
 *      vehicleInsuranceTypeId: // value for 'vehicleInsuranceTypeId'
 *   },
 * });
 */
export function useUploadInsuranceDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UploadInsuranceDocumentsMutation,
    Types.UploadInsuranceDocumentsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UploadInsuranceDocumentsMutation,
    Types.UploadInsuranceDocumentsMutationVariables
  >(UploadInsuranceDocumentsDocument, options);
}
export type UploadInsuranceDocumentsMutationHookResult = ReturnType<
  typeof useUploadInsuranceDocumentsMutation
>;
export type UploadInsuranceDocumentsMutationResult =
  Apollo.MutationResult<Types.UploadInsuranceDocumentsMutation>;
export type UploadInsuranceDocumentsMutationOptions =
  Apollo.BaseMutationOptions<
    Types.UploadInsuranceDocumentsMutation,
    Types.UploadInsuranceDocumentsMutationVariables
  >;
export const DeleteInsuranceDocumentDocument = gql`
  mutation deleteInsuranceDocument(
    $vehicleDocumentId: String!
    $vehicleInsuranceTypeId: String
  ) {
    deleteDocument(
      vehicleDocumentId: $vehicleDocumentId
      vehicleInsuranceTypeId: $vehicleInsuranceTypeId
    )
  }
`;
export type DeleteInsuranceDocumentMutationFn = Apollo.MutationFunction<
  Types.DeleteInsuranceDocumentMutation,
  Types.DeleteInsuranceDocumentMutationVariables
>;

/**
 * __useDeleteInsuranceDocumentMutation__
 *
 * To run a mutation, you first call `useDeleteInsuranceDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInsuranceDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInsuranceDocumentMutation, { data, loading, error }] = useDeleteInsuranceDocumentMutation({
 *   variables: {
 *      vehicleDocumentId: // value for 'vehicleDocumentId'
 *      vehicleInsuranceTypeId: // value for 'vehicleInsuranceTypeId'
 *   },
 * });
 */
export function useDeleteInsuranceDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteInsuranceDocumentMutation,
    Types.DeleteInsuranceDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteInsuranceDocumentMutation,
    Types.DeleteInsuranceDocumentMutationVariables
  >(DeleteInsuranceDocumentDocument, options);
}
export type DeleteInsuranceDocumentMutationHookResult = ReturnType<
  typeof useDeleteInsuranceDocumentMutation
>;
export type DeleteInsuranceDocumentMutationResult =
  Apollo.MutationResult<Types.DeleteInsuranceDocumentMutation>;
export type DeleteInsuranceDocumentMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteInsuranceDocumentMutation,
  Types.DeleteInsuranceDocumentMutationVariables
>;
export const GenerateUserOnboardingStripeLinkDocument = gql`
  mutation GenerateUserOnboardingStripeLink($userId: String!) {
    generateUserOnboardingStripeLink(userId: $userId)
  }
`;
export type GenerateUserOnboardingStripeLinkMutationFn =
  Apollo.MutationFunction<
    Types.GenerateUserOnboardingStripeLinkMutation,
    Types.GenerateUserOnboardingStripeLinkMutationVariables
  >;

/**
 * __useGenerateUserOnboardingStripeLinkMutation__
 *
 * To run a mutation, you first call `useGenerateUserOnboardingStripeLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateUserOnboardingStripeLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateUserOnboardingStripeLinkMutation, { data, loading, error }] = useGenerateUserOnboardingStripeLinkMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGenerateUserOnboardingStripeLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.GenerateUserOnboardingStripeLinkMutation,
    Types.GenerateUserOnboardingStripeLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.GenerateUserOnboardingStripeLinkMutation,
    Types.GenerateUserOnboardingStripeLinkMutationVariables
  >(GenerateUserOnboardingStripeLinkDocument, options);
}
export type GenerateUserOnboardingStripeLinkMutationHookResult = ReturnType<
  typeof useGenerateUserOnboardingStripeLinkMutation
>;
export type GenerateUserOnboardingStripeLinkMutationResult =
  Apollo.MutationResult<Types.GenerateUserOnboardingStripeLinkMutation>;
export type GenerateUserOnboardingStripeLinkMutationOptions =
  Apollo.BaseMutationOptions<
    Types.GenerateUserOnboardingStripeLinkMutation,
    Types.GenerateUserOnboardingStripeLinkMutationVariables
  >;
export const GenerateCompanyOnboardingStripeLinkDocument = gql`
  mutation GenerateCompanyOnboardingStripeLink($companyId: String!) {
    generateCompanyOnboardingStripeLink(companyId: $companyId)
  }
`;
export type GenerateCompanyOnboardingStripeLinkMutationFn =
  Apollo.MutationFunction<
    Types.GenerateCompanyOnboardingStripeLinkMutation,
    Types.GenerateCompanyOnboardingStripeLinkMutationVariables
  >;

/**
 * __useGenerateCompanyOnboardingStripeLinkMutation__
 *
 * To run a mutation, you first call `useGenerateCompanyOnboardingStripeLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateCompanyOnboardingStripeLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateCompanyOnboardingStripeLinkMutation, { data, loading, error }] = useGenerateCompanyOnboardingStripeLinkMutation({
 *   variables: {
 *      companyId: // value for 'companyId'
 *   },
 * });
 */
export function useGenerateCompanyOnboardingStripeLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.GenerateCompanyOnboardingStripeLinkMutation,
    Types.GenerateCompanyOnboardingStripeLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.GenerateCompanyOnboardingStripeLinkMutation,
    Types.GenerateCompanyOnboardingStripeLinkMutationVariables
  >(GenerateCompanyOnboardingStripeLinkDocument, options);
}
export type GenerateCompanyOnboardingStripeLinkMutationHookResult = ReturnType<
  typeof useGenerateCompanyOnboardingStripeLinkMutation
>;
export type GenerateCompanyOnboardingStripeLinkMutationResult =
  Apollo.MutationResult<Types.GenerateCompanyOnboardingStripeLinkMutation>;
export type GenerateCompanyOnboardingStripeLinkMutationOptions =
  Apollo.BaseMutationOptions<
    Types.GenerateCompanyOnboardingStripeLinkMutation,
    Types.GenerateCompanyOnboardingStripeLinkMutationVariables
  >;
export const ResendEmailVerificationLinkDocument = gql`
  mutation resendEmailVerificationLink($userId: String!) {
    resendEmailVerificationLink(userId: $userId)
  }
`;
export type ResendEmailVerificationLinkMutationFn = Apollo.MutationFunction<
  Types.ResendEmailVerificationLinkMutation,
  Types.ResendEmailVerificationLinkMutationVariables
>;

/**
 * __useResendEmailVerificationLinkMutation__
 *
 * To run a mutation, you first call `useResendEmailVerificationLinkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendEmailVerificationLinkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendEmailVerificationLinkMutation, { data, loading, error }] = useResendEmailVerificationLinkMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useResendEmailVerificationLinkMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ResendEmailVerificationLinkMutation,
    Types.ResendEmailVerificationLinkMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ResendEmailVerificationLinkMutation,
    Types.ResendEmailVerificationLinkMutationVariables
  >(ResendEmailVerificationLinkDocument, options);
}
export type ResendEmailVerificationLinkMutationHookResult = ReturnType<
  typeof useResendEmailVerificationLinkMutation
>;
export type ResendEmailVerificationLinkMutationResult =
  Apollo.MutationResult<Types.ResendEmailVerificationLinkMutation>;
export type ResendEmailVerificationLinkMutationOptions =
  Apollo.BaseMutationOptions<
    Types.ResendEmailVerificationLinkMutation,
    Types.ResendEmailVerificationLinkMutationVariables
  >;
export const ResetPasswordDocument = gql`
  mutation resetPassword($userId: String!) {
    resetPassword(userId: $userId)
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<
  Types.ResetPasswordMutation,
  Types.ResetPasswordMutationVariables
>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ResetPasswordMutation,
    Types.ResetPasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ResetPasswordMutation,
    Types.ResetPasswordMutationVariables
  >(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<
  typeof useResetPasswordMutation
>;
export type ResetPasswordMutationResult =
  Apollo.MutationResult<Types.ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  Types.ResetPasswordMutation,
  Types.ResetPasswordMutationVariables
>;
export const SaveOneTimePasswordDocument = gql`
  mutation saveOneTimePassword($userId: String!, $oneTimePassword: String!) {
    saveOneTimePassword(userId: $userId, oneTimePassword: $oneTimePassword)
  }
`;
export type SaveOneTimePasswordMutationFn = Apollo.MutationFunction<
  Types.SaveOneTimePasswordMutation,
  Types.SaveOneTimePasswordMutationVariables
>;

/**
 * __useSaveOneTimePasswordMutation__
 *
 * To run a mutation, you first call `useSaveOneTimePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveOneTimePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveOneTimePasswordMutation, { data, loading, error }] = useSaveOneTimePasswordMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      oneTimePassword: // value for 'oneTimePassword'
 *   },
 * });
 */
export function useSaveOneTimePasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.SaveOneTimePasswordMutation,
    Types.SaveOneTimePasswordMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.SaveOneTimePasswordMutation,
    Types.SaveOneTimePasswordMutationVariables
  >(SaveOneTimePasswordDocument, options);
}
export type SaveOneTimePasswordMutationHookResult = ReturnType<
  typeof useSaveOneTimePasswordMutation
>;
export type SaveOneTimePasswordMutationResult =
  Apollo.MutationResult<Types.SaveOneTimePasswordMutation>;
export type SaveOneTimePasswordMutationOptions = Apollo.BaseMutationOptions<
  Types.SaveOneTimePasswordMutation,
  Types.SaveOneTimePasswordMutationVariables
>;
export const UploadVerificationDocumentDocument = gql`
  mutation uploadVerificationDocument($file: Upload!, $userId: String!) {
    uploadVerificationDocument(file: $file, userId: $userId)
  }
`;
export type UploadVerificationDocumentMutationFn = Apollo.MutationFunction<
  Types.UploadVerificationDocumentMutation,
  Types.UploadVerificationDocumentMutationVariables
>;

/**
 * __useUploadVerificationDocumentMutation__
 *
 * To run a mutation, you first call `useUploadVerificationDocumentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadVerificationDocumentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadVerificationDocumentMutation, { data, loading, error }] = useUploadVerificationDocumentMutation({
 *   variables: {
 *      file: // value for 'file'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUploadVerificationDocumentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UploadVerificationDocumentMutation,
    Types.UploadVerificationDocumentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UploadVerificationDocumentMutation,
    Types.UploadVerificationDocumentMutationVariables
  >(UploadVerificationDocumentDocument, options);
}
export type UploadVerificationDocumentMutationHookResult = ReturnType<
  typeof useUploadVerificationDocumentMutation
>;
export type UploadVerificationDocumentMutationResult =
  Apollo.MutationResult<Types.UploadVerificationDocumentMutation>;
export type UploadVerificationDocumentMutationOptions =
  Apollo.BaseMutationOptions<
    Types.UploadVerificationDocumentMutation,
    Types.UploadVerificationDocumentMutationVariables
  >;
export const VehicleCommentSuggestionsDocument = gql`
  query vehicleCommentSuggestions {
    vehicleCommentSuggestions
  }
`;

/**
 * __useVehicleCommentSuggestionsQuery__
 *
 * To run a query within a React component, call `useVehicleCommentSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleCommentSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleCommentSuggestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useVehicleCommentSuggestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.VehicleCommentSuggestionsQuery,
    Types.VehicleCommentSuggestionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.VehicleCommentSuggestionsQuery,
    Types.VehicleCommentSuggestionsQueryVariables
  >(VehicleCommentSuggestionsDocument, options);
}
export function useVehicleCommentSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.VehicleCommentSuggestionsQuery,
    Types.VehicleCommentSuggestionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.VehicleCommentSuggestionsQuery,
    Types.VehicleCommentSuggestionsQueryVariables
  >(VehicleCommentSuggestionsDocument, options);
}
export type VehicleCommentSuggestionsQueryHookResult = ReturnType<
  typeof useVehicleCommentSuggestionsQuery
>;
export type VehicleCommentSuggestionsLazyQueryHookResult = ReturnType<
  typeof useVehicleCommentSuggestionsLazyQuery
>;
export type VehicleCommentSuggestionsQueryResult = Apollo.QueryResult<
  Types.VehicleCommentSuggestionsQuery,
  Types.VehicleCommentSuggestionsQueryVariables
>;
export const CreateVehicleDamageCommentDocument = gql`
  mutation createVehicleDamageComment(
    $userId: String!
    $content: String!
    $vehicleDamageId: String!
  ) {
    createVehicleDamageComment(
      data: {
        user: { connect: { id: $userId } }
        content: $content
        vehicleDamage: { connect: { id: $vehicleDamageId } }
      }
    ) {
      id
    }
  }
`;
export type CreateVehicleDamageCommentMutationFn = Apollo.MutationFunction<
  Types.CreateVehicleDamageCommentMutation,
  Types.CreateVehicleDamageCommentMutationVariables
>;

/**
 * __useCreateVehicleDamageCommentMutation__
 *
 * To run a mutation, you first call `useCreateVehicleDamageCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateVehicleDamageCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createVehicleDamageCommentMutation, { data, loading, error }] = useCreateVehicleDamageCommentMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      content: // value for 'content'
 *      vehicleDamageId: // value for 'vehicleDamageId'
 *   },
 * });
 */
export function useCreateVehicleDamageCommentMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.CreateVehicleDamageCommentMutation,
    Types.CreateVehicleDamageCommentMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.CreateVehicleDamageCommentMutation,
    Types.CreateVehicleDamageCommentMutationVariables
  >(CreateVehicleDamageCommentDocument, options);
}
export type CreateVehicleDamageCommentMutationHookResult = ReturnType<
  typeof useCreateVehicleDamageCommentMutation
>;
export type CreateVehicleDamageCommentMutationResult =
  Apollo.MutationResult<Types.CreateVehicleDamageCommentMutation>;
export type CreateVehicleDamageCommentMutationOptions =
  Apollo.BaseMutationOptions<
    Types.CreateVehicleDamageCommentMutation,
    Types.CreateVehicleDamageCommentMutationVariables
  >;
export const VehicleDamageCommentsDocument = gql`
  query VehicleDamageComments(
    $where: VehicleDamageCommentWhereInput
    $orderBy: [VehicleDamageCommentOrderByWithRelationInput!]
  ) {
    vehicleDamageComments(where: $where, orderBy: $orderBy) {
      id
      userId
      user {
        email
      }
      vehicleDamageId
      content
      createdAt
    }
  }
`;

/**
 * __useVehicleDamageCommentsQuery__
 *
 * To run a query within a React component, call `useVehicleDamageCommentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleDamageCommentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleDamageCommentsQuery({
 *   variables: {
 *      where: // value for 'where'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useVehicleDamageCommentsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.VehicleDamageCommentsQuery,
    Types.VehicleDamageCommentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.VehicleDamageCommentsQuery,
    Types.VehicleDamageCommentsQueryVariables
  >(VehicleDamageCommentsDocument, options);
}
export function useVehicleDamageCommentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.VehicleDamageCommentsQuery,
    Types.VehicleDamageCommentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.VehicleDamageCommentsQuery,
    Types.VehicleDamageCommentsQueryVariables
  >(VehicleDamageCommentsDocument, options);
}
export type VehicleDamageCommentsQueryHookResult = ReturnType<
  typeof useVehicleDamageCommentsQuery
>;
export type VehicleDamageCommentsLazyQueryHookResult = ReturnType<
  typeof useVehicleDamageCommentsLazyQuery
>;
export type VehicleDamageCommentsQueryResult = Apollo.QueryResult<
  Types.VehicleDamageCommentsQuery,
  Types.VehicleDamageCommentsQueryVariables
>;
export const UpdateVehicleDamageDocument = gql`
  mutation updateVehicleDamage(
    $where: VehicleDamageWhereUniqueInput!
    $data: VehicleDamageUpdateInput!
  ) {
    updateVehicleDamage(data: $data, where: $where) {
      id
    }
  }
`;
export type UpdateVehicleDamageMutationFn = Apollo.MutationFunction<
  Types.UpdateVehicleDamageMutation,
  Types.UpdateVehicleDamageMutationVariables
>;

/**
 * __useUpdateVehicleDamageMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleDamageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleDamageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleDamageMutation, { data, loading, error }] = useUpdateVehicleDamageMutation({
 *   variables: {
 *      where: // value for 'where'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateVehicleDamageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateVehicleDamageMutation,
    Types.UpdateVehicleDamageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateVehicleDamageMutation,
    Types.UpdateVehicleDamageMutationVariables
  >(UpdateVehicleDamageDocument, options);
}
export type UpdateVehicleDamageMutationHookResult = ReturnType<
  typeof useUpdateVehicleDamageMutation
>;
export type UpdateVehicleDamageMutationResult =
  Apollo.MutationResult<Types.UpdateVehicleDamageMutation>;
export type UpdateVehicleDamageMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateVehicleDamageMutation,
  Types.UpdateVehicleDamageMutationVariables
>;
export const VehicleCloudBoxxConnectDocument = gql`
  mutation vehicleCloudBoxxConnect($vin: String!, $vehicleId: String) {
    vehicleCloudBoxxConnect(vin: $vin, vehicleId: $vehicleId) {
      qnr
      isActivated
    }
  }
`;
export type VehicleCloudBoxxConnectMutationFn = Apollo.MutationFunction<
  Types.VehicleCloudBoxxConnectMutation,
  Types.VehicleCloudBoxxConnectMutationVariables
>;

/**
 * __useVehicleCloudBoxxConnectMutation__
 *
 * To run a mutation, you first call `useVehicleCloudBoxxConnectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVehicleCloudBoxxConnectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vehicleCloudBoxxConnectMutation, { data, loading, error }] = useVehicleCloudBoxxConnectMutation({
 *   variables: {
 *      vin: // value for 'vin'
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useVehicleCloudBoxxConnectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.VehicleCloudBoxxConnectMutation,
    Types.VehicleCloudBoxxConnectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.VehicleCloudBoxxConnectMutation,
    Types.VehicleCloudBoxxConnectMutationVariables
  >(VehicleCloudBoxxConnectDocument, options);
}
export type VehicleCloudBoxxConnectMutationHookResult = ReturnType<
  typeof useVehicleCloudBoxxConnectMutation
>;
export type VehicleCloudBoxxConnectMutationResult =
  Apollo.MutationResult<Types.VehicleCloudBoxxConnectMutation>;
export type VehicleCloudBoxxConnectMutationOptions = Apollo.BaseMutationOptions<
  Types.VehicleCloudBoxxConnectMutation,
  Types.VehicleCloudBoxxConnectMutationVariables
>;
export const VehicleCloudBoxxDisconnectDocument = gql`
  mutation vehicleCloudBoxxDisconnect($vehicleId: String!) {
    vehicleCloudBoxxDisconnect(vehicleId: $vehicleId)
  }
`;
export type VehicleCloudBoxxDisconnectMutationFn = Apollo.MutationFunction<
  Types.VehicleCloudBoxxDisconnectMutation,
  Types.VehicleCloudBoxxDisconnectMutationVariables
>;

/**
 * __useVehicleCloudBoxxDisconnectMutation__
 *
 * To run a mutation, you first call `useVehicleCloudBoxxDisconnectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVehicleCloudBoxxDisconnectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vehicleCloudBoxxDisconnectMutation, { data, loading, error }] = useVehicleCloudBoxxDisconnectMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useVehicleCloudBoxxDisconnectMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.VehicleCloudBoxxDisconnectMutation,
    Types.VehicleCloudBoxxDisconnectMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.VehicleCloudBoxxDisconnectMutation,
    Types.VehicleCloudBoxxDisconnectMutationVariables
  >(VehicleCloudBoxxDisconnectDocument, options);
}
export type VehicleCloudBoxxDisconnectMutationHookResult = ReturnType<
  typeof useVehicleCloudBoxxDisconnectMutation
>;
export type VehicleCloudBoxxDisconnectMutationResult =
  Apollo.MutationResult<Types.VehicleCloudBoxxDisconnectMutation>;
export type VehicleCloudBoxxDisconnectMutationOptions =
  Apollo.BaseMutationOptions<
    Types.VehicleCloudBoxxDisconnectMutation,
    Types.VehicleCloudBoxxDisconnectMutationVariables
  >;
export const VehicleCloudBoxxActivateDocument = gql`
  mutation vehicleCloudBoxxActivate(
    $qnr: String!
    $vehicleId: String!
    $activate: Boolean!
  ) {
    vehicleCloudBoxxActivate(
      qnr: $qnr
      activate: $activate
      vehicleId: $vehicleId
    )
  }
`;
export type VehicleCloudBoxxActivateMutationFn = Apollo.MutationFunction<
  Types.VehicleCloudBoxxActivateMutation,
  Types.VehicleCloudBoxxActivateMutationVariables
>;

/**
 * __useVehicleCloudBoxxActivateMutation__
 *
 * To run a mutation, you first call `useVehicleCloudBoxxActivateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVehicleCloudBoxxActivateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [vehicleCloudBoxxActivateMutation, { data, loading, error }] = useVehicleCloudBoxxActivateMutation({
 *   variables: {
 *      qnr: // value for 'qnr'
 *      vehicleId: // value for 'vehicleId'
 *      activate: // value for 'activate'
 *   },
 * });
 */
export function useVehicleCloudBoxxActivateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.VehicleCloudBoxxActivateMutation,
    Types.VehicleCloudBoxxActivateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.VehicleCloudBoxxActivateMutation,
    Types.VehicleCloudBoxxActivateMutationVariables
  >(VehicleCloudBoxxActivateDocument, options);
}
export type VehicleCloudBoxxActivateMutationHookResult = ReturnType<
  typeof useVehicleCloudBoxxActivateMutation
>;
export type VehicleCloudBoxxActivateMutationResult =
  Apollo.MutationResult<Types.VehicleCloudBoxxActivateMutation>;
export type VehicleCloudBoxxActivateMutationOptions =
  Apollo.BaseMutationOptions<
    Types.VehicleCloudBoxxActivateMutation,
    Types.VehicleCloudBoxxActivateMutationVariables
  >;
export const CloudBoxxStatusLogsDocument = gql`
  query CloudBoxxStatusLogs($vehicleId: String!) {
    cloudBoxxStatusLogs(vehicleId: $vehicleId) {
      id
      createdAt
      status
      connected
      user {
        id
        email
      }
    }
  }
`;

/**
 * __useCloudBoxxStatusLogsQuery__
 *
 * To run a query within a React component, call `useCloudBoxxStatusLogsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCloudBoxxStatusLogsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCloudBoxxStatusLogsQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useCloudBoxxStatusLogsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.CloudBoxxStatusLogsQuery,
    Types.CloudBoxxStatusLogsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.CloudBoxxStatusLogsQuery,
    Types.CloudBoxxStatusLogsQueryVariables
  >(CloudBoxxStatusLogsDocument, options);
}
export function useCloudBoxxStatusLogsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.CloudBoxxStatusLogsQuery,
    Types.CloudBoxxStatusLogsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.CloudBoxxStatusLogsQuery,
    Types.CloudBoxxStatusLogsQueryVariables
  >(CloudBoxxStatusLogsDocument, options);
}
export type CloudBoxxStatusLogsQueryHookResult = ReturnType<
  typeof useCloudBoxxStatusLogsQuery
>;
export type CloudBoxxStatusLogsLazyQueryHookResult = ReturnType<
  typeof useCloudBoxxStatusLogsLazyQuery
>;
export type CloudBoxxStatusLogsQueryResult = Apollo.QueryResult<
  Types.CloudBoxxStatusLogsQuery,
  Types.CloudBoxxStatusLogsQueryVariables
>;
export const VehicleEquipmentsDocument = gql`
  query VehicleEquipments($vehicleId: String!) {
    vehicleEquipments(where: { vehicleId: { contains: $vehicleId } }) {
      id
      equipmentType {
        id
        name
      }
    }
  }
`;

/**
 * __useVehicleEquipmentsQuery__
 *
 * To run a query within a React component, call `useVehicleEquipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleEquipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleEquipmentsQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useVehicleEquipmentsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.VehicleEquipmentsQuery,
    Types.VehicleEquipmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.VehicleEquipmentsQuery,
    Types.VehicleEquipmentsQueryVariables
  >(VehicleEquipmentsDocument, options);
}
export function useVehicleEquipmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.VehicleEquipmentsQuery,
    Types.VehicleEquipmentsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.VehicleEquipmentsQuery,
    Types.VehicleEquipmentsQueryVariables
  >(VehicleEquipmentsDocument, options);
}
export type VehicleEquipmentsQueryHookResult = ReturnType<
  typeof useVehicleEquipmentsQuery
>;
export type VehicleEquipmentsLazyQueryHookResult = ReturnType<
  typeof useVehicleEquipmentsLazyQuery
>;
export type VehicleEquipmentsQueryResult = Apollo.QueryResult<
  Types.VehicleEquipmentsQuery,
  Types.VehicleEquipmentsQueryVariables
>;
export const AddVehicleAvailabilityDocument = gql`
  mutation addVehicleAvailability(
    $comment: String!
    $startDateTime: DateTime!
    $endDateTime: DateTime!
    $expiredAt: DateTime
    $isAvailable: Boolean!
    $vehicleId: String!
    $type: AvailabilityType!
    $sharedVehicleGroups: [String!]!
    $availabilityId: String
  ) {
    addVehicleAvailability(
      comment: $comment
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      expiredAt: $expiredAt
      vehicleId: $vehicleId
      isAvailable: $isAvailable
      type: $type
      sharedVehicleGroups: $sharedVehicleGroups
      availabilityId: $availabilityId
    )
  }
`;
export type AddVehicleAvailabilityMutationFn = Apollo.MutationFunction<
  Types.AddVehicleAvailabilityMutation,
  Types.AddVehicleAvailabilityMutationVariables
>;

/**
 * __useAddVehicleAvailabilityMutation__
 *
 * To run a mutation, you first call `useAddVehicleAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVehicleAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVehicleAvailabilityMutation, { data, loading, error }] = useAddVehicleAvailabilityMutation({
 *   variables: {
 *      comment: // value for 'comment'
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *      expiredAt: // value for 'expiredAt'
 *      isAvailable: // value for 'isAvailable'
 *      vehicleId: // value for 'vehicleId'
 *      type: // value for 'type'
 *      sharedVehicleGroups: // value for 'sharedVehicleGroups'
 *      availabilityId: // value for 'availabilityId'
 *   },
 * });
 */
export function useAddVehicleAvailabilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.AddVehicleAvailabilityMutation,
    Types.AddVehicleAvailabilityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.AddVehicleAvailabilityMutation,
    Types.AddVehicleAvailabilityMutationVariables
  >(AddVehicleAvailabilityDocument, options);
}
export type AddVehicleAvailabilityMutationHookResult = ReturnType<
  typeof useAddVehicleAvailabilityMutation
>;
export type AddVehicleAvailabilityMutationResult =
  Apollo.MutationResult<Types.AddVehicleAvailabilityMutation>;
export type AddVehicleAvailabilityMutationOptions = Apollo.BaseMutationOptions<
  Types.AddVehicleAvailabilityMutation,
  Types.AddVehicleAvailabilityMutationVariables
>;
export const UpdateVehicleAvailabilityDocument = gql`
  mutation updateVehicleAvailability(
    $availabilityId: String!
    $sharedVehicleGroups: [String!]
    $comment: String
    $startDateTime: DateTime
    $endDateTime: DateTime
    $expiredAt: DateTime
    $isAvailable: Boolean
    $type: AvailabilityType
  ) {
    updateVehicleAvailability(
      availabilityId: $availabilityId
      comment: $comment
      sharedVehicleGroups: $sharedVehicleGroups
      startDateTime: $startDateTime
      endDateTime: $endDateTime
      expiredAt: $expiredAt
      isAvailable: $isAvailable
      type: $type
    )
  }
`;
export type UpdateVehicleAvailabilityMutationFn = Apollo.MutationFunction<
  Types.UpdateVehicleAvailabilityMutation,
  Types.UpdateVehicleAvailabilityMutationVariables
>;

/**
 * __useUpdateVehicleAvailabilityMutation__
 *
 * To run a mutation, you first call `useUpdateVehicleAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateVehicleAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateVehicleAvailabilityMutation, { data, loading, error }] = useUpdateVehicleAvailabilityMutation({
 *   variables: {
 *      availabilityId: // value for 'availabilityId'
 *      sharedVehicleGroups: // value for 'sharedVehicleGroups'
 *      comment: // value for 'comment'
 *      startDateTime: // value for 'startDateTime'
 *      endDateTime: // value for 'endDateTime'
 *      expiredAt: // value for 'expiredAt'
 *      isAvailable: // value for 'isAvailable'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUpdateVehicleAvailabilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateVehicleAvailabilityMutation,
    Types.UpdateVehicleAvailabilityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateVehicleAvailabilityMutation,
    Types.UpdateVehicleAvailabilityMutationVariables
  >(UpdateVehicleAvailabilityDocument, options);
}
export type UpdateVehicleAvailabilityMutationHookResult = ReturnType<
  typeof useUpdateVehicleAvailabilityMutation
>;
export type UpdateVehicleAvailabilityMutationResult =
  Apollo.MutationResult<Types.UpdateVehicleAvailabilityMutation>;
export type UpdateVehicleAvailabilityMutationOptions =
  Apollo.BaseMutationOptions<
    Types.UpdateVehicleAvailabilityMutation,
    Types.UpdateVehicleAvailabilityMutationVariables
  >;
export const DeleteVehicleAvailabilityDocument = gql`
  mutation deleteVehicleAvailability($availabilityId: String!) {
    deleteVehicleAvailability(availabilityId: $availabilityId)
  }
`;
export type DeleteVehicleAvailabilityMutationFn = Apollo.MutationFunction<
  Types.DeleteVehicleAvailabilityMutation,
  Types.DeleteVehicleAvailabilityMutationVariables
>;

/**
 * __useDeleteVehicleAvailabilityMutation__
 *
 * To run a mutation, you first call `useDeleteVehicleAvailabilityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteVehicleAvailabilityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteVehicleAvailabilityMutation, { data, loading, error }] = useDeleteVehicleAvailabilityMutation({
 *   variables: {
 *      availabilityId: // value for 'availabilityId'
 *   },
 * });
 */
export function useDeleteVehicleAvailabilityMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteVehicleAvailabilityMutation,
    Types.DeleteVehicleAvailabilityMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteVehicleAvailabilityMutation,
    Types.DeleteVehicleAvailabilityMutationVariables
  >(DeleteVehicleAvailabilityDocument, options);
}
export type DeleteVehicleAvailabilityMutationHookResult = ReturnType<
  typeof useDeleteVehicleAvailabilityMutation
>;
export type DeleteVehicleAvailabilityMutationResult =
  Apollo.MutationResult<Types.DeleteVehicleAvailabilityMutation>;
export type DeleteVehicleAvailabilityMutationOptions =
  Apollo.BaseMutationOptions<
    Types.DeleteVehicleAvailabilityMutation,
    Types.DeleteVehicleAvailabilityMutationVariables
  >;
export const RevokeAffectedBookingsDocument = gql`
  mutation revokeAffectedBookings(
    $bookings: [VehicleRevokeAffectedBookingArgs!]!
  ) {
    revokeAffectedBookings(bookings: $bookings)
  }
`;
export type RevokeAffectedBookingsMutationFn = Apollo.MutationFunction<
  Types.RevokeAffectedBookingsMutation,
  Types.RevokeAffectedBookingsMutationVariables
>;

/**
 * __useRevokeAffectedBookingsMutation__
 *
 * To run a mutation, you first call `useRevokeAffectedBookingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeAffectedBookingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeAffectedBookingsMutation, { data, loading, error }] = useRevokeAffectedBookingsMutation({
 *   variables: {
 *      bookings: // value for 'bookings'
 *   },
 * });
 */
export function useRevokeAffectedBookingsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.RevokeAffectedBookingsMutation,
    Types.RevokeAffectedBookingsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.RevokeAffectedBookingsMutation,
    Types.RevokeAffectedBookingsMutationVariables
  >(RevokeAffectedBookingsDocument, options);
}
export type RevokeAffectedBookingsMutationHookResult = ReturnType<
  typeof useRevokeAffectedBookingsMutation
>;
export type RevokeAffectedBookingsMutationResult =
  Apollo.MutationResult<Types.RevokeAffectedBookingsMutation>;
export type RevokeAffectedBookingsMutationOptions = Apollo.BaseMutationOptions<
  Types.RevokeAffectedBookingsMutation,
  Types.RevokeAffectedBookingsMutationVariables
>;
export const VehicleAvailabilityDocument = gql`
  query vehicleAvailability(
    $vehicleId: String!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    vehicleAvailability(
      vehicleId: $vehicleId
      startDate: $startDate
      endDate: $endDate
    ) {
      availability {
        availabilityId
        createdAt
        comment
        isAvailable
        vehicleId
        startDateTime
        endDateTime
        type
        sharedVehicleGroupRelAvailability {
          id
          sharedVehicleGroup {
            id
            name
            isPrivate
          }
        }
      }
      bookings {
        id
        bookingHash
        startDate
        endDate
        effectiveStartDate
        effectiveEndDate
        user {
          id
          email
        }
        effectiveBookingCost
      }
    }
  }
`;

/**
 * __useVehicleAvailabilityQuery__
 *
 * To run a query within a React component, call `useVehicleAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleAvailabilityQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useVehicleAvailabilityQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.VehicleAvailabilityQuery,
    Types.VehicleAvailabilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.VehicleAvailabilityQuery,
    Types.VehicleAvailabilityQueryVariables
  >(VehicleAvailabilityDocument, options);
}
export function useVehicleAvailabilityLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.VehicleAvailabilityQuery,
    Types.VehicleAvailabilityQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.VehicleAvailabilityQuery,
    Types.VehicleAvailabilityQueryVariables
  >(VehicleAvailabilityDocument, options);
}
export type VehicleAvailabilityQueryHookResult = ReturnType<
  typeof useVehicleAvailabilityQuery
>;
export type VehicleAvailabilityLazyQueryHookResult = ReturnType<
  typeof useVehicleAvailabilityLazyQuery
>;
export type VehicleAvailabilityQueryResult = Apollo.QueryResult<
  Types.VehicleAvailabilityQuery,
  Types.VehicleAvailabilityQueryVariables
>;
export const GetAffectedBookingsDocument = gql`
  query getAffectedBookings(
    $vehicleId: String!
    $startDate: DateTime!
    $endDate: DateTime!
  ) {
    getAffectedBookings(
      vehicleId: $vehicleId
      startDate: $startDate
      endDate: $endDate
    ) {
      id
      bookingHash
      bookingStatus
      vehicle {
        id
        vin
      }
      startDate
      endDate
      effectiveStartDate
      effectiveEndDate
      user {
        id
        email
      }
    }
  }
`;

/**
 * __useGetAffectedBookingsQuery__
 *
 * To run a query within a React component, call `useGetAffectedBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAffectedBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAffectedBookingsQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useGetAffectedBookingsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetAffectedBookingsQuery,
    Types.GetAffectedBookingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetAffectedBookingsQuery,
    Types.GetAffectedBookingsQueryVariables
  >(GetAffectedBookingsDocument, options);
}
export function useGetAffectedBookingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetAffectedBookingsQuery,
    Types.GetAffectedBookingsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetAffectedBookingsQuery,
    Types.GetAffectedBookingsQueryVariables
  >(GetAffectedBookingsDocument, options);
}
export type GetAffectedBookingsQueryHookResult = ReturnType<
  typeof useGetAffectedBookingsQuery
>;
export type GetAffectedBookingsLazyQueryHookResult = ReturnType<
  typeof useGetAffectedBookingsLazyQuery
>;
export type GetAffectedBookingsQueryResult = Apollo.QueryResult<
  Types.GetAffectedBookingsQuery,
  Types.GetAffectedBookingsQueryVariables
>;
export const UpdateBookingQuestionDocument = gql`
  mutation updateBookingQuestion(
    $data: BookingQuestionUpdateDataInput!
    $userId: String!
    $bookingQuestionId: String!
    $vehicleId: String
  ) {
    updateBookingQuestion(
      userId: $userId
      bookingQuestionId: $bookingQuestionId
      vehicleId: $vehicleId
      data: $data
    )
  }
`;
export type UpdateBookingQuestionMutationFn = Apollo.MutationFunction<
  Types.UpdateBookingQuestionMutation,
  Types.UpdateBookingQuestionMutationVariables
>;

/**
 * __useUpdateBookingQuestionMutation__
 *
 * To run a mutation, you first call `useUpdateBookingQuestionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingQuestionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingQuestionMutation, { data, loading, error }] = useUpdateBookingQuestionMutation({
 *   variables: {
 *      data: // value for 'data'
 *      userId: // value for 'userId'
 *      bookingQuestionId: // value for 'bookingQuestionId'
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useUpdateBookingQuestionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UpdateBookingQuestionMutation,
    Types.UpdateBookingQuestionMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UpdateBookingQuestionMutation,
    Types.UpdateBookingQuestionMutationVariables
  >(UpdateBookingQuestionDocument, options);
}
export type UpdateBookingQuestionMutationHookResult = ReturnType<
  typeof useUpdateBookingQuestionMutation
>;
export type UpdateBookingQuestionMutationResult =
  Apollo.MutationResult<Types.UpdateBookingQuestionMutation>;
export type UpdateBookingQuestionMutationOptions = Apollo.BaseMutationOptions<
  Types.UpdateBookingQuestionMutation,
  Types.UpdateBookingQuestionMutationVariables
>;
export const BookingQuestionsDocument = gql`
  query bookingQuestions($userId: String!, $vehicleId: String) {
    bookingQuestions(userId: $userId, vehicleId: $vehicleId) {
      id
      kind
      type
      title
      parsedTitle
      orderIndex
      isEnabled
      isRequired
      defaultValue
      requiredValue
      userId
      vehicleId
      bookingQuestionId
    }
  }
`;

/**
 * __useBookingQuestionsQuery__
 *
 * To run a query within a React component, call `useBookingQuestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingQuestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingQuestionsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useBookingQuestionsQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.BookingQuestionsQuery,
    Types.BookingQuestionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.BookingQuestionsQuery,
    Types.BookingQuestionsQueryVariables
  >(BookingQuestionsDocument, options);
}
export function useBookingQuestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.BookingQuestionsQuery,
    Types.BookingQuestionsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.BookingQuestionsQuery,
    Types.BookingQuestionsQueryVariables
  >(BookingQuestionsDocument, options);
}
export type BookingQuestionsQueryHookResult = ReturnType<
  typeof useBookingQuestionsQuery
>;
export type BookingQuestionsLazyQueryHookResult = ReturnType<
  typeof useBookingQuestionsLazyQuery
>;
export type BookingQuestionsQueryResult = Apollo.QueryResult<
  Types.BookingQuestionsQuery,
  Types.BookingQuestionsQueryVariables
>;
export const UploadImagesDocument = gql`
  mutation uploadImages($files: [Upload!]!, $vehicleId: String) {
    uploadImages(files: $files, vehicleId: $vehicleId) {
      id
      name
      type
      createdAt
      orderIndex
    }
  }
`;
export type UploadImagesMutationFn = Apollo.MutationFunction<
  Types.UploadImagesMutation,
  Types.UploadImagesMutationVariables
>;

/**
 * __useUploadImagesMutation__
 *
 * To run a mutation, you first call `useUploadImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadImagesMutation, { data, loading, error }] = useUploadImagesMutation({
 *   variables: {
 *      files: // value for 'files'
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useUploadImagesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UploadImagesMutation,
    Types.UploadImagesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UploadImagesMutation,
    Types.UploadImagesMutationVariables
  >(UploadImagesDocument, options);
}
export type UploadImagesMutationHookResult = ReturnType<
  typeof useUploadImagesMutation
>;
export type UploadImagesMutationResult =
  Apollo.MutationResult<Types.UploadImagesMutation>;
export type UploadImagesMutationOptions = Apollo.BaseMutationOptions<
  Types.UploadImagesMutation,
  Types.UploadImagesMutationVariables
>;
export const DeleteImageDocument = gql`
  mutation deleteImage($vehiclePhotoId: String!, $vehicleId: String) {
    deleteImage(vehiclePhotoId: $vehiclePhotoId, vehicleId: $vehicleId)
  }
`;
export type DeleteImageMutationFn = Apollo.MutationFunction<
  Types.DeleteImageMutation,
  Types.DeleteImageMutationVariables
>;

/**
 * __useDeleteImageMutation__
 *
 * To run a mutation, you first call `useDeleteImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteImageMutation, { data, loading, error }] = useDeleteImageMutation({
 *   variables: {
 *      vehiclePhotoId: // value for 'vehiclePhotoId'
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useDeleteImageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.DeleteImageMutation,
    Types.DeleteImageMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.DeleteImageMutation,
    Types.DeleteImageMutationVariables
  >(DeleteImageDocument, options);
}
export type DeleteImageMutationHookResult = ReturnType<
  typeof useDeleteImageMutation
>;
export type DeleteImageMutationResult =
  Apollo.MutationResult<Types.DeleteImageMutation>;
export type DeleteImageMutationOptions = Apollo.BaseMutationOptions<
  Types.DeleteImageMutation,
  Types.DeleteImageMutationVariables
>;
export const UploadDamageImagesDocument = gql`
  mutation uploadDamageImages(
    $files: [Upload!]!
    $type: VehiclePhotoType!
    $vehicleId: String
  ) {
    uploadDamageImages(files: $files, type: $type, vehicleId: $vehicleId) {
      id
      name
      type
      createdAt
      orderIndex
    }
  }
`;
export type UploadDamageImagesMutationFn = Apollo.MutationFunction<
  Types.UploadDamageImagesMutation,
  Types.UploadDamageImagesMutationVariables
>;

/**
 * __useUploadDamageImagesMutation__
 *
 * To run a mutation, you first call `useUploadDamageImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDamageImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDamageImagesMutation, { data, loading, error }] = useUploadDamageImagesMutation({
 *   variables: {
 *      files: // value for 'files'
 *      type: // value for 'type'
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useUploadDamageImagesMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.UploadDamageImagesMutation,
    Types.UploadDamageImagesMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.UploadDamageImagesMutation,
    Types.UploadDamageImagesMutationVariables
  >(UploadDamageImagesDocument, options);
}
export type UploadDamageImagesMutationHookResult = ReturnType<
  typeof useUploadDamageImagesMutation
>;
export type UploadDamageImagesMutationResult =
  Apollo.MutationResult<Types.UploadDamageImagesMutation>;
export type UploadDamageImagesMutationOptions = Apollo.BaseMutationOptions<
  Types.UploadDamageImagesMutation,
  Types.UploadDamageImagesMutationVariables
>;
export const VehiclePhotosDocument = gql`
  query vehiclePhotos($where: VehiclePhotoWhereInput) {
    vehiclePhotos(where: $where) {
      id
      name
      type
      vehicleId
      createdAt
    }
  }
`;

/**
 * __useVehiclePhotosQuery__
 *
 * To run a query within a React component, call `useVehiclePhotosQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehiclePhotosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehiclePhotosQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVehiclePhotosQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.VehiclePhotosQuery,
    Types.VehiclePhotosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.VehiclePhotosQuery,
    Types.VehiclePhotosQueryVariables
  >(VehiclePhotosDocument, options);
}
export function useVehiclePhotosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.VehiclePhotosQuery,
    Types.VehiclePhotosQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.VehiclePhotosQuery,
    Types.VehiclePhotosQueryVariables
  >(VehiclePhotosDocument, options);
}
export type VehiclePhotosQueryHookResult = ReturnType<
  typeof useVehiclePhotosQuery
>;
export type VehiclePhotosLazyQueryHookResult = ReturnType<
  typeof useVehiclePhotosLazyQuery
>;
export type VehiclePhotosQueryResult = Apollo.QueryResult<
  Types.VehiclePhotosQuery,
  Types.VehiclePhotosQueryVariables
>;
export const VehicleLocationsDocument = gql`
  query vehicleLocations($where: VehicleLocationWhereInput) {
    vehicleLocations(where: $where) {
      id
      name
      isDefault
      radius
      latitude
      longitude
      vehicleId
    }
  }
`;

/**
 * __useVehicleLocationsQuery__
 *
 * To run a query within a React component, call `useVehicleLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleLocationsQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useVehicleLocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.VehicleLocationsQuery,
    Types.VehicleLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.VehicleLocationsQuery,
    Types.VehicleLocationsQueryVariables
  >(VehicleLocationsDocument, options);
}
export function useVehicleLocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.VehicleLocationsQuery,
    Types.VehicleLocationsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.VehicleLocationsQuery,
    Types.VehicleLocationsQueryVariables
  >(VehicleLocationsDocument, options);
}
export type VehicleLocationsQueryHookResult = ReturnType<
  typeof useVehicleLocationsQuery
>;
export type VehicleLocationsLazyQueryHookResult = ReturnType<
  typeof useVehicleLocationsLazyQuery
>;
export type VehicleLocationsQueryResult = Apollo.QueryResult<
  Types.VehicleLocationsQuery,
  Types.VehicleLocationsQueryVariables
>;
export const ChangeDeviceStatusDocument = gql`
  mutation changeDeviceStatus(
    $vehicleId: String!
    $property: String!
    $value: String!
  ) {
    changeDeviceStatus(
      vehicleId: $vehicleId
      property: $property
      value: $value
    )
  }
`;
export type ChangeDeviceStatusMutationFn = Apollo.MutationFunction<
  Types.ChangeDeviceStatusMutation,
  Types.ChangeDeviceStatusMutationVariables
>;

/**
 * __useChangeDeviceStatusMutation__
 *
 * To run a mutation, you first call `useChangeDeviceStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeDeviceStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeDeviceStatusMutation, { data, loading, error }] = useChangeDeviceStatusMutation({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *      property: // value for 'property'
 *      value: // value for 'value'
 *   },
 * });
 */
export function useChangeDeviceStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    Types.ChangeDeviceStatusMutation,
    Types.ChangeDeviceStatusMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    Types.ChangeDeviceStatusMutation,
    Types.ChangeDeviceStatusMutationVariables
  >(ChangeDeviceStatusDocument, options);
}
export type ChangeDeviceStatusMutationHookResult = ReturnType<
  typeof useChangeDeviceStatusMutation
>;
export type ChangeDeviceStatusMutationResult =
  Apollo.MutationResult<Types.ChangeDeviceStatusMutation>;
export type ChangeDeviceStatusMutationOptions = Apollo.BaseMutationOptions<
  Types.ChangeDeviceStatusMutation,
  Types.ChangeDeviceStatusMutationVariables
>;
export const GetVehicleStatusDocument = gql`
  query getVehicleStatus($vehicleId: String!) {
    getVehicleStatus(vehicleId: $vehicleId) {
      immobilizer
      ignition
      keyfob
      central_lock
      rfid_tag_state_1
      rfid_tag_state_2
      fuel_level
      latitude
      longitude
      mileage
      charging
      battery
    }
  }
`;

/**
 * __useGetVehicleStatusQuery__
 *
 * To run a query within a React component, call `useGetVehicleStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetVehicleStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetVehicleStatusQuery({
 *   variables: {
 *      vehicleId: // value for 'vehicleId'
 *   },
 * });
 */
export function useGetVehicleStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    Types.GetVehicleStatusQuery,
    Types.GetVehicleStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.GetVehicleStatusQuery,
    Types.GetVehicleStatusQueryVariables
  >(GetVehicleStatusDocument, options);
}
export function useGetVehicleStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.GetVehicleStatusQuery,
    Types.GetVehicleStatusQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.GetVehicleStatusQuery,
    Types.GetVehicleStatusQueryVariables
  >(GetVehicleStatusDocument, options);
}
export type GetVehicleStatusQueryHookResult = ReturnType<
  typeof useGetVehicleStatusQuery
>;
export type GetVehicleStatusLazyQueryHookResult = ReturnType<
  typeof useGetVehicleStatusLazyQuery
>;
export type GetVehicleStatusQueryResult = Apollo.QueryResult<
  Types.GetVehicleStatusQuery,
  Types.GetVehicleStatusQueryVariables
>;
export const VehicleTypesQueryDocument = gql`
  query vehicleTypesQuery {
    fuelTypes {
      id
      name
    }
    vehicleTypes {
      id
      name
    }
    vehicleKindTypes {
      id
      name
    }
    transmissionTypes {
      id
      name
    }
    driveTypes {
      id
      name
    }
    seatTypes {
      id
      name
    }
    childSeatTypes {
      id
      name
    }
    seatFrontTypes {
      id
      name
    }
    doorTypes {
      id
      name
    }
    energyEfficiencyTypes {
      id
      name
    }
    emissionCodeTypes {
      id
      name
    }
    consumptionTypes {
      id
      name
    }
    shieldColorTypes {
      id
      name
    }
    particularUseTypes {
      id
      name
    }
    brandTypes {
      id
      name
    }
    modelTypes {
      id
      name
    }
    bodyTypes {
      id
      name
    }
    colorTypes {
      id
      name
    }
    equipmentTypes {
      id
      name
    }
    unpublishReasonTypes {
      id
      name
    }
    revokeReasonTypes {
      id
      name
    }
    vehicleInsuranceTypes {
      id
      name
    }
  }
`;

/**
 * __useVehicleTypesQueryQuery__
 *
 * To run a query within a React component, call `useVehicleTypesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useVehicleTypesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVehicleTypesQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useVehicleTypesQueryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    Types.VehicleTypesQueryQuery,
    Types.VehicleTypesQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    Types.VehicleTypesQueryQuery,
    Types.VehicleTypesQueryQueryVariables
  >(VehicleTypesQueryDocument, options);
}
export function useVehicleTypesQueryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    Types.VehicleTypesQueryQuery,
    Types.VehicleTypesQueryQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    Types.VehicleTypesQueryQuery,
    Types.VehicleTypesQueryQueryVariables
  >(VehicleTypesQueryDocument, options);
}
export type VehicleTypesQueryQueryHookResult = ReturnType<
  typeof useVehicleTypesQueryQuery
>;
export type VehicleTypesQueryLazyQueryHookResult = ReturnType<
  typeof useVehicleTypesQueryLazyQuery
>;
export type VehicleTypesQueryQueryResult = Apollo.QueryResult<
  Types.VehicleTypesQueryQuery,
  Types.VehicleTypesQueryQueryVariables
>;
