import { FormGroup, Grid } from '@mui/material';
import { ReferenceInput, minLength, useGetIdentity } from 'react-admin';

import DateInput from '../../../common/DateInput';
import MultipleTypeInput from '../../../common/MultipleTypeInput';
import NumberInput from '../../../common/NumberInput';
import SelectInput from '../../../common/SelectInput';
import TextInput from '../../../common/TextInput';
import TypeInput from '../../../common/TypeInput';
import UserInput from '../../../common/UserInput';
import { UserRole } from '../../../@generated/schemas';
import { omit } from 'lodash';
import { useFormContext } from 'react-hook-form';

export const requiredFields = [
  'firstName',
  'lastName',
  'address',
  'houseNumber',
  'postCode',
  'city',
  'country',
  'insurance',
  'plateNumber',
  'shieldColorTypeId',
  'vehicleKindTypeId',
  'brandTypeId',
  'modelTypeId',
  'vin',
  'bodyTypeId',
  'colorTypeId',
  'seatTypeId',
  'seatFrontTypeId',
  'curbWeight',
  'rootNumber',
  'payloadWeight',
  'approvalType',
  'grossWeight',
  'capacity',
  'power',
  'commissioningAt',
  'departmentEmissionCode',
];

const VehicleBasicDetails = (props) => {
  const { options } = props;
  const { identity } = useGetIdentity();
  const { setValue, watch } = useFormContext();

  const onChangeVin = (event) => {
    setValue('vin', event.target.value.replace('o', '0').replace('O', '0'));
  };

  const onBlurRootNumber = (event) => {
    setValue(
      'rootNumber',
      event.target.value
        .replaceAll('.', '')
        .replace(/.{3}/g, '$&.')
        .replace(/\.$/, ''),
    );
  };

  const handleSelectCompany = async () => {
    setValue('firstName', null);
    setValue('lastName', null);
    setValue('address', null);
    setValue('houseNumber', null);
    setValue('city', null);
    setValue('country', null);
    setValue('birthdate', null);
    setValue('postCode', null);
    setValue('userId', null);
    setValue('user', null);
  };

  let userRequiredFields = requiredFields;
  const watchData = watch();
  if (watchData.companyId) {
    userRequiredFields = Object.values(
      omit(requiredFields, [
        'firstName',
        'lastName',
        'address',
        'houseNumber',
        'postCode',
        'city',
        'birthdate',
        'country',
      ]),
    );
  }

  return (
    <Grid container spacing={8}>
      <Grid item md={6}>
        <ReferenceInput reference="Company" source="companyId">
          <SelectInput
            fullWidth
            disabled={identity?.userRole !== UserRole.SuperAdmin}
            optionText="name"
            onChange={handleSelectCompany}
          />
        </ReferenceInput>
        <hr />
        <UserInput />
        {watchData.userId ? (
          <div>
            <FormGroup row>
              <TextInput
                source="lastName"
                requiredFieldsList={userRequiredFields}
                label="1 - Last name"
                style={{ marginRight: 8 }}
              />
              <TextInput
                source="firstName"
                requiredFieldsList={userRequiredFields}
                label="2 - First name"
              />
            </FormGroup>
            <FormGroup row>
              <TextInput
                requiredFieldsList={userRequiredFields}
                source="address"
                label="3 - Address"
                style={{ marginRight: 8 }}
              />
              <TextInput
                requiredFieldsList={userRequiredFields}
                source="houseNumber"
                label="4 - House number"
              />
            </FormGroup>
            <FormGroup row>
              <TextInput
                requiredFieldsList={userRequiredFields}
                source="postCode"
                label="5 - Postcode"
                style={{ marginRight: 8 }}
              />
              <TextInput
                requiredFieldsList={userRequiredFields}
                source="city"
                label="6 - City"
              />
            </FormGroup>
            <FormGroup row>
              <DateInput
                source="birthdate"
                label="7 - Birthdate"
                variant="outlined"
                requiredFieldsList={userRequiredFields}
                style={{ marginRight: 8, flex: 1 }}
              />
              <TextInput
                requiredFieldsList={userRequiredFields}
                source="country"
                label="8 - Country"
              />
            </FormGroup>
          </div>
        ) : null}
        <TextInput
          requiredFieldsList={requiredFields}
          source="insurance"
          multiline
          style={{ width: '100%' }}
          label="9 - Insurance"
          component="pre"
        />
        <TextInput
          source="cantonalNotes"
          multiline
          requiredFieldsList={requiredFields}
          style={{ width: '100%' }}
          label="13 - Cantonal notes"
          component="pre"
        />
        <TextInput
          source="decreesAuthority"
          multiline
          requiredFieldsList={requiredFields}
          style={{ width: '100%' }}
          label="14 - Decrees authority"
          component="pre"
        />
      </Grid>
      <Grid item md={6}>
        <FormGroup row>
          <TextInput
            requiredFieldsList={requiredFields}
            source="plateNumber"
            label="15 - Plate number"
            style={{ marginRight: 8 }}
          />
          <TypeInput
            requiredFieldsList={requiredFields}
            source="shieldColorTypeId"
            data={options?.shieldColorTypes}
            reference="ShieldColorType"
            label="16 - Plate color"
          />
        </FormGroup>
        <TypeInput
          source="particularUseTypeId"
          requiredFieldsList={requiredFields}
          data={options?.particularUseTypes}
          label="17 - Particular use"
          reference="ParticularUseType"
          style={{ width: '100%' }}
        />
        <FormGroup row>
          <TypeInput
            requiredFieldsList={requiredFields}
            source="vehicleKindTypeId"
            data={options?.vehicleKindTypes}
            reference="VehicleKindType"
            label="19 - Vehicle kind"
            style={{ marginRight: 8 }}
          />
          <TextInput
            requiredFieldsList={requiredFields}
            source="vehicleCode"
            label="20 - Code"
          />
        </FormGroup>
        <FormGroup row>
          <TypeInput
            requiredFieldsList={requiredFields}
            source="brandTypeId"
            data={options?.brandTypes}
            reference="BrandType"
            label="21 - Brand"
            style={{ marginRight: 8 }}
          />
          <TypeInput
            requiredFieldsList={requiredFields}
            source="modelTypeId"
            data={options?.modelTypes}
            reference="ModelType"
            label="21 - Model"
          />
        </FormGroup>
        <TextInput
          source="vin"
          label="23 - Vin"
          requiredFieldsList={requiredFields}
          onChange={onChangeVin}
          style={{ width: '100%' }}
        />
        <TypeInput
          requiredFieldsList={requiredFields}
          source="bodyTypeId"
          data={options?.bodyTypes}
          reference="BodyType"
          label="25 - Body type"
          style={{ width: '50%' }}
        />
        <TypeInput
          requiredFieldsList={requiredFields}
          source="colorTypeId"
          data={options?.colorTypes}
          reference="ColorType"
          label="26 - Color"
          style={{ width: '100%' }}
        />
        <FormGroup row>
          <FormGroup row style={{ marginRight: 8 }}>
            <TypeInput
              requiredFieldsList={requiredFields}
              source="seatTypeId"
              data={options?.seatTypes}
              reference="SeatType"
              label="27 - Seats"
              style={{ marginRight: 8 }}
            />
            <MultipleTypeInput
              requiredFieldsList={requiredFields}
              source="childSeatTypes_ids"
              data={options?.childSeatTypes}
              reference="ChildSeatType"
              label="Child seats"
              style={{ marginRight: 8 }}
            />
            <TypeInput
              requiredFieldsList={requiredFields}
              source="seatFrontTypeId"
              data={options?.seatFrontTypes}
              reference="SeatFrontType"
              label="27 - Front seats"
            />
          </FormGroup>
          <NumberInput
            requiredFieldsList={requiredFields}
            source="curbWeight"
            label="30 - Curb weight (kg)"
          />
        </FormGroup>
        <FormGroup row>
          <TextInput
            requiredFieldsList={requiredFields}
            source="rootNumber"
            label="18 - Root number"
            onBlur={onBlurRootNumber}
            style={{ marginRight: 8 }}
          />
          <NumberInput
            requiredFieldsList={requiredFields}
            source="payloadWeight"
            label="32 - Payload weight (kg)"
          />
        </FormGroup>
        <FormGroup row>
          <TextInput
            validate={minLength(1)}
            requiredFieldsList={requiredFields}
            source="approvalType"
            label="24 - Approval type nr."
            style={{ marginRight: 8 }}
          />
          <NumberInput
            requiredFieldsList={requiredFields}
            source="grossWeight"
            label="33 - Gross weight (kg)"
          />
        </FormGroup>
        <FormGroup row>
          <NumberInput
            requiredFieldsList={requiredFields}
            source="capacity"
            label="37 - Capacity (cm3)"
            style={{ marginRight: 8 }}
          />
          <NumberInput source="trainWeight" label="35 - Train weight (kg)" />
        </FormGroup>
        <FormGroup row>
          <NumberInput
            requiredFieldsList={requiredFields}
            source="power"
            label="76 - Power (kW)"
            style={{ marginRight: 8 }}
          />
          <NumberInput source="towingWeight" label="31 - Towing capacity" />
        </FormGroup>
        <FormGroup row>
          <NumberInput
            source="capacityPerKg"
            requiredFieldsList={requiredFields}
            label="78 - Power per kg (kW/kg)"
            style={{ marginRight: 8 }}
          />
          <NumberInput
            source="roofLoadWeight"
            requiredFieldsList={requiredFields}
            label="55 - Roof load weight (kg)"
          />
        </FormGroup>
        <FormGroup row>
          <DateInput
            requiredFieldsList={requiredFields}
            source="commissioningAt"
            label="36 - Commissioning date"
            variant="outlined"
            style={{ marginRight: 8, flex: 1 }}
          />
          <TextInput
            requiredFieldsList={requiredFields}
            source="departmentEmissionCode"
            label="72 - Department Emission Code"
          />
        </FormGroup>
        <DateInput
          requiredFieldsList={requiredFields}
          source="testedAt"
          label="39 - Tested at"
          variant="outlined"
          style={{ width: '50%' }}
        />
      </Grid>
    </Grid>
  );
};

export default VehicleBasicDetails;
