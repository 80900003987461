import { FormGroup } from '@mui/material';
import TextInput from '../../common/TextInput';
import { VFC } from 'react';

const InsuranceField: VFC<{
  source: string;
  type?: string;
  label?: string;
  validation?: any;
  requiredFields: string[];
}> = ({ source, label, type, requiredFields }) => {
  return (
    <FormGroup row>
      <TextInput
        source={source}
        label={label}
        type={type}
        requiredFieldsList={requiredFields}
        style={{ marginRight: 8 }}
      />
    </FormGroup>
  );
};

export default InsuranceField;
