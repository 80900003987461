import { FormControlLabel, Switch, Tooltip, Typography } from '@mui/material';
import {
  ReferenceArrayField,
  Tab,
  TabbedShowLayout,
  useDataProvider,
  useGetIdentity,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import {
  UserRole,
  Vehicle,
  VehicleConnectionType,
  VehiclePhotoType,
} from '../../@generated/schemas';
import VehicleAdditionalInfo, {
  requiredFields as additionalInfoRequiredFields,
} from './additionalInfo';
import VehicleBasicDetails, {
  requiredFields as basicInfoRequiredFields,
} from './basicInfo';
import {
  VehiclePrices,
  requiredFields as pricesRequiredFields,
} from './prices';
import { useEffect, useState } from 'react';

import { Booking } from './booking';
import CheckIcon from '@mui/icons-material/Check';
import { UnpublishReasonModal } from './unpublishReasonModal';
import { VehicleAvailability } from './availability';
import { VehicleDamageList } from '../vehicleDamages/vehicleDamages';
import { VehicleGallery } from './gallery';
import { VehicleInsurance } from './insurance';
import VehicleLocations from './locations';
import VehicleTroubleshoot from './troubleshoot';
import i18next from 'i18next';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { useTranslate } from '../../locales';

const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const TABS = {
  basicInfo: 'basicInfo',
  additionalInfo: 'additionalInfo',
  gallery: 'gallery',
  locations: 'locations',
  availability: 'availability',
  prices: 'prices',
  booking: 'booking',
  troubleshoot: 'troubleshoot',
  insurance: 'insurance',
  damages: 'damages',
};

export const activateToggleTooltip = (
  formData: Vehicle,
  skipDisabled = false,
): string | boolean => {
  // if (!formData?.isOwnerStripeOnboarded) {
  //   return 'User is not connected to stripe account';
  // }
  if (!formData?.userId && !formData?.companyId) {
    return i18next.t('admin.missingAssignedUserOrCompany') as string;
  }
  if (
    !formData?.qnr &&
    formData?.connectionType === VehicleConnectionType.Cloudboxx
  ) {
    return i18next.t('admin.cloudboxxShouldBeConnected') as string;
  }
  if (
    !formData?.cloudBoxxActivatedAt &&
    formData?.connectionType === VehicleConnectionType.Cloudboxx
  ) {
    return i18next.t('admin.cloudboxxShouldBeActivated') as string;
  }

  if (formData.ownInsurance) {
    if (
      !formData.vehicleInsuranceType?.name ||
      !formData.vehicleInsuranceType?.policyNumber ||
      !formData.vehicleInsuranceType?.policyHolder ||
      !formData.vehicleInsuranceType?.postalAddress
    ) {
      return i18next.t('admin.missingSetupForOwnInsurance') as string;
    }
    if (formData.vehicleInsuranceType?.vehicleInsuranceRuleSets?.length === 0) {
      return i18next.t('admin.missingAtLeastOneRulesetForOwnInsurance') as string;
    }
  }

  if (!formData.ownInsurance && !formData.vehicleInsuranceType) {
    return i18next.t('admin.missingInsurance') as string;
  }

  if (formData?.disabled && !skipDisabled) {
    return formData.unpublishReason || i18next.t('admin.vehicleIsNotVisible') as string;
  }

  return false;
};

export const VehicleTabs = (props) => {
  const record = useRecordContext<Vehicle>();
  const notify = useNotify();
  const translate = useTranslate();
  const { identity } = useGetIdentity();
  const refreshData = useRefresh();
  const dataProvider = useDataProvider();
  const { formState, getValues, watch } = useFormContext();

  const allProperties = watch() as Vehicle;
  const vehiclePhotos =
    allProperties.vehiclePhotos?.filter(
      (image) => image.type === VehiclePhotoType.Gallery,
    ) || [];

  const [isOpenedReason, setOpenReason] = useState(false);

  const handleClose = () => setOpenReason(false);

  const handleSaveReason = async (value) => {
    const vehicle = await dataProvider.update<Vehicle>('Vehicle', {
      id: record?.id,
      data: {
        unpublishReason: typeof value === 'string' ? value : '',
        disabled: !allProperties.disabled,
      },
      previousData: {},
    });
    handleClose();
    refreshData();

    if (allProperties.disabled === true && vehicle?.data?.disabled === false) {
      notify(translate('admin.vehicleIsPublished'), { type: 'success' });
    } else {
      notify(translate('admin.vehicleIsNotVisibleInTheMap'), { type: 'warning' });
    }
  };

  useEffect(() => {
    const values = getValues() as Vehicle;
    if (typeof activateToggleTooltip(values, true) === 'string') {
      notify(
        `${translate('admin.vehicleCannotBePublishedDueTo')}: ${activateToggleTooltip(values)}`,
        { type: 'warning' },
      );
    }
  }, [
    allProperties.isOwnerStripeOnboarded,
    allProperties.userId,
    allProperties.qnr,
    allProperties.cloudBoxxActivatedAt,
    allProperties.ownInsurance,
    allProperties.vehicleInsuranceType,
  ]);

  let basicInfoFinished = false;
  let additionalInfoFinished = false;
  const galleryFinished = vehiclePhotos.length >= 5;
  const locationsFinished = record?.vehicleLocation?.length > 0;
  const availabilityFinished = record?.availability?.length > 0;
  let pricesFinished = false;
  let bookingDurationFinished = false;
  let insuranceFinished = false;

  if (
    allProperties.ownInsurance
      ? (allProperties.vehicleInsuranceType?.name &&
        allProperties.vehicleInsuranceType?.policyNumber &&
        allProperties.vehicleInsuranceType?.policyHolder &&
        allProperties.vehicleInsuranceType?.postalAddress &&
        allProperties.vehicleInsuranceType?.vehicleInsuranceRuleSets?.length >
        0) ||
      false
      : true
  ) {
    insuranceFinished = true;
  }

  if (
    record?.preBookingInterval &&
    record?.postBookingInterval &&
    record?.earlyOpenInterval
  ) {
    bookingDurationFinished = true;
  }

  // checking basic info
  const foundBasicInfoError = Object.keys(formState.errors).find((field) =>
    basicInfoRequiredFields.includes(field),
  );
  const foundBasicInfoRequiredField = basicInfoRequiredFields.find(
    (field) => record && !record[field],
  );
  if (!foundBasicInfoError && !foundBasicInfoRequiredField && record?.id) {
    basicInfoFinished = true;
  }

  // checking additional info
  const foundAdditionalInfoError = Object.keys(formState.errors).find((field) =>
    additionalInfoRequiredFields.includes(field),
  );
  const foundAdditionalInfoRequiredField = additionalInfoRequiredFields.find(
    (field) => {
      if (record?.connectionType === VehicleConnectionType.Cloudboxx) {
        if (field === 'smartcarVehicle') {
          // skipped
          return false;
        }

        return !record[field];
      } else if (record?.connectionType === VehicleConnectionType.Smartcar) {
        if (field === 'qnr') {
          // skipped
          return false;
        }

        return !record[field];
      } else if (record) {
        return !record[field];
      }
    },
  );
  if (!foundAdditionalInfoError && !foundAdditionalInfoRequiredField) {
    additionalInfoFinished = true;
  }

  // checking prices
  const foundPricesError = Object.keys(formState.errors).find((field) =>
    pricesRequiredFields.includes(field),
  );
  const foundPricesRequiredField = pricesRequiredFields.find(
    (field) => record && !record[field],
  );
  if (!foundPricesError && !foundPricesRequiredField) {
    pricesFinished = true;
  }

  const renderLabel = (label: string, isFinished = false) => {
    if (isFinished) {
      return (
        <Row>
          {label}
          <CheckIcon style={{ color: 'green' }} />
        </Row>
      );
    }
    return label;
  };

  return (
    <div style={{ position: 'relative' }}>
      <span style={{ display: 'flex' }}>
        <Typography variant="h5">
          {props.mode === 'edit' ? translate('admin.editVehicle') : translate('admin.createVehicle')}
        </Typography>
        {props.mode === 'edit' && identity?.userRole === UserRole.SuperAdmin && (
          <Tooltip
            placement="top"
            title={
              allProperties.disabled
                ? typeof activateToggleTooltip(allProperties) === 'string'
                  ? activateToggleTooltip(allProperties)
                  : translate('admin.readyForSubmit')
                : translate('admin.alreadyInMap')
            }>
            <div style={{ marginLeft: 20 }}>
              <FormControlLabel
                disabled={
                  typeof activateToggleTooltip(allProperties, true) === 'string'
                }
                onChange={() =>
                  allProperties?.disabled
                    ? handleSaveReason(true)
                    : setOpenReason(true)
                }
                control={<Switch checked={!allProperties.disabled} />}
                label={
                  allProperties?.disabled ||
                    !allProperties?.user ||
                    !allProperties?.isOwnerStripeOnboarded ||
                    !allProperties?.qnr ||
                    !allProperties?.cloudBoxxActivatedAt ||
                    !insuranceFinished
                    ? translate('admin.hiddenInMap')
                    : translate('admin.publishedInMap')
                }
              />
            </div>
          </Tooltip>
        )}
        {record?.unpublishReason && (
          <div>
            {translate('admin.unpublishReason')}: <i>{record?.unpublishReason}</i>
          </div>
        )}
      </span>

      {props.mode === 'create' && (
        <VehicleBasicDetails options={props.options} />
      )}
      {props.mode === 'edit' && (
        <TabbedShowLayout>
          <Tab label={renderLabel(translate('admin.basicInfo'), basicInfoFinished)} tabIndex={0}>
            <VehicleBasicDetails options={props.options} />
          </Tab>
          <Tab
            label={renderLabel(translate('admin.additional'), additionalInfoFinished)}
            path={TABS.additionalInfo}>
            <VehicleAdditionalInfo options={props.options} />
          </Tab>
          <Tab
            label={renderLabel(translate('admin.gallery'), galleryFinished)}
            path={TABS.gallery}>
            <VehicleGallery />
          </Tab>
          <Tab
            label={renderLabel(translate('admin.locations'), locationsFinished)}
            path={TABS.locations}>
            <VehicleLocations />
          </Tab>
          <Tab
            label={renderLabel(translate('admin.availability'), availabilityFinished)}
            path={TABS.availability}>
            <VehicleAvailability />
          </Tab>
          <Tab label={renderLabel(translate('admin.prices'), pricesFinished)} path={TABS.prices}>
            <VehiclePrices />
          </Tab>
          <Tab
            label={renderLabel(translate('admin.bookingColumn'), bookingDurationFinished)}
            path={TABS.booking}>
            <Booking />
          </Tab>
          <Tab
            label={renderLabel(translate('admin.insurance'), insuranceFinished)}
            path={TABS.insurance}
            disabled={!allProperties.ownInsurance}>
            <VehicleInsurance />
          </Tab>
          <Tab label={renderLabel(translate('admin.troubleshoot'))} path={TABS.troubleshoot}>
            <VehicleTroubleshoot />
          </Tab>
          <Tab label={renderLabel(translate('admin.damages'))} path={TABS.damages}>
            <ReferenceArrayField reference="VehicleDamage">
              <VehicleDamageList
                filter={{
                  vehicleId: { equals: record.id },
                }}
              />
            </ReferenceArrayField>
          </Tab>
        </TabbedShowLayout>
      )}

      <UnpublishReasonModal
        isOpened={isOpenedReason}
        handleClose={handleClose}
        onSave={handleSaveReason}
        unpublishReasonTypes={props.options?.unpublishReasonTypes}
      />
    </div>
  );
};
