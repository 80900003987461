import {
  AutocompleteInput,
  Datagrid,
  DeleteWithConfirmButton,
  EditButton,
  Filter,
  FunctionField,
  List,
  ReferenceField,
  ReferenceInput,
  TextField,
} from 'react-admin';

import { BillingAccount as BillingAccountType } from '../../../@generated/schemas';
import { Chip } from '@mui/material';
import CustomPagination from '../../../CustomPagination';
import { ResourceView } from '@ra-data-prisma/dataprovider';
import { gql } from '@apollo/client';
import { useTranslate } from '../../../locales';

export const BillingAccountFilter = (props: any) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <ReferenceInput source="companyId" reference="Company" alwaysOn>
        <AutocompleteInput
          optionText="name"
          label={translate('admin.company')}
        />
      </ReferenceInput>
    </Filter>
  );
};

export const BillingAccountList = (props: any) => {
  const translate = useTranslate();
  return (
    <List
      {...props}
      title={translate('admin.chargingStationBillingAccounts')}
      filter={{
        deletedAt: { equals: null },
      }}
      filters={<BillingAccountFilter />}
      sort={{ field: 'name', order: 'asc' }}
      pagination={<CustomPagination />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="name" label={translate('admin.name')} />
        <TextField
          source="departmentName"
          label={translate('admin.departmentName')}
        />
        <FunctionField
          source="chargingStationRFIDs"
          label={translate('admin.countConnectedRfids')}
          render={(row: BillingAccountType) => (
            <Chip label={row.chargingStationRFIDs?.length || 0} />
          )}
        />
        <ReferenceField
          reference="Company"
          source="companyId"
          label={translate('admin.company')}>
          <TextField source="name" />
        </ReferenceField>
        <EditButton />
        <DeleteWithConfirmButton />
      </Datagrid>
    </List>
  );
};

export const BillingAccount: ResourceView = {
  resource: 'BillingAccount',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment BillingAccountFragment on BillingAccount {
          company {
            id
            name
          }
          chargingStationRFIDs {
            id
            tag
            description
          }
        }
      `,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment BillingAccountFragment on BillingAccount {
          company {
            id
            name
          }
        }
      `,
    },
  },
};
