import { Create, SimpleForm } from 'react-admin';

import { ChargingStationForm } from './form';
import { transform } from '../../../common/TranslationInput';

export const ChargingStationCreate = () => {
  return (
    <Create transform={(data) => transform(data, 'name')}>
      <SimpleForm>
        <ChargingStationForm />
      </SimpleForm>
    </Create>
  );
};
