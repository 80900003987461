import {
  AutocompleteInput,
  Datagrid,
  DateField,
  FunctionField,
  Link,
  Filter as RAFilter,
  List as RAList,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';

import CustomPagination from '../../CustomPagination';
import { ResourceView } from '@ra-data-prisma/dataprovider';
import { gql } from '@apollo/client';
import mapEnumSelectInput from 'src/libs/mapEnumSelectInput';

export const Filter = (props: any) => (
  <RAFilter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
    <SelectInput
      label="Type"
      source="type"
      choices={mapEnumSelectInput(['EMAIL', 'SMS', 'NOTIFICATION'])}
    />
    <ReferenceInput reference="User" source="userId">
      <AutocompleteInput label="email" source="email" optionText="email" alwaysOn />
    </ReferenceInput>
  </RAFilter>
);

export const MessagesList = (props: any) => {
  return (
    <RAList
      {...props}
      filters={<Filter />}
      undoable="false"
      perPage={50}
      pagination={<CustomPagination />}>
      <Datagrid>
        <FunctionField
          source="user.email"
          render={(record) =>
            record?.user?.id && (
              <Link to={`/User/${record.user.id}`}>{record.user.email}</Link>
            )
          }
        />
        <TextField source="subject" />
        <TextField source="body" />
        <TextField source="type" />
        <DateField source="createdAt" showTime />
      </Datagrid>
    </RAList>
  );
};

export const Message: ResourceView = {
  resource: 'Message',
  fragment: {
    many: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment MessageFragment on Message {
          user {
            email
            id
          }
        }
      `,
    },
  },
};
