import {
  MenuItemLink,
  MenuProps,
  useGetIdentity,
  useSidebarState,
} from 'react-admin';

import ApartmentIcon from '@mui/icons-material/Apartment';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import Box from '@mui/material/Box';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import EvStationIcon from '@mui/icons-material/EvStation';
import GroupIcon from '@mui/icons-material/Group';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import SendIcon from '@mui/icons-material/Send';
import SubMenu from './CustomSubMenu';
import SummarizeIcon from '@mui/icons-material/Summarize';
import TableChartIcon from '@mui/icons-material/TableChart';
import { UserRole } from './@generated/schemas';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { useState } from 'react';
import { useTranslate } from './locales';

type MenuName =
  | 'menuLists'
  | 'chargingStationMenuList'
  | 'gateControllersMenuList';

const CustomMenu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuLists: false,
    chargingStationMenuList: false,
    gateControllersMenuList: false,
  });
  const [open] = useSidebarState();
  const { identity } = useGetIdentity();
  const translate = useTranslate();

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  if (identity?.presetPassword) {
    return (
      <Box
        sx={{
          width: open ? 200 : 50,
          marginTop: 1,
          marginBottom: 1,
          transition: (theme) =>
            theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }}>
        <MenuItemLink
          to="/change-my-password"
          primaryText={translate('admin.changePassword')}
          dense={dense}
        />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}>
      {[UserRole.SuperAdmin, UserRole.CompanyAdmin].includes(
        identity?.userRole,
      ) && (
        <MenuItemLink
          to="/User"
          primaryText={translate('admin.users')}
          leftIcon={<AssignmentIndIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin, UserRole.CompanyAdmin].includes(
        identity?.userRole,
      ) && (
        <MenuItemLink
          to="/PxlVisionManualVerification"
          primaryText={translate('admin.userVerifications')}
          leftIcon={<VerifiedUserIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin].includes(identity?.userRole) && (
        <MenuItemLink
          to="/Company"
          primaryText={translate('admin.companies')}
          leftIcon={<ApartmentIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin].includes(identity?.userRole) && (
        <MenuItemLink
          to="/CompanyVehicleGroup"
          primaryText={translate('admin.companyVehicleGroup')}
          leftIcon={<WorkspacesIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin, UserRole.CompanyAdmin].includes(
        identity?.userRole,
      ) && (
        <MenuItemLink
          to="/Vehicle"
          primaryText={translate('admin.vehicles')}
          leftIcon={<DirectionsCarIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin, UserRole.CompanyAdmin].includes(
        identity?.userRole,
      ) && (
        <MenuItemLink
          to="/VehicleReport"
          primaryText={translate('admin.vehicleReports')}
          leftIcon={<SummarizeIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin].includes(identity?.userRole) && (
        <SubMenu
          handleToggle={() => handleToggle('gateControllersMenuList')}
          isOpen={state.gateControllersMenuList}
          name={translate('admin.gateControllers')}
          icon={<PlaylistAddIcon />}
          dense={dense}>
          <MenuItemLink
            to="/GateController"
            primaryText={translate('admin.gates')}
            leftIcon={<MeetingRoomIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/GateControllerTransaction"
            primaryText={translate('admin.transactions')}
            leftIcon={<ReceiptLongIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/GateControllerType"
            primaryText={translate('admin.types')}
            leftIcon={<MergeTypeIcon />}
            dense={dense}
          />
        </SubMenu>
      )}
      {[UserRole.SuperAdmin].includes(identity?.userRole) && (
        <SubMenu
          handleToggle={() => handleToggle('chargingStationMenuList')}
          isOpen={state.chargingStationMenuList}
          name={translate('admin.chargingStations')}
          icon={<PlaylistAddIcon />}
          dense={dense}>
          <MenuItemLink
            to="/ChargingStationTransactionReport"
            primaryText={translate('admin.transactionReports')}
            leftIcon={<AssessmentIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/ChargingStationTransaction"
            primaryText={translate('admin.transactions')}
            leftIcon={<ReceiptLongIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/BillingAccount"
            primaryText={translate('admin.billingAccounts')}
            leftIcon={<GroupIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/ChargingStationCluster"
            primaryText={translate('admin.eveClusters')}
            leftIcon={<WorkspacesIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/ChargingStation"
            primaryText={translate('admin.eves')}
            leftIcon={<EvStationIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/ChargingStationRfidInvisia"
            primaryText={translate('admin.rfidsInvisia')}
            leftIcon={<CenterFocusStrongIcon />}
            dense={dense}
          />
        </SubMenu>
      )}
      {[UserRole.SuperAdmin, UserRole.CompanyAdmin].includes(
        identity?.userRole,
      ) && (
        <MenuItemLink
          to="/Booking"
          primaryText={translate('admin.bookings')}
          leftIcon={<BookmarksIcon />}
          dense={dense}
        />
      )}
      {([UserRole.SuperAdmin].includes(identity?.userRole) ||
        identity?.company?.companyVehicleGroups?.[0]?.enabledBookingScheduler ===
          true) && (
        <MenuItemLink
          to="/Booking/scheduler"
          primaryText={translate('admin.bookingScheduler')}
          leftIcon={<TableChartIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin, UserRole.CompanyAdmin].includes(identity?.userRole) && (
        <MenuItemLink
          to="/Booking/create"
          primaryText={translate('admin.createBooking')}
          leftIcon={<BookmarkAddIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin].includes(identity?.userRole) && (
        <MenuItemLink
          to="/VehicleInsuranceType"
          primaryText={translate('admin.insurances')}
          leftIcon={<SafetyCheckIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin, UserRole.CompanyAdmin].includes(
        identity?.userRole,
      ) && (
        <MenuItemLink
          to="/SharedVehicleGroup"
          primaryText={translate('admin.sharedGroup')}
          leftIcon={<WorkspacesIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin].includes(identity?.userRole) && (
        <MenuItemLink
          to="/VehicleDamage"
          primaryText={translate('admin.vehicleDamages')}
          leftIcon={<ErrorOutlineIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin].includes(identity?.userRole) && (
        <MenuItemLink
          to="/VehicleCloudBoxxLog"
          primaryText={translate('admin.cloudboxxLogs')}
          leftIcon={<ListAltIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin].includes(identity?.userRole) && (
        <MenuItemLink
          to="/Message"
          primaryText={translate('admin.messages')}
          leftIcon={<SendIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin].includes(identity?.userRole) && (
        <SubMenu
          handleToggle={() => handleToggle('menuLists')}
          isOpen={state.menuLists}
          name={translate('admin.listManagement')}
          icon={<PlaylistAddIcon />}
          dense={dense}>
          <MenuItemLink
            to="/ColorType"
            primaryText={translate('admin.colorList')}
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/BrandType"
            primaryText={translate('admin.brandList')}
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/ConsumptionType"
            primaryText={translate('admin.consumptionList')}
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/DoorType"
            primaryText={translate('admin.doorList')}
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/DriveType"
            primaryText={translate('admin.driverList')}
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/EmissionCodeType"
            primaryText={translate('admin.emissionCodeList')}
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/EnergyEfficiencyType"
            primaryText={translate('admin.energyEfficiencyList')}
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/EquipmentType"
            primaryText={translate('admin.equipmentList')}
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/FuelType"
            primaryText={translate('admin.fuelList')}
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/ModelType"
            primaryText={translate('admin.modelList')}
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/ParticularUseType"
            primaryText={translate('admin.particularUseList')}
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/ShieldColorType"
            primaryText={translate('admin.plateColorList')}
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/SeatType"
            primaryText={translate('admin.seatList')}
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/ChildSeatType"
            primaryText={translate('admin.childSeatList')}
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/SeatFrontType"
            primaryText={translate('admin.seatFrontList')}
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/TransmissionType"
            primaryText={translate('admin.transmissionList')}
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/VehicleType"
            primaryText={translate('admin.vehicleList')}
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/VehicleKindType"
            primaryText={translate('admin.vehicleKindList')}
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/RevokeReasonType"
            primaryText={translate('admin.revokeReasonList')}
            leftIcon={null}
            dense={dense}
          />
        </SubMenu>
      )}
    </Box >
  );
};

export default CustomMenu;
