import {
  AutocompleteInput,
  Datagrid,
  DateField,
  FunctionField,
  Link,
  Filter as RAFilter,
  List as RAList,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
} from 'react-admin';

import CustomPagination from '../../CustomPagination';
import { ResourceView } from '@ra-data-prisma/dataprovider';
import { gql } from '@apollo/client';
import mapEnumSelectInput from 'src/libs/mapEnumSelectInput';
import { useTranslate } from '../../locales';

export const Filter = (props: any) => {
  const translate = useTranslate();
  return (
    <RAFilter {...props}>
      <TextInput label={translate('admin.search')} source="q" alwaysOn />
      <SelectInput
        label={translate('admin.type')}
        source="type"
        choices={mapEnumSelectInput(['EMAIL', 'SMS', 'NOTIFICATION'])}
      />
      <ReferenceInput
        reference="User"
        source="userId"
        label={translate('admin.emailField')}>
        <AutocompleteInput
          label={translate('admin.emailField')}
          source="email"
          optionText="email"
          alwaysOn
        />
      </ReferenceInput>
    </RAFilter>
  );
};

export const MessagesList = (props: any) => {
  const translate = useTranslate();
  return (
    <RAList
      {...props}
      filters={<Filter />}
      undoable="false"
      perPage={50}
      pagination={<CustomPagination />}>
      <Datagrid>
        <FunctionField
          source="user.email"
          label={translate('admin.emailField')}
          render={(record) =>
            record?.user?.id && (
              <Link to={`/User/${record.user.id}`}>{record.user.email}</Link>
            )
          }
        />
        <TextField source="subject" label={translate('admin.subject')} />
        <TextField source="body" label={translate('admin.body')} />
        <TextField source="type" label={translate('admin.type')} />
        <DateField
          source="createdAt"
          label={translate('admin.createdAt')}
          showTime
        />
      </Datagrid>
    </RAList>
  );
};

export const Message: ResourceView = {
  resource: 'Message',
  fragment: {
    many: {
      type: 'document',
      mode: 'extend',
      doc: gql`
        fragment MessageFragment on Message {
          user {
            email
            id
          }
        }
      `,
    },
  },
};
