import * as TranslateInput from '../../common/TranslationInput';

import {
  Create,
  DeleteButton,
  Edit,
  SaveButton,
  SimpleForm,
  Toolbar,
  useCreate,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
  useUpdate,
} from 'react-admin';
import { VehicleTabs, activateToggleTooltip } from './vehicleTabs';

import { Vehicle } from '../../@generated/schemas';
import { omit } from 'lodash';
import sanitizeEmptyValues from '../../libs/sanitizeEmptyValues';
import { useCallback } from 'react';
import { useTranslate } from '../../locales';
import { useVehicleTypesQueryQuery } from '../../@generated/hooks';

const getTransformedData = (data) => {
  data.vehicleEquipment =
    data['@@ra-many-to-many/Vehicle/VehicleEquipment/EquipmentType'] || [];

  if (!data.ownInsurance) {
    data.ownInsurancePolicyNumber = null;
    data.vehicleInsurance = null;
  }

  data.childSeatTypes = data.childSeatTypes_ids || [];

  if (window.location.href.includes('/additionalInfo')) {
    return TranslateInput.transform(data, 'rules');
  } else {
    return data;
  }
};

export const VehicleAdd = (props) => {
  const notify = useNotify();
  const [saveAdd] = useCreate();
  const redirect = useRedirect();
  const translate = useTranslate();
  const { data } = useVehicleTypesQueryQuery({ fetchPolicy: 'no-cache' });

  const onSubmit = useCallback(async (values) => {
    try {
      const vehicle = await saveAdd(
        'Vehicle',
        {
          data: sanitizeEmptyValues(getTransformedData(values)),
        },
        {
          returnPromise: true,
        },
      );
      notify(translate('admin.successfullySaved'), { type: 'success' });
      redirect(`/Vehicle/${vehicle.id}`);
    } catch (error) {
      notify(error.message, { type: 'error' });
    }
  }, []);

  return (
    <Create
      {...props}
      mutationMode="pessimistic"
      onFailure={(error) => notify(error.message, { type: 'error' })}>
      <SimpleForm
        onSubmit={onSubmit}
        defaultValues={{
          preBookingInterval: 15,
          postBookingInterval: 15,
          earlyOpenInterval: 10,
          disabled: true,
          vehicleLocation: [],
        }}>
        <VehicleTabs {...props} options={data} mode="create" />
      </SimpleForm>
    </Create>
  );
};

const EditTitle = () => {
  const translate = useTranslate();
  const record = useRecordContext<Vehicle>();
  return (
    <div>
      {translate('admin.vehicle')}: {record?.brandType?.name} {record?.modelType?.name} (
      {record?.vin})
    </div>
  );
};

const VehicleEditToolbar = () => (
  <Toolbar>
    <SaveButton alwaysEnable />
    <DeleteButton style={{ marginLeft: 'auto' }} />
  </Toolbar>
);

export const VehicleEdit = (props) => {
  const translate = useTranslate();
  const notify = useNotify();
  const [saveEdit] = useUpdate();
  const refresh = useRefresh();
  const dataProvider = useDataProvider();
  const { data } = useVehicleTypesQueryQuery({ fetchPolicy: 'no-cache' });

  const onSubmit = useCallback(async (values: Vehicle) => {
    try {
      const previousData = await dataProvider.getOne('Vehicle', {
        id: values.id,
      });
      const previousLocations = await dataProvider.getList('VehicleLocation', {
        pagination: {
          page: 1,
          perPage: 100,
        },
        sort: {
          field: '',
          order: 'ASC',
        },
        filter: {
          vehicleId: values.id,
        },
      });

      const editData = {
        id: values.id,
        data: {
          ...sanitizeEmptyValues(getTransformedData(values)),
          vehicleInsuranceType:
            values.vehicleInsuranceType && values.ownInsurance
              ? values.vehicleInsuranceType
              : values.vehicleInsuranceTypeId,
        },
        previousData: {
          ...sanitizeEmptyValues(getTransformedData(previousData.data)),
          vehicleLocation: previousLocations.data.map((item) =>
            omit(sanitizeEmptyValues(item), ['booking']),
          ),
          vehicleInsuranceType: previousData.data.vehicleInsuranceType,
        },
      };

      await saveEdit(
        'Vehicle',
        editData,
        {
          returnPromise: true,
        },
      );

      const vehicleStatus =
        typeof activateToggleTooltip(values, true) === 'string'
          ? activateToggleTooltip(values)
          : 'Ready for submit';

      notify(`${translate('admin.successfullySaved')}: ${vehicleStatus}`, { type: 'success' });
      refresh();
    } catch (error) {
      notify(error.message, { type: 'error' });
    }
  }, []);

  return (
    <Edit
      {...props}
      redirect={false}
      mutationMode="pessimistic"
      title={<EditTitle />}>
      <SimpleForm onSubmit={onSubmit} toolbar={<VehicleEditToolbar />}>
        <VehicleTabs {...props} options={data} mode="edit" />
      </SimpleForm>
    </Edit>
  );
};
