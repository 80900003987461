import {
  Datagrid,
  EditButton,
  FunctionField,
  List,
  TextField,
} from 'react-admin';

import CustomPagination from '../../../CustomPagination';

export const ChargingStationList = () => {
  return (
    <List
      title="EVE's"
      hasCreate
      filter={{
        deletedAt: {
          equals: null,
        },
      }}
      pagination={<CustomPagination />}>
      <Datagrid>
        <FunctionField
          source="name"
          render={(row) =>
            JSON.parse(row.name || '{}')?.en ||
            JSON.parse(row.name || '{}')?.de ||
            JSON.parse(row.name || '{}')?.it ||
            JSON.parse(row.name || '{}')?.fr
          }
        />
        <TextField source="evesId" />
        <TextField source="status" />
        <TextField label="Company" source="chargingStationCluster.company.name" />
        <EditButton />
      </Datagrid>
    </List>
  );
};
