import { SelectInput, useCreate } from 'react-admin';
import { useEffect, useState } from 'react';

import { getValidate } from '../libs/checkRequiredField';
import { useFormContext } from 'react-hook-form';

const TypeInput = (props) => {
  const { getFieldState, setValue, getValues } = useFormContext();
  const field = getFieldState(props.source);
  const [create] = useCreate();

  const errorMessage = props.error || field?.error?.message || '';

  const [list, setList] = useState<{ id: string; name: string }[]>([]);

  useEffect(() => {
    setList(
      (props.data || []).map((item) => ({
        id: item.id,
        name: item.name,
      })),
    );
  }, [props.data]);

  const validate = getValidate(props);

  return (
    <SelectInput
      {...props}
      validate={validate}
      source={props.source}
      choices={list || []}
      variant="outlined"
      label={props.label}
      style={{ flex: 1, marginBottom: 0, ...props.style }}
      onCreate={async () => {
        const newValue = prompt('Enter a new value');

        if (!newValue) {
          setValue(props.source, getValues(props.source) || null);

          return false;
        }
        const newRecord = await create(
          props.reference,
          {
            data: {
              name: newValue,
            },
          },
          {
            returnPromise: true,
          },
        );

        const item = {
          id: newRecord?.id,
          name: newRecord?.name,
        };

        setList([...list, item]);

        setValue(props.source, item.id);

        return item;
      }}
      error={!!errorMessage}
      helperText={errorMessage}
    />
  );
};

export default TypeInput;
