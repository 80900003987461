import {
  Datagrid,
  FunctionField,
  ReferenceArrayField,
  ReferenceInput,
  SelectInput,
  SimpleShowLayout,
} from 'react-admin';
import { FormGroup, Grid } from '@mui/material';

import TranslateInput from 'src/common/TranslationInput';

export const ChargingStationClusterForm = () => {
  return (
    <Grid container spacing={8}>
      <Grid item md={6}>
        <FormGroup row>
          <TranslateInput source="name" hideName />
        </FormGroup>
        <ReferenceInput reference="Company" source="company_id">
          <SelectInput optionText="name" fullWidth />
        </ReferenceInput>
      </Grid>
      <Grid item md={6}>
        <SimpleShowLayout>
          <ReferenceArrayField
            label="EVE's"
            source="chargingStations_ids"
            reference="ChargingStation">
            <Datagrid style={{ width: '100%' }} bulkActionButtons={false}>
              <FunctionField
                source="name"
                label="EVE"
                render={(row) =>
                  JSON.parse(row.name || '{}')?.en ||
                  JSON.parse(row.name || '{}')?.de ||
                  JSON.parse(row.name || '{}')?.it ||
                  JSON.parse(row.name || '{}')?.fr
                }
              />
            </Datagrid>
          </ReferenceArrayField>
        </SimpleShowLayout>
      </Grid>
    </Grid>
  );
};
