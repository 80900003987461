import { MenuItemLink, MenuProps, useGetIdentity, usePermissions, useSidebarState } from 'react-admin';

import ApartmentIcon from '@mui/icons-material/Apartment';
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarksIcon from '@mui/icons-material/Bookmarks';
import Box from '@mui/material/Box';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import EvStationIcon from '@mui/icons-material/EvStation';
import GroupIcon from '@mui/icons-material/Group';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MeetingRoomIcon from '@mui/icons-material/MeetingRoom';
import MergeTypeIcon from '@mui/icons-material/MergeType';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import SafetyCheckIcon from '@mui/icons-material/SafetyCheck';
import SendIcon from '@mui/icons-material/Send';
import SubMenu from './CustomSubMenu';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { UserRole } from './@generated/schemas';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { useState } from 'react';

type MenuName =
  | 'menuLists'
  | 'chargingStationMenuList'
  | 'gateControllersMenuList';

const CustomMenu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuLists: false,
    chargingStationMenuList: false,
    gateControllersMenuList: false,
  });
  const [open] = useSidebarState();
  const { identity } = useGetIdentity();

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  if (identity?.presetPassword) {
    return (
      <Box
        sx={{
          width: open ? 200 : 50,
          marginTop: 1,
          marginBottom: 1,
          transition: (theme) =>
            theme.transitions.create('width', {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }}>
        <MenuItemLink
          to="/change-my-password"
          primaryText="Change password"
          dense={dense}
        />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}>

      {[UserRole.SuperAdmin, UserRole.CompanyAdmin].includes(identity?.userRole) && (
        <MenuItemLink
          to="/User"
          primaryText="Users"
          leftIcon={<AssignmentIndIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin, UserRole.CompanyAdmin].includes(identity?.userRole) && (
        <MenuItemLink
          to="/PxlVisionManualVerification"
          primaryText="User verifications"
          leftIcon={<VerifiedUserIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin].includes(identity?.userRole) && (
        <MenuItemLink
          to="/Company"
          primaryText="Companies"
          leftIcon={<ApartmentIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin].includes(identity?.userRole) && (
        <MenuItemLink
          to="/CompanyVehicleGroup"
          primaryText="Company vehicle group"
          leftIcon={<WorkspacesIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin, UserRole.CompanyAdmin].includes(identity?.userRole) && (
        <MenuItemLink
          to="/Vehicle"
          primaryText="Vehicles"
          leftIcon={<DirectionsCarIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin, UserRole.CompanyAdmin].includes(identity?.userRole) && (
        <MenuItemLink
          to="/VehicleReport"
          primaryText="Vehicle reports"
          leftIcon={<SummarizeIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin].includes(identity?.userRole) && (
        <SubMenu
          handleToggle={() => handleToggle('gateControllersMenuList')}
          isOpen={state.gateControllersMenuList}
          name="Gate controllers"
          icon={<PlaylistAddIcon />}
          dense={dense}>
          <MenuItemLink
            to="/GateController"
            primaryText="Gates"
            leftIcon={<MeetingRoomIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/GateControllerTransaction"
            primaryText="Transactions"
            leftIcon={<ReceiptLongIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/GateControllerType"
            primaryText="Types"
            leftIcon={<MergeTypeIcon />}
            dense={dense}
          />
        </SubMenu>
      )}
      {[UserRole.SuperAdmin].includes(identity?.userRole) && (
        <SubMenu
          handleToggle={() => handleToggle('chargingStationMenuList')}
          isOpen={state.chargingStationMenuList}
          name="Charging stations"
          icon={<PlaylistAddIcon />}
          dense={dense}>
          <MenuItemLink
            to="/ChargingStationTransactionReport"
            primaryText="Reports"
            leftIcon={<AssessmentIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/ChargingStationTransaction"
            primaryText="Transactions"
            leftIcon={<ReceiptLongIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/BillingAccount"
            primaryText="Billing accounts"
            leftIcon={<GroupIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/ChargingStationCluster"
            primaryText="EVE clusters"
            leftIcon={<WorkspacesIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/ChargingStation"
            primaryText="EVEs"
            leftIcon={<EvStationIcon />}
            dense={dense}
          />
          <MenuItemLink
            to="/ChargingStationRfidInvisia"
            primaryText="RFIDs invisia"
            leftIcon={<CenterFocusStrongIcon />}
            dense={dense}
          />
        </SubMenu>
      )}
      {[UserRole.SuperAdmin, UserRole.CompanyAdmin].includes(identity?.userRole) && (
        <MenuItemLink
          to="/Booking"
          primaryText="Bookings"
          leftIcon={<BookmarksIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin, UserRole.CompanyAdmin].includes(identity?.userRole) && (
        <MenuItemLink
          to="/Booking/scheduler"
          primaryText="Booking scheduler"
          leftIcon={<BookmarksIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin, UserRole.CompanyAdmin].includes(identity?.userRole) && (
        <MenuItemLink
          to="/Booking/create"
          primaryText="Create booking"
          leftIcon={<BookmarkAddIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin].includes(identity?.userRole) && (
        <MenuItemLink
          to="/VehicleInsuranceType"
          primaryText="Insurances"
          leftIcon={<SafetyCheckIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin, UserRole.CompanyAdmin].includes(identity?.userRole) && (
        <MenuItemLink
          to="/SharedVehicleGroup"
          primaryText="Shared group"
          leftIcon={<WorkspacesIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin].includes(identity?.userRole) && (
        <MenuItemLink
          to="/VehicleDamage"
          primaryText="VehicleDamages"
          leftIcon={<ErrorOutlineIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin].includes(identity?.userRole) && (
        <MenuItemLink
          to="/VehicleCloudBoxxLog"
          primaryText="CloudBoxx logs"
          leftIcon={<ListAltIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin].includes(identity?.userRole) && (
        <MenuItemLink
          to="/Message"
          primaryText="Messages"
          leftIcon={<SendIcon />}
          dense={dense}
        />
      )}
      {[UserRole.SuperAdmin].includes(identity?.userRole) && (
        <SubMenu
          handleToggle={() => handleToggle('menuLists')}
          isOpen={state.menuLists}
          name="List management"
          icon={<PlaylistAddIcon />}
          dense={dense}>
          <MenuItemLink
            to="/ColorType"
            primaryText="Color list"
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/BrandType"
            primaryText="Brand list"
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/ConsumptionType"
            primaryText="Consumption list"
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/DoorType"
            primaryText="Door list"
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/DriveType"
            primaryText="Drive list"
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/EmissionCodeType"
            primaryText="Emission code list"
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/EnergyEfficiencyType"
            primaryText="Energy efficiency list"
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/EquipmentType"
            primaryText="Equipment list"
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/FuelType"
            primaryText="Fuel list"
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/ModelType"
            primaryText="Model list"
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/ParticularUseType"
            primaryText="Particular use list"
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/ShieldColorType"
            primaryText="Plate color list"
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/SeatType"
            primaryText="Seat list"
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/ChildSeatType"
            primaryText="Child seat list"
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/SeatFrontType"
            primaryText="Seat front list"
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/TransmissionType"
            primaryText="Transmission list"
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/VehicleType"
            primaryText="Vehicle list"
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/VehicleKindType"
            primaryText="Vehicle kind list"
            leftIcon={null}
            dense={dense}
          />
          <MenuItemLink
            to="/RevokeReasonType"
            primaryText="Revoke reason list"
            leftIcon={null}
            dense={dense}
          />
        </SubMenu>
      )}
    </Box >
  );
};

export default CustomMenu;
