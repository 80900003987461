import {
  TextInput as AdminTextInput,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  EditButton,
  Filter,
  FunctionField,
  List as RAList,
  ReferenceInput,
  TextField,
  useGetIdentity,
  useRecordContext,
  useRefresh,
  useUpdate,
} from 'react-admin';

import { Button } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CustomPagination from '../../CustomPagination';
import DateInput from '../../common/DateInput';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import SelectInput from '../../common/SelectInput';
import { UserRole } from '../../@generated/schemas';
import { convertEnumFilterItems } from '../../libs/convertEnumFilterItems';
import { getTokenSalt } from '../../libs/tokenHash';
import { useTranslate } from '../../locales';

export const UserFilter = (props: any) => {
  const { identity } = useGetIdentity();
  const translate = useTranslate();

  return (
    <Filter {...props}>
      <AdminTextInput
        label={translate('admin.search')}
        source="q"
        key="search"
        alwaysOn
      />
      <SelectInput
        choices={[
          { id: 0, name: 'Level 0' },
          { id: 1, name: 'Level 1' },
          { id: 2, name: 'Level 2' },
        ]}
        source="userLevel"
        label={translate('admin.userLevel')}
      />
      <AdminTextInput
        source="email"
        key="email"
        label={translate('admin.emailField')}
      />
      <AdminTextInput source="firstName" label={translate('admin.firstName')} />
      <AdminTextInput source="lastName" label={translate('admin.lastName')} />
      <DateInput source="phone" label={translate('admin.phone')} />
      {[UserRole.SuperAdmin].includes(identity?.userRole) && (
        <ReferenceInput
          reference="Company"
          source="companyId"
          label={translate('admin.company')}>
          <SelectInput
            optionText="name"
            alwaysOn
            label={translate('admin.company')}
          />
        </ReferenceInput>
      )}
      <SelectInput
        source="userRole"
        label={translate('admin.userRole')}
        choices={convertEnumFilterItems(UserRole)}
      />
      <DateInput
        source="createdAt_gte"
        label={translate('admin.createdAtFrom')}
      />
      <DateInput
        source="createdAt_lte"
        label={translate('admin.createdAtTo')}
      />
    </Filter>
  );
};

const UnlockAccount = () => {
  const record = useRecordContext();
  const refreshData = useRefresh();
  const [update, { isLoading }] = useUpdate();
  const { identity } = useGetIdentity();
  const translate = useTranslate();

  const handleClick = async (data) => {
    await update('User', { id: record.id, data });
    refreshData();
  };

  if (!record.loginBlockedAt) {
    return (
      <Button
        variant="text"
        onClick={() =>
          handleClick({
            loginBlockedAt: new Date(),
            failedLoginAttempts: 0,
            tokenSalt: getTokenSalt(),
          })
        }
        color="primary"
        disabled={isLoading || identity?.id === record.id}
        startIcon={<LockIcon />}>
        {translate('admin.lock')}
      </Button>
    );
  }

  return (
    <Button
      variant="text"
      onClick={() =>
        handleClick({
          loginBlockedAt: {
            set: null,
          },
          failedLoginAttempts: 0,
        })
      }
      color="primary"
      disabled={isLoading || identity?.id === record.id}
      startIcon={<LockOpenIcon />}>
      {translate('admin.unlock')}
    </Button>
  );
};

const CustomDeleteButton = () => {
  const record = useRecordContext();
  const { identity } = useGetIdentity();

  if (identity?.id === record?.id) return null;

  return <DeleteWithConfirmButton mutationMode="pessimistic" record={record} />;
};

export const UsersList = (props: any) => {
  const { identity } = useGetIdentity();
  const translate = useTranslate();
  const defaultFilter: any = {
    deletedAt: { equals: null },
  };

  if (identity?.userRole && identity?.userRole !== UserRole.SuperAdmin) {
    defaultFilter.company = {
      equals: identity.companyId,
    };
  }

  return (
    <RAList
      {...props}
      filters={<UserFilter />}
      filter={defaultFilter}
      pagination={<CustomPagination />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="email" label={translate('admin.emailField')} />
        <FunctionField
          label={translate('admin.company')}
          render={(row) => row?.company?.name || 'Drivemycar'}
        />
        <TextField source="firstName" label={translate('admin.firstName')} />
        <TextField source="lastName" label={translate('admin.lastName')} />
        <TextField source="phone" label={translate('admin.phone')} />
        <FunctionField
          label={translate('admin.userLevel')}
          render={(row) => {
            if (row.userLevel.level < 50) {
              return '0';
            }
            if (row.userLevel.level < 100) {
              return '1';
            }
            return (
              <div style={{ display: 'flex' }}>
                <span style={{ marginTop: 2, marginRight: 8 }}>2</span>{' '}
                <CheckIcon style={{ color: 'green' }} />
              </div>
            );
          }}
        />
        <DateField
          source="loginBlockedAt"
          label={translate('admin.loginBlocked')}
          locales="de-DE"
          showTime
        />
        <DateField
          source="createdAt"
          label={translate('admin.createdAt')}
          locales="de-DE"
          showTime
        />
        <TextField source="userRole" label={translate('admin.userRole')} />
        <EditButton />
        <UnlockAccount />
        <CustomDeleteButton />
      </Datagrid>
    </RAList>
  );
};
