import {
  AutocompleteInput,
  BooleanInput,
  Create,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput,
  required,
  useGetIdentity,
} from 'react-admin';
import {
  Box,
  FormGroup,
  Grid,
  ImageList,
  ImageListItem,
  Button as MuiButton,
  TextField as MuiTextField,
} from '@mui/material';
import { GoogleMap, Marker, StandaloneSearchBox } from '@react-google-maps/api';
import {
  VehicleDamageKind,
  VehicleDamageTime,
  VehicleDamageType,
} from '../../@generated/schemas';
import { useRef, useState } from 'react';

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { DefaultLocation } from '../vehicles/locations';
import getStoragePath from '../../libs/getStoragePath';
import styled from 'styled-components';
import { useFormContext } from 'react-hook-form';
import { useTranslate } from '../../locales';
import { useUploadDamageImagesMutation } from '../../@generated/hooks';

const GoogleMapsInputWrapper = styled.div`
  position: absolute;
  top: 65px;
  left: 10px;
  z-index: 100;
`;

export const VehicleDamageCreate = (props: any) => {
  const { identity } = useGetIdentity();
  const translate = useTranslate();
  const [upload] = useUploadDamageImagesMutation();
  const uploadInputRef = useRef<any>();

  return (
    <Create {...props}>
      <SimpleForm
        defaultValues={{
          user: identity?.id,
          latitude: DefaultLocation.latitude,
          longitude: DefaultLocation.longitude,
          vehiclePhotos: [],
        }}>
        <Box style={{ width: '100%' }}>
          <Grid container spacing={8}>
            <Grid item md={6}>
              <FormGroup row>
                <SelectInput
                  source="kind"
                  label={translate('admin.kind')}
                  required
                  style={{ flex: 1, marginRight: 8 }}
                  choices={[
                    {
                      id: VehicleDamageKind.Crack,
                      name: translate('admin.crack'),
                    },
                    {
                      id: VehicleDamageKind.Dent,
                      name: translate('admin.dent'),
                    },
                    {
                      id: VehicleDamageKind.MissingPiece,
                      name: translate('admin.missingPiece'),
                    },
                    {
                      id: VehicleDamageKind.Puncture,
                      name: translate('admin.puncture'),
                    },
                    {
                      id: VehicleDamageKind.Scratch,
                      name: translate('admin.scratch'),
                    },
                    {
                      id: VehicleDamageKind.Others,
                      name: translate('admin.others'),
                    },
                  ]}
                />
                <SelectInput
                  source="time"
                  label={translate('admin.time')}
                  required
                  style={{ flex: 1 }}
                  choices={[
                    {
                      id: VehicleDamageTime.NewReport,
                      name: translate('admin.newReport'),
                    },
                    {
                      id: VehicleDamageTime.NotReported,
                      name: translate('admin.notReported'),
                    },
                  ]}
                />
              </FormGroup>
              <SelectInput
                source="type"
                label={translate('admin.type')}
                required
                style={{ width: '100%' }}
                choices={[
                  {
                    id: VehicleDamageType.DamageFrontLeft,
                    name: translate('admin.damageFrontLeft'),
                  },
                  {
                    id: VehicleDamageType.DamageFrontRight,
                    name: translate('admin.damageFrontRight'),
                  },
                  {
                    id: VehicleDamageType.DamageBackLeft,
                    name: translate('admin.damageBackLeft'),
                  },
                  {
                    id: VehicleDamageType.DamageBackRight,
                    name: translate('admin.damageBackRight'),
                  },
                ]}
              />
              <FormGroup>
                <TextInput source="description" label={translate('admin.description')} validate={[required()]} />
                <ReferenceInput
                  source="user"
                  reference="User">
                  <AutocompleteInput
                    label={translate('admin.reportedBy')}
                    optionText={(choice: any) => choice?.email || translate('admin.clear')}
                  />
                </ReferenceInput>
                <ReferenceInput
                  source="vehicle"
                  resource="id"
                  reference="Vehicle">
                  <AutocompleteInput
                    validate={[required()]}
                    label={translate('admin.vehicle')}
                    optionText={(choice: any) =>
                      `${choice?.vin || ''} (${choice?.plateNumber}, ${
                        choice?.brandType?.name || ''
                      }, ${choice?.modelType?.name || ''})` || translate('admin.clear')
                    }
                  />
                </ReferenceInput>
              </FormGroup>
              <FormGroup row>
                <ReferenceInput source="booking" reference="Booking">
                  <AutocompleteInput
                    label={translate('admin.bookingColumn')}
                    optionText={(choice: any) => choice?.bookingHash || translate('admin.clear')}
                  />
                </ReferenceInput>
              </FormGroup>
              <BooleanInput
                source="isPublic"
                label={translate('admin.isPublic')}
                style={{ flex: 1 }}
              />
              <BooleanInput
                source="isResolved"
                label={translate('admin.isResolved')}
                style={{ flex: 1 }}
              />
              <FormDataConsumer>
                {() => {
                  const { setValue, watch } = useFormContext();
                  const type = watch('type');
                  const vehiclePhotos = watch('vehiclePhotos');
                  const onChange = async (event) => {
                    const files = event.target.files;

                    try {
                      const response = await upload({
                        variables: {
                          files,
                          type,
                        },
                      });

                      if (response?.data?.uploadDamageImages.length > 0) {
                        setValue('vehiclePhotos', [
                          ...vehiclePhotos,
                          ...response?.data?.uploadDamageImages,
                        ]);
                      }

                      uploadInputRef.current.value = null;
                    } catch (error) {
                      console.log(error);
                    }
                  };

                  return (
                    <label htmlFor="upload-file">
                      <input
                        accept="image/*"
                        ref={uploadInputRef}
                        id="upload-file"
                        onChange={onChange}
                        multiple
                        type="file"
                        hidden
                      />
                      <MuiButton
                        disabled={!type}
                        onClick={() => uploadInputRef?.current?.click()}>
                        {translate('admin.addNewImage')}
                        <CloudUploadIcon style={{ marginLeft: 8 }} />
                      </MuiButton>
                    </label>
                  );
                }}
              </FormDataConsumer>
              <FormDataConsumer>
                {() => {
                  const { watch } = useFormContext();
                  const vehiclePhotos = watch('vehiclePhotos') || [];
                  return (
                    <ImageList>
                      {vehiclePhotos.map((item) => (
                        <ImageListItem key={item.id}>
                          <img
                            src={getStoragePath(`damages/${item.name}`)}
                            srcSet={getStoragePath(`damages/${item.name}`)}
                            alt={item.name}
                            loading="lazy"
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  );
                }}
              </FormDataConsumer>
            </Grid>
            <Grid item md={6}>
              <FormDataConsumer>
                {() => {
                  const { setValue, watch } = useFormContext();
                  const [searchBoxRef, setSearchBoxRef] =
                    useState<google.maps.places.SearchBox>();
                  const geocoder = new google.maps.Geocoder();

                  const latitude =
                    watch('latitude') || DefaultLocation.latitude;
                  const longitude =
                    watch('longitude') || DefaultLocation.longitude;

                  const handleSearchboxChanged = () => {
                    const places = searchBoxRef.getPlaces();
                    const firstPlace = places[0];

                    const lat = firstPlace.geometry.location.lat();
                    const lng = firstPlace.geometry.location.lng();

                    setValue('latitude', lat);
                    setValue('longitude', lng);
                  };
                  return (
                    <>
                      <GoogleMap
                        mapContainerStyle={{
                          width: '100%',
                          height: 400,
                        }}
                        center={{
                          lat: latitude,
                          lng: longitude,
                        }}
                        zoom={16}>
                        <GoogleMapsInputWrapper>
                          <StandaloneSearchBox
                            onLoad={setSearchBoxRef}
                            onPlacesChanged={handleSearchboxChanged}>
                            <MuiTextField
                              variant="outlined"
                              onKeyUp={(event) => {
                                if (event.key === 'Enter') {
                                  event.stopPropagation();
                                }
                              }}
                              placeholder={translate('admin.searchAddress')}
                              style={{ background: 'white' }}
                            />
                          </StandaloneSearchBox>
                        </GoogleMapsInputWrapper>
                        <Marker
                          draggable
                          onDragEnd={(e) => {
                            geocoder
                              .geocode({ location: e.latLng })
                              .then((response) => {
                                if (response.results[0]) {
                                  setValue('latitude', e.latLng.lat());
                                  setValue('longitude', e.latLng.lng());
                                }
                              });
                          }}
                          position={{
                            lat: latitude,
                            lng: longitude,
                          }}
                        />
                      </GoogleMap>
                      <TextInput
                        source="latitude"
                        label={translate('admin.latitude')}
                        disabled
                        validate={[required()]}
                      />
                      <TextInput
                        source="longitude"
                        label={translate('admin.longitude')}
                        disabled
                        validate={[required()]}
                      />
                    </>
                  );
                }}
              </FormDataConsumer>
            </Grid>
          </Grid>
        </Box>
      </SimpleForm>
    </Create>
  );
};
