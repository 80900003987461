import { useEffect, useState } from 'react';

import { CREATE } from 'react-admin';
import { Options } from '@ra-data-prisma/dataprovider';
import buildDataProvider from './buildDataProvider';
import withAuthProvider from './authProvider';

export const appProvidersFactory = () => {
  return (options: Options) => {
    const [dataProvider, setDataProvider] = useState<any>();
    const authProvider = withAuthProvider();

    useEffect(() => {
      buildDataProvider(options)
        .then((p) => {
          setDataProvider(() => p);
        })
        .catch((e) => {
          console.error(e);
        });
    }, []);

    const getDataProvider = (type: string, resource: string, params: any) => {
      if (!dataProvider) return false;

      // console.log(type, resource, params);

      // if (type === GET_LIST && resource === 'Booking') {
      //   if (params.filter?.bookingStatus) {
      //     switch (params.filter?.bookingStatus) {
      //       case BookingStatus.Revoked:
      //         params.filter.revokedAt = {
      //           not: null
      //         };
      //         break;
      //       case BookingStatus.Cancelled:
      //         params.filter.noShowAt = {
      //           equals: null
      //         };
      //         params.filter.cancelDate = {
      //           not: null
      //         };
      //         params.filter.cancellationFees = {
      //           gte: 0
      //         };
      //         break;
      //       case BookingStatus.NoShow:
      //         params.filter.noShowAt = {
      //           not: null
      //         };
      //         // TODO calculate 60 minutes
      //         params.filter.effectiveStartDate = {
      //           equals: null
      //         };
      //         break;
      //       case BookingStatus.WaitingForUser:
      //         params.filter.userConfirmedAt = {
      //           equals: null
      //         };
      //         params.filter.cancelDate = {
      //           equals: null
      //         };
      //         params.filter.revokedAt = {
      //           equals: null
      //         };
      //         break;
      //       case BookingStatus.Late:
      //         params.filter.effectiveStartDate = {
      //           not: null
      //         };
      //         params.filter.effectiveEndDate = {
      //           equals: null
      //         };
      //         // TODO IS LATE
      //         break;
      //       case BookingStatus.OwnBooking:
      //         // params.filter.userId = {
      //         //   not: null
      //         // };
      //         // params.filter.vehicle = {
      //         //   is: {

      //         //   }
      //         // };
      //         // TODO IS LATE
      //         break;
      //     }

      //     delete params.filter.bookingStatus;
      //   }

        // return dataProvider(type, resource, params);
      // }

      if (type === CREATE && resource === 'VehicleEquipment') {
        return dataProvider(type, resource, {
          data: {
            equipmentType: params.data.equipmentTypeId,
            vehicle: params.data.vehicleId,
          },
        });
      }

      return dataProvider(type, resource, params);
    };

    return {
      dataProvider: dataProvider && getDataProvider,
      authProvider,
    };
  };
};
