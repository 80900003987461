import { ResourceView } from '@ra-data-prisma/dataprovider';
import { gql } from '@apollo/client';

const fragmentGateController = gql`
  fragment GateControllerFragment on GateController {
    gateType {
      id
      name
    }
    company {
      name
    }
  }
`;

export const GateController: ResourceView = {
  resource: 'GateController',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: fragmentGateController,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: fragmentGateController,
    },
  },
};

const fragmentGateControllerRuleSet = gql`
  fragment GateControllerRuleSetFragment on GateControllerRuleSet {
    rules {
      id
      property
      fromCompareOperator
      fromValue
      toCompareOperator
      toValue
    }
  }
`;

export const GateControllerRuleSet: ResourceView = {
  resource: 'GateControllerRuleSet',
  fragment: {
    one: {
      type: 'document',
      mode: 'extend',
      doc: fragmentGateControllerRuleSet,
    },
    many: {
      type: 'document',
      mode: 'extend',
      doc: fragmentGateControllerRuleSet,
    },
  },
};
