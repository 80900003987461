import {
  AutocompleteInput,
  BooleanField,
  Datagrid,
  DateField,
  DeleteWithConfirmButton,
  EditButton,
  Filter,
  List,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  useDataProvider,
  useNotify,
  useRecordContext,
  useRefresh,
} from 'react-admin';
import { Button, Tooltip } from '@mui/material';
import {
  VehicleConnectionType,
  Vehicle as VehicleType,
} from '../../../@generated/schemas';

import CustomPagination from '../../../CustomPagination';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { ResourceView } from '@ra-data-prisma/dataprovider';
import { UnpublishReasonModal } from '../unpublishReasonModal';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { activateToggleTooltip } from '../vehicleTabs';
import { convertEnumFilterItems } from '../../../libs/convertEnumFilterItems';
import { gql } from '@apollo/client';
import { useState } from 'react';
import { useTranslate } from '../../../locales';
import { useVehicleTypesQueryQuery } from '../../../@generated/hooks';

export const VehicleFilter = (props: any) => {
  const translate = useTranslate();
  return (
    <Filter {...props}>
      <TextInput label={translate('admin.search')} source="q" alwaysOn />
      <TextInput source="vin" label={translate('admin.vin')} />
      <TextInput source="plateNumber" label={translate('admin.plateNumber')} />
      <SelectInput
        source="connectionType"
        label={translate('admin.connectionType')}
        choices={convertEnumFilterItems(VehicleConnectionType)}
      />
      <ReferenceInput
        source="brandTypeId"
        reference="BrandType"
        label={translate('admin.brandType')}>
        <AutocompleteInput
          translateChoice={false}
          optionText={(choice: any) => choice?.id && choice.name}
        />
      </ReferenceInput>
      <ReferenceInput
        source="modelTypeId"
        reference="ModelType"
        label={translate('admin.modelType')}>
        <AutocompleteInput
          optionText={(choice: any) => choice?.id && choice.name}
        />
      </ReferenceInput>
    </Filter>
  );
};

const DisableVehicle = (props) => {
  const translate = useTranslate();
  const record = useRecordContext<VehicleType>();

  const refreshData = useRefresh();
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [isOpenedReason, setOpenReason] = useState(false);

  const handleClose = () => setOpenReason(false);

  const handleSaveReason = async (value) => {
    const vehicle = await dataProvider.update<VehicleType>('Vehicle', {
      id: record?.id,
      data: {
        unpublishReason: typeof value === 'string' ? value : '',
        disabled: !record.disabled,
      },
      previousData: {},
    });
    handleClose();
    refreshData();

    if (record.disabled === true && vehicle?.data?.disabled === false) {
      notify(translate('admin.vehicleIsPublished'), { type: 'success' });
    } else {
      notify(translate('admin.vehicleIsUnPublished'), { type: 'warning' });
    }
  };

  const ButtonContent = (
    <span>
      <Button
        variant="text"
        disabled={
          typeof activateToggleTooltip(record, true) === 'string' ? true : false
        }
        onClick={() =>
          record?.disabled ? handleSaveReason(true) : setOpenReason(true)
        }
        color="primary"
        startIcon={
          record?.disabled ? <RemoveRedEyeIcon /> : <VisibilityOffIcon />
        }>
        {record?.disabled
          ? translate('admin.release')
          : translate('admin.hide')}
      </Button>

      <UnpublishReasonModal
        isOpened={isOpenedReason}
        handleClose={handleClose}
        onSave={handleSaveReason}
        unpublishReasonTypes={props.unpublishReasonTypes}
      />
    </span>
  );

  if (typeof activateToggleTooltip(record, true) === 'string') {
    return (
      <Tooltip placement="top" title={activateToggleTooltip(record)}>
        {ButtonContent}
      </Tooltip>
    );
  }

  return ButtonContent;
};

export const VehicleList = (props: any) => {
  const { data, refetch } = useVehicleTypesQueryQuery({
    fetchPolicy: 'no-cache',
  });
  const translate = useTranslate();

  const defaultFilter = {
    deletedAt: { equals: null },
    // user: identity?.userRole && identity?.userRole !== UserRole.SuperAdmin ? {
    //   is: {
    //     companyId: identity.companyId
    //   }
    // } : {},
    // companyId: identity?.userRole && identity?.userRole !== UserRole.SuperAdmin ? identity.companyId : {}
  };

  return (
    <List
      {...props}
      filter={defaultFilter}
      undoable={false}
      filters={<VehicleFilter />}
      pagination={<CustomPagination />}>
      <Datagrid bulkActionButtons={false}>
        <TextField source="vin" label={translate('admin.vin')} />
        <TextField
          source="plateNumber"
          label={translate('admin.plateNumber')}
        />
        <TextField source="brandType.name" label={translate('admin.brand')} />
        <TextField source="modelType.name" label={translate('admin.model')} />
        <TextField
          source="connectionType"
          label={translate('admin.connectionType')}
        />
        <DateField
          source="createdAt"
          showTime
          label={translate('admin.createdAt')}
        />
        <BooleanField source="disabled" label={translate('admin.disabled')} />
        <TextField
          source="unpublishReason"
          label={translate('admin.unpublishReason')}
        />
        <EditButton />
        <DeleteWithConfirmButton mutationMode="pessimistic" />
        <DisableVehicle
          unpublishReasonTypes={data?.unpublishReasonTypes}
          handleRefetchTypes={refetch}
        />
      </Datagrid>
    </List>
  );
};

export const Vehicle: ResourceView = {
  resource: 'Vehicle',
  fragment: {
    one: {
      type: 'document',
      mode: 'replace',
      doc: gql`
        fragment VehicleFragment on Vehicle {
          id
          vin
          rootNumber
          qnr
          convadisVehicle
          convadisOrganisation
          convadisAppKey
          enabledRfidCard1
          enabledRfidCard2
          smartcarConnectAuthUrl
          smartcarVehicle
          firstName
          lastName
          address
          houseNumber
          postCode
          city
          country
          birthdate
          cantonalNotes
          insurance
          decreesAuthority
          departmentEmissionCode
          capacity
          power
          curbWeight
          capacityPerKg
          payloadWeight
          grossWeight
          trainWeight
          towingWeight
          roofLoadWeight
          catalogPrice
          enabledSmsAlerts
          initialOdometer
          valueOfAccessories
          vehicleCode
          bookingInformation
          plateNumber
          trunkDimension
          approvalType
          fuelPassword
          rules
          currentLong
          currentLat
          disabled
          priceHour
          priceDay
          priceKm
          priceWeek
          includedKmHour
          includedKmDay
          includedKmWeek
          preBookingInterval
          postBookingInterval
          earlyOpenInterval
          userId
          vehicleDamageId
          unpublishReason
          createdAt
          deletedAt
          commissioningAt
          testedAt
          cloudBoxxActivatedAt
          fuelTypeId
          doorTypeId
          vehicleTypeId
          vehicleKindTypeId
          transmissionTypeId
          driveTypeId
          seatTypeId
          seatFrontTypeId
          energyEfficiencyTypeId
          emissionCodeTypeId
          consumptionTypeId
          shieldColorTypeId
          particularUseTypeId
          brandTypeId
          modelTypeId
          bodyTypeId
          colorTypeId
          ownership
          ownInsurance
          connectionType
          vehicleInsuranceTypeId
          vehicleInsuranceType {
            id
            name
            content
            contentLinks
            isPrivate
            policyNumber
            policyHolder
            postalAddress
            billingAddress
            phoneNumber
            emailAddress
            vehicleInsuranceRuleSets {
              id
              ownership
              deductiblePerCollision
              deductiblePerPartialCover
              amount
              updatedAt
              vehicleInsuranceRules {
                id
                property
                fromCompareOperator
                fromValue
                toCompareOperator
                toValue
              }
            }
          }
          brandType {
            id
            name
          }
          user {
            id
          }
          vehicleDamage {
            id
          }
          fuelType {
            id
          }
          doorType {
            id
          }
          vehicleType {
            id
          }
          vehicleKindType {
            id
          }
          transmissionType {
            id
          }
          driveType {
            id
          }
          seatType {
            id
          }
          childSeatTypes {
            id
          }
          seatFrontType {
            id
          }
          energyEfficiencyType {
            id
          }
          emissionCodeType {
            id
          }
          consumptionType {
            id
          }
          shieldColorType {
            id
          }
          particularUseType {
            id
          }
          bodyType {
            id
          }
          colorType {
            id
          }
          vehiclePhotos {
            id
            type
          }
          modelType {
            id
            name
          }
          vehicleLocation {
            id
            latitude
            isDefault
            radius
            longitude
            name
            vehicleId
          }
          vehicleEquipment {
            id
          }
          availability {
            id
          }
          bookingQuestion {
            id
          }
          defaultVehicleImage
          isOwnerStripeOnboarded
          sharedVehicleGroupRelVehicle {
            id
            sharedVehicleGroup {
              id
              name
              deletedAt
              discount
              isPrivate
              ownerBehavior
            }
          }
          companyId
          company {
            id
            name
            sharedVehicleGroupRelCompany {
              sharedVehicleGroup {
                id
                name
                discount
                deletedAt
                ownerBehavior
                isPrivate
              }
            }
            defaultSharedVehicleGroup {
              id
              name
              discount
              deletedAt
              ownerBehavior
              isPrivate
            }
          }
        }
      `,
    },
    many: {
      type: 'document',
      mode: 'replace',
      doc: gql`
        fragment VehicleFragment on Vehicle {
          id
          vin
          qnr
          convadisVehicle
          convadisOrganisation
          convadisAppKey
          enabledRfidCard1
          enabledRfidCard2
          plateNumber
          cloudBoxxActivatedAt
          smartcarVehicle
          createdAt
          disabled
          unpublishReason
          brandType {
            id
            name
          }
          userId
          modelType {
            id
            name
          }
          connectionType
          vehicleLocation {
            id
            latitude
            isDefault
            longitude
            radius
            vehicleId
            name
          }
          isOwnerStripeOnboarded
          ownInsurance
          companyId
          vehicleInsuranceTypeId
          vehicleInsuranceType {
            id
            name
            content
            contentLinks
            isPrivate
            policyNumber
            policyHolder
            postalAddress
            billingAddress
            phoneNumber
            emailAddress
            vehicleInsuranceRuleSets {
              id
              ownership
              deductiblePerCollision
              deductiblePerPartialCover
              amount
              updatedAt
              vehicleInsuranceRules {
                id
                property
                fromCompareOperator
                fromValue
                toCompareOperator
                toValue
              }
            }
          }
        }
      `,
    },
  },
};
